import React from "react";

import SVGIcons from '../images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import './mission.scss';


function Mission() {

  const basicDetails = [
    {
      icon: SVGIcons.HoursIcon(),
      title: translateThis('Hours'),
      info: translateThis('timing530 to 6'),
    },
    {
      icon: SVGIcons.AcceptedAgesIcon(),
      title: translateThis('Accepted ages'),
      info: translateThis('Babies from 6 weeks onwards up to 12years old'),
    },
    {
      icon: SVGIcons.DaysIcon(),
      title: translateThis('Days'),
      info: translateThis('Open from Monday to Friday'),
    },
    {
      icon: SVGIcons.SubsidyIcon(),
      title: translateThis('Subsidy programs'),
      info: translateThis('C4K Care 4 Kids CDF Children Defense Fund'),
    },
    
  ];
  const missionItems = [
    {
      title: translateThis('Our Mission'),
      description: translateThis('Our Mission text'),
    },
    {
      title: translateThis('Summer season'),
      description: translateThis('Summer season text'),
    },
  ];

  return (
    <>
      <div className="our-mission-wrp francisca f-wrp">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="each-program-sec f-wrp ">
              <ul>
                {basicDetails.map((element, key) => (
                  <li key={key}>
                    <span className='program-icon'>{element.icon}</span>
                    <div className="detail-wrp">
                      <h3>{element.title}</h3>
                      <p>{element.info}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="each-mission-sec f-wrp ">
              <ul>
                {missionItems.map((element, key) => (
                  <li key={key}>
                    <h1>{element.title}</h1>
                    <p>{element.description}</p>

                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mission;