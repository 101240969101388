import * as React from 'react';
import { Button } from '@mui/material';
import TabLayout from '../../../components/tabs';

import './index.scss';

import ProviderPortalHeader from '../components/header';
import ProfileList from './employeeProfileList';
import DailyCheckInList from './dailyCheckInList';
import ModalPopUp from '../../../components/modal/modal.popup';
import AddEditEmployeeForm from './addEditEmployee';
import { translateThis } from '../../../helpers/language.helper';
import { getUniqueId } from '../../../helpers/helper.functions';

const EmployeeSection = () => {
    const [showPopup, setShowPopup] = React.useState(false);
    const [tabKey, setTabKey] = React.useState(getUniqueId());

    const tabNames = ["Profiles", "Daily Check in"];
    const tabContents = [
        <span key={'to_profiles'}><ProfileList /></span>,
        <span key={'to_dailyCheckIn'}><DailyCheckInList /></span>,


    ];

    return (
        <div className='employeeTab-wrp'>
            <ProviderPortalHeader header='Employees' />
            <Button onClick={() => setShowPopup(true)} className='red-btn' style={{ maxWidth: '275px', float: 'right' }}>{translateThis("Create employee profile")}</Button>
            <div className='tab-sec-wrp pay-table employeeTab-section f-wrp'>
                <TabLayout
                key={tabKey}
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
            {showPopup ? <ModalPopUp
                onClose={() => setShowPopup(false)}
                isOpen={showPopup}
            >
                <AddEditEmployeeForm
                    onSuccess={() => {
                        setShowPopup(false)
                        setTabKey(getUniqueId())
                    }}
                    onClose={() => setShowPopup(false)}
                />
            </ModalPopUp> : null}
        </div>
    )
}

export default EmployeeSection;