/* eslint-disable global-require */
const AppIcons = {
  bannerLogo: require('../bannerLogo.png'),
  bannerBG: require('../bannerBG.png'),
  bannerBGMob: require('../bannerBGMob.png'),
  logo: require('../Logo.png'),
  bilingual: require('./service/bilingual.png'),
  ehsccp: require('./service/ehsccp.png'),
  medicalAid: require('./service/medical_aid.png'),
  playground: require('./service/playground.png'),
  specialNeeds: require('./service/special_needs.png'),
  subsidizedCare: require('./service/subsidized_care.png'),
  smartKids: require('../smartkids.png'),
  aboutImg: require('../aboutImg.png'),
  mail: require('./mail.png'),
  mapPoint: require('./mapPoint.png'),
  phone: require('./phone.png'),
  contact: require('../contact.png'),
  days: require('./days.png'),
  hours: require('./hours.png'),
  ages: require('./ages.png'),
  programs: require('./programs.png'),

  learning: require('../learning.png'),
  learning1: require('./learning/learn_1.png'),
  learning2: require('./learning/learn_2.png'),
  learning3: require('./learning/learn_3.png'),
  learning4: require('./learning/learn_4.png'),
  learning5: require('./learning/learn_5.png'),
  learning6: require('./learning/learn_6.png'),
  learning7: require('./learning/learn_7.png'),
  learning8: require('./learning/learn_8.png'),

  hotBallon: require('../hotBallon.png'),

  gallery1: require('../gallery/gallery_1.png'),
  gallery2: require('../gallery/gallery_2.png'),
  gallery3: require('../gallery/gallery_3.png'),
  gallery4: require('../gallery/gallery_4.png'),
  gallery5: require('../gallery/gallery_5.png'),
  gallery6: require('../gallery/gallery_6.png'),
  gallery7: require('../gallery/gallery_7.png'),
  gallery8: require('../gallery/gallery_8.png'),
  gallery9: require('../gallery/gallery_9.png'),
  gallery10: require('../gallery/gallery_10.png'),
  gallery11: require('../gallery/gallery_11.png'),
  gallery12: require('../gallery/gallery_12.png'),
  gallery13: require('../gallery/gallery_13.png'),
  gallery14: require('../gallery/gallery_14.png'),
  gallery15: require('../gallery/gallery_15.png'),
  gallery16: require('../gallery/gallery_16.png'),
  gallery17: require('../gallery/gallery_17.png'),
  gallery18: require('../gallery/gallery_18.png'),
  gallery19: require('../gallery/gallery_19.png'),
  gallery20: require('../gallery/gallery_20.png'),
  gallery21: require('../gallery/gallery_21.png'),
  gallery22: require('../gallery/gallery_22.png'),
  gallery23: require('../gallery/gallery_23.png'),
  gallery24: require('../gallery/gallery_24.png'),
  gallery25: require('../gallery/gallery_25.png'),
  gallery26: require('../gallery/gallery_26.png'),
  gallery27: require('../gallery/gallery_27.png'),
  gallery28: require('../gallery/gallery_28.png'),
  gallery29: require('../gallery/gallery_29.png'),
  gallery30: require('../gallery/gallery_30.png'),

  founder: require('../founder.png'),
  payment: require('../payment.png'),
  
  mihapp: require('../mihapp.png'),
  loginBG: require('../login.jpg'),
  rigisterBG: require('../register.jpg'),
  
  ASQLogo: require('../ASQLogo.png'),
  sparklerLogo: require('../sparklerLogo.png'),

  certificate1: require('./certificate/certificate1.png'),
  certificate2: require('./certificate/certificate2.png'),
  certificate3: require('./certificate/certificate3.png'),
  certificate4: require('./certificate/certificate4.png'),
  certificate5: require('./certificate/certificate5.png'),
  certificate6: require('./certificate/certificate6.png'),


};
export default AppIcons;
/* eslint-enable global-require */
