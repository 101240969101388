import { useState } from 'react';

// @mui
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Grid from '@mui/material/Grid';


import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';

// ----------------------------------------------------------------------
import { translateThis } from '../../../../helpers/language.helper';
import ChildrenListDropdown from '../../../../components/dropdowns/children.dropdown';
import { createPaymentSession } from '../../../../services/api/payment';
import StripeGateway from '../../../../components/paymentGateways/stripe';
import { getUniqueId } from '../../../../helpers/helper.functions';





const ParentPaymentForm = () => {
    const [isPaymentCreating, setIsPaymentCreating] = useState(false);
    const [amount, setAmount] = useState();
    const [formId, setFormId] = useState(getUniqueId('payment-form'));
    const [selectedChildId, setSelectedChildId] = useState('');
    const [paymentKey, setPaymentKey] = useState('');
    const [publicKey, setPublicKey] = useState('');


    // initiate a payment session by child id
    const initiatePaymentSession = () => {
        if (selectedChildId && amount) {
            setIsPaymentCreating(true);

            createPaymentSession({
                childId: selectedChildId,
                amount: parseFloat(amount)
            })
                .then(({ success, data, message }) => {
                    if (success && data.clientSecret) {
                        setPaymentKey(data.clientSecret)
                        setPublicKey(data.publicKey)
                    } else {
                        showSnackbar().warning(message)
                    }
                })
                .catch(() => showSnackbar().failure('Something went wrong'))
                .finally(() => setIsPaymentCreating(false));
        }

    }

    const cancelPaymentSession = () => {
        setSelectedChildId('');
        setAmount(0);
        setPaymentKey('')
        setFormId(getUniqueId('payment-form-new-id'))

    }

    return (
        <>
            <div key={formId}>
                <>
                    <Stack className='panel-form-sec blk-bg payment-main-wrp forms-main-wrapper f-wrp' spacing={3}>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                            <Grid item xs={12} sm={12} md={6}>
                                <Grid item xs={12} sm={12} md={12} className='payment-child-list'>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis('Select one or more children to pay for')}</h3>
                                    </div>
                                    <ChildrenListDropdown onChange={setSelectedChildId} />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis('Enter the amount you want to pay')}</h3>
                                    </div>
                                    <TextInput
                                        isDisabled={!selectedChildId || paymentKey !== ''}
                                        key={`child_payment_form_Key_total_usd`}
                                        label={translateThis('child_payment_form_total_usd')}
                                        name='child_payment_form_total_usd'
                                        id='child_payment_form_total_usd'
                                        translationKey='child_payment_form_total_usd'
                                        type='number'
                                        required
                                        placeholder='Total USD'
                                        className='custom-textfield'
                                        getValue={(_, value) => setAmount(value)}
                                        useDefaultValidation
                                    />
                                </Grid>
                                {/* {!paymentKey && <Grid item xs={2} sm={2} md={2}>
                                    <LoadingButton
                                        loading={isPaymentCreating}
                                        loadingPosition='start'
                                        startIcon={<></>} 
                                        fullWidth
                                        disabled={!amount || !selectedChildId}
                                        size="large"
                                        type="button"
                                        variant="contained"
                                        className='fill-btn'
                                        onClick={initiatePaymentSession}>
                                        Confirm
                                    </LoadingButton>
                                </Grid>} */}


                            </Grid>

                            {paymentKey && <Grid item xs={12} sm={12} md={12}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp' style={{ marginBottom: '14px' }} >
                                        <h3>{translateThis('Pay using Stripe')}</h3>
                                    </div>
                                </Grid>
                                <Grid container spacing={3} className='pay-card-box'>
                                    {/* <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '0' }}>
                                        <Button onClick={() => null} className='card-btn'>
                                            <SVGIcons.PayCardIcon />
                                            <p>Card</p></Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '0' }}>
                                        <Button onClick={() => null} className='card-btn'>
                                            <SVGIcons.PaypalIcon />
                                            <p>Paypal</p></Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} style={{ paddingTop: '0' }}>
                                        <Button onClick={() => null} className='card-btn'>
                                            <SVGIcons.KlarnaIcon />
                                            <p>Klarna</p></Button>
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} md={12}>
                                        {(paymentKey && publicKey) && <StripeGateway publicKey={publicKey} paymentKey={paymentKey} />}
                                    </Grid>

                                </Grid>
                            </Grid>}
                        </Grid>
                    </Stack>
                </>

                <br />

                <div className='button-sec f-wrp' style={{ paddingBottom: '24px' }}>
                    <div className='lft-btn-sec'>
                        <Button onClick={cancelPaymentSession} className='pln-btn'>{translateThis('cancel')}</Button>
                    </div>
                    <div className='ryt-btn-sec'>

                        {/* <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            Submit
                        </LoadingButton> */}


                        {!paymentKey &&
                            <LoadingButton
                                loading={isPaymentCreating}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                disabled={!amount || !selectedChildId}
                                size="large"
                                type="button"
                                variant="contained"
                                className='fill-btn'
                                onClick={initiatePaymentSession}>
                                {translateThis('Confirm')}
                            </LoadingButton>}

                    </div>
                </div>
            </div>
        </>
    );
}

export default ParentPaymentForm;