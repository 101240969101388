import React from "react";

import SVGIcons from '../images/icons/svgIcons';

import './programs.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Programs() {

  const programItems1 = [
    {
      icon: SVGIcons.ProgramIcon7(),
      title: translateThis('AB Patterns & Shapes'),
    },
    {
      icon: SVGIcons.ProgramIcon8(),
      title: translateThis('Flash cards & Sight words​​'),
    },
    {
      icon: SVGIcons.ProgramIcon9(),
      title: translateThis('Calendar & Holidays'),
    },
  ];
  const programItems2 = [
    {
      icon: SVGIcons.ProgramIcon4(),
      title: translateThis('Outside play '),
    },
    {
      icon: SVGIcons.ProgramIcon13(),
      title: translateThis('Circle time & Story time'),
    },
    {
      icon: SVGIcons.ProgramIcon12(),
      title: translateThis('Numbers and math '),
    },
  ];

  const programItems3 = [
    {
      icon: SVGIcons.ProgramIcon1(),
      title: translateThis('Colors, Crafts & Scissors'),
    },
    {
      icon: SVGIcons.ProgramIcon2(),
      title: translateThis('Names, Phonics & Reading'),
    },
    {
      icon: SVGIcons.ProgramIcon3(),
      title: translateThis('Music, Dance & Singing'),
    },
  ];

  const programItems4 = [
    {
      icon: SVGIcons.ProgramIcon10(),
      title: translateThis('Wash hands & Toilet training'),
    },
    {
      icon: SVGIcons.ProgramIcon14(),
      title: translateThis('Sensory bins'),
    },
    {
      icon: SVGIcons.ProgramIcon15(),
      title: translateThis('Tidy after self'),
    },
  ];

  return (
    <>
      <div className="Francisca-program-wrp f-wrp">

        <div className="row">
          
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec Green1C f-wrp ">
                <ul>
                  {programItems1.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{element.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec Amaranth f-wrp ">
                <ul>
                  {programItems2.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{element.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec Cobalt f-wrp ">
                <ul>
                  {programItems3.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{element.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec Monza f-wrp ">
                <ul>
                  {programItems4.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{element.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          

        </div>


      </div>
    </>
  );
}

export default Programs;