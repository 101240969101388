import React, { useState, useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import './index.scss'

// Custom components
import SVGIcons from '../../../assets/images/icons/svgIcons';

import { DATE_EXTEND_FORMAT } from '../../../constants';
import RichTextViewer from '../../../components/rich-text-editor/TextViewer';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';

import { providerConfigState } from '../../../state/reducerAtoms/provider.atom';


const ViewBulletin = (props) => {
    const {
        onClose = () => null
    } = props;
    const apiCaller = new ApiCaller('bulletin');
    const bulletinId = useRecoilValue(providerConfigState).bulletinSection?.selectedBulletinId;
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        image: {
            fileKey: '',
            tempURL: ''
        },
        title: '',
        subTitle: '',
        author: '',
        description: ''
    });


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (bulletinId) {
            getData()
        }
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, [bulletinId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(bulletinId)
            .then((data) => {
                if (data) {
                    setFormData(data)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }


    return (
        <>
            <div className='back-btn-wrp f-wrp'>
                <IconButton
                    onClick={onClose}
                    aria-label="Go Back">
                    <SVGIcons.BackIcon />
                    Back
                </IconButton>
            </div>
            <div className='view-bulletin-wrp f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                {(!isLoading && formData.title !== '') ? <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={5}>
                        {(formData?.image?.tempURL) ? <div className='preview-img-sec f-wrp'>
                            <img src={formData?.image?.tempURL} alt='Bulletin' />
                        </div> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <div className='bulletin-con-sec f-wrp'>
                            <div className='basic-details f-wrp'>
                                <p>{formData?.author}</p>
                                {(formData?.createdAt) ? <p>{dayjs(formData?.createdAt).format(DATE_EXTEND_FORMAT)}</p> : null}
                            </div>
                            <div className='f-wrp'>
                                <h1>{formData?.title}</h1>
                                <h2>{formData?.subTitle}</h2>
                                {(formData.description) ? <RichTextViewer richText={formData.description} /> : null}
                                </div>
                        </div>
                    </Grid>
                </Grid> : <span>Loading...</span>}
            </div>
        </>
    );
}
ViewBulletin.propTypes = {
    onClose: PropTypes.func
}
export default ViewBulletin;












