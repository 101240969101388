import React from "react";
// import { element } from "prop-types";
// import SVGIcons from '../images/icons/svgIcons';
import AppIcons from "../images/icons";
import './teachers.scss';
import { translateThis } from "../../../../helpers/language.helper";




function Teachers() {

  const teachersItems = [
    {
      image: AppIcons.teachers1,
      title: translateThis('teachers_title_1'),
      position: translateThis('teachers_position_1'),
      description: translateThis('teachers_description_1'),
      descriptionPoints: <ul>
        <li>{translateThis('descriptionPointsList_1')}</li>
        <li>{translateThis('descriptionPointsList_2')}</li>
        <li>{translateThis('descriptionPointsList_3')}</li>
        <li>{translateThis('descriptionPointsList_4')}</li>
        <li>{translateThis('descriptionPointsList_5')}</li>
        <li>{translateThis('descriptionPointsList_6')}</li>
      </ul>
    },
    {
      image: AppIcons.teachers2,
      title: translateThis('teachers_title_2'),
      position: translateThis('teachers_position_2'),
      description: translateThis('teachers_description_2'),
    },

  ];


  return (
    <>
      <div className="Francisca-teachers-wrp f-wrp">
        <div className="row">
          {teachersItems.map((element, key) => (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" key={key}>
              <div className="each-teachers-sec f-wrp ">
                <div className="teachers-img-sec f-wrp">
                  <span className='fee-sec-icon'><img src={element.image} alt={element.title} /></span>
                  <div className="title-box f-wrp">
                    <h2>{element.title}</h2>
                    <p>{element.position}</p>
                  </div>
                </div>

                <div className="teachers-con-sec f-wrp">
                  <div className="teachers-con-box">
                    <p>{element.description}</p>
                    {element.descriptionPoints}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Teachers;