import React from "react";

import SVGIcons from '../images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import './programs.scss';


function Programs() {

  const programItems1 = [
    {
      icon: SVGIcons.FriendlyProgramIcon1(),
      title: 'AB patterns & shapes',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon2(),
      title: 'Arts and crafts',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon3(),
      title: 'Phonics & reading',
    },
  ];
  const programItems2 = [
    {
      icon: SVGIcons.FriendlyProgramIcon4(),
      title: 'Outdoor activities & ball pit',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon5(),
      title: 'Circle time & story time',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon6(),
      title: 'Theme of the week',
    },
  ];

  const programItems3 = [
    {
      icon: SVGIcons.FriendlyProgramIcon7(),
      title: 'Holding pencil & scissors',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon8(),
      title: 'Numbers and math',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon9(),
      title: 'Wash hands & toilet training',
    },
  ];

  const programItems4 = [
    {
      icon: SVGIcons.FriendlyProgramIcon10(),
      title: 'Music, dance & singing',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon11(),
      title: 'Calendar & holidays',
    },
    {
      icon: SVGIcons.FriendlyProgramIcon12(),
      title: 'Flash cards & sight words',
    },
  ];

  return (
    <>
      <div className="FriendFamily-program-wrp f-wrp">

        <div className="row">
          
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec friendlyProgram-1 f-wrp ">
                <ul>
                  {programItems1.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec friendlyProgram-2 f-wrp ">
                <ul>
                  {programItems2.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec friendlyProgram-3 f-wrp ">
                <ul>
                  {programItems3.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec friendlyProgram-4 f-wrp ">
                <ul>
                  {programItems4.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default Programs;