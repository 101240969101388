import React from 'react';

import './header.scss';
import AppIcons from '../../../assets/images/icons';


export default function FacilityAppHeader() {
    return (
        <>
            <div className='main-head-wrp FacilityAppHeader f-wrp'>
                <div className='container'>
                    <div className='burger-prof-sec'>
                        <div className='logo-wrp'>
                            <span>
                                <img className='logo' src={AppIcons.PPLogo} alt='evolveLogo' />
                                {/* <img className='slogan' src={AppIcons.evolveSlogan} alt='evolveSlogan' /> */}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}