import * as React from 'react';
// import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import { Button } from '@mui/material';
// import SVGIcons from '../../../../../assets/images/icons/svgIcons';
import AppIcons from '../../../../../assets/images/icons';
import './index.scss';



export default function BurgerMenu() {

  const evolveNavItems = [
    {
      link: '/ppplatform/#glimpse',
      title: 'Get a glimpse',
    },
    {
      link: '/ppplatform/#feature',
      title: 'Features',
    },
    {
      link: '/ppplatform/#tutorials',
      title: 'User Manual',
    },
    {
      link: '/ppplatform/#pricing', 
      title: 'Pricing',
    },
    {
      link: '/ppplatform/#testimonial',
      title: 'Testimonials',
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  /**
   * Handles the submenu click
   * @param {Event} event 
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the submenu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className='burger-menu-sec evolve'>
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className='burger-icon'
        >

          <img src={AppIcons.evolveBurgerIcon} alt='burger menu' />

        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          PaperProps={{
            style: {
              maxWidth: '250px',
            }
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          className='menu-main-wrp'
        >
          {evolveNavItems.filter((item) => !item.isHidden).map((element) => (
            <MenuItem key={element.title} className='each-menuItem'>
              <NavHashLink to={`${element.link}`} smooth style={{ width: '100%' }} >
                <Button sx={{ textAlign: 'center' }} onClick={handleClose}>
                  {element.icon ? <span className='menu-icon'><img src={element.icon} alt={element.title} /></span> : null} 
                  <span>{element.title}</span>
                </Button>
              </NavHashLink>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>

  )

}