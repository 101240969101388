import React from 'react';
import { Button } from '@mui/material';
import { NavHashLink } from 'react-router-hash-link';
import Marquee from "react-fast-marquee";
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import KathleenServices from './Services/Services';
// import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import { translateThis } from '../../../helpers/language.helper';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import Programs from './programs/programs';
import Approach from './approach/approach';
import SVGIcons from './images/icons/svgIcons';
import Teachers from './teachers/teachers';

const KathleenTemplate = () => {

    const pageName = "sweet sunrise";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='kathleenTemplates'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <div className='scroll-top f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollTop} alt='banner' /></span>
                            </Marquee>
                        </div>

                        <span className='pcBG'><img src={AppIcons.bannerBG} alt='banner' /></span>
                        <span className='mobBG'><img src={AppIcons.bannerBGMob} alt='banner' /></span>
                        <div className='scroll-bottom f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollBottom} alt='banner' /></span>
                            </Marquee>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <div className='banner-con'>
                                        <h1>{translateThis('Where every day begins with a Sweet Sunrise of Care')}</h1>
                                        <p>{translateThis('Welcome to Sweet Sunrise Childcare')}</p>
                                    </div>
                                </div>
                                <div className='banner-btn-sec f-wrp'>
                                    <Button onClick={() => {
                                        window.location.href = '/sweetsunrise/my-portal'
                                    }} className='sqr-btn'>{translateThis('Parent Portal')}</Button>
                                    <NavHashLink smooth to={'#tailored-approach'} >
                                        <Button className='sqr-btn child2'>{translateThis('Our classes')}</Button>
                                    </NavHashLink>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-slider-sec f-wrp'>
                    <div className='service-sec-header f-wrp'>
                        <div className='container'>
                            <h1>{translateThis('Our Services')}</h1>
                            <p>{translateThis('Explore our range of enriching services designed to nurture and inspire your')}.</p>
                        </div>
                    </div>
                    <KathleenServices />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <h1>{translateThis('Our Work')}</h1>
                                <p>{translateThis('Our Work Text1')} </p>
                                <p>{translateThis('Our Work Text2')}</p>
                                <p>{translateThis('Our Work Text3')}</p>
                            </div>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='programs-slider-sec f-wrp'>
                    <div className='container'>
                        <div className='programs-sec-header f-wrp'>
                            <h1>{translateThis('Our Program')}</h1>
                            <p>{translateThis('Our ProgramText')}</p>
                        </div>
                        <div className='gap f-wrp' style={{ paddingTop: '75px' }} />
                        <Programs />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='gallery-sec-header f-wrp'>
                            <h1>{translateThis('Our Captured Memories')}</h1>
                            <p>{translateThis('Come join us on a visual journey through cherished moments at Sweet Sunrise')}.</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Gallery />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='install-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='install-sec-blk f-wrp'>
                            <div className='install-img'>
                                <span><img src={AppIcons.installImg} alt='Our Installations' /></span>
                            </div>
                            <div className='install-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Our Installations')}</h1>
                                    <p>{translateThis('Our Installations Text1')} <b>{translateThis('Our Installations Text2')}</b> {translateThis('Our Installations Text3')} <b />
                                    {translateThis('Our Installations Text4')} <b />
                                        <b>{translateThis('Our Installations Text5')}</b></p>

                                    <NavHashLink smooth to={'#getInTouch'} >
                                        <Button className='sqr-btn'>{translateThis('Get in touch')}</Button>
                                    </NavHashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='approach-blk-wrp f-wrp' id='tailored-approach'>
                    <div className='container'>
                        <div className='approach-sec-header f-wrp'>
                            <h1>{translateThis('Our Tailored Approach')}</h1>
                            <p>{translateThis('Guiding every stage with expertise, our tailored approach at Sweet Sunrise ensures')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Approach />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='info-blk-wrp f-wrp' id='getInTouch'>
                    <div className='container'>
                        <div className='info-sec-blk f-wrp'>
                            <div className='info-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Our Info')}</h1>
                                    <p>{translateThis('Reach out to us today and let')}</p>
                                    <ul>
                                        <li><span>{SVGIcons.LicenseIcon()} </span>{translateThis('License DCFH.57729')}</li>
                                        <li><span>{SVGIcons.PinIcon()} </span>{translateThis('844 Colorado ave, Bridgeport, Connecticut')}</li>
                                        <li><span>{SVGIcons.MailIcon()} </span>{translateThis('sweetsunrisefamilydaycare@gmail.com')}</li>
                                        <li><span>{SVGIcons.PhoneIcon()} </span>{translateThis('(203) 534-8261')}</li>
                                    </ul>
                                    <Button onClick={() => {
                                        window.location.href = '/sweetsunrise'
                                    }} className='sqr-btn'>{translateThis('Whatsapp')}</Button>
                                </div>
                            </div>
                            <div className='info-img'>
                                <span><img src={AppIcons.infoImg} alt='Our info' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='teachers-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='teachers-sec-header f-wrp'>
                            <h1>{translateThis('Our Teachers')}</h1>
                            <p>{translateThis('Meet the heart of Sweet Sunrise: our passionate teachers who bring knowledge and care together, fostering a vibrant learning journey for every child in their capable hands.')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Teachers />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='payment-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='payment-sec-blk f-wrp'>
                            <div className='payment-img'>
                                <span><img src={AppIcons.paymentBG} alt='Our payment' /></span>
                            </div>
                            <div className='payment-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Our Payment Options')}</h1>
                                    <p>{translateThis('Our Payment Options Text_1')} <b>{translateThis('Our Payment Options Text_1_1')}</b> {translateThis('Our Payment Options Text_1_2')} </p><br />
                                    <p>{translateThis('Our Payment Options Text_2')} <b>{translateThis('Our Payment Options Text_2_1')}</b> {translateThis('Our Payment Options Text_2_2')} <b>{translateThis('Our Payment Options Text_2_3')}</b> {translateThis('Our Payment Options Text_2_4')} </p><br />
                                    <p>{translateThis('Our Payment Options Text_3')} <b>{translateThis('Our Payment Options Text_3_1')}</b></p>

                                    <Button onClick={() => {
                                        window.location.href = '/sweetsunrise/my-portal'
                                    }} className='sqr-btn'>{translateThis('Pay online')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='testimonial-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{translateThis('Our Clients Say')}</h1>
                            <p>{translateThis('Our Clients Say_text')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Testimonial />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

            </div>
        </AppLayout>
    )
}

export default KathleenTemplate;