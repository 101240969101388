import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AppIcons from '../../assets/images/icons';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';

export default function AppLogo() {
    
    const { facilityInfo = {} } = useRecoilValue(myPortalState);
    const currentFacilityURL = getCurrentFacilityURL();
    return (
        <Link to={`/${currentFacilityURL}`} className='auth-logo'><img src={facilityInfo?.facilityLogo?.tempURL || AppIcons.logo} alt='logo' /> </Link>
    )
}
