import * as React from 'react';
import { useRecoilState } from 'recoil';

import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import TextInput from '../../components/inputs/textInput/textInput';
import './index.scss';
import { getAuthId } from '../../helpers/auth.helper';
import { showSnackbar } from '../../components/snackbar/index';
import ProfilePicUploader from '../../components/awsS3ImageUploader/profilePic.uploader';

import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { getProfile, updateProfile } from '../../services/api/auth';
import SVGIcons from '../../assets/images/icons/svgIcons';

export default function Profile() {
    const userId = getAuthId();
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState({});
    // eslint-disable-next-line
    const [_, setMyPortalState] = useRecoilState(myPortalState)


    /**
  the legendary use effect function
  This will run very first on render
   */
    React.useEffect(() => {
        getData()
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        getProfile()
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...data };
        setData({ ...formDataAs, ...{ [name]: value } });
    }


    /**
   * updateData
   */
    const updateData = () => {
        if (userId) {
            const postData = {
                firstName: data.firstName,
                lastName: data.lastName,
                profilePic: data?.profilePic?.fileKey
            }
            updateProfile(postData)
                .then(() => showSnackbar().success('Profile updated successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getData();
                })
        }

    }

    const onCancel = () => {
        setMyPortalState((prv) => ({ ...prv, ...{ selectedNavItem: 'parents', pageHeader: 'Parents' } }))

    }


    return (
        <>

            {(isLoading) ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : (!isLoading && data) ? <div className='profile-wrapper f-wrp'>
                <div className='profile-img-con'>
                    <ProfilePicUploader
                        key={`profile-pic`}
                        onUploadStart={() => null}
                        onUploadEnd={(img) => setFormValue("profilePic", img)}
                        // onRemoveFile={() => setFormValue("profilePic", {fileKey: ''})}
                        onRemoveFile={() => setFormValue("profilePic", '')}
                        defaultImage={data?.profilePic}
                    />
                </div>
                <div className='profile-details child-profile f-wrp'>

                    <Grid className='profile-wrapper f-wrp' container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`first-Name`}
                                label='First Name'
                                name='firstName'
                                type='text'
                                value={data?.firstName}
                                getValue={setFormValue}
                                required
                                placeholder='First Name'
                                className='custom-textfield'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                                key={`last-name`}
                                label='Last name'
                                name='lastName'
                                type='text'
                                value={data?.lastName}
                                getValue={setFormValue}
                                required
                                placeholder='Last name'
                                className='custom-textfield'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                                key={`login-email`}
                                label='E-mail'
                                name='email'
                                type='email'
                                value={data?.email}
                                required
                                readOnly
                                placeholder='Please enter your email address'
                                className='custom-textfield'
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                                key={`login-password`}
                                label='Password'
                                name='password'
                                type='password'
                                required
                                placeholder='Please enter your password'
                                className='custom-textfield'
                            />
                            <Stack className='additional-btn' variant='div' spacing={3} style={{ marginTop: '0' }}>
                                <p style={{ textAlign: 'right', color: '#FF7940',  margin: '0' }}><a>Change password</a></p>
                            </Stack>
                        </Grid> */}
                    </Grid>
                    <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                        <div className='ryt-btn-sec'>
                            <Button onClick={onCancel} className='pln-btn'>Cancel</Button>
                            <Button onClick={updateData} className='fill-btn'>Save changes</Button>
                        </div>
                    </div>

                </div>
            </div> : null}

            <div className='note-box f-wrp'>
                <p>You can terminate your account at any given time. All your data will be deleted from our databases within 72h.<Link to={'/'} style={{ color: '#D12229' }}>Delete</Link></p>
            </div>



        </>
    );
}