import React from 'react';
import dayjs from 'dayjs';
import propTypes from 'prop-types';
import { Grid, Button, Stack, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteRounded } from '@mui/icons-material';

import './index.scss'

// Custom components
import BasicDropdown from '../../../components/dropdowns/basic.dropdown';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import ChildrenListDropdown from '../../../components/dropdowns/children.dropdown';
import { getChild } from '../../../services/api/child';
import { showSnackbar } from '../../../components/snackbar/index';
import { translateThis } from '../../../helpers/language.helper';
import { DATE_TIME_EXTENDED_FORMAT } from '../../../constants';
import TextInput from '../../../components/inputs/textInput/textInput';
import FileUploader from '../../../components/awsS3ImageUploader/file.uploader';
import { getURLExtension, getUniqueId } from '../../../helpers/helper.functions';
import AppIcons from '../../../assets/images/icons';
import { submitForm } from '../../../services/api/dynamicForms';



const DynamicFormsUploadTab = (props) => {

    const { id = '' } = props;
    const [selectedFormName, setSelectedFormName] = React.useState('');
    const [formKey, setFormKey] = React.useState(getUniqueId());
    const [selectedChildId, setSelectedChildId] = React.useState('');
    const [customFormName, setCustomFormName] = React.useState('');
    const [selectedChild, setSelectedChild] = React.useState(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [file, setFile] = React.useState({});


    /**
     * 
     * @param {string} value 
     */
    const setSelectedChildAs = (value) => {
        setSelectedChildId(value);
        setSelectedFormName('');
        getChildById(value)
    }

    /**
         * Set the selected form
         * @param {string} formName selected form
         */
    const handleFormSelection = (formName) => {
        setSelectedFormName(formName);
    };

    // get child data from api
    const getChildById = (childId) => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => { });
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (selectedChildId &&
            selectedFormName &&
            file &&
            file?.fileKey &&
            selectedChild &&
            selectedChild.id) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName: selectedFormName.replaceAll('_', '-'),
            formTitle: customFormName || selectedFormName.replaceAll('_', ' '),
            userId: selectedChild?.parentId,
            childId: selectedChildId,
            formData: [],
            pdfURL: file?.fileKey,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: '',
                parentSignedDate: '',
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onFormComplete()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        } else {
            showSnackbar().warning("Please fill the required fields")
        }

    };

    /**
     * On Form Complete
     */
    const onFormComplete = () => {
        setFormKey(getUniqueId());
        setCustomFormName('')
        setFile({})

    }

    return (
        <>
            <div key={formKey} className='panel-form-sec forms-main-wrapper dailyCommUpload f-wrp'>
                <div className='info-txt'>
                    <span>{translateThis("Here you can upload the forms you completed OFFLINE")}</span>
                </div>
                <div className='info-head f-wrp'>
                    <span>{translateThis("Assign your upload to a child and a form")}</span>
                </div>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={12} md={6}>
                            <div className="child-selection-div f-wrp">
                                {!id && <ChildrenListDropdown onChange={setSelectedChildAs} />}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={12} md={6}>
                            <div className="form-selection-div f-wrp">
                                <BasicDropdown
                                    key={`communication-select-${formKey}`}
                                    label="Select a communication"
                                    id='providerFormSelect'
                                    isDisabled={!selectedChildId}
                                    options={[
                                        {
                                            'label': <b>{translateThis("Care 4 Kids forms")} </b>,
                                        },
                                        {
                                            'label': <p>{translateThis("8. Parent-Provider agreement")}<br /><i>{translateThis("8. Parent-Provider agreementText")} </i></p>,
                                            "id": 'parent_provider_agreement',
                                            "value": 'parent_provider_agreement',
                                        },
                                        {
                                            'label': <p>{translateThis("9. Summer parent-provider agreement")}<br /><i>{translateThis("9. Summer parent-provider agreementText")} </i></p>,
                                            "id": 'summer_pp_agreement',
                                            "value": 'summer_pp_agreement',
                                        },

                                    ]}
                                    onChange={(value) => handleFormSelection(value)}
                                    value={selectedFormName}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                                key={`provider_communication_form_customName-${formKey}`}
                                label={"Form name (optional)"}
                                name='provider_communication_form_customName'
                                id='provider_communication_form_customName'
                                value={customFormName}
                                translationKey='provider_communication_form_customName'
                                type='text'
                                isRequired={false}
                                placeholder='Form name'
                                className='custom-textfield'
                                getValue={(_, value) => {
                                    setCustomFormName(value)
                                }}
                            />

                        </Grid>

                        

                        <Grid item xs={12} sm={12} md={12}>
                            {file?.tempURL ? <span>
                                <IconButton children={<DeleteRounded />} onClick={() => setFile({ fileKey: '', tempURL: '' })} />
                                {getURLExtension(file?.tempURL) === "pdf" ? <img style={{ width: '200px', height: '200px' }} src={AppIcons.pdfIcon} alt={"file"} /> : <img style={{ width: '200px', height: '200px' }} src={file.tempURL} alt={"file"} />}
                            </span> : null}
                            <br />
                            <Stack className='panel-form f-wrp calender-sec' spacing={3}>
                                <FileUploader
                                    key={`communication-uploader-${formKey}`}
                                    acceptOnly='application/pdf,image/*'
                                    uploadIcon={<SVGIcons.UploadIcon />}
                                    uploadCaption={translateThis("Upload a file or take a photo")}
                                    onUploadEnd={(file) => setFile(file)}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='button-sec f-wrp'>
                                <div className='lft-btn-sec' >
                                    <Button onClick={onFormComplete} className='cancel-btn'>{translateThis("cancel")}</Button>
                                </div>
                                <div className='ryt-btn-sec'>


                                    <LoadingButton
                                        loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='fill-btn'
                                        onClick={onSubmit}
                                    >
                                       {translateThis("submit")}
                                    </LoadingButton>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

DynamicFormsUploadTab.propTypes = {
    id: propTypes.string
}
export default DynamicFormsUploadTab;