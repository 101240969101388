/* eslint-disable global-require */
const AppIcons = {
  bannerLogo: require('../bannerLogo.png'),
  bannerBG: require('../bannerBG.png'),
  bannerBGMob: require('../bannerBGMob.png'),
  logo: require('../Logo.png'),
  scrollTop: require('../scrollTop.png'),
  scrollBottom: require('../scrollBottom.png'),
  aboutImg: require('../aboutImg.png'),
  installImg: require('../installImg.png'),

  mail: require('./mail.png'),
  mapPoint: require('./mapPoint.png'),
  phone: require('./phone.png'),
  contact: require('../contact.png'),
  days: require('./days.png'),
  hours: require('./hours.png'),
  ages: require('./ages.png'),
  programs: require('./programs.png'),

  gallery1: require('../gallery/gallery_1.png'),
  gallery2: require('../gallery/gallery_2.png'),
  gallery3: require('../gallery/gallery_3.png'),
  gallery4: require('../gallery/gallery_4.png'),
  gallery5: require('../gallery/gallery_5.png'),
  gallery6: require('../gallery/gallery_6.png'),
  gallery7: require('../gallery/gallery_7.png'),
  gallery8: require('../gallery/gallery_8.png'),
 
  approach1: require('../approach/approach1.png'),
  approach2: require('../approach/approach2.png'),
  approach3: require('../approach/approach3.png'),

  infoImg: require('../infoImg.png'),

  teachers1: require('../teachers/teachers1.png'),
  teachers2: require('../teachers/teachers2.png'),

  paymentBG: require('../paymentBG.png'),
  
  founder: require('../founder.png'),
  payment: require('../payment.png'),
  
  mihapp: require('../mihapp.png'),
 
  
};
export default AppIcons;
/* eslint-enable global-require */
