import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Testimonial() {


  const testimonialItems = [
    {
      name: 'Leidmaire S.',
      date: 'Aug 17',
      content: <><p>{translateThis('angelicaTestimonialItems1')}</p></>,
    },
    {
      name: 'Jacquie',
      date: 'Aug 16',
      content: <><p>{translateThis('angelicaTestimonialItems2')}</p></>,
    },
    {
      name: 'Nisheida Smalls',
      date: 'Aug 20',
      content: <><p>{translateThis('angelicaTestimonialItems3')}</p></>,
    },
    {
      name: 'Belky',
      date: 'May 26, 2022',
      content: <><p>{translateThis('angelicaTestimonialItems4')}</p></>,
    },
    {
      name: 'Lizz',
      date: 'May 25, 2022',
      content: <><p>{translateThis('angelicaTestimonialItems5')}</p></>,
    },
    {
      name: 'Brenda',
      date: 'Oct 09, 2021',
      content: <><p>{translateThis('angelicaTestimonialItems6')}</p></>,
    },
  ];

  return (
    <>
      <div className="testimonial-sec-wrp angelica">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  {element.content}
                  <h2>{translateThis(element.name)} <span>· {element.date}</span></h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;