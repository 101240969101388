import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// Components
import AppLayout from '../../layouts/app/AppLayout';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PasswordResetMailSuccess() {
  const pageName = 'Email sent success';
  return (
    <AppLayout
      isLoadHeader={false}
      isLoadFooter={false}
      pageName={pageName}
    >
      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Password reset
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Password reset email sent successfully. Please check your inbox for more information.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_login.png"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to={`/${getCurrentFacilityURL()}/auth/login`} size="large" variant="contained" component={RouterLink}>
            Back to login
          </Button>
        </StyledContent>
      </Container>
    </AppLayout>
  );
}
