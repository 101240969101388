import React from "react";
import AppIcons from '../images/icons/index';

import './Services.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Services() {


  const aboutItems = [
    {
      className: 'food-program',
      icon: AppIcons.service1,
      title: translateThis('service Food program'),
      description: translateThis('service Food program Text'),
    },
    {
      className: 'food-program',
      icon: AppIcons.service2,
      title: translateThis('service Medical support'),
      description: translateThis('service Medical support Text'),
    },
    {
      className: 'food-program',
      icon: AppIcons.service3,
      title: translateThis('service Bilingue'),
      description: translateThis('service Bilingue Text'),
    },
    {
      className: 'food-program',
      icon: AppIcons.service4,
      title: translateThis('service Subsidized care'),
      description: translateThis('service Subsidized care Text'),
    },
    {
      className: 'food-program',
      icon: AppIcons.service5,
      title: translateThis('service Reading oriented'),
      description: translateThis('service Reading oriented Text'),
    },
    {
      className: 'food-program',
      icon: AppIcons.service6,
      title: translateThis('service Years of experience'),
      description: translateThis('service Years of experience Text'),
    },
   
  ];

  return (
    <>
      <div className="service-section-wrp argentina f-wrp">

          {aboutItems.map((element, key) => (
              <div className={`${element.className} each-service-sec f-wrp `} key={key}>
                <span className='service-icon'>
                  <img src={element.icon} alt={element.title} />
                </span>
                <div className="service-con-sec">
                  <h3>{element.title}</h3>
                  <p>{element.description}</p>
                </div>
              </div>
          ))}
      
      </div>






    </>
  );
}

export default Services;