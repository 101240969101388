import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../assets/css/form.scss';
import SVGIcons from '../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';


// Helpers
import { getAuthId } from '../../../helpers/auth.helper';
import { getChildFormBy } from '../../../services/api/child';
import { answerGetter } from '../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../helpers/language.helper';

// Services
import { submitForm } from '../../../services/api/dynamicForms';

// ----------------------------------------------------------------------


const PPContactForm = (props) => {
    const formName = 'pp_contact_form';
    const { childId } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState([]);
    const [initialFormData, setInitialFormData] = useState({});
    const [isSendSubscriberReminderChecked, setIsSendSubscriberReminderChecked] = useState(false);
    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (childId && parentId) {
            getData()
        }
        return () => {
            setInitialFormData({})
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData(data)
                if (data?.formData) {
                    setFormData(data?.formData)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.name && formData.email && formData.message) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName,
            userId: parentId,
            childId,
            formData
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    // navigate('/', { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='pp-contact-frm standard-form' spacing={1}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    label={translateThis('Name')}
                                    name='subscribe_form_Question_1'
                                    id='subscribe_form_Question_1'
                                    value={answerGetter('subscribe_form_Question_1', initialFormData?.formData)?.answer}
                                    translationKey='Name'
                                    type='text'
                                    required
                                    placeholder={translateThis('Name')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'Name',
                                            type: 'text'
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    label={translateThis('Last name')}
                                    name='subscribe_form_Question_2'
                                    id='subscribe_form_Question_2'
                                    value={answerGetter('subscribe_form_Question_2', initialFormData?.formData)?.answer}
                                    translationKey='Last name'
                                    type='text'
                                    required
                                    placeholder={translateThis('Last name')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'Last name',
                                            type: 'text'
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>

                                <TextInput
                                    label={translateThis('Phone')}
                                    name='subscribe_form_Question_4'
                                    id='subscribe_form_Question_4'
                                    value={answerGetter('subscribe_form_Question_4', initialFormData?.formData)?.answer}
                                    translationKey='Phone'
                                    type='number'
                                    required
                                    placeholder={translateThis('Phone')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'Phone',
                                            type: 'text'
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    label={translateThis('subscribe_form_Question_6')}
                                    name='subscribe_form_Question_6'
                                    id='subscribe_form_Question_6'
                                    value={answerGetter('subscribe_form_Question_6', initialFormData?.formData)?.answer}
                                    translationKey={translateThis('subscribe_form_Question_6')}
                                    type='text'
                                    required
                                    placeholder={translateThis('subscribe_form_Question_6')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'subscribe_form_Question_6',
                                            type: 'text'
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={translateThis('E-mail')}
                                    name='subscribe_form_Question_5'
                                    id='subscribe_form_Question_5'
                                    value={answerGetter('subscribe_form_Question_5', initialFormData?.formData)?.answer}
                                    translationKey='E-mail'
                                    type='email'
                                    required
                                    placeholder={translateThis('E-mail')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'E-mail',
                                            type: 'text'
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type='text'
                                    defaultValue=""
                                    className='custom-textfield f-wrp'
                                    name="Comments"
                                    label="Comments"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='login-btn fill-btn'
                            onClick={onSubmit}>
                            {translateThis('Send')}
                        </LoadingButton>
                    </form>

                </Stack>
            </div>}
        </>
    );
}

PPContactForm.propTypes = {
    childId: propTypes.string
}

export default PPContactForm;