import * as React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../helpers/language.helper';




export default function Tutorials() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>        
            <div className='tutorial-sec-wrp provider-tutorial-wrp f-wrp'>
                <div className='user-manual-wrp f-wrp'>
                    <div className='container'>
                        <div className='manual-header f-wrp'>
                            <p>{translateThis('Mastering your Digital Facility with Step-by-Step Guides!')}</p>
                        </div>
                        <div className='manual-header maxWidth_767 f-wrp'>
                            <div className='video-btn-wrp'>
                                <p>{SVGIcons.BooksIcon()} {translateThis('Watch all the videos in')}</p>
                                <ul className='video-button-list'>
                                    <li>
                                        <Link className='video-button provider-hub' target='_blank' to={'https://youtube.com/playlist?list=PLZvAEaMuf9TZm0lZhZOBkAFca97wuZ9_D&feature=shared'}>{SVGIcons.PlayButtonIcon()} {translateThis('Provider Hub')}</Link>
                                    </li>
                                    <li>
                                        <Link className='video-button parent-hub' target='_blank' to={'https://youtube.com/playlist?list=PLZvAEaMuf9TYMmHw_CeZsPgr36fd-_F57&feature=shared'}>{SVGIcons.PlayButtonIcon()} {translateThis('Parent Hub')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='accordian-wrapper maxWidth_767 f-wrp'>
                            <div className='accordian-sec-wrp f-wrp'>
                                <p>{SVGIcons.BookIcon()} {translateThis('App Walkthrough')}</p>
                                <div className='accordian-sec-box f-wrp'>

                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <span className='stokeSvg'>{SVGIcons.GrommetIcon()}</span>
                                            <h4>{translateThis('Portal overview')} <p>{translateThis('accordianTextOne')}</p></h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ul>
                                                <li><Link onClick={() => window.open('https://youtu.be/Fg5K3tZmDZI', '_blank')} ><span className='parentIcon'>{SVGIcons.ParentIcon()}</span> {translateThis('Parent Portal')}</Link></li>
                                                <li><Link onClick={() => window.open('https://youtu.be/ewRuuchr7Zo', '_blank')} ><span>{SVGIcons.HomeIcon()}</span> {translateThis('Provider Portal')}</Link></li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <span>{SVGIcons.UserAddIcon()}</span>
                                            <h4>{translateThis('Enrollment')} <p>{translateThis('accordianTextTwo')}</p></h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ul>
                                                <li><Link onClick={() => window.open('https://www.youtube.com/playlist?list=PLZvAEaMuf9TYTFv0jy8LbbzMSACu4t-A1', '_blank')} ><span className='parentIcon'>{SVGIcons.ParentIcon()}</span> {translateThis('Parent Portal')}</Link></li>
                                                <li><Link onClick={() => window.open('https://www.youtube.com/watch?v=IJhT-pyPP-I', '_blank')} ><span>{SVGIcons.HomeIcon()}</span> {translateThis('Provider Portal')}</Link></li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <span>{SVGIcons.CogIcon()}</span>
                                            <h4>{translateThis('Features')} <p>{translateThis('accordianTextThree')}</p></h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ul>
                                                <li><Link onClick={() => window.open('https://youtu.be/lCzKvvmdGlQ', '_blank')} ><span className='PalmIcon'>{SVGIcons.PalmIcon()}</span> {translateThis('Attendance')}</Link></li>
                                                <li><Link onClick={() => window.open('https://youtube.com/playlist?list=PLZvAEaMuf9TYmX6-rQPsojwdAZf04htdZ&feature=shared', '_blank')} ><span>{SVGIcons.FormsIcon()}</span> {translateThis('Forms')}</Link></li>
                                                <li><Link onClick={() => window.open('https://youtube.com/playlist?list=PLZvAEaMuf9Ta4QA3Iw3b2ayLI4i-Kv9Sr&feature=shared', '_blank')} ><span>{SVGIcons.CatalogIcon()}</span> {translateThis('Communications')}</Link></li>
                                                <li><Link onClick={() => window.open('https://youtu.be/R0npuCCHARg', '_blank')} ><span>{SVGIcons.AccidentIcon()}</span> {translateThis('Accidents')}</Link></li>
                                                <li><Link onClick={() => window.open('#', '_blank')} ><span className='PaymentsIcon'>{SVGIcons.PaymentsIcon()}</span> {translateThis('Payments')}</Link></li>
                                                <li><Link onClick={() => window.open('https://youtu.be/_jhzvgOWARI', '_blank')} ><span>{SVGIcons.BulletinIcon()}</span> {translateThis('Bulletin')}</Link></li>
                                                <li><Link onClick={() => window.open('https://youtu.be/neYRZWg6RwQ', '_blank')} ><span>{SVGIcons.RuleIcon()}</span> {translateThis('Policies')}</Link></li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                        <AccordionSummary
                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <span className='stokeSvg'>{SVGIcons.EmployeeIcon()}</span>
                                            <h4>{translateThis('Staff')} <p>{translateThis('accordianTextFour')}</p></h4>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ul>
                                                <li><Link onClick={() => window.open('https://www.youtube.com/playlist?list=PLZvAEaMuf9TYPcBfd9DPIchpjG0SSHcny', '_blank')} ><span className='EmployeeIcon'>{SVGIcons.EmployeeIcon()}</span> {translateThis('Employee Portal')}</Link></li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
