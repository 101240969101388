import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// css
import './index.scss';

// components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import { usdFormatter } from '../../../helpers/currency.helper';
import { DATE_TIME_FORMAT } from '../../../constants/index';



const PaymentHistory = () => {
    const apiCaller = new ApiCaller('payment')
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({query: 'sortBy=createdAt:desc'})
            .then(({data}) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 3,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            minWidth: 100,
            valueFormatter: ({ value }) => usdFormatter(value)
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            minWidth: 180,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT)

        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
            minWidth: 90,
            renderCell: (params) => {
                const { status } = params.row;
                return <span className={`payment-status status-${status}`}>{status}  </span>
            }
        }

    ]

    return (
        <>
            <AppTable
                key={'parent-payment-table'}
                columns={tableColumns}
                isLoading={isLoading}
                data={dataArr}
            />

        </>
    )
}
PaymentHistory.propTypes = {}
export default PaymentHistory;