import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';

const C4KRedeterminationView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView f-wrp">

                <div className='prof-sec'>
                    {/* <div className='prof-img'>
                        <span><img src={AppIcons.registerBG} alt='profile' /></span>
                    </div> */}
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div>
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="lft-section">
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.c4k} alt='profile' /></span>
                    </div>
                    <div className="ryt-section" style={{ textAlign: 'center' }}>
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>{translateThis("Care 4 Kids Redetermination")}</b></h2>
                    </div>
                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 1 Head of Household Information")}</b></h3>
                    <p>{translateThis("The head of household is the parent or adult legally responsible for the child")} <b>{translateThis("18")}</b> {translateThis("and living with an adult, the adult is considered the applicant and must fill out and sign this Redetermination.")}</p>
                    <div className='gap' />
                    <div className='auto-flex'>
                        <p>{translateThis("C4K Case Number")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_caseNumber', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='flex-box'>
                        <p>{translateThis("First Name")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_firstName', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("M.I.")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_MI', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Last Name")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_lastName', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Date of Birth")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>{translateThis("Street Address")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_address', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Floor/Apartment number")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_floorNumber', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>{translateThis("City")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_city', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("State")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_state', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Zipcode")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_zipcode', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>{translateThis("Primary Phone")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_primaryPhone', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Work Phone")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_workPhone', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Social Security Number (optional)")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_socialSecurityNumber', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("E-mail Address")}: <b>{answerGetter('c4k_redetermination_sectionOne_form_question_email', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='check-box inline'>
                        <p>{translateThis("Gender")}:  </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_Gender", formData)?.answer === 'F (Female)' ? 'checked' : ''}>{translateThis("F Female")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_Gender", formData)?.answer === 'M (Male)' ? 'checked' : ''}>{translateThis("M Male")}</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("Marital Status")}:  </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Married' ? 'checked' : ''}>{translateThis("Married")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Single' ? 'checked' : ''}>{translateThis("Single")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Separated' ? 'checked' : ''}>{translateThis("Separated")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_maritalStatus", formData)?.answer === 'Divorced' ? 'checked' : ''}>{translateThis("Divorced")}</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("generalQN_1")} </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN1", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN1", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("generalQN_2")} </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN2", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN2", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("generalQN_3")} </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN3", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN3", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("generalQN_4")} </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN4", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN4", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("c4k_redetermination_sectionOne_form_Key_generalQN_5")} </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    {answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5", formData)?.answer === 'Yes' ?
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionOne_form_Key_generalQN_5_1")} </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5_1", formData)?.answer === 'Active Duty U.S. Military' ? 'checked' : ''}>{translateThis("Active Duty U.S. Military")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_5_1", formData)?.answer === 'National Guard Military Reserve' ? 'checked' : ''}>{translateThis("National Guard Military Reserve")}</li>
                            </ul>
                        </div> : null}

                    <div className='check-box inline'>
                        <p>{translateThis("c4k_redetermination_sectionOne_form_Key_generalQN_6")} </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_6", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionOne_form_Key_generalQN_6", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <p>{translateThis("c4k_redetermination_sectionOne_form_question_primaryLanguage")} <b>{answerGetter('c4k_redetermination_sectionOne_form_question_primaryLanguage', formData)?.answer || '-'}</b></p>
                    <div className='check-box inline'>
                        <ul>
                            <li className='checked'><b>Marque aquí si desea recibir cartas y formularios en español. {translateThis("Check here to receive letters and forms in Spanish")}</b></li>
                        </ul>
                    </div>

                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 2 Information on the other parent living in your home")}</b></h3>
                    <p>{translateThis("You MUST list your spouse, civil union partner or other legal parent of your children")}</p>
                    <div className='gap' />
                    <div className='flex-box'>
                        <p>{translateThis("c4k_redetermination_sectionTwo_form_question_Name")}: <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_Name', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("c4k_redetermination_sectionTwo_form_DateOFBirth")}: <b>{answerGetter('c4k_redetermination_sectionTwo_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("c4k_redetermination_sectionTwo_form_question_relationship")}: <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_relationship', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Social Security Number (optional)")}: <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_socialSecurityNumber', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("Gender")}:  </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_Gender", formData)?.answer === 'F (Female)' ? 'checked' : ''}>{translateThis("F Female")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_Gender", formData)?.answer === 'M (Male)' ? 'checked' : ''}>{translateThis("M Male")}</li>
                        </ul>
                    </div>
                    <div className='auto-flex'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionTwo_form_Key_generalQN_1")}  </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_1", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_1", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_1", formData)?.answer === 'Yes' ?
                            <p>{translateThis("Name of Child")} : <b>{answerGetter('c4k_redetermination_sectionTwo_form_question_generalQN_1_1', formData)?.answer || '-'}</b></p> : null}

                    </div>

                    <div className='check-box inline'>
                        <p>{translateThis("generalQN_5")} </p>
                        <ul>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    {answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5", formData)?.answer === 'Yes' ?
                        <div className='check-box inline'>
                            <p>{translateThis("generalQN_5_1")} </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5_1", formData)?.answer === 'Active Duty U.S. Military' ? 'checked' : ''}>{translateThis("Active Duty U.S. Military")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionTwo_form_Key_generalQN_5_1", formData)?.answer === 'National Guard Military Reserve' ? 'checked' : ''}>{translateThis("National Guard Military Reserve")}</li>
                            </ul>
                        </div> : null}

                </div>

                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 3 Children information")}</b></h3>
                    <p>{translateThis("Please list all children under the age of 18 that live in the home")}</p>
                    <p style={{ fontSize: '13px' }}><b>{translateThis("KEY A (Asian) B (Black/African Descent) C (White) N (American Indian/Alaskan Native) P (Native Hawaiian/Other Pacific Islander) NA (I prefer not to answer)")}</b></p>
                    <div className='gap' />
                    <div className='each-child'>
                        <div className='auto-flex'>
                            <p>{translateThis("c4k_redetermination_sectionTwo_form_question_Name")}: <b>{answerGetter('c4k_redetermination_sectionThree_form_question_Name', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Date of Birth")}: <b>{answerGetter('c4k_redetermination_sectionThree_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Relationship to Applicant")}: <b>{answerGetter('c4k_redetermination_sectionThree_form_question_relationship', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Social Security Number (optional)")}: <b>{answerGetter('c4k_redetermination_sectionThree_form_question_socialSecurityNumber', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionThree_form_Key_careNeeded")}  </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_careNeeded", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_careNeeded", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>{translateThis("Gender")}:  </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Gender", formData)?.answer === 'F (Female)' ? 'checked' : ''}>{translateThis("F Female")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Gender", formData)?.answer === 'M (Male)' ? 'checked' : ''}>{translateThis("M Male")}</li>
                            </ul>
                        </div>

                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionThree_form_Key_race")} </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_race", formData)?.answer === 'A (Asian)' ? 'checked' : ''}>{translateThis("A Asian")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_race", formData)?.answer === 'B (Black/African)' ? 'checked' : ''}>{translateThis("B BlackAfrican")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_race", formData)?.answer === 'C (White)' ? 'checked' : ''}>{translateThis("C White")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_race", formData)?.answer === 'N (American Indian/Alaska Native)' ? 'checked' : ''}>{translateThis("N American IndianAlaska Native")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_race", formData)?.answer === 'P (Native Hawaiian/Other Pacific Islander)' ? 'checked' : ''}>{translateThis("P Native HawaiianOther Pacific Islander")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_race", formData)?.answer === 'I prefer not to answer' ? 'checked' : ''}>{translateThis("I prefer not to answer")}</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionThree_form_Key_hispanicLatino")} </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_hispanicLatino", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_hispanicLatino", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_hispanicLatino", formData)?.answer === 'NA' ? 'checked' : ''}>{translateThis("NA")}</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionThree_form_Key_Citizenship")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Citizenship", formData)?.answer === 'Citizen' ? 'checked' : ''}>{translateThis("Citizen")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Citizenship", formData)?.answer === 'Permanent Resident' ? 'checked' : ''}>{translateThis("Permanent Resident")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Citizenship", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                            </ul>
                        </div>

                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionThree_form_Key_Shots")} </p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Shots", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionThree_form_Key_Shots", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                    </div>

                    <div className='gap' />
                    {((answerGetter('c4k_redetermination_sectionThree_form_Key_careNeeded', formData)?.answer === "Yes") &&
                        answerGetter('c4k_redetermination_sectionThree_form_extraChildren', formData)?.answer
                    ) && <>
                            {answerGetter('c4k_redetermination_sectionThree_form_extraChildren', formData)?.rawAnswer.map((no, i) => <div key={`each-child-${no}`} className='each-child'>
                                <h4>{translateThis("Child")} #{i + 2}</h4>
                                <div className='check-box inline'>
                                    <p>{translateThis("Gender")}:  </p>
                                    <ul>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_Gender`, formData)?.answer === 'F (Female)' ? 'checked' : ''}>{translateThis("F Female")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_Gender`, formData)?.answer === 'M (Male)' ? 'checked' : ''}>{translateThis("M Male")}</li>
                                    </ul>
                                </div>

                                <div className='check-box inline'>
                                    <p>{translateThis("c4k_redetermination_sectionThree_form_Key_race")} </p>
                                    <ul>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_race`, formData)?.answer === 'A (Asian)' ? 'checked' : ''}>{translateThis("A Asian")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_race`, formData)?.answer === 'B (Black/African)' ? 'checked' : ''}>{translateThis("B BlackAfrican")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_race`, formData)?.answer === 'C (White)' ? 'checked' : ''}>{translateThis("C White")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_race`, formData)?.answer === 'N (American Indian/Alaska Native)' ? 'checked' : ''}>{translateThis("N American IndianAlaska Native")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_race`, formData)?.answer === 'P (Native Hawaiian/Other Pacific Islander)' ? 'checked' : ''}>{translateThis("P Native HawaiianOther Pacific Islander")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_race`, formData)?.answer === 'I prefer not to answer' ? 'checked' : ''}>{translateThis("I prefer not to answer")}</li>
                                    </ul>
                                </div>
                                <div className='check-box inline'>
                                    <p>{translateThis("c4k_redetermination_sectionThree_form_Key_hispanicLatino")} </p>
                                    <ul>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_hispanicLatino`, formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_hispanicLatino`, formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_hispanicLatino`, formData)?.answer === 'NA' ? 'checked' : ''}>{translateThis("NA")}</li>
                                    </ul>
                                </div>
                                <div className='check-box inline'>
                                    <p>{translateThis("c4k_redetermination_sectionThree_form_Key_Citizenship")} </p>
                                    <ul>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_Citizenship`, formData)?.answer === 'Citizen' ? 'checked' : ''}>{translateThis("Citizen")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_Citizenship`, formData)?.answer === 'Permanent Resident' ? 'checked' : ''}>{translateThis("Permanent Resident")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_Citizenship`, formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                                    </ul>
                                </div>

                                <div className='check-box inline'>
                                    <p>{translateThis("c4k_redetermination_sectionThree_form_Key_Shots")} </p>
                                    <ul>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_Shots`, formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                        <li className={answerGetter(`c4k_redetermination_sectionThree_form_${no}_Shots`, formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                    </ul>
                                </div>
                            </div>)}
                        </>}

                    <hr />
                    <div className='gap' />
                    <div className='each-child'>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_1")} </p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_1", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_1", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                </ul>
                            </div>
                            {answerGetter("c4k_redetermination_sectionThree_form_QN_1", formData)?.answer === 'Yes' ?
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_2")} : <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_2', formData)?.answer || '-'}</b></p> : null}
                        </div>

                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_3")}</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_3", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_3", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                </ul>
                            </div>
                            {answerGetter("c4k_redetermination_sectionThree_form_QN_3", formData)?.answer === 'Yes' ?
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_4")} : <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_4', formData)?.answer || '-'}</b></p> : null}
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_5")}</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                </ul>
                            </div>
                            {answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'Yes' ?
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_6Label")}</p> : null}
                        </div>
                        {answerGetter("c4k_redetermination_sectionThree_form_QN_5", formData)?.answer === 'Yes' ?
                            <div className='flex-box'>
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_6")}: <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_6', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("c4k_redetermination_sectionThree_form_QN_7")}: <b>{answerGetter('c4k_redetermination_sectionThree_form_QN_7', formData)?.answer || '-'}</b></p>
                            </div> : null}
                        {((answerGetter('c4k_redetermination_sectionThree_form_QN_5', formData)?.answer === "Yes") &&
                            answerGetter('c4k_redetermination_sectionThree_form_underAgeParents', formData)?.answer
                        ) && <>
                                {answerGetter('c4k_redetermination_sectionThree_form_underAgeParents', formData)?.rawAnswer.map((no, i) => <div key={`each-child-${no}`}>
                                    <h4>{translateThis("c4k_redetermination_sectionThree_form_QN_6")} #{i + 2}</h4>
                                    <div className='flex-box'>
                                        <p>{translateThis("c4k_redetermination_sectionThree_form_QN_6")}: <b>{answerGetter(`c4k_redetermination_sectionThree_form_${no}_parentName`, formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("c4k_redetermination_sectionThree_form_QN_7")}: <b>{answerGetter(`c4k_redetermination_sectionThree_form_${no}_childName`, formData)?.answer || '-'}</b></p>

                                    </div>
                                </div>)}
                            </>}

                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 4: Work/training activity and income information")}</b></h3>
                    <p>{translateThis("Fill out the information below for all parents in the home.")} <b>{translateThis("If there are more than 2 activities, make a copy of this page")}</b></p>
                    <div className='gap' />

                    <div className='each-child'>
                        <p>{translateThis("Complete the following information about your work/training activity.")}</p>
                        <div className='gap' />
                        <p>{translateThis("Name of parent in the home")}: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_1', formData)?.answer || '-'}</b></p>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFour_form_QN_2")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Work') ? 'checked' : ''}>{translateThis("Work")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('High School') ? 'checked' : ''}>{translateThis("High School")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Self-Employed') ? 'checked' : ''}>{translateThis("Self-Employed")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Training or Education approved by JFES') ? 'checked' : ''}>{translateThis("Training or Education approved by JFES")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Higher Education') ? 'checked' : ''}>{translateThis("Higher Education")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('GED/Adult Education') ? 'checked' : ''}>{translateThis("GED/Adult Education")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_2", formData)?.rawAnswer.includes('Workforce Development/Training program') ? 'checked' : ''}>{translateThis("Workforce Development/Training program")}</li>

                            </ul>
                        </div>
                        <div className="flex-box">
                            <p>Name of Employer/Business/Program/School: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_3', formData)?.answer || '-'}</b></p>
                            <p>Employer Industry/Type of Work: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_4', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>Address: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_5', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("City")}: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_6', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("State")}: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_7', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Zipcode")}: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_8', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>Start Date: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_9', formData)?.answer || '-'}</b></p>
                            <p>Phone: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_10', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>Hours per week you work or participate in a training activity:  <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_11', formData)?.answer || '-'}</b></p>
                            <p>Days per week you work or attend a training activity: <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_12', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='check-box inline' style={{ flexDirection: 'column' }}>
                            <p>How frequently do you get paid?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_13", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                            </ul>
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                <p>How much do you get paid before taxes are deducted (gross income)?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Hourly' ? 'checked' : ''}>{translateThis("Hourly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_14", formData)?.answer === 'Annually' ? 'checked' : ''}>{translateThis("Annually")}</li>
                                </ul>
                            </div>

                            <p>Amount : <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_15', formData)?.answer || '-'}</b></p>
                        </div>

                        <div className='auto-flex'>
                            <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                <p>If you are self-employed, how much do you get paid before taxes and expenses are deducted (gross income)?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Hourly' ? 'checked' : ''}>{translateThis("Hourly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_16", formData)?.answer === 'Annually' ? 'checked' : ''}>{translateThis("Annually")}</li>
                                </ul>
                            </div>

                            <p>Amount : <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_17', formData)?.answer || '-'}</b></p>
                        </div>

                        <div className='auto-flex'>
                            <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                <p>If you are self-employed, what are your expenses (dollar amount)?</p>
                                <ul>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Hourly' ? 'checked' : ''}>{translateThis("Hourly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                    <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_18", formData)?.answer === 'Annually' ? 'checked' : ''}>{translateThis("Annually")}</li>
                                </ul>
                            </div>

                            <p>Amount : <b>{answerGetter('c4k_redetermination_sectionFour_form_QN_19', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='check-box inline'>
                            <p>Daily roundtrip commute from child care setting to work/activity?</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === 'None' ? 'checked' : ''}>{translateThis("None")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === '1-30 minutes' ? 'checked' : ''}>{translateThis("1-30 minutes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === '31-60 minutes' ? 'checked' : ''}>{translateThis("31-60 minutes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_20", formData)?.answer === 'More than 60 minutes' ? 'checked' : ''}>{translateThis("More than 60 minutes")}</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFour_form_QN_21")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_21", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFour_form_QN_21", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <ul>
                                <li className='checked'><b>{translateThis("c4k_redetermination_sectionFour_form_QN_22")}</b></li>
                            </ul>
                        </div>

                    </div>
                    {(answerGetter('c4k_redetermination_sectionThree_form_trainingSections', formData)?.answer) && <>
                        {answerGetter('c4k_redetermination_sectionThree_form_trainingSections', formData)?.rawAnswer.map((no) => <div key={`each-child-${no}`} className='each-child'>
                            <div className='gap' />
                            <p>{translateThis("Name of parent in the home")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_1`, formData)?.answer || '-'}</b></p>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_redetermination_sectionFour_form_QN_2")}</p>
                                <ul>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_2`, formData)?.rawAnswer.includes('Work') ? 'checked' : ''}>{translateThis("Work")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_2`, formData)?.rawAnswer.includes('High School') ? 'checked' : ''}>{translateThis("High School")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_2`, formData)?.rawAnswer.includes('Self-Employed') ? 'checked' : ''}>{translateThis("Self-Employed")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_2`, formData)?.rawAnswer.includes('Training or Education approved by JFES') ? 'checked' : ''}>{translateThis("Training or Education approved by JFES")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_2`, formData)?.rawAnswer.includes('Higher Education') ? 'checked' : ''}>{translateThis("Higher Education")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_2`, formData)?.rawAnswer.includes('GED/Adult Education') ? 'checked' : ''}>{translateThis("GED/Adult Education")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_2`, formData)?.rawAnswer.includes('Workforce Development/Training program') ? 'checked' : ''}>{translateThis("Workforce Development/Training program")}</li>

                                </ul>
                            </div>
                            <div className="flex-box">
                                <p>{translateThis("c4k_redetermination_sectionFour_form_QN_3")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_3`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("c4k_redetermination_sectionFour_form_QN_4")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_4`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='auto-flex'>
                                <p>{translateThis("Address")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_5`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("City")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_6`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("State")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_7`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Zipcode")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_8`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='auto-flex'>
                                <p>{translateThis("Start Date")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_9`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Phone")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_10`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='auto-flex'>
                                <p>{translateThis("Hours per week you work or participate in a training activity")}:  <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_11`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Days per week you work or attend a training activity")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_12`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                <p>{translateThis("c4k_redetermination_sectionFour_form_QN_13")}</p>
                                <ul>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_13`, formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_13`, formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_13`, formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_13`, formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                </ul>
                            </div>
                            <div className='auto-flex'>
                                <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                    <p>{translateThis("c4k_redetermination_sectionFour_form_QN_14")}</p>
                                    <ul>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_14`, formData)?.answer === 'Hourly' ? 'checked' : ''}>{translateThis("Hourly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_14`, formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_14`, formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_14`, formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_14`, formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_14`, formData)?.answer === 'Annually' ? 'checked' : ''}>{translateThis("Annually")}</li>
                                    </ul>
                                </div>

                                <p>{translateThis("Amount")}: <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_15`, formData)?.answer || '-'}</b></p>
                            </div>

                            <div className='auto-flex'>
                                <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                    <p>{translateThis("c4k_redetermination_sectionFour_form_QN_16")}</p>
                                    <ul>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_16`, formData)?.answer === 'Hourly' ? 'checked' : ''}>{translateThis("Hourly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_16`, formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_16`, formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_16`, formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_16`, formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_16`, formData)?.answer === 'Annually' ? 'checked' : ''}>{translateThis("Annually")}</li>
                                    </ul>
                                </div>

                                <p>{translateThis("Amount")} : <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_17`, formData)?.answer || '-'}</b></p>
                            </div>

                            <div className='auto-flex'>
                                <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                    <p>{translateThis("c4k_redetermination_sectionFour_form_QN_18")}</p>
                                    <ul>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_18`, formData)?.answer === 'Hourly' ? 'checked' : ''}>{translateThis("Hourly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_18`, formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_18`, formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_18`, formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_18`, formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_18`, formData)?.answer === 'Annually' ? 'checked' : ''}>{translateThis("Annually")}</li>
                                    </ul>
                                </div>

                                <p>{translateThis("Amount")} : <b>{answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_19`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_redetermination_sectionFour_form_QN_20")}</p>
                                <ul>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_20`, formData)?.answer === 'None' ? 'checked' : ''}>{translateThis("None")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_20`, formData)?.answer === '1-30 minutes' ? 'checked' : ''}>{translateThis("1-30 minutes")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_20`, formData)?.answer === '31-60 minutes' ? 'checked' : ''}>{translateThis("31-60 minutes")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_20`, formData)?.answer === 'More than 60 minutes' ? 'checked' : ''}>{translateThis("More than 60 minutes")}</li>
                                </ul>
                            </div>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_redetermination_sectionFour_form_QN_21")}</p>
                                <ul>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_21`, formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                    <li className={answerGetter(`section_${no}_c4k_redetermination_sectionFour_form_QN_21`, formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                </ul>
                            </div>
                            <div className='check-box inline'>
                                <ul>
                                    <li className='checked'><b>{translateThis("c4k_redetermination_sectionFour_form_QN_22")}</b></li>
                                </ul>
                            </div>

                        </div>)}
                    </>}
                    <p><b>{translateThis("If the other parent in the household is working or in a training activity")}</b></p>
                </div>

                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 5 Child support paid and additional income information")}</b></h3>
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFive_form_QN_1")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_1", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_1", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_1", formData)?.answer === 'Yes' ?
                            <div className="details-section">
                                <div className="lft-section">
                                    <p>{translateThis("c4k_redetermination_sectionFive_form_QN_1_1")}</p>
                                    <p>{translateThis("c4k_redetermination_sectionFive_form_QN_2")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_2', formData)?.answer || '-'}</b></p>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_3")}</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_3", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFive_form_QN_4")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_4", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_4", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_4", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_5")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_5', formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_6")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_6', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_7")}</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_7", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFive_form_QN_8")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_8", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_8", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_8", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_9")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_9', formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_10")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_10', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_11")}</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_11", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFive_form_QN_12")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_12", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_12", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_12", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_13")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_13', formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_14")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_14', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_15")}</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_15", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFive_form_QN_16")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_16", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_16", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_16", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_17")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_17', formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_18")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_18', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_19")}</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_19", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <div className='each-section'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_redetermination_sectionFive_form_QN_20")}</p>
                            <ul>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                            {answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'Yes' ?
                                <p>&nbsp; &nbsp; &nbsp;{translateThis("c4k_redetermination_sectionFive_form_QN_21")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_21', formData)?.answer || '-'}</b></p> : null}

                        </div>
                        {answerGetter("c4k_redetermination_sectionFive_form_QN_20", formData)?.answer === 'Yes' ?
                            <div className="details-section" style={{ alignItems: 'center' }}>
                                <div className="lft-section">
                                    <div className='flex-box'>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_22")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_22', formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_23")} <b>{answerGetter('c4k_redetermination_sectionFive_form_QN_23', formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className="ryt-section">
                                    <div className='check-box inline' style={{ flexDirection: 'column' }}>
                                        <p>{translateThis("c4k_redetermination_sectionFive_form_QN_24")}</p>
                                        <ul>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Weekly' ? 'checked' : ''}>{translateThis("Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Bi-Weekly' ? 'checked' : ''}>{translateThis("Bi-Weekly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Semi-Monthly' ? 'checked' : ''}>{translateThis("Semi-Monthly")}</li>
                                            <li className={answerGetter("c4k_redetermination_sectionFive_form_QN_24", formData)?.answer === 'Monthly' ? 'checked' : ''}>{translateThis("Monthly")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}

                    </div>
                    <div className='gap' />
                    <div className='gap' />
                </div>

                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 6 Parents rights and responsibilities")}</b></h3>
                    <div className='gap' />
                    <div className='parents-rights-sec'>
                        <p>{translateThis("Parents_rights_and_responsibilities_1")} <b>{translateThis("Parents_rights_and_responsibilities_1_1")}</b> {translateThis("Parents_rights_and_responsibilities_1_2")} <b>{translateThis("Parents_rights_and_responsibilities_1_3")}</b>.</p>
                        <ul>
                            <li>{translateThis("Parents_rights_and_responsibilities_2")} <b>{translateThis("Parents_rights_and_responsibilities_2_1")} </b>.</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_3")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_4")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_5")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_6")}</li>
                        </ul>

                        <br />
                        <p><b>{translateThis("Parents_rights_and_responsibilities_7")}</b> </p>
                        <ul>
                            <li>{translateThis("Parents_rights_and_responsibilities_8")} &nbsp;www.ctcare4kids.com .</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_9")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_10")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_11")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_12")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_13")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_14")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_15")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_16")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_17")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_18")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_19")}</li>
                            <li>{translateThis("Parents_rights_and_responsibilities_20")}</li>
                        </ul>
                        <br />
                        <p><b>{translateThis("Parents_rights_and_responsibilities_21")}<br />
                            {translateThis("Parents_rights_and_responsibilities_22")}</b></p>
                    </div>


                    <div className="details-section sign-blk" style={{ alignItems: 'center' }}>
                        <div className="lft-section">
                            <div className="signature-blk" style={{ float: 'left' }}>
                                <div style={{ float: 'left', textAlign: 'center' }}>
                                    <h2 style={{ fontSize: '42px' }}>{answerGetter('c4k_redetermination_sectionSix_form_QN_applicantSign', formData)?.answer || '-'}</h2>
                                    <p>{translateThis("Applicant signature")}</p>
                                    <p>{translateThis("Date")}: <b>{
                                        data.additionalInfo && data.additionalInfo.parentSignedDate
                                            ? data.additionalInfo.parentSignedDate
                                            : ''
                                    }</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div className="signature-blk" style={{ float: 'left' }}>
                                <div style={{ float: 'right', textAlign: 'center' }}>
                                    <h2 style={{ fontSize: '42px' }}>{answerGetter('c4k_redetermination_sectionSix_form_QN_responsibleSign', formData)?.answer || '-'}</h2>
                                    <p>{translateThis("Signature of other legally responsible adult living with you")}</p>
                                    <p>{translateThis("Date")}: <b>{
                                        (data.additionalInfo && data.additionalInfo.parentSignedDate && answerGetter('c4k_redetermination_sectionSix_form_QN_responsibleSign', formData)?.answer)
                                            ? data.additionalInfo.parentSignedDate
                                            : ''
                                    }</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

C4KRedeterminationView.propTypes = {
    data: PropTypes.any
}

export default C4KRedeterminationView;