import React from 'react';
import { Link } from 'react-router-dom';
import SVGIcons from '../images/icons/svgIcons';
import AppIcons from '../images/icons';

import './header.scss';
import BurgerMenu from '../../../../components/nav-section/burger';
import AppPopOverMenu from '../../../../components/appPopOverMenu/index';
import { switchLanguage } from '../../../../helpers/language.helper';


export default function AppHeader() {
    return (
        <>
            <div className='main-head-wrp f-wrp angelicaTemplates'>
                <div className='container'>
                    <div className='main-header f-wrp'>
                        <Link to={'/smartkids'} className='main-logo'><img src={AppIcons.logo} alt='Logo' /></Link>
                    
                        <div className='burger-prof-sec'>
                            <div className='language-box'>
                            <AppPopOverMenu
                                icon={SVGIcons.GlobalIcon()}
                                options={[
                                    {
                                        label: 'English',
                                        onClick: ()=> switchLanguage('en')
                                    },
                                    {
                                        label: 'Spanish',
                                        onClick: ()=> switchLanguage('es')
                                    }, 
                                ]}
                            />
                            </div>
                            <div className='f-wrp'>
                                <div className='menu-box'>
                                    <BurgerMenu />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}