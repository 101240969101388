import * as React from 'react';
import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';

let postFormData = [];
const EarlyChildhoodHealthAssessmentRecord = (props) => {
    const formName = 'early-childhood-health-assessment-record';
    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState(postFormData);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        // const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        const filteredFormDataBoo = postFormData.filter((answer) => answer.questionId !== answerObj.questionId);
        // filteredFormData.push(answerObj)
        filteredFormDataBoo.push(answerObj)
        postFormData = filteredFormDataBoo;
        // setFormData(filteredFormData);
        // setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName,
            formTitle: 'Early childhood health assessment record',
            userId: parentId,
            childId,
            formData: postFormData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>
                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head echar-form-wrp f-wrp'>
                            <h2>{translateThis("Early childhood health assessment")}</h2>
                            <p><b>{translateThis("To Parent or Guardian")}</b> {translateThis("To Parent or Guardian Note")}</p>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Child")}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                {(selectedChild) && <TextInput
                                    key={`early_childhood_health_assessment_form_Key_name`}
                                    label={translateThis('early_childhood_health_assessment_form_question_name')}
                                    name='early_childhood_health_assessment_form_question_name'
                                    id='early_childhood_health_assessment_form_question_name'
                                    value={answerGetter('early_childhood_health_assessment_form_question_name', initialFormData)?.answer || `${selectedChild?.firstName || ''} ${selectedChild?.lastName || ''}`}
                                    translationKey='early_childhood_health_assessment_form_question_name'
                                    type='text'
                                    required
                                    autocomplete="off"
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_name',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />}
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        {(selectedChild && selectedChild.birthday) && <DatePicker
                                            value={answerGetter('early_childhood_health_assessment_form_Key_birthday', initialFormData)?.answer ?
                                                dayjs(answerGetter('early_childhood_health_assessment_form_Key_birthday', initialFormData)?.answer) :
                                                dayjs(selectedChild.birthday)
                                            }
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_Key_birthday',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'early_childhood_health_assessment_form_Key_birthday',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                            label={translateThis("Birthday")}
                                        />}
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3}>
                                <FormControl className='border-option-input' style={{ padding: '3px 15px 10px', marginTop: '25px' }}>
                                    <FormLabel id="early_childhood_health_assessment_form_Key_question_sex">{translateThis('sex')}</FormLabel>
                                    <RadioGroup row
                                        aria-labelledby="early_childhood_health_assessment_form_question_sex"
                                        defaultValue={answerGetter('early_childhood_health_assessment_form_Key_question_sex', initialFormData)?.answer}
                                        name="early_childhood_health_assessment_form_Key_question_sex"
                                        onChange={(e) => {
                                            setFormValue({
                                                questionId: 'early_childhood_health_assessment_form_Key_question_sex',
                                                answer: e.target.value,
                                                translationKey: 'early_childhood_health_assessment_form_Key_question_sex',
                                                type: 'radio'
                                            })
                                        }}
                                    >
                                        <FormControlLabel value="male" control={<Radio />} label={translateThis("Male")} />
                                        <FormControlLabel value="female" control={<Radio />} label={translateThis("Female")} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_address`}
                                    label={translateThis('early_childhood_health_assessment_form_question_address')}
                                    name='early_childhood_health_assessment_form_question_address'
                                    id='early_childhood_health_assessment_form_question_address'
                                    value={answerGetter('early_childhood_health_assessment_form_question_address', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_address'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_address',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_city`}
                                    label={translateThis('early_childhood_health_assessment_form_question_city')}
                                    name='early_childhood_health_assessment_form_question_city'
                                    id='early_childhood_health_assessment_form_question_city'
                                    value={answerGetter('early_childhood_health_assessment_form_question_city', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_city'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_city',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_zipcode`}
                                    label={translateThis('early_childhood_health_assessment_form_question_zipcode')}
                                    name='early_childhood_health_assessment_form_question_zipcode'
                                    id='early_childhood_health_assessment_form_question_zipcode'
                                    value={answerGetter('early_childhood_health_assessment_form_question_zipcode', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_zipcode'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_zipcode',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Parent Guardian name")}</h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_guardianName`}
                                    label={translateThis('early_childhood_health_assessment_form_question_guardianName')}
                                    name='early_childhood_health_assessment_form_question_guardianName'
                                    id='early_childhood_health_assessment_form_question_guardianName'
                                    value={answerGetter('early_childhood_health_assessment_form_question_guardianName', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_guardianName'
                                    type='text'
                                    required
                                    autocomplete="off"
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_guardianName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_guardianHomePhone`}
                                    label={translateThis('early_childhood_health_assessment_form_question_guardianHomePhone')}
                                    name='early_childhood_health_assessment_form_question_guardianHomePhone'
                                    id='early_childhood_health_assessment_form_question_guardianHomePhone'
                                    value={answerGetter('early_childhood_health_assessment_form_question_guardianHomePhone', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_guardianHomePhone'
                                    type='number'
                                    required
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_guardianHomePhone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_guardianCellPhone`}
                                    label={translateThis('early_childhood_health_assessment_form_question_guardianCellPhone')}
                                    name='early_childhood_health_assessment_form_question_guardianCellPhone'
                                    id='early_childhood_health_assessment_form_question_guardianCellPhone'
                                    value={answerGetter('early_childhood_health_assessment_form_question_guardianCellPhone', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_guardianCellPhone'
                                    type='number'
                                    required
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_guardianCellPhone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Early Childhood Program")}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_ChildhoodName`}
                                    label={translateThis('early_childhood_health_assessment_form_question_ChildhoodName')}
                                    name='early_childhood_health_assessment_form_question_ChildhoodName'
                                    id='early_childhood_health_assessment_form_question_ChildhoodName'
                                    value={answerGetter('early_childhood_health_assessment_form_question_ChildhoodName', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_ChildhoodName'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_ChildhoodName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_ChildhoodPhone`}
                                    label={translateThis('early_childhood_health_assessment_form_Key_ChildhoodPhone')}
                                    name='early_childhood_health_assessment_form_Key_ChildhoodPhone'
                                    id='early_childhood_health_assessment_form_Key_ChildhoodPhone'
                                    value={answerGetter('early_childhood_health_assessment_form_Key_ChildhoodPhone', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_Key_ChildhoodPhone'
                                    type='number'
                                    required
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_Key_ChildhoodPhone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_PrimaryHealthCareProvider`}
                                    label={translateThis('early_childhood_health_assessment_form_question_PrimaryHealthCareProvider')}
                                    name='early_childhood_health_assessment_form_question_PrimaryHealthCareProvider'
                                    id='early_childhood_health_assessment_form_question_PrimaryHealthCareProvider'
                                    value={answerGetter('early_childhood_health_assessment_form_question_PrimaryHealthCareProvider', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_question_PrimaryHealthCareProvider'
                                    type='text'
                                    required
                                    placeholder='Type here'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_question_PrimaryHealthCareProvider',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_dentistName`}
                                    label={translateThis('early_childhood_health_assessment_form_Key_dentistName')}
                                    name='early_childhood_health_assessment_form_Key_dentistName'
                                    id='early_childhood_health_assessment_form_Key_dentistName'
                                    value={answerGetter('early_childhood_health_assessment_form_Key_dentistName', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_Key_dentistName'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_Key_dentistName',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_raceEthnicity">{translateThis('raceEthnicity')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_raceEthnicity', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_raceEthnicity"
                                            id="early_childhood_health_assessment_form_question_raceEthnicity"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_raceEthnicity',
                                                    answer: e.target.value,
                                                    translationKey: 'raceEthnicity',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="American Indian/Alaska Native" control={<Radio />} label={translateThis("American Indian/Alaska Native")} />
                                            <FormControlLabel value="Asian" control={<Radio />} label={translateThis("Asian")} />
                                            <FormControlLabel value="Black or African American" control={<Radio />} label={translateThis("Black or African American")} />
                                            <FormControlLabel value="Hispanic/Latino of any race" control={<Radio />} label={translateThis("Hispanic/Latino of any race")} />
                                            <FormControlLabel value="Native Hawaiian/Pacific Islander" control={<Radio />} label={translateThis("Native Hawaiian/Pacific Islander")} />
                                            <FormControlLabel value="White" control={<Radio />} label={translateThis("White")} />
                                            <FormControlLabel value="other" className='with-txt-box' control={<Radio />} label={
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <span>{translateThis("Other")}</span>
                                                    {(answerGetter('early_childhood_health_assessment_form_question_raceEthnicity', formData)?.answer === "other") && <TextInput
                                                        key={`early_childhood_health_assessment_form_Key_question_raceEthnicity_other`}
                                                        label={translateThis('early_childhood_health_assessment_form_question_raceEthnicity_other')}
                                                        name='early_childhood_health_assessment_form_question_raceEthnicity_other'
                                                        id='early_childhood_health_assessment_form_question_raceEthnicity_other'
                                                        value={answerGetter('early_childhood_health_assessment_form_question_raceEthnicity_other', initialFormData)?.answer}
                                                        translationKey='early_childhood_health_assessment_form_question_raceEthnicity_other'
                                                        type='text'
                                                        placeholder='Type here'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'early_childhood_health_assessment_form_question_raceEthnicity_other',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />}
                                                </div>
                                            } />
                                        </RadioGroup>


                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel className='main-head' id="medicaidText" style={{ color: '#B4AC9C' }}>{translateThis('medicaidText')}</FormLabel>
                                <TextInput
                                    key={`early_childhood_health_assessment_form_Key_medicaidNumber`}
                                    label={translateThis('early_childhood_health_assessment_form_Key_medicaidNumber')}
                                    name='early_childhood_health_assessment_form_Key_medicaidNumber'
                                    id='early_childhood_health_assessment_form_Key_medicaidNumber'
                                    value={answerGetter('early_childhood_health_assessment_form_Key_medicaidNumber', initialFormData)?.answer}
                                    translationKey='early_childhood_health_assessment_form_Key_medicaidNumber'
                                    type='number'
                                    required
                                    placeholder='Phone'
                                    className='custom-textfield maxWidth300'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'early_childhood_health_assessment_form_Key_medicaidNumber',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_insuranceQN_1">{translateThis('insuranceQN_1')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_insuranceQN_1', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_insuranceQN_1"
                                            id="early_childhood_health_assessment_form_question_insuranceQN_1"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_insuranceQN_1',
                                                    answer: e.target.value,
                                                    translationKey: 'insuranceQN_1',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_insuranceQN_2">{translateThis('insuranceQN_2')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_insuranceQN_2', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_insuranceQN_2"
                                            id="early_childhood_health_assessment_form_question_insuranceQN_2"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_insuranceQN_2',
                                                    answer: e.target.value,
                                                    translationKey: 'insuranceQN_2',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_insuranceQN_3">{translateThis('insuranceQN_3')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_insuranceQN_3', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_insuranceQN_3"
                                            id="early_childhood_health_assessment_form_question_insuranceQN_3"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_insuranceQN_3',
                                                    answer: e.target.value,
                                                    translationKey: 'insuranceQN_3',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <span><i>{translateThis("If your child does not have health insurance")} </i>{translateThis("1-877-CT-HUSKY")}</span>
                                </div>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Please answer these health history questions about your child before the physical examination")}<i> {translateThis("To be completed by parent/guardian")}</i></h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_1">{translateThis('historyQuestion_1')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_1', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_1"
                                            id="early_childhood_health_assessment_form_question_historyQuestion_1"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_1',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_1',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_2">{translateThis('historyQuestion_2')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_2', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_2"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_2',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_2',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_3">{translateThis('historyQuestion_3')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_3', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_3"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_3',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_3',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_4">{translateThis('historyQuestion_4')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_4', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_4"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_4',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_4',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_5">{translateThis('historyQuestion_5')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_5', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_5"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_5',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_5',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_6">{translateThis('historyQuestion_6')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_6', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_6"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_6',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_6',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_7">{translateThis('historyQuestion_7')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_7', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_7"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_7',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_7',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_8">{translateThis('historyQuestion_8')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_8', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_8"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_8',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_8',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_9">{translateThis('historyQuestion_9')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_9', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_9"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_9',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_9',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_10">{translateThis('historyQuestion_10')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_10', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_10"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_10',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_10',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_11">{translateThis('historyQuestion_11')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_11', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_11"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_11',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_11',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_12">{translateThis('historyQuestion_12')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_12', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_12"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_12',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_12',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_13">{translateThis('historyQuestion_13')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_13', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_13"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_13',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_13',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_14">{translateThis('historyQuestion_14')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_14', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_14"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_14',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_14',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_15">{translateThis('historyQuestion_15')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_15', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_15"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_15',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_15',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_16">{translateThis('historyQuestion_16')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_16', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_16"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_16',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_16',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_17">{translateThis('historyQuestion_17')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_17', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_17"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_17',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_17',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_18">{translateThis('historyQuestion_18')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_18', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_18"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_18',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_18',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_19">{translateThis('historyQuestion_19')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_19', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_19"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_19',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_19',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_20">{translateThis('historyQuestion_20')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_20', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_20"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_20',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_20',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_21">{translateThis('historyQuestion_21')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_21', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_21"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_21',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_21',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_22">{translateThis('historyQuestion_22')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_22', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_22"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_22',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_22',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_23">{translateThis('historyQuestion_23')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_23', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_23"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_23',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_23',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_24">{translateThis('historyQuestion_24')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_24', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_24"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_24',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_24',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_25">{translateThis('historyQuestion_25')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_25', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_25"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_25',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_25',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_26">{translateThis('historyQuestion_26')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_26', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_26"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_26',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_26',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_27">{translateThis('historyQuestion_27')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_27', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_27"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_27',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_27',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_28">{translateThis('historyQuestion_28')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_28', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_28"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_28',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_28',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_29">{translateThis('historyQuestion_29')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_29', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_29"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_29',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_29',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Any concern about your child")}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_30">{translateThis('historyQuestion_30')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_30', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_30"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_30',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_30',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_31">{translateThis('historyQuestion_31')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_31', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_31"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_31',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_31',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_32">{translateThis('historyQuestion_32')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_32', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_32"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_32',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_32',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_33">{translateThis('historyQuestion_33')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_33', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_33"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_33',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_33',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_34">{translateThis('historyQuestion_34')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_34', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_34"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_34',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_34',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_35">{translateThis('historyQuestion_35')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_35', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_35"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_35',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_35',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_36">{translateThis('historyQuestion_36')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_36', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_36"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_36',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_36',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_37">{translateThis('historyQuestion_37')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_37', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_37"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_37',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_37',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_38">{translateThis('historyQuestion_38')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_38', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_38"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_38',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_38',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <span>{translateThis("Explain all yes answers or provide any additional information")}</span>
                                </div>
                                <TextField
                                    key={`early_childhood_health_assessment_form_Key_comments`}
                                    label={translateThis('early_childhood_health_assessment_form_comments')}
                                    name='early_childhood_health_assessment_form_comments'
                                    id='early_childhood_health_assessment_form_comments'
                                    defaultValue={answerGetter('early_childhood_health_assessment_form_comments', initialFormData)?.answer}
                                    type='text'
                                    multiline
                                    placeholder=''
                                    className='custom-textfield'
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: 'early_childhood_health_assessment_form_comments',
                                            answer: e.target.value,
                                            translationKey: 'early_childhood_health_assessment_form_comments',
                                            type: 'text'
                                        })
                                    }}
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl className='with-txtArea-sec'>
                                        <FormLabel className='main-head' id="early_childhood_health_assessment_form_question_historyQuestion_39">{translateThis('historyQuestion_39')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('early_childhood_health_assessment_form_question_historyQuestion_39', initialFormData)?.answer}
                                            name="early_childhood_health_assessment_form_question_historyQuestion_39"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'early_childhood_health_assessment_form_question_historyQuestion_39',
                                                    answer: e.target.value,
                                                    translationKey: 'historyQuestion_39',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <span>{translateThis("Please list any medications your child will need to take during program hours")}</span>
                                </div>
                                <TextField
                                    key={`early_childhood_health_assessment_form_Key_comments_2`}
                                    label={translateThis('early_childhood_health_assessment_form_comments_2')}
                                    name='early_childhood_health_assessment_form_comments_2'
                                    id='early_childhood_health_assessment_form_comments_2'
                                    defaultValue={answerGetter('early_childhood_health_assessment_form_comments_2', initialFormData)?.answer}
                                    type='text'
                                    multiline
                                    placeholder=''
                                    className='custom-textfield'
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: 'early_childhood_health_assessment_form_comments_2',
                                            answer: e.target.value,
                                            translationKey: 'early_childhood_health_assessment_form_comments_2',
                                            type: 'text'
                                        })
                                    }}
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head'>
                                    <i>{translateThis("All medications taken in child")}</i>
                                    <p>{translateThis("I give my consent for my child")}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className='maxWidth200Con f-wrp'>
                                    <div className='info-head f-wrp' style={{ padding: '8px' }}>
                                        <span>&nbsp;</span>
                                    </div>
                                    <div className='maxWidth200' style={{ float: 'right' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('early_childhood_health_assessment_form_Key_DateOFApplication', initialFormData)?.answer ?
                                                        dayjs(answerGetter('early_childhood_health_assessment_form_Key_DateOFApplication', initialFormData)?.answer) :
                                                        ''
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'early_childhood_health_assessment_form_Key_DateOFApplication',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'early_childhood_health_assessment_form_DateOFApplication',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label={translateThis("Date")} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>


                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <span>{translateThis("Parent Guardian signature")}</span>
                                    </div>
                                    <TextInput
                                        key={`early_childhood_health_assessment_form_key_question_signatureName`}
                                        label={translateThis('early_childhood_health_assessment_form_question_signatureName')}
                                        name='early_childhood_health_assessment_form_question_signatureName'
                                        id='early_childhood_health_assessment_form_question_signatureName'
                                        translationKey='early_childhood_health_assessment_form_question_signatureName'
                                        type='text'
                                        required
                                        placeholder='Type your name'
                                        className='custom-textfield'
                                        getValue={(_, value) => {
                                            setSignature(value)
                                        }}
                                        useDefaultValidation
                                    />
                                    <FormGroup className='check-label'>
                                        <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("I")}, {signature && <b>{signature},</b>} {translateThis("affirm that the information")}</span>
                                        } />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </>
                <br />
                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='pln-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm() || !isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>
                    </div>
                </div>
            </div> : null}
        </>
    );
}

EarlyChildhoodHealthAssessmentRecord.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default React.memo(EarlyChildhoodHealthAssessmentRecord);