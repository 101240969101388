import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../images/icons/svgIcons';
// import SVGIcons from '../images/icons/index';

import './Services.scss';
import { translateThis } from "../../../../helpers/language.helper";


function FriendlyFamilyServices() {

  const aboutItems = [
    {
      className: 'medical',
      icon: SVGIcons.FriendlyMedical(),
      title: 'Medical support',
      description: 'Certified CPR and First Aid professionals ensuring your child’s safety.',
    },
    {
      className: 'playground',
      icon: SVGIcons.FriendlyPlayground(),
      title: 'Playground',
      description: 'Fun and safe outdoor play areas to stimulate physical development.',
    },
    {
      className: 'subsidized',
      icon: SVGIcons.FriendlyCare(),
      title: 'Subsidized care',
      description: 'Affordable childcare options with C4K subsidized care program.',
    },
    
  ];

  return (
    <>
      <div className="FriendFamily-Services-wrp">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={30}
            slidesPerView={3}
            className='content-sec-mob'
            navigation
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1275: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}

          >
            {aboutItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className={`${element.className} each-service-sec f-wrp `}>
                  <span className='service-icon'>{element.icon}</span>
                  <div className="service-con-sec f-wrp">
                    <h2>{translateThis(element.title)}</h2>
                    <p>{translateThis(element.description)}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
      </div>
    </>
  );
}

export default FriendlyFamilyServices;