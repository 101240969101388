import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_TIME_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';


const SpecialNeeds = (props) => {
    const formName = 'special-needs';
    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName: 'special-needs',
            formTitle: 'Special Needs',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>
                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Special Needs")}</h2>
                        </div>
                        <div className='parents-rights-sec'>
                            <ul style={{ paddingLeft: '15px' }}>
                                <li style={{ listStylePosition: 'outside', marginBottom: '10px' }}>{translateThis("Special Needs Text_1")}.</li>
                                <li style={{ listStylePosition: 'outside' }}>{translateThis("Special Needs Text_2")}.</li>
                            </ul>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head'>
                                    <p><b>{translateThis("Special Needs Text_3")}</b></p>
                                    <p>{translateThis("Special Needs Text_4")}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`special_needs_form_Key_patientName`}
                                    label={translateThis('special_needs_form_question_patientName')}
                                    name='special_needs_form_question_patientName'
                                    id='special_needs_form_question_patientName'
                                    value={answerGetter('special_needs_form_question_patientName', initialFormData)?.answer}
                                    translationKey='special_needs_form_question_patientName'
                                    type='text'
                                    required
                                    autocomplete="off"
                                    placeholder='Patient’s Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'special_needs_form_question_patientName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} style={{ padding: '0' }}>
                                <Divider style={{ display: 'none' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Special Needs Text_6")}</h3>
                                    </div>
                                    <TextInput
                                        key={`special_needs_form_Key_adultName`}
                                        label={translateThis('special_needs_form_question_adultName')}
                                        name='special_needs_form_question_adultName'
                                        id='special_needs_form_question_adultName'
                                        value={answerGetter('special_needs_form_question_adultName', initialFormData)?.answer}
                                        translationKey='special_needs_form_question_adultName'
                                        type='text'
                                        required
                                        autocomplete="off"
                                        placeholder='Parent or supervising adult name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'special_needs_form_question_adultName',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>


                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Parent Guardian signature")}</h3>
                                    </div>
                                    <TextInput
                                        key={`special_needs_form_key_question_signatureName`}
                                        label={translateThis('special_needs_form_question_signatureName')}
                                        name='special_needs_form_question_signatureName'
                                        id='special_needs_form_question_signatureName'
                                        translationKey='special_needs_form_question_signatureName'
                                        value={answerGetter('special_needs_form_question_signatureName', initialFormData?.formData)?.answer}
                                        type='text'
                                        required
                                        placeholder='Type your name'
                                        className='custom-textfield'
                                        getValue={(_, value) => {
                                            setSignature(value)
                                        }}
                                        useDefaultValidation
                                    />
                                    <FormGroup className='check-label'>
                                        <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("I")}, {signature && <b>{signature},</b>} {translateThis("affirm that the information provided is true and complete")}.</span>
                                        } />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </>
                <br />
                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='pln-btn'>Cancel</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm() || !isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            Submit
                        </LoadingButton>
                    </div>
                </div>
            </div> : null}
        </>
    );
}

SpecialNeeds.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default SpecialNeeds;