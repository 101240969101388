import React from 'react';
import { Button } from '@mui/material';
import { NavHashLink } from 'react-router-hash-link';
import Marquee from "react-fast-marquee";
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import FriendlyFamilyServices from './Services/Services';
import './custom.scss';
import { translateThis } from '../../../helpers/language.helper';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import Programs from './programs/programs';
import SVGIcons from './images/icons/svgIcons';
import Teachers from './teachers/teachers';

const FriendlyFamilyTemplate = () => {

    const routineItems = [
        {
            icon: SVGIcons.FriendlyHours(),
            title: translateThis('routineHours'),
            content: translateThis('routineHoursText'),
        },
        {
            icon: SVGIcons.FriendlyAge(),
            title: translateThis('routineAccepted ages'),
            content: translateThis('routineAccepted ages Text'),
        },
        {
            icon: SVGIcons.FriendlyDays(),
            title: translateThis('routineDays'),
            content: translateThis('routineDays Text'),
        },
        {
            icon: SVGIcons.FriendlySubsidy(),
            title: translateThis('routineSubsidy programs'),
            content: translateThis('routineSubsidy programsText'),
        },

    ];

    const pageName = "Friendly Family";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='FriendFamilyTemplates'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <div className='scroll-top f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollTop} alt='banner' /></span>
                            </Marquee>
                        </div>

                        <span className='pcBG'><img src={AppIcons.bannerBG} alt='banner' /></span>
                        <span className='mobBG'><img src={AppIcons.bannerBGMob} alt='banner' /></span>
                        <div className='scroll-bottom f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollBottom} alt='banner' /></span>
                            </Marquee>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <div className='banner-con'>
                                        <span><img src={AppIcons.logo} alt='Friendly family' /></span>
                                        <h1>{translateThis('Safe, reliable care at Friendly Family')}</h1>
                                        <p>{translateThis('Offering dependable childcare in a nurturing environment. Trust Friendly Family Childcare for your child’s growth and happiness.')}</p>
                                    </div>
                                </div>
                                <div className='banner-btn-sec f-wrp'>
                                    <Button onClick={() => {
                                        window.location.href = 'https://www.evolvechildcare.com/friendlyfamily/my-portal'
                                    }} className='sqr-btn'>{translateThis('Parent Portal')}</Button>
                                    <NavHashLink smooth to={'#our-program'} >
                                        <Button className='sqr-btn child2'>{translateThis('Our program')}</Button>
                                    </NavHashLink>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='service-slider-sec f-wrp'>
                    <div className='service-sec-header f-wrp'>
                        <div className='container'>
                            <h1>{translateThis('Our Services')}</h1>
                            <p>{translateThis('At Friendly Family Childcare, we offer a comprehensive range of services designed to support your child’s growth and well-being.')}</p>
                        </div>
                    </div>
                    <FriendlyFamilyServices />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <h1>{translateThis('Our Work')}</h1>
                                <p>{translateThis('Friendly Family Our Work Text1')} </p>
                                <p>{translateThis('Friendly Family Our Work Text2')}</p>
                                <p>{translateThis('Friendly Family Our Work Text3')}</p>
                            </div>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='info-wrapper-section f-wrp'>
                    <div className='routine-box f-wrp'>
                        <ul>
                            {routineItems.map((element, key) => (
                                <li key={key}>
                                    <div className='each-routine-box f-wrp'>
                                        <div className='icon-sec'>
                                            <span>{element.icon}</span>
                                        </div>
                                        <div className='content-sec'>
                                            <h3>{element.title}</h3>
                                            <p>{element.content}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='programs-slider-sec f-wrp' id='our-program'>
                    <div className='container'>
                        <div className='programs-sec-header f-wrp'>
                            <h1>{translateThis('Our Program')}</h1>
                            <p>{translateThis('Our ProgramText')}</p>
                        </div>
                        <div className='gap f-wrp' style={{ paddingTop: '75px' }} />
                        <Programs />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='daily-schedule-sec f-wrp'>
                    <div className='container'>
                        <div className='daily-schedule-sec-header f-wrp'>
                            <h1>{translateThis('Daily schedule')}</h1>
                            <p>{translateThis('Our schedule each day remains flexible. The schedule for infants and toddlers is based upon individual needs.')}</p>
                        </div>
                        <div className='gap f-wrp' style={{ paddingTop: '75px' }} />
                        <div className='schedule-con-box f-wrp'>
                            <div className='schedule-sec-box f-wrp'>
                                <div className='each-schedule-box infant f-wrp'>
                                    <h1>{translateThis('Infant')}</h1>
                                    <p>{translateThis('The infants’ lesson plans include creative/sensory, motor and language, feeding, napping, diapering and activities.')}</p>
                                    <span>{translateThis('0-17 months')}</span>
                                </div>
                                <div className='each-schedule-box Toddler f-wrp'>
                                    <h1>{translateThis('Toddler & above')}</h1>
                                    <p>{translateThis('The older children show readiness for a more structured environment, they are encouraged to join the group activities.')}</p>
                                    <span>{translateThis('18 months - 3 years')}</span>
                                </div>
                            </div>
                            <p>{translateThis('A typical day at Friendly Family Childcare LLC looks like the following:')}</p>
                            <div className='timeTable-con-box f-wrp'>
                                <div className='each-timeTable-box f-wrp'>
                                    <ul>
                                        <li>
                                            <span>{translateThis('7:30am - 8:00am')}</span>
                                            <p>{translateThis('Arrival, greeting and free play (puzzles, dress up, books, puppets)')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('8:00am - 8:30am')}</span>
                                            <p>{translateThis('Clean up, wash hands and setting up for breakfast')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('8:30am - 9:00am')}</span>
                                            <p>{translateThis('Breakfast')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('9:00am - 9:30am')}</span>
                                            <p>{translateThis('Diaper & potty time, wash hands and get ready for circle time. /Napping')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('9:30am - 10:00am')}</span>
                                            <p>{translateThis('Good morning circle time/story time, songs, finger-play, sharing special toy for show and tell')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('10:00am - 10:30am')}</span>
                                            <p>{translateThis('Snack time (Nutritious and healthy snacks)')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('10:30am - 11:00am')}</span>
                                            <p>{translateThis('Educational planned activities: A thematic curriculum is planned based upon the theories of Emergent curriculum and Multiple Intelligence. Children also can have the opportunity to free play as they wish')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('11:00am - 11:45pm')}</span>
                                            <p>{translateThis('Outdoor play time and or indoor gross motor activities and free play')}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className='each-timeTable-box f-wrp'>
                                    <ul>
                                        <li>
                                            <span>{translateThis('12:00pm - 12:45pm')}</span>
                                            <p>{translateThis('Lunch Time')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('12:45pm - 1:00pm')}</span>
                                            <p>{translateThis('Clean up, brush teeth, wash hands and faces, diaper changes, toileting, story reading on cots, settle down for naps')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('1:00pm - 3:00pm')}</span>
                                            <p>{translateThis('Nap Time')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('3:00pm - 3:15pm')}</span>
                                            <p>{translateThis('Wake-up, diapering/toileting as needed, wash hands')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('3:15pm - 3:45pm')}</span>
                                            <p>{translateThis('Snack Time')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('3:45pm - 4:00pm')}</span>
                                            <p>{translateThis('Clean up, wash hands, toilet time')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('4:00pm - 4:45pm')}</span>
                                            <p>{translateThis('Outdoor play')}</p>
                                        </li>
                                        <li>
                                            <span>{translateThis('4:45pm - 5:00pm')}</span>
                                            <p>{translateThis('Wash hands, diaper change and get ready for pick up')}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='gallery-sec-header f-wrp'>
                            <h1>{translateThis('Our photos')}</h1>
                            <p>{translateThis('See the heart of Friendly Family Childcare in our gallery, showcasing moments of joy and growth.')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Gallery />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='install-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='install-sec-blk f-wrp'>
                            <div className='install-img'>
                                <span><img src={AppIcons.installImg} alt='Our Installations' /></span>
                            </div>
                            <div className='install-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Friendly Our Installations')}</h1>
                                    <p>{translateThis('Friendly Our Installations Text1')}</p>

                                    <NavHashLink smooth to={'#getInTouch'} >
                                        <Button className='sqr-btn'>{translateThis('Schedule live tour')}</Button>
                                    </NavHashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='info-blk-wrp f-wrp' id='getInTouch'>
                    <div className='container'>
                        <div className='info-sec-blk f-wrp'>
                            <div className='info-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Friendly Contact us')}</h1>
                                    <p>{translateThis('We’re here to help with any questions. Feel free to reach out to us through any of the following methods:')}</p>
                                    <ul>
                                        <li><span>{SVGIcons.PinIcon()} </span>{translateThis('12 Friendly Road, Norwalk, CT, United States, 06851')}</li>
                                        <li><span>{SVGIcons.MailIcon()} </span>{translateThis('friendlyfamilychildcare@gmail.com')}</li>
                                        <li><span>{SVGIcons.PhoneIcon()} </span>{translateThis('914-552-7225 · 203-694-0839')}</li>
                                    </ul>

                                    <ul className='social-media-list'>
                                        <li>
                                            <Button onClick={() => {
                                                window.location.href = 'https://www.evolvechildcare.com/friendlyfamily'
                                            }} className='social-btn'><img src={AppIcons.fb} alt='Facebook'/></Button>
                                        </li>
                                        <li>
                                            <Button onClick={() => {
                                                window.location.href = 'https://www.evolvechildcare.com/friendlyfamily'
                                            }} className='social-btn'><img src={AppIcons.whatsapp} alt='Whatsapp'/></Button>
                                        </li>
                                        <li>
                                            <Button onClick={() => {
                                                window.location.href = 'https://www.evolvechildcare.com/friendlyfamily'
                                            }} className='social-btn'><img src={AppIcons.insta} alt='Instagram'/></Button>
                                        </li>
                                    </ul>


                                </div>
                            </div>
                            <div className='info-img'>
                                <span><img src={AppIcons.infoImg} alt='Our info' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='teachers-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='teachers-sec-header f-wrp'>
                            <h1>{translateThis('Friendly Our Teachers')}</h1>
                            <p>{translateThis('Our skilled and caring staff are committed to providing a nurturing environment for your child’s growth and development.')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Teachers />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='payment-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='payment-sec-blk f-wrp'>
                            <div className='payment-img'>
                                <span><img src={AppIcons.paymentBG} alt='Our payment' /></span>
                            </div>
                            <div className='payment-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Friendly Our Payment Options')}</h1>
                                    <p>{translateThis('Friendly Our Payment Options Text_1')} </p><br/>
                                    <p>{translateThis('Friendly Our Payment Options Text_2')} </p>
                                    <Button onClick={() => {
                                        window.location.href = 'https://www.evolvechildcare.com/friendlyfamily/my-portal'
                                    }} className='sqr-btn'>{translateThis('Pay online')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='testimonial-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{translateThis('Friendly Our Clients Say')}</h1>
                            <p>{translateThis('Friendly Our Clients Say_text')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Testimonial />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

            </div>
        </AppLayout>
    )
}

export default FriendlyFamilyTemplate;