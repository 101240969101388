import React from "react";
// import { element } from "prop-types";
import { translateThis } from '../../../../helpers/language.helper';
import AppIcons from "../images/icons";
import './teachers.scss';




function Teachers() {

  const teachersItems = [
    {
      image: AppIcons.teachers2,
      title: 'Kathleen Tejada',
      position: 'Director',
      description: "Kathleen shares her home to provide child care services to her community and reassure parents that their children are in good hands. Kathleen is a licensed professional and has started her Bachelor Degree on Early Childhood Education. She has a passion of helping children advance throughout learning and actively engaging with their environment. As a bilingual individual she's willing to speak both languages, spanish & english to the children. As a mother of two, she is experienced to supply children with their needs. Kathleen wants the children to feel respected and loved in this daycare, though she wants parents to know that she'll take good care of their children as if they were her owns.",
    },
    {
      image: AppIcons.teachers1,
      title: 'Eronelys Castro',
      position: 'Assistant',
      description: "As a licensed assistant, Eronelys provides nutritious meals for the children to enjoy and to have the energy to go throughout the day. She also plans outdoor activities like, pool day, field day, etc. As a team Eronely and Kathleen work to fullfill all the childrens needs and maintaning them safe. Eronelys has two children of her own, she understands parents want the best for their children.",
    },

  ];


  return (
    <>
      <div className="Kathleen-teachers-wrp f-wrp">
        <div className="row">
          {teachersItems.map((element, key) => (
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" key={key}>
              <div className="each-teachers-sec f-wrp ">
                <div className="teachers-img-sec f-wrp">
                  <span className='fee-sec-icon'><img src={element.image} alt={element.title} /></span>
                  <div className="title-box f-wrp">
                    <h2>{translateThis(element.title)}</h2>
                    <p>{translateThis(element.position)}</p>
                  </div>
                </div>

                <div className="teachers-con-sec f-wrp">
                  <p>{translateThis(element.description)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Teachers;