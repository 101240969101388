import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Marquee from "react-fast-marquee";
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import Services from './Services/Services';
import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import { translateThis } from '../../../helpers/language.helper';





const AngelicaTemplate = () => {

    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);
    useEffect(() => {
        setHeight(elementRef.current.clientHeight);
    }, []);



    const programItems = [
        {
            icon: AppIcons.days,
            title: 'Days',
            description: <p>{translateThis('Open from Monday to Friday')}</p>,
        },
        {
            icon: AppIcons.hours,
            title: 'Hours',
            description: <p>{translateThis('From 6:30am to 5:00pm')}</p>,
        },
        {
            icon: AppIcons.ages,
            title: 'Accepted Ages',
            description: <p>{translateThis('We accept up to 9 children From 6 weeks to 12 years old')}</p>,
        },
        {
            icon: AppIcons.programs,
            title: 'Subsidy Programs',
            description: <><p>{translateThis('C4K')} <i>{translateThis('Care 4 Kids')}</i> <br />{translateThis('DCF')} <i>{translateThis('Children & Family Department')}</i> <br />{translateThis('CACFP')} <i>{translateThis('Child and Adult Care Food Program')}</i> <br />{translateThis('EHSCCP')} <i>{translateThis('Early Head Start')}</i></p></>,
        },
    ];

    const certificationItems = [
        {
            icon: AppIcons.certificate1,
        },
        {
            icon: AppIcons.certificate2,
        },
        {
            icon: AppIcons.certificate3,
        },
        {
            icon: AppIcons.certificate4,
        },
        {
            icon: AppIcons.certificate5,
        },
        {
            icon: AppIcons.certificate6,
        },
    ];

    const learningItems = [
        {
            icon: AppIcons.learning1,
            className: 'yellow',
            title: 'Physical Development and Health',
            description: <><ul><li>{translateThis('Develop Gross Motor Skills')}</li>
                <li>{translateThis('Develop Fine Motor Skills')}</li>
                <li>{translateThis('Acquire Adaptive Skills')}</li>
                <li>{translateThis('Maintain Physical Health and Well-Being')}</li>
                <li>{translateThis('Safety and Responsibility')}</li>
                <li>{translateThis('Physical Health Status')}</li>
                <li>{translateThis('Visual Motor Integration')}</li>
                <li>{translateThis('Mobility')}</li>
            </ul></>,
        },
        {
            icon: AppIcons.learning2,
            className: 'Turquoise',
            title: 'Social and Emotional Development',
            description: <><ul><li>{translateThis('Develop Self-Regulation')}</li>
                <li>{translateThis('Develop Trusting, Healthy Attachments and Relationships with Primary Caregivers')}</li>
                <li>{translateThis('Develop, Express, Recognize, and Respond to Emotions')}</li>
                <li>{translateThis('Develop Self-Awareness, Self-Concept, and Competence')}</li>
                <li>{translateThis('Develop Social Relationships')}</li>
                <li>{translateThis('Adult relationships')}</li>
                <li>{translateThis('Personal Preferences')}</li>
            </ul></>,
        },
        {
            icon: AppIcons.learning3,
            className: 'Green',
            title: 'Science',
            description: <>
                <ul><li>{translateThis('Apply Scientific Practices')}</li>
                    <li>{translateThis('Engage in the Process of Engineering')}</li>
                    <li>{translateThis('Understand Patterns, Process, and Relationships of Living Things')}</li>
                    <li>{translateThis('Understand Physical Science')}</li>
                    <li>{translateThis('Understand Features of Earth')}</li>
                    <li>{translateThis('Questioning and Defining Problems')}</li>
                    <li>{translateThis('Unity and Diversity of Life')}</li>
                    <li>{translateThis('Earth and Human Activity')}</li>

                </ul></>,
        },
        {
            icon: AppIcons.learning4,
            className: 'purple',
            title: 'Mathmatics',
            description: <>
                <ul><li>{translateThis('Understand Counting and Cardinality')}</li>
                    <li>{translateThis('Understand and Describe Relationships to Solve Problems (Operations and Algebraic Thinking)')}</li>
                    <li>{translateThis('Understand the Attributes and Relative Properties of Objects (Measurement and Data)')}</li>
                    <li>{translateThis('Understand Shapes and Spatial Relationships (Geometry and Spatial Sense)')}</li>

                </ul></>,
        },
        {
            hasValue: 'no',
            image: AppIcons.learning,
            className: ' hide'
        },
        {
            icon: AppIcons.learning5,
            className: 'Teal',
            title: 'Creative Arts',
            description: <>
                <ul><li>{translateThis('Engage In and Enjoy the Arts')}</li>
                    <li>{translateThis('Explore and Respond to Creative Works')}</li>
                    <li>{translateThis('Music')}</li>
                    <li>{translateThis('Visual Arts')}</li>
                    <li>{translateThis('Drama')}</li>
                    <li>{translateThis('Dance')}</li>

                </ul></>,
        },
        {
            icon: AppIcons.learning6,
            className: 'Orange',
            title: 'Cognition',
            description: <>
                <ul><li>{translateThis('Use Logic and Reasoning')}</li>
                    <li>{translateThis('Develop Eective Approaches to Learning')}</li>
                    <li>{translateThis('Strengthen Executive Functioning')}</li>
                    <li>{translateThis('Curiosity and Initiative')}</li>
                    <li>{translateThis('Eagerness to Learn')}</li>
                    <li>{translateThis('Cause and Eect')}</li>
                    <li>{translateThis('Problem Solving')}</li>
                    <li>{translateThis('Cognitive Flexibility')}</li>
                    <li>{translateThis('Working Memory')}</li>
                </ul></>,
        },
        {
            icon: AppIcons.learning7,
            className: 'Violet',
            title: 'Social Studies',
            description: <>
                <ul><li>{translateThis('Understand Change Over Time')}</li>
                    <li>{translateThis('Understand Self, Family, and a Diverse Community')}</li>
                    <li>{translateThis('Learn About People and the Environment')}</li>
                    <li>{translateThis('Develop an Understanding of Economic Systems and Resources')}</li>
                    <li>{translateThis('Culture')}</li>
                    <li>{translateThis('Individual Development and Identity')}</li>
                    <li>{translateThis('Individuals, Groups, and Institutions')}</li>

                </ul></>,
        },
        {
            icon: AppIcons.learning8,
            className: 'red',
            title: 'Language & Literacy',
            description: <>
                <ul>
                    <li>{translateThis('Understand Language')}</li>
                    <li>{translateThis('Use Language')}</li>
                    <li>{translateThis('Use Language for Social Interaction')}</li>
                    <li>{translateThis('Gain Book Appreciation and Knowledge')}</li>
                    <li>{translateThis('Gain Knowledge of Print and its Uses')}</li>
                    <li>{translateThis('Develop Phonological Awareness')}</li>
                    <li>{translateThis('Convey meaning through drawing, letters, and words')}</li>
                    <li>{translateThis('Language Comprehension')}</li>
                </ul></>,
        },
    ];




    // custom home functions here
    const pageName = "Smart kids";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='angelicaTemplates'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <span className='pcBG'><img src={AppIcons.bannerBG} alt='banner' /></span>
                        <span className='mobBG'><img src={AppIcons.bannerBGMob} alt='banner' /></span>

                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <span className='pc-icon'><img src={AppIcons.bannerLogo} alt='banner icon' /></span>
                                    <span className='mob-icon'><img src={AppIcons.logo} alt='banner icon' /></span>
                                    <div className='banner-con'>
                                        <h1>{translateThis('Nurturing')} <br />{translateThis('Young Minds')}</h1>
                                        <p>{translateThis('We offer an environment where children are given opportunities to learn and grow through intentional play according to age and needs')}</p>
                                    </div>
                                </div>
                                <Button onClick={() => {
                                    window.location.href = '/smartkids/my-portal'
                                }} className='sqr-btn'>{translateThis('Parent Portal')}</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-slider-sec f-wrp'>
                    <Services />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <h1>{translateThis('About')} <img src={AppIcons.smartKids} alt='smart kids' /></h1>
                                <p>{translateThis('abtText1')}</p>
                                <p>{translateThis('abtText2')}</p>
                                <p>{translateThis('abtText3')}</p>
                                <span>{translateThis('Angélica')}</span>
                            </div>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='certification-sec-wrp f-wrp'>
                    <div className='certification-sec-header f-wrp'>
                        <div className='container'>
                            <h1>{translateThis('CERTIFICATIONS')}</h1>
                        </div>
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '30px' }} />
                    <div className='certification-con-wrp f-wrp'>
                        <div className='container'>
                            <div className='certification-grid'>
                                <Marquee
                                    autoFill
                                    direction={"left"}
                                    speed={70}
                                >
                                    {certificationItems.map((element, key) => (
                                        <div className='each-certification-sec f-wrp' key={key} style={{padding: '0 30px'}}>
                                            <span className='certification-icon'><img src={element.icon} alt="certification" /></span>
                                        </div>
                                    ))}
                                </Marquee>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap orangeBand f-wrp' style={{ paddingTop: '30px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='contact-blk-wrp f-wrp'>
                    <div className='contact-sec-blk f-wrp'>
                        <div className='contact-con'>
                            <div className='f-wrp'>
                                <h1>{translateThis('Get in touch')}</h1>
                                <h4>{translateThis('Have questions or ready to enroll')} </h4>
                                <p>{translateThis('Reach out to us for more information about our nurturing childcare programs')}</p>
                                <ul>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.mapPoint} alt='pin point' /></span>
                                            <p>{translateThis('22 Clinton St Waterbury, CT 06710')}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.mail} alt='mail' /></span>
                                            <p>{translateThis('smartkids122415@gmail.com')}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.phone} alt='call' /></span>
                                            <p>{translateThis('1 (203) 558-8172')}</p>
                                        </div>
                                    </li>
                                </ul>
                                <Button onClick={() => {
                                    window.location.href = '/smartkids'
                                }} className='sqr-btn'>{translateThis('Contact us')}</Button>
                            </div>
                        </div>
                        <div className='contact-img'>
                            <span><img src={AppIcons.contact} alt='child' /></span>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='program-sec-wrp f-wrp'>
                    <div className='program-sec-header f-wrp'>
                        <div className='container'>
                            <h1>{translateThis('PROGRAMS')}</h1>
                            <p>{translateThis('Here is important information about our facility')}</p>
                        </div>
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '30px' }} />
                    <div className='program-con-wrp f-wrp'>
                        <div className='container'>
                            <div className='program-grid'>
                                {programItems.map((element, key) => (
                                    <div className='each-program-sec f-wrp' key={key}>
                                        <span className='program-icon'><img src={element.icon} alt={element.title} /></span>
                                        <h3>{translateThis(element.title)}</h3>
                                        {element.description}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='ASQ-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='ASQ-content-wrp f-wrp'>
                            <div className='row'>
                                <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='asq-image-box f-wrp'>
                                        <span><img src={AppIcons.ASQLogo} alt='ASQ logo' /></span>
                                    </div>
                                </div>
                                <div className='col-lg-7 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='asq-image-content f-wrp'>
                                        <h1>{translateThis('asqTitle')}</h1>
                                        <p>{translateThis('In')} <b>{translateThis('Smart Kids')}</b> {translateThis('asqTitleText')}</p>

                                        <div className='each-asq-con f-wrp'>
                                            <h3>{translateThis('asqCon1title')}</h3>
                                            <p>{translateThis('asqCon1text')}</p>
                                        </div>
                                        <div className='each-asq-con f-wrp'>
                                            <h3>{translateThis('asqCon2title')}</h3>
                                            <p>{translateThis('asqCon2text')}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sparkler-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='sparkler-con-box'>
                            <div className='row'>
                                <div className='col-lg-7 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='sparkler-con-wrp f-wrp'>
                                        <h1>{translateThis('sparklerTitle')}</h1>
                                        <p>{translateThis('sparklerText1')}</p>
                                        <p>{translateThis('sparklerText2')}</p>
                                        {/* <p>{translateThis('sparklerText3')}</p> */}
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='sparkler-img-wrp f-wrp'>
                                        <div className='sparkler-image-box f-wrp'>
                                            <span><img src={AppIcons.sparklerLogo} alt='ASQ logo' /></span>

                                            <p>{translateThis('sparklerText4')} <Link onClick={() => window.open('https://play.google.com/store/apps/details?id=com.mindful_inc.sparkler&hl=en_US&gl=US', '_blank')}> {translateThis('sparklerText4_1')} </Link> {translateThis('sparklerText4_2')} <Link onClick={() => window.open('https://apps.apple.com/us/app/sparkler-play-for-parenting/id1236766824', '_blank')}> {translateThis('sparklerText4_3')} </Link> {translateThis('sparklerText4_4')}</p>
                                            <div className='intro-txt'>
                                                <p>{translateThis('sparklerText5')} <Link onClick={() => window.open('https://cdi.211ct.org/wp-content/uploads/2015/08/General_ENGLISH_Sparkler-Handout-for-FAMILIES-w-QR-Code-1.pdf', '_blank')}> {translateThis('sparklerText5_1')} </Link></p>
                                                <p>{translateThis('sparklerText6')} <Link onClick={() => window.open('https://playsparkler.org/', '_blank')}> {translateThis('sparklerText6_1')} </Link> {translateThis('sparklerText6_2')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='learning-sec-wrp f-wrp'>
                    <div className='container'>
                        <div className='learning-sec-header f-wrp'>
                            <h1>{translateThis('EARLY LEARNING')}</h1>
                            <p>{translateThis('Across all ages and domains, early learning experiences will support children')}</p>
                        </div>
                        <div className='learning-con-wrp f-wrp'>
                            <div className='learning-grid'>
                                <div className="row">
                                    {learningItems.map((element, key) => (
                                        <div className={`${element.className} col-lg-4 col-md-6 col-sm-12 col-xs-12 `} key={key}>
                                            {element.hasValue ? <div className={`${element.className} each-learning-sec f-wrp `}>
                                                <span className='learning-img'><img src={element.image} alt={element.title} /></span>
                                            </div> : <div className={`${element.className} each-learning-sec f-wrp `}>
                                                <div className='learing-header f-wrp'>
                                                    <h3>{translateThis(element.title)}</h3>
                                                </div>
                                                <div className='learing-content f-wrp'>
                                                    <span className='learning-icon'><img src={element.icon} alt={element.title} /></span>
                                                    {element.description}
                                                </div>
                                            </div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='activities-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='activities-grid-wrp f-wrp'>
                            <div className='activities-img-sec'>
                                <span><img src={AppIcons.hotBallon} alt='Hot Ballon' /></span>
                            </div>
                            <div className='activities-con-sec'>
                                <h1>{translateThis('Activities')} </h1>
                                <p>{translateThis('From creative arts to enriching language, we have something for every curious mind')}</p>
                                <ul>
                                    <li><span>{SVGIcons.Act1Icon()}</span><p>{translateThis('Theme of the month')}</p></li>
                                    <li><span>{SVGIcons.Act2Icon()}</span><p>{translateThis('AB Patterns')}</p></li>
                                    <li><span>{SVGIcons.Act3Icon()}</span><p>{translateThis('Flash cards')}</p></li>
                                    <li><span>{SVGIcons.Act4Icon()}</span><p>{translateThis('Circle time')}</p></li>
                                    <li><span>{SVGIcons.Act5Icon()}</span><p>{translateThis('Colors & crafts')}</p></li>
                                    <li><span>{SVGIcons.Act6Icon()}</span><p>{translateThis('Holding pencil')}</p></li>
                                    <li><span>{SVGIcons.Act7Icon()}</span><p>{translateThis('Music, dance & songs')}</p></li>
                                    <li><span>{SVGIcons.Act8Icon()}</span><p>{translateThis('Sensory bins')}</p></li>
                                    <li><span>{SVGIcons.Act9Icon()}</span><p>{translateThis('Phonics and reading')}</p></li>
                                    <li><span>{SVGIcons.Act10Icon()}</span><p>{translateThis('Numbers and math concepts')}</p></li>
                                    <li><span>{SVGIcons.Act11Icon()}</span><p>{translateThis('Story time')}</p></li>
                                    <li><span>{SVGIcons.Act12Icon()}</span><p>{translateThis('Spelling and writing')}</p></li>
                                    <li><span>{SVGIcons.Act13Icon()}</span><p>{translateThis('Tidy after self')}</p></li>
                                    <li><span>{SVGIcons.Act14Icon()}</span><p>{translateThis('Toilet training')}</p></li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='gallery-sec-header f-wrp'>
                        <h1>{translateThis('Gallery')}</h1>
                    </div>
                    <Gallery />

                    <div className='gap theme-band f-wrp' style={{ paddingTop: '75px' }} />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-founder-wrp f-wrp'>
                    <div className='founder-sec-header f-wrp'>
                        <div className='container'>
                            <div className='gap f-wrp' style={{ paddingTop: '35px' }} />
                            <h1>{translateThis('Meet Our founder')}</h1>
                            <p>{translateThis('Get to know the soul behind Smart Kids')}</p>

                            <div className='founder-con-wrp'>
                                <div className='founder-img'>
                                    <h4 style={{ opacity: '0' }}>hide</h4>
                                </div>
                                <div className='founder-con-blk' style={{ textAlign: 'left' }} ref={elementRef}>
                                    <p><b>{translateThis('Angelica C. Flores')}</b> {translateThis('was born in Mexico City, Mexico and immigrated to Waterbury, Connecticut in April')}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='founder-con-sec f-wrp'>
                        <div className='container'>
                            <div className='founder-con-wrp'>
                                <div className='founder-img' style={{ marginTop: `${-height + 20}px` }}>
                                    <span><img src={AppIcons.founder} alt='founder' /></span>
                                    <h2>{translateThis('Angélica C. Flores')}</h2>
                                    <p>{translateThis('Founder')}</p>
                                </div>
                                <div className='founder-con-blk'>
                                    <p className='mob-para'><b>{translateThis('Angelica C. Flores')}</b> {translateThis('founderText1')}</p>
                                    <p>{translateThis('founderText2')} <Link style={{ color: '#3771C8', textDecorationLine: 'underline' }} onClick={() => window.open('http://www.madre-latina.com/', '_blank')}>{translateThis('founderText2_1')}</Link>{translateThis('founderText2_2')} <Link style={{ color: '#3771C8', textDecorationLine: 'underline' }} onClick={() => window.open('http://www.madre-latina.com/', '_blank')}>{translateThis('founderText2_1')}</Link> {translateThis('founderText2_3')}</p>
                                    <p>{translateThis('founderText3')}</p>
                                    <p>{translateThis('founderText4')}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='testimonial-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{translateThis('Reviews')}</h1>
                        </div>
                        <Testimonial />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='scrolling-sec f-wrp'>

                    <Marquee
                        autoFill
                        direction={"left"}
                        speed={70}
                    >
                        <div style={{ padding: '0 75px' }}> {translateThis('No registration fees')} </div> <div style={{ padding: '0 75px' }}> {translateThis('No registration fees Text')} </div>
                    </Marquee>
                </div>

                <div className='pay-optiopn-wrp f-wrp'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className='payment-content-sec f-wrp'>
                                    <h1>{translateThis('Payment Options')}</h1>
                                    <p>{translateThis('Payments Made Easy We are pleased to announce we now offer the convenience')} </p>

                                    <p><b>{translateThis('We do not charge for registration fees')}</b> {translateThis('Also diapers, wipes, and meals are all included in the tuition')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                <div className='payment-button-sec f-wrp'>
                                    <span>
                                        <img src={AppIcons.payment} alt='payment' />
                                    </span>
                                    <Button onClick={() => {
                                        window.location.href = '/smartkids/my-portal'
                                    }} className='sqr-btn'>{translateThis('Pay online')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap theme-band f-wrp' style={{ paddingTop: '30px' }} />
            </div>
        </AppLayout>
    )
}

export default AngelicaTemplate;