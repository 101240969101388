import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import ReactPlayer from 'react-player/youtube';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { Button } from '@mui/material';
import AppLayout from '../../layouts/app/AppLayout';
import './home.scss';
import './bannerAnimation.scss';
import AppIcons from '../../assets/images/icons';
import SVGIcons from '../../assets/images/icons/svgIcons';
import { getAuthRoleName } from '../../helpers/auth.helper';
import ApiCaller from '../../services/api/general';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { showSnackbar } from '../../components/snackbar/index';
import KathleenTemplate from '../../features/websiteThemes/kathleen/index';
import AngelicaTemplate from '../../features/websiteThemes/angelica';
import FranciscaTemplate from '../../features/websiteThemes/francisca';
import RafaelaTemplate from '../../features/websiteThemes/rafaela';
import ArgentinaTemplate from '../../features/websiteThemes/argentina';
import YreneTemplate from '../../features/websiteThemes/yrene';
import FriendlyFamilyTemplate from '../../features/websiteThemes/friendlyFamily';
import { translateThis } from '../../helpers/language.helper';
import FacilityAppHeader from './facilityHeader';
import PPPlatformHomePage from './ppPlatformHomePage';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';



const parkingSliderItems = [
    {
        Image: AppIcons.parkingSlider1,
        content: <><p>{translateThis('Your facility')} <b>{translateThis('100% Digital')}</b> {translateThis('on')} <b>{translateThis('Mobile')}</b></p></>,
    },
    {
        Image: AppIcons.parkingSlider10,
        content: <><p>{translateThis('Attract clients with a catchy')} <b>{translateThis('Website')}</b></p></>,
    },
    {
        Image: AppIcons.parkingSlider11,
        content: <><p>{translateThis('From web to forms, all with')} <b>{translateThis('your logo')}</b></p></>,
    },
    {
        Image: AppIcons.parkingSlider2,
        content: <><p>{translateThis('All')} <b>{translateThis('forms')}</b> & <b>{translateThis('daily comms')}</b> {translateThis('online')}</p></>,
    },
    {
        Image: AppIcons.parkingSlider3,
        content: <><p>{translateThis('Fill all')} <b>{translateThis('Care4Kids')} </b>{translateThis('forms online')}</p></>,
    },
    {
        Image: AppIcons.parkingSlider4,
        content: <><p>{translateThis('Bilingual')} <b>{translateThis('English')}</b> & <b>{translateThis('Spanish')}</b></p></>,
    },
    {
        Image: AppIcons.parkingSlider5,
        content: <><p>{translateThis('Accept')} <b>{translateThis('credit card')}</b> {translateThis('payments')}</p></>,
    },
    {
        Image: AppIcons.parkingSlider6,
        content: <><p>{translateThis('Share')} <b>{translateThis('news, events')}</b> & <b>{translateThis('pictures')}</b></p></>,
    },
    {
        Image: AppIcons.parkingSlider7,
        content: <><p>{translateThis('Receive')} <b>{translateThis('messages')}</b> & <b>{translateThis('notifications')}</b></p></>,
    },
    {
        Image: AppIcons.parkingSlider8,
        content: <><p>{translateThis('Children daily')} <b>{translateThis('check-ins / outs')}</b></p></>,
    },
    {
        Image: AppIcons.parkingSlider9,
        content: <><p>{translateThis('Manage your')} <b>{translateThis('staff members')}</b> {translateThis('online')}</p></>,
    },


];



const HomePage = () => {
    const { facility = '' } = useParams();
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('facility')
    const [isLoading, setIsLoading] = useState(true)
    const [facilityData, setFacilityData] = useState({});
    const [configState, setConfigState] = useRecoilState(myPortalState);
    const appStateAs = useRecoilValue(appConfigAtomState);
    const [appState, setAppState] = useState({
        selectedLang: appStateAs?.appStateAs || ''
    })    

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ facilityInfo: { ...prv.facilityInfo, ...modifiedObj } } }));


    useEffect(() => {
        if (facility) {
            getFacilityInfo()
        }
        setAppState(appStateAs);
        setTimeout(() => {
            document.body.classList.add('facility-home');
        }, 500);
        // eslint-disable-next-line
    }, [facility, appStateAs]);

    const getFacilityInfo = () => {
        if (facilityData) {
            // Do something later
        }
        if (configState) {
            apiCaller.getData(facility).then((data) => {
                if (data) {
                    setFacilityData(data)
                    modifyConfigState(data);
                } else {
                    showSnackbar().failure("Requested facility not found");
                    navigator('/')
                }
            }).finally(() => setIsLoading(false))
        }
    }


    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };





    // custom home functions here
    const pageName = "Home";
    return (
        <>
            {(facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "jskidscastle") ? <FranciscaTemplate key={`FranciscaTemplate-${appState?.selectedLang}`} /> :
                (facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "brumbrum") ? <RafaelaTemplate key={`RafaelaTemplate-${appState?.selectedLang}`} /> :
                    (facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "sweetsunrise") ? <KathleenTemplate key={`KathleenTemplate-${appState?.selectedLang}`} /> :
                        (facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "kairos") ? <ArgentinaTemplate key={`ArgentinaTemplate-${appState?.selectedLang}`} /> :
                            (facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "smartkids") ? <AngelicaTemplate key={`AngelicaTemplate-${appState?.selectedLang}`} /> :
                                (facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "preciousmoment") ? <YreneTemplate key={`YreneTemplate-${appState?.selectedLang}`} /> :
                                    (facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "friendlyfamily") ? <FriendlyFamilyTemplate key={`FriendFamilyTemplates-${appState?.selectedLang}`} /> :
                                        (facilityData?.facilityURL && facilityData?.facilityURL.toLowerCase() === "ppplatform") ? <PPPlatformHomePage key={`PPPlatformHomePage-${appState?.selectedLang}`} /> :
                                            <AppLayout
                                                pageName={pageName}
                                                // isLoadHeader={false}
                                                isLoadFooter={false}
                                                customHeader={<FacilityAppHeader />}
                                                bodyClassName='home-main-wrp f-wrp'
                                            >
                                                {isLoading ? <span>Loading...</span> : (!isLoading) ? <>

                                                    <div className='parking-page-main-wrapper f-wrp'>
                                                        <div className='parking-banner-con f-wrp' style={{ minHeight: '100vh' }}>
                                                            <div className='parking-banner-img'>
                                                                <span><img src={AppIcons.parkingBanner} alt='banner' /></span>
                                                            </div>
                                                            <div className='parkingBanner-con'>
                                                                <div className='parkingBanner-sec-con'>
                                                                    <span><img src={facilityData?.facilityLogo?.tempURL || AppIcons.logo} alt='logo' /></span>
                                                                    <h1>{facilityData?.facilityInfo?.name}</h1>
                                                                    <p>Manage everything related to your kids accessing the PP Platform</p>


                                                                    {(getAuthRoleName()) ? <Button onClick={() => {
                                                                        window.location.href = `/${facilityData?.facilityURL}/my-portal`
                                                                    }} className='sample-btn'>Access Portal
                                                                    </Button> : <Link to={`/${facilityData?.facilityURL}/auth/login`} className='sample-btn' > Access Portal </Link>}

                                                                    {/* <span style={{marginTop: '60px'}}><img src={AppIcons.PPLogo} alt='logo' /></span> */}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='parking-slider-wrp f-wrp'>
                                                            <div className='container'>
                                                                <div className="row">
                                                                    {parkingSliderItems.map((element, key) => (
                                                                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12' key={key}>
                                                                            <div className="each-parking-items f-wrp">
                                                                                <span><img src={element.Image} alt="user" /></span>
                                                                                {translateThis(element.content)}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='user-manual-wrp f-wrp'>
                                                            <div className='container'>
                                                                <div className='manual-header f-wrp'>
                                                                    <h1>{translateThis('Tutorials')}</h1>
                                                                    <p>{translateThis('manualTitleText')}</p>
                                                                    <div className='video-btn-wrp'>
                                                                        <p>{SVGIcons.BooksIcon()} {translateThis('Watch all the videos in')}</p>
                                                                        <ul className='video-button-list'>
                                                                            <li>
                                                                                <Link className='video-button provider-hub' to={'https://youtube.com/playlist?list=PLZvAEaMuf9TZm0lZhZOBkAFca97wuZ9_D&feature=shared'}>{SVGIcons.PlayButtonIcon()} {translateThis('Provider Hub')}</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link className='video-button parent-hub' to={'https://youtube.com/playlist?list=PLZvAEaMuf9TYMmHw_CeZsPgr36fd-_F57&feature=shared'}>{SVGIcons.PlayButtonIcon()} {translateThis('Parent Hub')}</Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className='accordian-wrapper f-wrp'>
                                                                    <div className="row">
                                                                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                                                            <div className='accordian-sec-wrp f-wrp'>
                                                                                <p>{SVGIcons.BookIcon()} {translateThis('App Walkthrough')}</p>
                                                                                <div className='accordian-sec-box f-wrp'>

                                                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                                                            aria-controls="panel1bh-content"
                                                                                            id="panel1bh-header"
                                                                                        >
                                                                                            <span>{SVGIcons.GrommetIcon()}</span>
                                                                                            <h4>{translateThis('Portal overview')} <p>{translateThis('accordianTextOne')}</p></h4>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <ul>
                                                                                                <li><Link onClick={() => window.open('https://youtu.be/Fg5K3tZmDZI', '_blank')} ><span className='parentIcon'>{SVGIcons.ParentIcon()}</span> {translateThis('Parent Portal')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://youtu.be/ewRuuchr7Zo', '_blank')} ><span>{SVGIcons.HomeIcon()}</span> {translateThis('Provider Portal')}</Link></li>
                                                                                            </ul>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                                                            aria-controls="panel2bh-content"
                                                                                            id="panel2bh-header"
                                                                                        >
                                                                                            <span>{SVGIcons.UserAddIcon()}</span>
                                                                                            <h4>{translateThis('Enrollment')} <p>{translateThis('accordianTextTwo')}</p></h4>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <ul>
                                                                                                <li><Link onClick={() => window.open('https://youtube.com/playlist?list=PLZvAEaMuf9Ta4QA3Iw3b2ayLI4i-Kv9Sr&feature=shared', '_blank')} ><span className='parentIcon'>{SVGIcons.ParentIcon()}</span> {translateThis('Parent Portal')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://www.youtube.com/watch?v=yfGfZwMJsTc', '_blank')} ><span>{SVGIcons.HomeIcon()}</span> {translateThis('Provider Portal')}</Link></li>
                                                                                            </ul>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                                                            aria-controls="panel2bh-content"
                                                                                            id="panel2bh-header"
                                                                                        >
                                                                                            <span>{SVGIcons.CogIcon()}</span>
                                                                                            <h4>{translateThis('Features')} <p>{translateThis('accordianTextThree')}</p></h4>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <ul>
                                                                                                <li><Link onClick={() => window.open('https://youtu.be/lCzKvvmdGlQ', '_blank')} ><span className='PalmIcon'>{SVGIcons.PalmIcon()}</span> {translateThis('Attendance')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://youtube.com/playlist?list=PLZvAEaMuf9TYmX6-rQPsojwdAZf04htdZ&feature=shared', '_blank')} ><span>{SVGIcons.FormsIcon()}</span> {translateThis('Forms')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://youtube.com/playlist?list=PLZvAEaMuf9Ta4QA3Iw3b2ayLI4i-Kv9Sr&feature=shared', '_blank')} ><span>{SVGIcons.CatalogIcon()}</span> {translateThis('Communications')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://www.youtube.com/watch?v=W5LrKnyQKns', '_blank')} ><span>{SVGIcons.AccidentIcon()}</span> {translateThis('Accidents')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('#', '_blank')} ><span className='PaymentsIcon'>{SVGIcons.PaymentsIcon()}</span> {translateThis('Payments')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://youtu.be/lmDMI5X2Mco', '_blank')} ><span>{SVGIcons.BulletinIcon()}</span> {translateThis('Bulletin')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://www.youtube.com/watch?v=zZQU8trVI9s', '_blank')} ><span>{SVGIcons.RuleIcon()}</span> {translateThis('Policies')}</Link></li>
                                                                                            </ul>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                                                            aria-controls="panel2bh-content"
                                                                                            id="panel2bh-header"
                                                                                        >
                                                                                            <span>{SVGIcons.EmployeeIcon()}</span>
                                                                                            <h4>{translateThis('Employee')} <p>{translateThis('accordianTextFour')}</p></h4>

                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <ul>
                                                                                                <li><Link onClick={() => window.open('#', '_blank')} ><span>{SVGIcons.PlusIcon()}</span> {translateThis('Creation')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('#', '_blank')} ><span className='EmployeeIcon'>{SVGIcons.EmployeeIcon()}</span> {translateThis('Employee Portal')}</Link></li>
                                                                                                <li><Link onClick={() => window.open('https://youtu.be/lCzKvvmdGlQ', '_blank')} ><span className='PalmIcon'>{SVGIcons.PalmIcon()}</span> {translateThis('Attendance')}</Link></li>
                                                                                            </ul>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                                                            <div className='video-wrp f-wrp'>
                                                                                <span>
                                                                                    <ReactPlayer width={'100%'} height={278} url='https://youtu.be/ewRuuchr7Zo' />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>











                                                            </div>
                                                        </div>


                                                        <div className='parking-foot f-wrp'>
                                                            <div className='container'>
                                                                <p>© {dayjs().get('year')} MiHapp | Terms </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}
                                            </AppLayout>}
        </>
    )
}

export default HomePage;