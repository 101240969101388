import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';


const DisabilityVerification = (props) => {
    const formName = 'disability-verification';
    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName: 'disability-verification',
            formTitle: 'Disability verification',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>
                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Disability Verification")}</h2>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`disability_verification_form_Key_parentName`}
                                    label={translateThis('disability_verification_form_question_parentName')}
                                    name='disability_verification_form_question_parentName'
                                    id='disability_verification_form_question_parentName'
                                    value={answerGetter('disability_verification_form_question_parentName', initialFormData)?.answer}
                                    translationKey='disability_verification_form_question_parentName'
                                    type='text'
                                    required
                                    autocomplete="off"
                                    placeholder='Parent name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'disability_verification_form_question_parentName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            value={answerGetter('disability_verification_form_Key_DOB', initialFormData)?.answer ?
                                                dayjs(answerGetter('disability_verification_form_Key_DOB', initialFormData)?.answer) :
                                                ''
                                            }
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'disability_verification_form_Key_DOB',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'disability_verification_form_Key_DOB',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                            label={translateThis("DOB")}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>



                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head'>
                                    <p><b>{translateThis("Patient authorization to release information")}</b></p>
                                    <p>{translateThis("I hereby authorize the release of the requested medical/psychiatric information to the State of Connecticut Office of Early Childhood Care 4 Kids program.")}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className='maxWidth200Con f-wrp'>
                                    <div className='info-head f-wrp' style={{ padding: '8px' }}>
                                        <span>&nbsp;</span>
                                    </div>
                                    <div className='maxWidth200' style={{ float: 'right' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('disability_verification_form_Key_DateOFApplication', initialFormData)?.answer ?
                                                        dayjs(answerGetter('disability_verification_form_Key_DateOFApplication', initialFormData)?.answer) :
                                                        ''
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'disability_verification_form_Key_DateOFApplication',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'disability_verification_form_DateOFApplication',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label={translateThis("Submission time")} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>


                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <span>{translateThis("Parent Guardian signature")}</span>
                                    </div>
                                    <TextInput
                                        key={`disability_verification_form_key_question_signatureName`}
                                        label={translateThis('disability_verification_form_question_signatureName')}
                                        name='disability_verification_form_question_signatureName'
                                        value={answerGetter('disability_verification_form_question_signatureName', initialFormData?.formData)?.answer}
                                        id='disability_verification_form_question_signatureName'
                                        translationKey='disability_verification_form_question_signatureName'
                                        type='text'
                                        required
                                        placeholder='Type your name'
                                        className='custom-textfield'
                                        getValue={(_, value) => {
                                            setSignature(value)
                                        }}
                                        useDefaultValidation
                                    />
                                    <FormGroup className='check-label'>
                                        <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("I")}, {signature && <b>{signature},</b>} {translateThis("affirm that the information provided is true and complete")}</span>
                                        } />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </>
                <br />
                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='pln-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm() || !isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>
                    </div>
                </div>
            </div> : null}
        </>
    );
}

DisabilityVerification.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default DisabilityVerification;