import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';


const WrittenPermissionView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp writtenPermissionView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />
                {/* <div className='prof-sec'>
                    <div className='prof-img'>
                    <span><img src={AppIcons.registerBG} alt='profile' /></span>
                </div>
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div> */}
                <h3><b>{translateThis("Written permission form")}</b></h3>

                <div className="details-section">
                    <div className="lft-section">
                        <p>{translateThis("Child’s name")}: <b>{answerGetter("written_permission_form_question_name", formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>{translateThis("Child’s address")}: <b>{answerGetter("written_permission_form_question_address", formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        <p>{translateThis("Child’s Date of birth")}: <b>{answerGetter("written_permission_form_Key_birthday", formData)?.answer || dayjs(child?.birthday).format(DATE_FORMAT) || '-'}</b></p>
                        <div className="flex-box">
                            <p>{translateThis("City")}: <b>{answerGetter("written_permission_form_question_city", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Zipcode")}: <b>{answerGetter("written_permission_form_question_zipcode", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-box'>
                    <p>{translateThis("Persons permitted to remove the child from the care home on behalf of parent")}</p>
                    <div className='auto-grid width25'>
                        <div>
                            <p>{translateThis("Name")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianName", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Address")} &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianAddress", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("City")} &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianCity", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Zipcode")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianZipcode", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Phone")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianPhone", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Relationship")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianRelationship", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    {answerGetter('written_permission_form_responsible_adults', formData)?.answer ? <>
                        {answerGetter('written_permission_form_responsible_adults', formData)?.rawAnswer.map((no) => (
                            <div key={`detail-key-${no}`}>
                                {/* <p>Responsible adult {answerGetter('written_permission_form_responsible_adults', formData)?.answer ? `#${i + 2}` : ''}</p> */}
                                <div className='auto-grid width25'>
                                    <div>
                                        <p>{translateThis("Name")}: &nbsp;</p>
                                        <p><b>{answerGetter(`child_enrollment_form_question_adult_${no}_guardianName`, formData)?.answer || '-'}</b></p>
                                    </div>
                                    <div>
                                        <p>{translateThis("Address")} &nbsp;</p>
                                        <p><b>{answerGetter(`child_enrollment_form_question_adult_${no}_guardianAddress`, formData)?.answer || '-'}</b></p>
                                    </div>
                                    <div>
                                        <p>{translateThis("City")} &nbsp;</p>
                                        <p><b>{answerGetter(`child_enrollment_form_question_adult_${no}_guardianCity`, formData)?.answer || '-'}</b></p>
                                    </div>
                                    <div>
                                        <p>{translateThis("Zipcode")}: &nbsp;</p>
                                        <p><b>{answerGetter(`child_enrollment_form_question_adult_${no}_guardianZipCode`, formData)?.answer || '-'}</b></p>
                                    </div>
                                    <div>
                                        <p>{translateThis("Phone")}: &nbsp;</p>
                                        <p><b>{answerGetter(`child_enrollment_form_question_adult_${no}_guardianPhone`, formData)?.answer || '-'}</b></p>
                                    </div>
                                    <div>
                                        <p>{translateThis("Relationship")}: &nbsp;</p>
                                        <p><b>{answerGetter(`child_enrollment_form_question_adult_${no}_guardianRelationship`, formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                                <div className='gap' />
                            </div>
                        ))}
                    </> : null}

                </div>
                <div className='gap' />
                <div className='each-box'>
                    <p>{translateThis("In an emergency adults to be contacted if parent cannot be reached and to whom the child can be released")}</p>
                    <div className='auto-grid width25'>
                        <div>
                            <p>{translateThis("Name")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianName_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Address")} &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianAddress_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("City")} &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianCity_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Zipcode")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianZipcode_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Phone")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianPhone_2", formData)?.answer || '-'}</b></p>
                        </div>
                        <div>
                            <p>{translateThis("Relationship")}: &nbsp;</p>
                            <p><b>{answerGetter("written_permission_form_question_guardianRelationship_2", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                </div>

                {answerGetter('written_permission_form_emergency_responsible', formData)?.answer ? <>
                    {answerGetter('written_permission_form_emergency_responsible', formData)?.rawAnswer.map((no) => (
                        <div key={`detail-key-${no}`}>
                            {/* <p>Responsible adult {answerGetter('written_permission_form_emergency_responsible', formData)?.answer ? `#${i + 2}` : ''}</p> */}
                            <div className='auto-grid width25'>
                                <div>
                                    <p>{translateThis("Name")}: &nbsp;</p>
                                    <p><b>{answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianName`, formData)?.answer || '-'}</b></p>
                                </div>
                                <div>
                                    <p>{translateThis("Address")} &nbsp;</p>
                                    <p><b>{answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianAddress`, formData)?.answer || '-'}</b></p>
                                </div>
                                <div>
                                    <p>{translateThis("City")} &nbsp;</p>
                                    <p><b>{answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianCity`, formData)?.answer || '-'}</b></p>
                                </div>
                                <div>
                                    <p>{translateThis("Zipcode")}: &nbsp;</p>
                                    <p><b>{answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianZipCode`, formData)?.answer || '-'}</b></p>
                                </div>
                                <div>
                                    <p>{translateThis("Phone")}: &nbsp;</p>
                                    <p><b>{answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianPhone`, formData)?.answer || '-'}</b></p>
                                </div>
                                <div>
                                    <p>{translateThis("Relationship")}: &nbsp;</p>
                                    <p><b>{answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianRelationship`, formData)?.answer || '-'}</b></p>
                                </div>
                            </div>
                            <div className='gap' />
                        </div>
                    ))}
                </> : null}

                <div className='gap' />
                <div className='each-box'>
                    <p>{translateThis("Child’s Emergency Medical Care Provider")}:</p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("Name")}: <b>{answerGetter("written_permission_form_question_medicalCareName", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Phone")}: <b>{answerGetter("written_permission_form_question_medicalCarePhone", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Address")}: <b>{answerGetter("written_permission_form_question_medicalCareAddress", formData)?.answer || '-'}</b></p>
                        </div>
                        <div className="ryt-section">
                            <div className="flex-box">
                                <p>{translateThis("City")}: <b>{answerGetter("written_permission_form_question_medicalCareCity", formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Zipcode")}: <b>{answerGetter("written_permission_form_question_medicalCareZipcode", formData)?.answer || '-'}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap' />



                <div className='auto-flex'>
                    <p style={{ whiteSpace: 'nowrap', paddingRight: '10px', minWidth: '110px' }}>{translateThis("")}Child’s Physician:</p>
                    <div className="details-wrp" style={{ width: '100%' }}>
                        <div className='flex-box'>
                            <p>{translateThis("Name")}: <b>{answerGetter("written_permission_form_question_physicianName", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Phone")}: <b>{answerGetter("written_permission_form_question_physicianPhone", formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='flex-box'>
                            <p>{translateThis("Address")}: <b>{answerGetter("written_permission_form_question_physicianAddress", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("City")}: <b>{answerGetter("written_permission_form_question_physicianCity", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Zipcode")}: <b>{answerGetter("written_permission_form_question_physicianZipcode", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <div className='auto-flex'>
                    <p style={{ whiteSpace: 'nowrap', paddingRight: '10px', minWidth: '110px' }}>{translateThis("Child’s Dentist")}:</p>
                    <div className="details-wrp" style={{ width: '100%' }}>
                        <div className='flex-box'>
                            <p>{translateThis("Name")}: <b>{answerGetter("written_permission_form_question_dentistName", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Phone")}: <b>{answerGetter("written_permission_form_question_dentistPhone", formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='flex-box'>
                            <p>{translateThis("Address")}: <b>{answerGetter("written_permission_form_question_dentistAddress", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("City")}: <b>{answerGetter("written_permission_form_question_dentistCity", formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Zipcode")}: <b>{answerGetter("written_permission_form_question_dentistZipcode", formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <p style={{ textDecoration: 'underline' }}>{translateThis("My family child care provider and or approved substitute have my permission to")}:</p>

                <ul>
                    <li>{translateThis("permission_1")}. <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                        <ul>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_canTransport", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_canTransport", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div></li>
                    <li>{translateThis("permission_2")}. <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                        <ul>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_activity", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_activity", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div></li>
                    <li>{translateThis("permission_3")}:
                        <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                            <ul>
                                <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_3", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")} &nbsp;{answerGetter("written_permission_form_Key_permission_3", formData)?.answer === "Yes" ? answerGetter("written_permission_form_permission_3_hospitalName", formData)?.answer || '' : ''}</li>
                                <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_3", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                    </li>
                    <li>{translateThis("permission_4_view")} <br />{translateThis("permission_4_view_2")}
                        <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                            <ul>
                                <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_4", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_4", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div> </li>
                    <li>{translateThis("permission_5")}.
                        <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                            <ul>
                                <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_5", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_5", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        <br /> {translateThis("permission_4_view_3")}  </li>
                </ul>
                <p><b>{translateThis("permission_6")}.
                    <div className='check-box inline' style={{ marginBottom: '0', display: 'inline-block' }}>
                        <ul>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_6", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li style={{ paddingBottom: '0' }} className={answerGetter("written_permission_form_Key_permission_6", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                </b></p>

                <div className="signature-blk" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <h3>{
                            data.additionalInfo && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignature
                                : ''
                        }</h3>
                        <p>{translateThis("parent_or_guardian_signature")}</p>
                        <p>{translateThis("Submission time")}: <b>{
                            data.additionalInfo && data.additionalInfo.submissionDate
                                ? data.additionalInfo.submissionDate
                                : ''
                        }</b></p>
                    </div>
                    <div style={{ paddingLeft: '25px' }}>
                        <p style={{ color: '#4C483E' }}>{translateThis("childEnrollmentH1")}: <b>{translateThis("childEnrollmentH1DETAILS_view")}</b></p>
                    </div>
                </div>
            </div>
        </>
    )
}

WrittenPermissionView.propTypes = {
    data: PropTypes.any
}

export default WrittenPermissionView;