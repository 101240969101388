import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripePaymentForm from './paymentForm';

function StripeGateway({ paymentKey, publicKey }) {

    const stripePromise = loadStripe(publicKey);
    const [clientSecret, setClientSecret] = useState();

    useEffect(() => {
        if (paymentKey) {
            setClientSecret(paymentKey)
        }
    }, [paymentKey]);


    return (
        <>
            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret, }}>
                    <StripePaymentForm />
                </Elements>
            )}
        </>
    );
}
StripeGateway.propTypes = {
    paymentKey: PropTypes.string,
    publicKey: PropTypes.string
}
export default StripeGateway;