import React from "react";
// import { element } from "prop-types";
import { translateThis } from '../../../../helpers/language.helper';
import AppIcons from "../images/icons";
import './teachers.scss';




function Teachers() {

  const teachersItems = [
    {
      image: AppIcons.teachers1,
      title: 'Carlos Garcia',
      position: 'friendlySubstitute',
    },
    {
      image: AppIcons.teachers2,
      title: 'Blanca Cardona',
      position: 'friendlyProvider',
    },
    {
      image: AppIcons.teachers3,
      title: 'Eddy Mojica',
      position: 'friendlySubstitute',
    },

  ];


  return (
    <>
      <div className="FriendFamily-teachers-wrp f-wrp">
        <div className="row">
          {teachersItems.map((element, key) => (
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
              <div className="each-teachers-sec f-wrp ">
                <div className="teachers-img-sec f-wrp">
                  <span className='fee-sec-icon'><img src={element.image} alt={element.title} /></span>
                  <div className="title-box f-wrp">
                    <div className="f-wrp">
                      <h2>{translateThis(element.title)}</h2>
                      <p>{translateThis(element.position)}</p>
                    </div>
                  </div>
                </div>
                {element.description ? <div className="teachers-con-sec f-wrp">
                  <p>{translateThis(element.description)}</p>
                </div> : null}

              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Teachers;