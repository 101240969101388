import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showSnackbar } from '../../components/snackbar/index';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';

const PaymentSuccess = ()=> {
const navigator = useNavigate()

    useEffect(() => {
        showSnackbar().success('Payment completed successfully!');
        navigator(`/${getCurrentFacilityURL()}/my-portal`, {replace: true})
        return () => {
            
        };
    }, [navigator]);

    return (
        <div>
            SUCCESS
        </div>
    )
}

export default PaymentSuccess;