import React from 'react';
import AppIcons from '../images/icons';
import SVGIcons from '../images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import './footer.scss';


export default function AppFooter() {
    return (
        <div>
            <div className="project-main-footer FriendFamilyTemplates f-wrp">
                <div className="container">
                    <div className='copy-foot f-wrp'>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="copy-txt f-wrp">
                                    <p>{translateThis('Friendly Family Childcare Facility LLC')}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className='dev-blk'>
                                    <p style={{ textAlign: 'center', fontWeight: '400', marginTop: '5px', color: 'var(--friendlyText13)' }}>{translateThis('Built by')} &nbsp;<i style={{ cursor: 'pointer', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ textDecoration: "none", fontStyle: 'normal', cursor: 'pointer'}}>mihapp.co</span> &nbsp;<img style={{ display: 'inline-block', width: '25px', maxWidth: '25px', }} src={AppIcons.mihapp} alt="mihapp" /></i></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className='social-list f-wrp'>
                                    <ul>
                                        <li><span>{SVGIcons.InstaIcon()}</span></li>
                                        <li><span>{SVGIcons.FbIcon()}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}