import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { translateThis } from '../../../helpers/language.helper';
import './index.scss';

// Custom components
import SVGIcons from '../../../assets/images/icons/svgIcons';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import { DATE_EXTEND_FORMAT } from '../../../constants';
import RichTextViewer from '../../../components/rich-text-editor/TextViewer';



const ParentBulletinList = (props) => {
    const {
        onViewBulletin = () => null,
    } = props;

    const apiCaller = new ApiCaller('bulletin');
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({query: 'status=active'})
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }


    return (
        <>
            <div className='parent-bulletin f-wrp'>
                {(!isLoading && dataArr.length > 0) ? <Grid container spacing={2}>
                    {dataArr.map((data, key) => (
                        <Grid item xs={12} sm={12} md={4} key={key}>
                            <div className='each-bulletin-blk f-wrp'>
                                {(data.image && data.image?.tempURL) ? <div className='preview-img-sec f-wrp'>
                                    <img src={data.image?.tempURL} alt='Bulletin' />
                                </div>: null}
                                <div className='bulletin-con-sec f-wrp'>
                                    <div className='basic-details f-wrp'>
                                        <p>{data.author} </p>
                                        {(data?.createdAt) ? <p>{dayjs(data?.createdAt).format(DATE_EXTEND_FORMAT)}</p> : null}
                                    </div>
                                    <div className='f-wrp'>
                                        <h1>{data?.title}</h1>
                                        <h2>{data?.subTitle}</h2>
                                        {(data.description) ? <RichTextViewer richText={data.description} /> : null}
                                    </div>
                                    <Button className='pln-btn' onClick={() => onViewBulletin(data.id)} >{translateThis("Read more")} {SVGIcons.ArrowTrIcon()}</Button>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid> : (!isLoading && dataArr.length === 0) ? <span>{translateThis("No bulletins found")}</span> : <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            </div>
        </>
    );
}
ParentBulletinList.propTypes = {
    onViewBulletin: PropTypes.func,
}
export default ParentBulletinList;












