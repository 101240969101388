import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import AppIcons from '../../../assets/images/icons';

import './index.scss'
import ApiCaller from '../../../services/api/general';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { showSnackbar } from '../../../components/snackbar/index';



const ParentDynamicFormsDownloadsTab = () => {
    const documentApi = new ApiCaller('documents');
    const [isDocumentsLoading, setIsDocumentsLoading] = React.useState(false);
    const [dataArr, setDataArr] = React.useState([]);

    /**
 the legendary use effect function
 This will run very first on render
  */
    React.useEffect(() => {
        getDocuments()
        // eslint-disable-next-line
    }, []);

    // get form documents from API
    const getDocuments = () => {
        setIsDocumentsLoading(true);
        documentApi.getList({ query: '&type=form' })
            .then((data) => {
                if (data?.results) {
                    setDataArr(data.results || [])
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsDocumentsLoading(false));
    }


    const offlineChildCareFormsSet = [
        {
            title: '4.Early childhood health assessment',
            url: 'https://kcolors-dev.s3.amazonaws.com/2.+EC_HAR.pdf'
        },
        {
            title: '5.Immunization schedule',
            url: 'https://kcolors-dev.s3.amazonaws.com/4.+immunization_schedule.pdf'
        },

    ];
    const offlineSituationalFormsSet = [
        {
            title: '11.Disability verification',
            url: 'https://kcolors-dev.s3.amazonaws.com/Disability-Verification-Form.pdf'
        },
        {
            title: '12.Medical exemption',
            url: 'https://kcolors-dev.s3.amazonaws.com/medical_exemption_immunization_299204_421576_37726_v1.pdf'
        },
    ]

    const onlineChildCareFormsSet = [
        {
            title: '1.Child enrollment',
            url: 'https://kcolors-dev.s3.amazonaws.com/1.+Child+enrollment.pdf'
        },
        {
            title: '2.Emergency numbers',
            url: 'https://kcolors-dev.s3.amazonaws.com/5.+New-Emergency-Numbers-English.pdf'
        },
        {
            title: '3.Written permission',
            url: 'https://kcolors-dev.s3.amazonaws.com/6.+NEW-Written-Permission-Form.pdf'
        },

    ]

    const onlineCareForChildFormsSet = [
        {
            title: '6.C4K Application',
            url: 'https://kcolors-dev.s3.amazonaws.com/8.+English-C4K-Application.Checklist.final-002.docB_.pdf'
        },
        {
            title: '7.C4K Redetermination',
            url: 'https://kcolors-dev.s3.amazonaws.com/10.+REDET-ENGLISH-9.2021.pdf'
        },
        {
            title: '8.Parent-Provider agreement ',
            url:'https://kcolors-dev.s3.amazonaws.com/9.+Parent-Provider-Agreement-PPA-English-2020.pdf'
        },
        {
            title: '9.Summer Parent-Provider agreement',
            url: 'https://kcolors-dev.s3.amazonaws.com/11.+Summer-PPA-English-1.pdf'
        },
    ]

    const onlineSituationalFormsSet = [
        {
            title: '10.Self employment',
            url: 'https://kcolors-dev.s3.amazonaws.com/Self-Employment-Form-English2023.pdf'
        },
    ]

    return (
        <>
            <div className='panel-form-sec blk-bg forms-main-wrapper f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                <div className='info-txt'>
                    <span>Here you can download every form to complete OFFLINE, once completed you need to upload them in “Uploads” tab:</span>
                </div>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <h2>OFFLINE forms</h2>
                            </div>
                            <div className='red-txt f-wrp'>
                                <p>These forms can only be completed OFFLINE and are mandatory, except the ones under the Situational forms category</p>
                            </div>

                            <div className='info-head f-wrp'>
                                <span>Childcare forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {offlineChildCareFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>

                            <div className='info-head f-wrp'>
                                <span>Situational forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {offlineSituationalFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <h2>ONLINE forms</h2>
                            </div>
                            <div className='red-txt f-wrp'>
                                <p>You can also complete these forms ONLINE in the “To complete” tab</p>
                            </div>
                            <div className='info-head f-wrp'>
                                <span>Childcare forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {onlineChildCareFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>

                            <div className='info-head f-wrp'>
                                <span>Care 4 Kids forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {onlineCareForChildFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>

                            <div className='info-head f-wrp'>
                                <span>Situational forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {onlineSituationalFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>
                            <div className='info-head f-wrp'>
                                <span>Custom forms:</span>
                            </div>
                            {isDocumentsLoading ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : <div className='pdf-download f-wrp'>
                                {dataArr.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.file?.tempURL} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.name} /></span>
                                        <p>{element.name}</p>
                                    </Link>
                                ))}
                            </div>}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default ParentDynamicFormsDownloadsTab;