/* eslint-disable global-require */
const AppIcons = {
  bannerLogo: require('../bannerLogo.png'),
  bannerBG: require('../bannerBG.png'),
  logo: require('../Logo.png'),
  mihapp: require('../mihapp.png'),

  bilingual: require('./service/bilingual.png'),
  foodProgram: require('./service/foodProgram.png'),
  medicalSupport: require('./service/medicalSupport.png'),
  specialNeeds: require('./service/specialNeeds.png'),
  
  abtHead: require('./abtHead.png'),
  abtFoot: require('./abtFoot.png'),

  activity: require('../activity.png'),

  activity1: require('./activity/activity1.png'),
  activity2: require('./activity/activity2.png'),
  activity3: require('./activity/activity3.png'),
  activity4: require('./activity/activity4.png'),
  activity5: require('./activity/activity5.png'),
  activity6: require('./activity/activity6.png'),
  activity7: require('./activity/activity7.png'),
  activity8: require('./activity/activity8.png'),
  activity9: require('./activity/activity9.png'),
  activity10: require('./activity/activity10.png'),
  activity11: require('./activity/activity11.png'),
  activity12: require('./activity/activity12.png'),

  acceptedBG: require('../accepted.png'),

  gallery1: require('../gallery/gallery_1.png'),
  gallery2: require('../gallery/gallery_2.png'),
  gallery3: require('../gallery/gallery_3.png'),
  gallery4: require('../gallery/gallery_4.png'),
  gallery5: require('../gallery/gallery_5.png'),
  gallery6: require('../gallery/gallery_6.png'),
  gallery7: require('../gallery/gallery_7.png'),
  gallery8: require('../gallery/gallery_8.png'),
  gallery9: require('../gallery/gallery_9.png'),
  gallery10: require('../gallery/gallery_10.png'),
  gallery11: require('../gallery/gallery_11.png'),
  gallery12: require('../gallery/gallery_12.png'),
  gallery13: require('../gallery/gallery_13.png'),
  gallery14: require('../gallery/gallery_14.png'),
  
  faceBook: require('../faceBook.png'),
  whatsapp: require('../whatsapp.png'),
  instagram: require('../instagram.png'),

  paymentBG: require('../paymentBG.png'),

  staff_1: require('../staff_1.png'),
  staff_2: require('../staff_2.png'),


  aboutImg: require('../aboutImg.png'),
  mail: require('./mail.png'),
  mapPoint: require('./mapPoint.png'),
  phone: require('./phone.png'),
  contact: require('../contact.png'),
  days: require('./days.png'),
  hours: require('./hours.png'),
  ages: require('./ages.png'),
  programs: require('./programs.png'),
  
};
export default AppIcons;
/* eslint-enable global-require */
