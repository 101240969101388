import { atom } from "recoil";
import persistAtom from "../recoilPersister";
import { getAuthRoleName } from '../../helpers/auth.helper';
import { PROVIDER_ROLE } from '../../constants';

const userType = getAuthRoleName();
export const myPortalState = atom({
    key: 'myPortal', // unique ID (with respect to other atoms/selectors)
    effects_UNSTABLE: [persistAtom],
    default: {
      selectedNavItem: userType === PROVIDER_ROLE ? 'parents' :'children',
      pageHeader: userType === PROVIDER_ROLE ? 'Parents' :'children',
      tutorialLink: userType === PROVIDER_ROLE ? 'Parents' :'children',
      isDrawerOpen: true,
      facilityInfo: {}
    },
  });