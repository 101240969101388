import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import AppIcons from "../images/icons";


function Testimonial() {


  const testimonialItems = [
    {
      icon: AppIcons.user,
      name: 'Parent Name',
      content: <><p>Sed ut perspiciatis unde omnis iste natus error voluptatem ed ut perspiciatis unde omnis iste natus error voluptatem </p></>,
    },
    {
      icon: AppIcons.user,
      name: 'Parent Name',
      content: <><p>Sed ut perspiciatis unde omnis iste natus error voluptatem ed ut perspiciatis unde omnis iste natus error voluptatem </p></>,
    },
    {
      icon: AppIcons.user,
      name: 'Parent Name',
      content: <><p>Sed ut perspiciatis unde omnis iste natus error voluptatem ed ut perspiciatis unde omnis iste natus error voluptatem </p></>,
    },
    
  ];

  return (
    <>
      <div className="argentina-testimonial-wrp">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  <span className='testimonial-icon'><img src={element.icon} alt={element.title} /></span>
                  <div className="testimonial-content">
                    {element.content}
                    <h2>{element.name}</h2>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;