import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';


import SVGIcons from '../../../assets/images/icons/svgIcons';
import './index.scss';
import ProviderPortalHeader from '../components/header';
import AppIcons from '../../../assets/images/icons';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';

import { MONTH_YEAR_TEXT_FORMAT, TODAYS_DATE_EXTENDED_FORMAT } from '../../../constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const ProviderDashboard = () => {

    const apiCaller = new ApiCaller('dashboard');
    const [data, setData] = useState({
        cardData: [],
        dashboard: {}
    });
    const [isLoading, setIsLoading] = useState(true);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setData({
                cardData: [],
                dashboard: {}
            })
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getData('')
            .then((data) => formatData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    const formatData = (data)=> {
        const eachCards = [
            {
                headIcon: SVGIcons.CatalogIcon(),
                title: 'Daily logs',
                infoLine1: <><p>Pending signature <b>{data?.dailyLogsPendingSignature}</b></p></>,
                infoLine2: <><p>Daily submissions <b>{data?.dailyLogsPendingSubmitted}</b></p></>,
                link: ''
            },
            {
                headIcon: SVGIcons.FormsIcon(),
                title: 'Forms',
                infoLine1: <><p>Pending signature <b>{data?.formsPendingSignature}</b></p></>,
                infoLine2: <><p>Total submissions <b>{data?.formsPendingSubmitted}</b></p></>,
                link: ''
            },
            {
                headIcon: SVGIcons.AccidentIcon(),
                title: 'Accidents',
                infoLine1: <><p>Current month <b>{data?.accidentCurrentMonth}</b></p></>,
                infoLine2: <><p>Current year <b>{data?.accidentCurrentYear}</b></p></>,
                link: ''
            },
        ];
        const dashboardData = {
            cardData: eachCards,
            ...data
        }
        setData(dashboardData)
        
    }

    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             // position: 'top' as const,
    //         },
    //         title: {
    //             display: false,
    //             text: 'Chart.js Line Chart',
    //         },
    //     },
    // };

    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    // const dataAs = {
    //     labels,
    //     datasets: [
    //         {
    //             // label: 'Dataset 1',
    //             data: 100,
    //             borderColor: 'rgb(255, 99, 132)',
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         },
    //         {
    //             // label: 'Dataset 2',
    //             data: 1000,
    //             borderColor: 'rgb(53, 162, 235)',
    //             backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //         },
    //     ],
    // };


    

    const eachAttendance = [
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'Juan García',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'Bruno Diaz',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'Georgina Apesteguia',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },

    ];

    return (

        <>
            <ProviderPortalHeader header='Dashboard' />
            {isLoading ? <span>Loading... </span> : <Box component="div" className='dashboard-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Grid container spacing={2}>
                            {data?.cardData.map((element, key) => (
                                // eslint-disable-next-line react/jsx-key
                                <Grid item xs={12} sm={12} md={3} lg={3} key={key}>
                                    <div className={`${element.title} each-board-items f-wrp `}>
                                        <div className='card-header'>
                                            <span className={`${element.title}`}>{element.headIcon}</span>
                                            <h4>{element.title}</h4>
                                        </div>
                                        <div className='card-body'>
                                            {element.infoLine1}
                                            {element.infoLine2}
                                        </div>
                                        {/* <Divider />
                                        <div className='card-foot'>
                                            <Button className='pln-btn' onClick={() => console.log('clicked')} >Read more {SVGIcons.ArrowTrIcon()}</Button>
                                        </div> */}
                                    </div>
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={12} md={2} lg={3}>
                                <div className='inbox-card f-wrp'>
                                    <span>{SVGIcons.InboxIcon()}</span>
                                    <h4>Inbox <b>{data?.inbox}</b></h4>
                                    <p>Unread Messages</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                <div className='graph-wrp-sec'>
                                    <span className='graph-img'><img src={AppIcons.graphBG} alt='img' /></span>
                                    <div className='graph-box-card f-wrp'>
                                        <div className='card-header'>
                                            <span>{SVGIcons.PaymentsIcon()}</span>
                                            <h4>Payments collected</h4>
                                        </div>
                                        <div className='price-sec-container'>
                                            <div className='each-price-sec'>
                                                <h4>This month</h4>
                                                <p>{dayjs().format(MONTH_YEAR_TEXT_FORMAT)}</p>
                                                <h2>${data?.totalPaymentsOfMonth || 0}</h2>
                                            </div>
                                            <div className='each-price-sec'>
                                                <h4>This year</h4>
                                                <p>{dayjs().year()}</p>
                                                <h2>${data?.totalPaymentsOfYear || 0}</h2>
                                            </div>
                                            <div className='each-price-sec'>
                                                <h4>Historical</h4>
                                                <p>{dayjs().subtract(1, 'year').format(MONTH_YEAR_TEXT_FORMAT)} to {dayjs().format(MONTH_YEAR_TEXT_FORMAT)}</p>
                                                <h2>${data?.totalPaymentsOfAllTime || 0}</h2>
                                            </div>
                                        </div>
                                        <div className='graph-box f-wrp'>
                                            {/* <Line options={options} data={data} /> */}

                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className="each-board-items activeChildBox f-wrp">
                                    <div className='card-header'>
                                        <span>{SVGIcons.ChildIcon()}</span>
                                        <h4>Children</h4>
                                    </div>
                                    <div className='card-body'>
                                        <p>Active children <b>{data?.activeChildren}</b></p>
                                    </div>
                                    <div className='card-header'>
                                        <span>{SVGIcons.ParentIcon()}</span>
                                        <h4>Parents</h4>
                                    </div>
                                    <div className='card-body'>
                                        <p>Active parent <b>{data?.activeParent}</b></p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div className='date-indicator-sec each-board-items f-wrp'>
                            <h4>{dayjs().format(TODAYS_DATE_EXTENDED_FORMAT)}</h4>
                            <span>{SVGIcons.ProfileIcon()}</span>
                        </div>
                        <div className='attendance-box-wrp each-board-items f-wrp'>
                            <div className='card-header'>
                                <span>{SVGIcons.ClockIcon()}</span>
                                <h4>Attendance</h4>
                            </div>
                            <div className='attendanceList-sec f-wrp'>
                                <ul>
                                    {eachAttendance.map((element, key) => (
                                        <li key={key}>
                                            <span>{element.icon}</span>
                                            <h4>{element.title}</h4>
                                            <p>{element.time}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>}
        </>
    );
}

ProviderDashboard.propTypes = {};

export default ProviderDashboard;