import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CircularProgress, Button } from '@mui/material';
import ModalPopUp from '../../../components/modal/modal.popup';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import { DATE_READABLE_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../constants/index';
import { generatePDFBy } from '../../../services/api/pdf';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';
import SVGIcons from '../../../assets/images/icons/svgIcons';




const DynamicFormHistoryTab = () => {
    const apiCaller = new ApiCaller('form-submissions');
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedPreview, setSelectedPreview] = React.useState(<></>);


    const onViewClose = () => {
        setViewOpen(false);
    };
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({ query: 'sortBy=createdAt:desc&isProviderSigned=true&isParentSigned=true' })
            .then((data) => {
                if (data) {
                    const dataArray = data?.results || [];
                    setDataArr(dataArray)
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false));
    }

    /**
     * 
     * @param {string} formName 
     * @param {any} formData 
     */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }



    /**
   * Download PDF
   */
    const downloadPDF = (childId, userId, formName) => {
        if (childId && userId && formName) {
            setIsDownloading(true);
            showSnackbar().info("Selected form will be downloaded shortly")
            generatePDFBy(userId, childId, formName)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsDownloading(false);
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 1,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'formTitle',
            headerName: 'Form',
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'createdAt',
            headerName: 'Submission Date',
            flex: 2,
            minWidth: 150,
            valueGetter: ({ value }) => dayjs(value).format(DATE_READABLE_FORMAT),
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: ({ row }) => {
                const { childId, userId, formName, pdfURL } = row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => {
                            if (pdfURL) {
                                window.open(pdfURL.tempURL, "_blank");
                            } else {
                                onFormPreviewClick(formName, row)
                            }
                        }
                    },
                    {
                        label: isDownloading ? <CircularProgress size={20} /> : 'Download',
                        onClick: () => {
                            if (pdfURL) {
                                window.open(pdfURL.tempURL, "_blank");
                            } else {
                                downloadPDF(childId?.id, userId?.id, formName)
                            }
                        }
                    }
                ]} />
            }
        },

    ]




    return (
        <>

            <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                    <div className='mob-table-wrp childView f-wrp'>
                        {dataArr.length > 0 ? <ul>
                            {dataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box'>
                                        <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                        <h4>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                    </div>
                                    <div className='each-sec-box'>
                                        <h4>{element.childId?.firstName}&nbsp;{element.childId?.lastName}</h4>
                                        <span>{element.formTitle}</span>
                                    </div>
                                    <div className='each-sec-box'>
                                        <ul className='btn-wrp'>
                                            <li><button className='pln-btn ForwardIcon' type='button' onClick={(row) => {
                                                if (element.pdfURL) {
                                                    window.open(element.pdfURL.tempURL, "_blank");
                                                } else {
                                                    onFormPreviewClick(element.formName, row)
                                                }
                                            }}><SVGIcons.ForwardIcon /></button></li>
                                            <li><button className='pln-btn' type='button' onClick={() => {
                                                if (element.pdfURL) {
                                                    window.open(element.pdfURL.tempURL, "_blank");
                                                } else {
                                                    downloadPDF(element.childId?.id, element.userId?.id, element.formName)
                                                }
                                            }}><SVGIcons.DownloadIcon /></button></li>
                                        </ul>
                                    </div>
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholder} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>

                    {viewOpen ? <ModalPopUp
                        className='preview-popup'
                        isOpen={viewOpen}
                        onClose={onViewClose}
                        aria-labelledby="table-view"
                        aria-describedby="table-view"
                    >
                        {selectedPreview}
                        <Button onClick={onViewClose} className='fill-btn'>Close</Button>
                    </ModalPopUp> : null}
                </div>

            </div>
        </>
    )
}


export default DynamicFormHistoryTab;