import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// @mui
import { Stack, Button, TextField, FormGroup, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_EXTENDED_FORMAT, DATE_TIME_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';


const SelfEmployment = (props) => {
    const formName = 'self-employment';
    const stepRef = useRef(null)

    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    // const [secondSignature, setSecondSignature] = useState('');
    // const [isSecondSigned, setIsSecondSigned] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    // const [accreditedArr, setAccreditedArr] = useState([]);
    const [initialFormData, setInitialFormData] = useState(null);
    const [expensePerMile, setExpensePerMile] = useState('');

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (childId && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const submitThisForm = () => {
        const postData = {
            formName: 'self-employment',
            formTitle: 'Self Employment',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        } else {
            showSnackbar().warning("Please fill the required fields")
        }
    };

    /**
     * On Next button click
     */
    const onNextClick = () => {
        if (currentStep === 2) {
            submitThisForm()
        } else {
            const newStep = currentStep + 1;
            setCurrentStep(newStep);
            stepRef.current.scrollIntoView()
        }
    }
    /**
         * On Next button click
         */
    const onPrevStepClick = () => {
        if (currentStep > 1) {
            const newStep = currentStep - 1;
            setCurrentStep(newStep);
            stepRef.current.scrollIntoView()
        }
    }

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    // const onSelectAccreditedArr = (selectedItem, checked) => {
    //     let alreadySelectedItems = accreditedArr;
    //     if (!checked) {
    //         alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
    //     } else {
    //         alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
    //         alreadySelectedItems.push(selectedItem)
    //     }
    //     setAccreditedArr(alreadySelectedItems);
    //     setFormValue({
    //         questionId: 'self_employment_sectionFour_form_QN_2',
    //         answer: alreadySelectedItems.join(', '),
    //         rawAnswer: alreadySelectedItems,
    //         translationKey: 'self_employment_sectionFour_form_QN_2',
    //         type: 'Checkbox'
    //     })
    // }

    // const steps = [
    //     'Applicant',
    //     'Other Parent',
    //     'Children',
    //     'Work',
    //     'Child Support',
    //     'Rights',
    // ];

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>

                <>

                    <Stack key={formKey} className='standard-form form-full-width self-employment-wrp panel-form' spacing={3}>
                        <div ref={stepRef} className='info-head f-wrp'>
                            <h2>{translateThis("Self Employment")}</h2>
                        </div>
                        {currentStep && <div style={{ display: currentStep === 1 ? 'block' : 'none' }} className='C4KAppSectionOne f-wrp'>

                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_name`}
                                        label={translateThis('self_employment_sectionOne_form_question_name')}
                                        name='self_employment_sectionOne_form_question_name'
                                        id='self_employment_sectionOne_form_question_name'
                                        value={answerGetter('self_employment_sectionOne_form_question_name', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_name'
                                        type='text'
                                        placeholder='Name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_name',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_caseNumber`}
                                        label={translateThis('self_employment_sectionOne_form_question_caseNumber')}
                                        name='self_employment_sectionOne_form_question_caseNumber'
                                        id='self_employment_sectionOne_form_question_caseNumber'
                                        value={answerGetter('self_employment_sectionOne_form_question_caseNumber', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_caseNumber'
                                        type='number'
                                        placeholder='Case Number'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_caseNumber',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Home")}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_address`}
                                        label={translateThis('self_employment_sectionOne_form_question_address')}
                                        name='self_employment_sectionOne_form_question_address'
                                        id='self_employment_sectionOne_form_question_address'
                                        value={answerGetter('self_employment_sectionOne_form_question_address', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_address'
                                        type='text'
                                        required
                                        placeholder='Street'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_address',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_city`}
                                        label={translateThis('self_employment_sectionOne_form_question_city')}
                                        name='self_employment_sectionOne_form_question_city'
                                        id='self_employment_sectionOne_form_question_city'
                                        value={answerGetter('self_employment_sectionOne_form_question_city', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_city'
                                        type='text'
                                        required
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_city',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_state`}
                                        label={translateThis('self_employment_sectionOne_form_question_state')}
                                        name='self_employment_sectionOne_form_question_state'
                                        id='self_employment_sectionOne_form_question_state'
                                        value={answerGetter('self_employment_sectionOne_form_question_state', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_state'
                                        type='text'
                                        required
                                        placeholder='State'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_state',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_zipcode`}
                                        label={translateThis('self_employment_sectionOne_form_question_zipcode')}
                                        name='self_employment_sectionOne_form_question_zipcode'
                                        id='self_employment_sectionOne_form_question_zipcode'
                                        value={answerGetter('self_employment_sectionOne_form_question_zipcode', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_zipcode'
                                        type='number'
                                        required
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_zipcode',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Business")}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_business_Name`}
                                        label={translateThis('self_employment_sectionOne_form_question_business_Name')}
                                        name='self_employment_sectionOne_form_question_business_Name'
                                        id='self_employment_sectionOne_form_question_business_Name'
                                        value={answerGetter('self_employment_sectionOne_form_question_business_Name', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_business_Name'
                                        type='text'
                                        required
                                        placeholder='Business Name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_business_Name',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_address_1`}
                                        label={translateThis('self_employment_sectionOne_form_question_address_1')}
                                        name='self_employment_sectionOne_form_question_address_1'
                                        id='self_employment_sectionOne_form_question_address_1'
                                        value={answerGetter('self_employment_sectionOne_form_question_address_1', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_address_1'
                                        type='text'
                                        required
                                        placeholder='Street'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_address_1',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_city_1`}
                                        label={translateThis('self_employment_sectionOne_form_question_city_1')}
                                        name='self_employment_sectionOne_form_question_city_1'
                                        id='self_employment_sectionOne_form_question_city_1'
                                        value={answerGetter('self_employment_sectionOne_form_question_city_1', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_city_1'
                                        type='text'
                                        required
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_city_1',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_state_1`}
                                        label={translateThis('self_employment_sectionOne_form_question_state_1')}
                                        name='self_employment_sectionOne_form_question_state_1'
                                        id='self_employment_sectionOne_form_question_state_1'
                                        value={answerGetter('self_employment_sectionOne_form_question_state_1', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_state_1'
                                        type='text'
                                        required
                                        placeholder='State'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_state_1',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_zipcode_1`}
                                        label={translateThis('self_employment_sectionOne_form_question_zipcode_1')}
                                        name='self_employment_sectionOne_form_question_zipcode_1'
                                        id='self_employment_sectionOne_form_question_zipcode_1'
                                        value={answerGetter('self_employment_sectionOne_form_question_zipcode_1', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_zipcode_1'
                                        type='number'
                                        required
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_zipcode_1',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        key={`self_employment_sectionOne_form_Key_typeOfBusiness`}
                                        label={translateThis('self_employment_sectionOne_form_question_typeOfBusiness')}
                                        name='self_employment_sectionOne_form_question_typeOfBusiness'
                                        id='self_employment_sectionOne_form_question_typeOfBusiness'
                                        defaultValue={answerGetter('self_employment_sectionOne_form_question_typeOfBusiness', initialFormData)?.answer}
                                        type='text'
                                        multiline
                                        placeholder='Type of business (explain)'
                                        className='custom-textfield'
                                        onChange={(e) => {
                                            setFormValue({
                                                questionId: 'self_employment_sectionOne_form_question_typeOfBusiness',
                                                answer: e.target.value,
                                                translationKey: 'self_employment_sectionOne_form_question_typeOfBusiness',
                                                type: 'text'
                                            })
                                        }}
                                        rows={4}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_owner_Name_1`}
                                        label={translateThis('self_employment_sectionOne_form_question_owner_Name_1')}
                                        name='self_employment_sectionOne_form_question_owner_Name_1'
                                        id='self_employment_sectionOne_form_question_owner_Name_1'
                                        value={answerGetter('self_employment_sectionOne_form_question_owner_Name_1', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_owner_Name_1'
                                        type='text'
                                        required
                                        placeholder='Business Owner Name(s) 1'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_owner_Name_1',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_owner_Name_2`}
                                        label={translateThis('self_employment_sectionOne_form_question_owner_Name_2')}
                                        name='self_employment_sectionOne_form_question_owner_Name_2'
                                        id='self_employment_sectionOne_form_question_owner_Name_2'
                                        value={answerGetter('self_employment_sectionOne_form_question_owner_Name_2', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_owner_Name_2'
                                        type='text'
                                        required
                                        placeholder='Business Owner Name(s) 2'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_owner_Name_2',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`self_employment_sectionOne_form_Key_business_Phone`}
                                        label={translateThis('self_employment_sectionOne_form_question_business_Phone')}
                                        name='self_employment_sectionOne_form_question_business_Phone'
                                        id='self_employment_sectionOne_form_question_business_Phone'
                                        value={answerGetter('self_employment_sectionOne_form_question_business_Phone', initialFormData)?.answer}
                                        translationKey='self_employment_sectionOne_form_question_business_Phone'
                                        type='number'
                                        required
                                        placeholder='Business Phone'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'self_employment_sectionOne_form_question_business_Phone',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                value={answerGetter('self_employment_sectionOne_form_business_start_date', initialFormData)?.answer ?
                                                    dayjs(answerGetter('self_employment_sectionOne_form_business_start_date', initialFormData)?.answer) :
                                                    ''
                                                }
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: 'self_employment_sectionOne_form_business_start_date',
                                                        answer: dayjs(v).format(DATE_FORMAT),
                                                        translationKey: 'self_employment_sectionOne_form_business_start_date',
                                                        type: 'text'
                                                    })
                                                }
                                                }
                                                label={translateThis("Business start date (Month/Year)")} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form' style={{ marginTop: '25px' }} spacing={3}>
                                        <FormControl className='inline-options'>
                                            <FormLabel className='main-head' id="self_employment_sectionOne_form_business_tax_return">{translateThis('self_employment_sectionOne_form_business_tax_return')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Radio Role"
                                                defaultValue={answerGetter('self_employment_sectionOne_form_business_tax_return', initialFormData)?.answer}
                                                name="self_employment_sectionOne_form_business_tax_return"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'self_employment_sectionOne_form_business_tax_return',
                                                        answer: e.target.value,
                                                        translationKey: 'self_employment_sectionOne_form_business_tax_return',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Grid>

                            </Grid>

                            <div className='parents-rights-sec'>
                                <br />
                                <i style={{ fontSize: '12px' }}> <b> {translateThis("application")} ,</b> {translateThis("SelfEmployment_text")} <br />
                                {translateThis("At")} <b>{translateThis("redetermination")},</b> {translateThis("SelfEmployment_text_1")} </i>
                                <br />
                                <p><b>{translateThis("Instructions")}</b></p>
                                <ul className='number-list'>
                                    <li>{translateThis("Instructions_text_1")}
                                        <ul>
                                            <li>{translateThis("Instructions_text_2")}</li>
                                            <li>{translateThis("Instructions_text_3")}</li></ul></li>
                                    <li>{translateThis("Instructions_text_4")}
                                        <b>{translateThis("Note:")}</b> {translateThis("Instructions_text_5")}</li>
                                    <li>{translateThis("Instructions_text_6")}<b>{translateThis("IRS 1040")}</b> {translateThis("and")} <b>{translateThis("Schedule C.")}</b> {translateThis("Instructions_text_6_1")} </li>
                                    <li>{translateThis("Instructions_text_7")}<b>{translateThis("IRS forms 1120")}</b> {translateThis("and")} <b>{translateThis("1065.")}</b> {translateThis("Instructions_text_7_1")}  </li>
                                    <li>{translateThis("Instructions_text_8")}</li>
                                    <li>{translateThis("Instructions_text_9")}</li>
                                    <li>{translateThis("Instructions_text_10")}</li></ul>
                                <br />
                                <p>{translateThis("Instructions_text_11")}</p>

                            </div>
                        </div>}
                        {currentStep && <div style={{ display: currentStep === 2 ? 'block' : 'none' }} className='C4KAppSectionTwo f-wrp'>
                            <div className='info-head f-wrp'>
                                <h2>{translateThis("Business income calculation")}</h2>
                                <p>{translateThis("Please round all amounts to the nearest dollar")}.</p>
                            </div>
                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Income")}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn1">{translateThis('self_employment_sectionTwo_form_income_qn1')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_1`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_1')}
                                            name='self_employment_sectionTwo_form_question_answer_1'
                                            id='self_employment_sectionTwo_form_question_answer_1'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_1', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_1'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_1',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn2">{translateThis('self_employment_sectionTwo_form_income_qn2')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_1', initialFormData)?.answer ?
                                                        dayjs(answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_1', initialFormData)?.answer) :
                                                        ''
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'self_employment_sectionTwo_form_Key_income_answer_2_1',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'self_employment_sectionTwo_form_Key_income_answer_2_1',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label={translateThis("From")} />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_2', initialFormData)?.answer ?
                                                        dayjs(answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_2', initialFormData)?.answer) :
                                                        ''
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'self_employment_sectionTwo_form_Key_income_answer_2_2',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'self_employment_sectionTwo_form_Key_income_answer_2_2',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label={translateThis("To")} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Business Expenses")}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn3">{translateThis('self_employment_sectionTwo_form_income_qn3')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_3`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_3')}
                                            name='self_employment_sectionTwo_form_question_answer_3'
                                            id='self_employment_sectionTwo_form_question_answer_3'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_3', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_3'
                                            type='number'
                                            placeholder='Miles'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_3',
                                                    type: 'number'
                                                })
                                                setTimeout(() => {
                                                    setFormValue({
                                                        questionId: "self_employment_sectionTwo_form_question_answer_3_1",
                                                        answer: parseFloat(value * 0.58).toFixed(2),
                                                        translationKey: 'self_employment_sectionTwo_form_question_answer_3_1',
                                                        type: 'number'
                                                    })
                                                }, 2000);

                                                setExpensePerMile(parseFloat(value * 0.58).toFixed(2));
                                            }}
                                        />
                                        <TextField label={translateThis('self_employment_sectionTwo_form_question_answer_3_1')} value={expensePerMile} />

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn4">{translateThis('self_employment_sectionTwo_form_income_qn4')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_4`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_4')}
                                            name='self_employment_sectionTwo_form_question_answer_4'
                                            id='self_employment_sectionTwo_form_question_answer_4'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_4', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_4'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_4',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn5">{translateThis('self_employment_sectionTwo_form_income_qn5')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_5`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_5')}
                                            name='self_employment_sectionTwo_form_question_answer_5'
                                            id='self_employment_sectionTwo_form_question_answer_5'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_5', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_5'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_5',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn6">{translateThis('self_employment_sectionTwo_form_income_qn6')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_6`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_6')}
                                            name='self_employment_sectionTwo_form_question_answer_6'
                                            id='self_employment_sectionTwo_form_question_answer_6'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_6', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_6'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_6',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn7">{translateThis('self_employment_sectionTwo_form_income_qn7')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_7`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_7')}
                                            name='self_employment_sectionTwo_form_question_answer_7'
                                            id='self_employment_sectionTwo_form_question_answer_7'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_7', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_7'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_7',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn8">{translateThis('self_employment_sectionTwo_form_income_qn8')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_8`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_8')}
                                            name='self_employment_sectionTwo_form_question_answer_8'
                                            id='self_employment_sectionTwo_form_question_answer_8'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_8', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_8'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_8',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn9">{translateThis('self_employment_sectionTwo_form_income_qn9')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_9`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_9')}
                                            name='self_employment_sectionTwo_form_question_answer_9'
                                            id='self_employment_sectionTwo_form_question_answer_9'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_9', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_9'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_9',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Total Business Expenses")}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn10">{translateThis('self_employment_sectionTwo_form_income_qn10')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_10`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_10')}
                                            name='self_employment_sectionTwo_form_question_answer_10'
                                            id='self_employment_sectionTwo_form_question_answer_10'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_10', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_10'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_10',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Net Business Income")}</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormLabel style={{ whiteSpace: 'pre-line', color: '#98948A' }} id="self_employment_sectionTwo_form_income_qn11">{translateThis('self_employment_sectionTwo_form_income_qn11')}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid maxWidth={300} style={{ paddingTop: '0' }}>
                                        <TextInput
                                            key={`self_employment_sectionTwo_form_Key_income_answer_11`}
                                            label={translateThis('self_employment_sectionTwo_form_question_answer_11')}
                                            name='self_employment_sectionTwo_form_question_answer_11'
                                            id='self_employment_sectionTwo_form_question_answer_11'
                                            value={answerGetter('self_employment_sectionTwo_form_question_answer_11', initialFormData)?.answer}
                                            translationKey='self_employment_sectionTwo_form_question_answer_11'
                                            type='number'
                                            placeholder='Amount'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'self_employment_sectionTwo_form_question_answer_11',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                    </Grid>
                                </Grid>


                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='parents-rights-sec'>
                                        <br />
                                        <p><b>{translateThis("Certification")}</b></p>
                                        <ul>
                                            <p>{translateThis("Certification_1")}</p>
                                            <li>{translateThis("Certification_2")}</li>
                                            <li>{translateThis("Certification_3")}</li>
                                            <li>{translateThis("Certification_4")}</li>
                                        </ul>
                                        <p>{translateThis("Certification_5")}</p>

                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} />

                                <Grid item xs={12} sm={6} md={6}>

                                    <Stack className='panel-form' spacing={3}>
                                        <div className='info-head f-wrp'>
                                            <span>{translateThis("Business Owner")}</span>
                                        </div>
                                        <TextInput
                                            key={`disability_verification_form_key_question_signatureName`}
                                            label={translateThis('disability_verification_form_question_signatureName')}
                                            name='disability_verification_form_question_signatureName'
                                            value={answerGetter('disability_verification_form_question_signatureName', initialFormData?.formData)?.answer}
                                            id='disability_verification_form_question_signatureName'
                                            translationKey='disability_verification_form_question_signatureName'
                                            type='text'
                                            required
                                            placeholder='Type your name'
                                            className='custom-textfield'
                                            getValue={(_, value) => {
                                                setSignature(value)
                                            }}
                                            useDefaultValidation
                                        />
                                        <FormGroup className='check-label'>
                                            <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                                <span>{translateThis("I")}, {signature && <b>{signature},</b>}  {translateThis("affirm that the information provided is true and complete")}</span>
                                            } />
                                        </FormGroup>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>}
                    </Stack>
                </>

                <br />

                <div className='button-sec mob-btn-grid f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='cancel-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        <Button
                            onClick={onPrevStepClick}
                            disabled={currentStep === 1}
                            className='pln-btn'
                        >{translateThis("Previous")}</Button>

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={currentStep === 2 && !isSigned}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onNextClick}>
                            {currentStep === 2 ? <>{translateThis("submit")}</> : <>{translateThis("Next")}</>}
                        </LoadingButton>

                    </div>
                </div>
            </div> : null}
        </>
    );
}

SelfEmployment.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func,
}

export default SelfEmployment;