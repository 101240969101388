import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SVGIcons from '../../assets/images/icons/svgIcons';





const ModalPopUp = (props) => {
    const {
        isOpen = false,
        isShowBackButton = true,
        children = "",
        className = "",
        onClose } = props;

    const [open, setOpen] = React.useState(isOpen);


    /**
     * Handles the close click
     */
    const handleClose = () => {
        setOpen(false);

        if (typeof onClose === "function") {
            onClose();
        }
    };



    return (
        <div>
            <Dialog
                className={className}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    {isShowBackButton && <div className='back-btn-wrp f-wrp'>
                        <IconButton
                            onClick={onClose}
                            aria-label="Go Back">
                            <SVGIcons.BackIcon />
                            Back
                        </IconButton>

                    </div>}
                    {(typeof children === 'string') ? <DialogContentText id="alert-dialog-description">
                        {children}
                    </DialogContentText> : <>{children}</>}
                </DialogContent>

            </Dialog>
        </div>
    );
}

ModalPopUp.propTypes = {
    isOpen: PropTypes.bool,
    isShowBackButton: PropTypes.bool,
    children: PropTypes.any,
    onClose: PropTypes.func,
    className: PropTypes.string,
}

export default ModalPopUp;