import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Testimonial() {


  const testimonialItems = [
    {
      name: 'Hanen Lamar',
      shortName: 'HL',
      content: "Me and my son had a good experience with Kathleen’s daycare she’s really professional and love hard jobs my son loves her a lot she treats the kids with love and, thank you so much for being very helpful me and my son we never forget yo.",
    },
    {
      name: 'Kathy Martinez',
      shortName: 'KM',
      content: "Es una experiencia increíble mi niño desde que llega se siente en casa me encanta la vibra que desempeña cada persona que conforma este espacio le recomiendo que prueben que no se arrepentirán",
    },
    {
      name: 'Hanen Lamar',
      shortName: 'HL',
      content: "Me and my son had a good experience with Kathleen’s daycare she’s really professional and love hard jobs my son loves her a lot she treats the kids with love and, thank you so much for being very helpful me and my son we never forget yo.",
    },
    
  ];

  return (
    <>
      <div className="kathleen-testimonial-sec-wrp">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={10}
            slidesPerView={2}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1275: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  <p>{translateThis(element.content)}</p>
                  <h2><span>{translateThis(element.shortName)}</span>{translateThis(element.name)}</h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;