/* eslint-disable global-require */
const AppIcons = {
  bannerLogo: require('../bannerLogo.png'),
  bannerBG: require('../bannerBG.png'),
  
  logo: require('../Logo.png'),
  service1: require('../service/service1.png'),
  service2: require('../service/service2.png'),
  service3: require('../service/service3.png'),
  service4: require('../service/service4.png'),

  plane: require('../plane.png'),
  NAFCC: require('./credentials/NAFCC.png'),
  CDA: require('./credentials/CDA.png'),

  activity1: require('./activity/activity1.png'),
  activity2: require('./activity/activity2.png'),
  activity3: require('./activity/activity3.png'),
  activity4: require('./activity/activity4.png'),
  activity5: require('./activity/activity5.png'),
  activity6: require('./activity/activity6.png'),
  activity7: require('./activity/activity7.png'),
  activity8: require('./activity/activity8.png'),
  activity9: require('./activity/activity9.png'),
  activity10: require('./activity/activity10.png'),
  activity11: require('./activity/activity11.png'),
  activity12: require('./activity/activity12.png'),
  activity13: require('./activity/activity13.png'),
  activity14: require('./activity/activity14.png'),

  founder1: require('../founder/founder1.png'),
  founder2: require('../founder/founder2.png'),
  founder3: require('../founder/founder3.png'),
  founder4: require('../founder/founder4.png'),

  user1: require('../users/user1.png'),
  user2: require('../users/user2.png'),
  user3: require('../users/user3.png'),
  user4: require('../users/user4.png'),
  user5: require('../users/user5.png'),
  user6: require('../users/user6.png'),
  user7: require('../users/user7.png'),

  
  aboutImg: require('../aboutImg.png'),
  mail: require('./mail.png'),
  mapPoint: require('./mapPoint.png'),
  phone: require('./phone.png'),
  contact: require('../contact.png'),
  Hours: require('./Hours.png'),
  Days: require('./Days.png'),
  acceptedAges: require('./acceptedAges.png'),
  subsidyPrograms: require('./subsidyPrograms.png'),

  
  learning1: require('./learning/learn_1.png'),
  learning2: require('./learning/learn_2.png'),
  learning3: require('./learning/learn_3.png'),
  learning4: require('./learning/learn_4.png'),
  learning5: require('./learning/learn_5.png'),
  learning6: require('./learning/learn_6.png'),
  learning7: require('./learning/learn_7.png'),
  learning8: require('./learning/learn_8.png'),


  gallery1: require('../gallery/gallery_1.png'),
  gallery2: require('../gallery/gallery_2.png'),
  gallery3: require('../gallery/gallery_3.png'),
  gallery4: require('../gallery/gallery_4.png'),
  gallery5: require('../gallery/gallery_5.png'),
  gallery6: require('../gallery/gallery_6.png'),
  gallery7: require('../gallery/gallery_7.png'),
  gallery8: require('../gallery/gallery_8.png'),
  gallery9: require('../gallery/gallery_9.png'),
  gallery10: require('../gallery/gallery_10.png'),
  gallery11: require('../gallery/gallery_11.png'),
  gallery12: require('../gallery/gallery_12.png'),
  gallery13: require('../gallery/gallery_13.png'),
  gallery14: require('../gallery/gallery_14.png'),
  gallery15: require('../gallery/gallery_15.png'),
  gallery16: require('../gallery/gallery_16.png'),
  gallery17: require('../gallery/gallery_17.png'),
  gallery18: require('../gallery/gallery_18.png'),
  gallery19: require('../gallery/gallery_19.png'),
  gallery20: require('../gallery/gallery_20.png'),
  gallery21: require('../gallery/gallery_21.png'),
  gallery22: require('../gallery/gallery_22.png'),
  gallery23: require('../gallery/gallery_23.png'),
  gallery24: require('../gallery/gallery_24.png'),
  gallery25: require('../gallery/gallery_25.png'),
  gallery26: require('../gallery/gallery_26.png'),
  gallery27: require('../gallery/gallery_27.png'),
  gallery28: require('../gallery/gallery_28.png'),
  gallery29: require('../gallery/gallery_29.png'),
  gallery30: require('../gallery/gallery_30.png'),

  
  payment: require('../payment.png'),
  
  mihapp: require('../mihapp.png'),
  
};
export default AppIcons;
/* eslint-enable global-require */
