import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Testimonial() {


  const testimonialItems = [
    {
      name: translateThis('TestimonialName_1'),
      shortName: translateThis('TestimonialShortName_1'),
      content: translateThis('TestimonialContent_1'),
    },
    {
      name: translateThis('TestimonialName_2'),
      shortName: translateThis('TestimonialShortName_2'),
      content: translateThis('TestimonialContent_2'),
    },
    
  ];

  return (
    <>
      <div className="yrene-testimonial-sec-wrp">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={10}
            slidesPerView={2}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1275: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  <h2><span>{element.shortName}</span>{element.name}</h2>
                  <p>{element.content}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;