/* eslint-disable global-require */
const AppIcons = {
  bannerLogo: require('../bannerLogo.png'),
  bannerBG: require('../bannerBG.png'),
  bannerBGMob: require('../bannerBGMob.png'),
  logo: require('../Logo.png'),
  scrollTop: require('../scrollTop.png'),
  scrollBottom: require('../scrollBottom.png'),
  aboutImg: require('../aboutImg.png'),
  installImg: require('../installImg.jpg'),

  mail: require('./mail.png'),
  mapPoint: require('./mapPoint.png'),
  phone: require('./phone.png'),
  contact: require('../contact.png'),
  days: require('./days.png'),
  hours: require('./hours.png'),
  ages: require('./ages.png'),
  programs: require('./programs.png'),

  gallery1: require('../gallery/gallery_1.jpg'),
  gallery2: require('../gallery/gallery_2.jpg'),
  gallery3: require('../gallery/gallery_3.jpg'),
  gallery4: require('../gallery/gallery_4.jpg'),
  gallery5: require('../gallery/gallery_5.jpg'),
  gallery6: require('../gallery/gallery_6.jpg'),
  gallery7: require('../gallery/gallery_7.jpg'),
  gallery8: require('../gallery/gallery_8.png'),
  gallery9: require('../gallery/gallery_9.png'),
  gallery10: require('../gallery/gallery_10.png'),
  gallery11: require('../gallery/gallery_11.png'),
  gallery12: require('../gallery/gallery_12.png'),
  gallery13: require('../gallery/gallery_13.png'),
 
  teachingPlan1: require('../teachingPlan/plan1.png'),
  teachingPlan2: require('../teachingPlan/plan2.png'),
  teachingPlan3: require('../teachingPlan/plan3.png'),
  teachingPlan4: require('../teachingPlan/plan4.png'),

  infoImg: require('../infoImg.jpg'),

  teachers1: require('../teachers/teachers1.png'),
  teachers2: require('../teachers/teachers2.png'),

  paymentBG: require('../paymentBG.png'),
  
  founder: require('../founder.png'),
  payment: require('../payment.png'),
  
  mihapp: require('../mihapp.png'),
 
  user1:  require('../testimonial/user1.png'),
  user2:  require('../testimonial/user2.png'),
  user3:  require('../testimonial/user3.png'),
  
};
export default AppIcons;
/* eslint-enable global-require */
