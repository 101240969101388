import * as React from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppLayout from '../../layouts/app/AppLayout';
import ParentPortalDrawer from './components/drawer';
import ParentPortalHeader from './components/header';
import { getLatestMe } from '../../helpers/auth.helper';

import './index.scss';

import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import ChildrenSection from './children';
import DailyLogs from './dailyLogs/index';
import Policy from './policy';
import PaymentSection from './payment';
import Profile from '../profile';
import ParentDynamicFormSection from './dynamicForms';
import BulletinSection from './bulletin';
import InboxSection from './inboxSection';
import AccidentReport from './accidentReport';
import ChildrenAttendanceList from './childrenAttendance';
import Tutorials from './tutorials';

export default function ParentPortal() {

    const pageName = "Parent portal";
    const [content, setContent] = React.useState(<></>);
    const { selectedNavItem } = useRecoilValue(myPortalState);
    React.useEffect(() => {
        if (selectedNavItem) {
            onSelectNav(selectedNavItem)
        }
        getLatestMe()

    }, [selectedNavItem]);

    /**
     * Sets the content to display based on the current selection
     * @param {string} item 
     */
    const onSelectNav = (item) => {
        let contentSelected = <span>Children</span>
        switch (item) {
            case 'children':
                contentSelected = <ChildrenSection />;
                break;
            case 'logs':
                contentSelected = <DailyLogs />;
                break;
            case 'bulletin':
                contentSelected = <BulletinSection />;
                break;
            case 'inbox':
                contentSelected = <InboxSection />;
                break;
            case 'payment':
                contentSelected = <PaymentSection />;
                break;
            case 'forms':
                contentSelected = <ParentDynamicFormSection />;
                break;
            case 'accident':
                contentSelected = <AccidentReport />;
                break;
            case 'policies':
                contentSelected = <Policy />;
                break;
            case 'tutorials':
                contentSelected = <Tutorials />;
                break;
            case 'account':
                contentSelected = <Profile />;
                break;
            case 'kidCheckIn':
                contentSelected = <ChildrenAttendanceList />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        setContent(contentSelected);
    }

    return (
        <>
            <AppLayout
                pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName='parent-portal f-wrp'
            >

                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <ParentPortalDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-header'>
                            <CssBaseline />
                            <ParentPortalHeader />
                        </Box>
                        <Box className='panel-main-content'>
                            {content}
                        </Box>
                    </Box>
                </Box>
            </AppLayout>
        </>
    );
}