import * as React from 'react';
import propTypes from 'prop-types';

// @mui

import { Button, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';

// components
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';
import '../../../assets/css/form.scss';
// ----------------------------------------------------------------------

import ApiCaller from '../../../services/api/general';
import { getAuthDetailsByKey } from '../../../helpers/auth.helper';
import RichTextEditor from '../../../components/rich-text-editor/index';



const AddEditMessageForm = (props) => {
    const apiCaller = new ApiCaller('inbox')
    const { id,
        onClose = () => null,
        onSuccess = () => null,
    } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const resourceId = (id && id !== 'add-resource') ? id : '';


    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData && formData.subject &&
            formData.message
        ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            const action = apiCaller.postData;

            const formPostData = {
                email: getAuthDetailsByKey('email'),
                name: `${getAuthDetailsByKey('firstName')} ${getAuthDetailsByKey('lastName')}`,
                phoneNumber: getAuthDetailsByKey('phoneNumber'),
                subject: formData.subject,
                message: formData.message,
            }
            action(formPostData).then((data) => {
                if (data.success) {
                    onSuccess();
                    setFormData({})
                    showSnackbar().success("Message sent successfully!")
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsLoading(false));
        }
    };

    const onCancelClick = () => {
        setFormData({})
        if (typeof onClose === "function") {
            onClose();
        }

    }


    return (
        <form key={resourceId} className='add-new-message-layout f-wrp'>
            <Stack className='new-message standard-form' spacing={3}>
                <h3>New Message</h3>

                <TextInput
                    key={`subject-name`}
                    label='Subject'
                    name='subject'
                    value={formData?.subject}
                    type='text'
                    required
                    placeholder='Subject'
                    getValue={setFormValue}
                    className='custom-textfield'
                />

                <Grid item xs={12} sm={12} md={12}>
                    <RichTextEditor
                    toolbarOnFocus={false}
                        defaultValue={''}
                        onChange={(value) => setFormValue('message', JSON.stringify(value))}

                    />
                </Grid>
                <div className='btn-wrp'>
                    <Button
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        className='standard-btn pln-btn'
                        onClick={() => onCancelClick()}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition='start'
                        startIcon={<Send />} // To remove Mui warning
                        fullWidth
                        disabled={!isValidForm()}
                        size="large"
                        type="submit"
                        variant="contained"
                        className='standard-btn'
                        onClick={onSubmit}>
                        {resourceId ? 'Update' : 'Send'}
                    </LoadingButton>
                </div>

            </Stack>
        </form>
    );
}

AddEditMessageForm.propTypes = {
    id: propTypes.string,
    onClose: propTypes.func,
    onSuccess: propTypes.func,
}

export default AddEditMessageForm;