import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../images/icons/svgIcons';
// import SVGIcons from '../images/icons/index';

import './Services.scss';
import { translateThis } from "../../../../helpers/language.helper";


function KathleenServices() {


  const aboutItems = [
    {
      className: 'ages',
      icon: SVGIcons.AgesIcon(),
      title: 'Ages',
      description: 'We are accepting children from 2 months to 12 years old.',
    },
    {
      className: 'food',
      icon: SVGIcons.FoodIcon(),
      title: 'Food program',
      description: 'We are under Saugatuck Food Program to provide your child with nourishing meals.',
    },
    {
      className: 'playground',
      icon: SVGIcons.PlaygroundIcon(),
      title: 'Playground',
      description: 'Explore, play, and grow on our enriching playground at Sweet Sunrise.',
    },
    {
      className: 'subsidizedCare',
      icon: SVGIcons.SubCareIcon(),
      title: 'Subsidized care',
      description: 'Opening doors to quality care for all families, including subsidized options.',
    },

    {
      className: 'bilingual',
      icon: SVGIcons.BilingualIcon(),
      title: 'Bilingual',
      description: 'Nurturing language with a bilingual touch and cross-cultural understanding.',
    },
  ];

  return (
    <>
      <div className="Kathleen-Services-wrp f-wrp">

        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={4}
          className='content-sec-mob'
          navigation
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1275: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}

        >
          {aboutItems.map((element, key) => (
            <SwiperSlide key={key}>
              <div className={`${element.className} each-service-sec f-wrp `}>
                <span className='service-icon'>{element.icon}</span>
                <div className="service-con-sec f-wrp">
                  <h2>{translateThis(element.title)}</h2>
                  <p>{translateThis(element.description)}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default KathleenServices;