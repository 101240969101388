import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';


const EmergencyNumbersView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {}, pdfLogo = '' } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp emergencyNumbersView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {pdfLogo && <span style={{ maxWidth: '250px', display: 'block' }}><img src={pdfLogo} alt='profile' /></span>}
                    </div>
                </div>
                <div className='gap' />
                {/* <div className='prof-sec'>
                    <div className='prof-img'>
                        <span><img src={AppIcons.registerBG} alt='profile' /></span>
                    </div>
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div> */}
                <h3><b>{translateThis("EMERGENCY NUMBERS")}</b></h3>
                <table style={{ color: '#0F0A01' }}>
                    <tbody>
                        <tr>
                            <td><b>{translateThis("FIRE")}:</b> {translateThis("911")}</td>
                            <td><b>{translateThis("Poison Control")}:</b> {translateThis("1-800-222-1222")}</td>
                        </tr>
                        <tr>
                            <td><b>{translateThis("POLICE")}:</b> {translateThis("911")}</td>
                            <td><b>{translateThis("OEC Child Care Licensing")}:</b> {translateThis("1-800-282-6063 or 1-860-500-4450")}</td>
                        </tr>
                        <tr>
                            <td><b>{translateThis("AMBULANCE")}:</b> {translateThis("911")}</td>
                            <td><b>{translateThis("Child Abuse Care Line")}:</b> {translateThis("1-800-842-2288")}</td>
                        </tr>
                    </tbody>
                </table>
                <div className='auto-flex' style={{flexWrap: 'wrap'}}>
                    <p style={{minWidth:' 50%'}}>{translateThis("Emergency Caregiver Name")}: <b>{answerGetter('emergency_form_question_name', formData)?.answer || '-'}</b></p>
                    <p style={{minWidth:' 50%'}}>{translateThis("Phone")}: <b>{answerGetter('emergency_form_question_phone', formData)?.answer || '-'}</b></p>
                </div>

                <p style={{ fontSize: '16px', margin: '10px 0' }}>{translateThis("Child’s Name")}: <b style={{ fontSize: '15px' }}>{answerGetter('emergency_form_question_childName', formData)?.answer || `${child?.firstName} ${child?.lastName}`}</b></p>

                <div className='each-box' style={{ marginBottom: '10px' }}>
                    <p>{translateThis("Parent")}: <b>{answerGetter('emergency_form_question_parentName_1', formData)?.answer || '-'}</b></p>
                    <div className='flex-box'>
                        <p>{translateThis("Telephone")}: <b>{answerGetter('emergency_form_question_homeNumber_1', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("early_childhood_health_assessment_form_question_guardianCellPhone")}: <b>{answerGetter('emergency_form_question_cellPhoneNumber_1', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Work number")}: <b>{answerGetter('emergency_form_question_workNumber_1', formData)?.answer || '-'}</b></p>
                    </div>
                </div>
                <div className='each-box' style={{ marginBottom: '10px' }}>
                    <p>{translateThis("Parent")}: <b>{answerGetter('emergency_form_question_parentName_2', formData)?.answer || '-'}</b></p>
                    <div className='flex-box'>
                        <p>{translateThis("Telephone")}: <b>{answerGetter('emergency_form_question_homeNumber_2', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("emergency_form_question_cellPhoneNumber_2")}: <b>{answerGetter('emergency_form_question_cellPhoneNumber_2', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Work number")}: <b>{answerGetter('emergency_form_question_workNumber_2', formData)?.answer || '-'}</b></p>
                    </div>
                </div>
                <div style={{ display: 'none' }} className='auto-flex'>
                    <p style={{ fontSize: '16px', lineHeight: '1', paddingRight: '10px' }}>{translateThis("Notes/Other")}:</p>
                    <p><b>{answerGetter('child_enrollment_form_notes', formData)?.answer || '-'}</b></p>
                </div>

                <div style={{ display: 'none' }} className="signature-blk">
                    <div style={{ maxWidth: '9cm' }}>
                        <h3>{
                            data.additionalInfo && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignature
                                : ''
                        }</h3>
                        <p>{translateThis("Parent / guardian signature")}</p>
                        <p>{translateThis("Submission time")}: <b>{
                            data.additionalInfo && data.additionalInfo.parentSignedDate && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignedDate
                                : ''
                        }</b></p>
                    </div>
                </div>
            </div>
        </>
    )
}

EmergencyNumbersView.propTypes = {
    data: PropTypes.any
}
export default EmergencyNumbersView;