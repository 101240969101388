import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AppIcons from '../images/icons/index';

import './gallery.scss';


function Gallery() {


  const galleryItems = [
    {
      image: AppIcons.gallery1,
    },
    {
      image: AppIcons.gallery2,
    },
    {
      image: AppIcons.gallery3,
    },
    {
      image: AppIcons.gallery4,
    },
    {
      image: AppIcons.gallery1,
    },
    {
      image: AppIcons.gallery2,
    },
    {
      image: AppIcons.gallery3,
    },
    {
      image: AppIcons.gallery4,
    },
    {
      image: AppIcons.gallery1,
    },
    {
      image: AppIcons.gallery2,
    },
    {
      image: AppIcons.gallery3,
    },
    {
      image: AppIcons.gallery4,
    },
    {
      image: AppIcons.gallery1,
    },
    {
      image: AppIcons.gallery2,
    },
    {
      image: AppIcons.gallery3,
    },
    {
      image: AppIcons.gallery4,
    },
    
    
  ];

  return (
    <>
      <div className="gallery-section-wrp argentina f-wrp">

        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          spaceBetween={10}
          slidesPerView={4}
          className='content-sec-mob'
          navigation
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            dynamicBullets: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          
        >
          {galleryItems.map((element, key) => (
            <SwiperSlide key={key}>
                <span className='gallery-image'><img src={element.image} alt='gallery' /></span>
            </SwiperSlide>
          ))}
        </Swiper>



      </div>






    </>
  );
}

export default Gallery;