import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CircularProgress, Button } from '@mui/material';
import ModalPopUp from '../../../components/modal/modal.popup';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { DATE_TIME_FORMAT } from '../../../constants/index';
import AppButton from '../../../components/buttons/app.button';
import TextInput from '../../../components/inputs/textInput/textInput';
import { translateThis } from '../../../helpers/language.helper';
import { generatePDFBy } from '../../../services/api/pdf';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';




const ParentDynamicFormsToSignTab = () => {
    const apiCaller = new ApiCaller('form-submissions');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openSubmissionDialog, setOpenSubmissionDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [signature, setSignature] = useState('');
    const [viewOpen, setViewOpen] = useState(false);
    const [selectedPreview, setSelectedPreview] = useState(<></>);


    const onViewClose = () => {
        setViewOpen(false);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => {
                if (data) {
                    let dataArray = data?.results || [];
                    dataArray = dataArray.filter((data) => (!data.isProviderSigned || !data.isParentSigned) && ['parent-provider-agreement', 'summer-pp-agreement'].includes(data.formName))
                    setDataArr(dataArray)
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false));
    }
    /**
     * 
     * @param {string} formName 
     * @param {any} formData 
     */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }



    /**
   * submit Daily Log
   */
    const submitSignature = () => {
        if (selectedId && signature) {
            setIsSubmitting(true);
            apiCaller.updateData(selectedId, {
                isParentSigned: true,
                status: 'completed',
                additionalInfo: {
                    isParentSigned: true,
                    parentSignature: signature,
                    parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                }
            })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been signed successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenSubmissionDialog(false);
                    setIsSubmitting(false);
                    setSelectedId('');
                    setSignature('');
                    getDataArr();
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }

    }

    /**
   * Download PDF
   */
    const downloadPDF = (childId, userId, formName) => {
        if (childId && userId && formName) {
            setIsDownloading(true);
            showSnackbar().info("Selected form will be downloaded shortly")
            generatePDFBy(userId, childId, formName)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsDownloading(false);
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 2,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'formTitle',
            headerName: 'Form',
            flex: 2,
            minWidth: 260,
        },
        {
            field: 'createdAt',
            headerName: 'Submission Date',
            flex: 2,
            minWidth: 200,
            valueGetter: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
            field: 'isParentSigned',
            headerName: 'Parent',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => params.row?.isParentSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <AppButton
                    onClick={() => {
                        setOpenSubmissionDialog(true);
                        setSelectedId(params.row?.id);
                    }}
                    btnText='Sign'
                />,
        },
        {
            field: 'isProviderSigned',
            headerName: 'Provider',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => params.row?.isProviderSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <span className='pending'><SVGIcons.ClockCountdownIcon /> Pending</span>,
        },
        {
            field: 'id',
            headerName: 'Actions',
            minWidth: 90,
            flex: 0.8,
            sortable: false,
            renderCell: (params) => {
                const { childId, userId, formName } = params.row
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onFormPreviewClick(formName, params.row)
                    },
                    {
                        label: isDownloading ? <CircularProgress size={20} /> : 'Download',
                        onClick: () => downloadPDF(childId?.id, userId?.id, formName)
                    }
                ]} />
            }
        },

    ]




    return (
        <>

            <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec parent-table  f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                </div>
                {openSubmissionDialog && <ConfirmDialog
                    isOpen={openSubmissionDialog}
                    onClose={() => {
                        setOpenSubmissionDialog(false);
                        setSelectedId('');
                        setSignature('');
                    }}
                    title={`Form submission signature`}
                    className={'delete-popup'}
                    description={<div>
                        <TextInput
                            key={`signature_signature`}
                            label={translateThis('typeYourName')}
                            name='signature'
                            id='signature'
                            value={signature}
                            type='text'
                            required
                            placeholder={translateThis('typeYourName')}
                            className='custom-textfield'
                            getValue={(_, value) => {
                                setSignature(value)
                            }}
                            useDefaultValidation
                        />
                        <br />
                        <span>
                            By clicking this checkbox you confirm you have read and accepted the submission made by the parent. <span style={{ color: '#FF3D00' }}> This action cannot be undone.</span>
                        </span>
                    </div>}
                    okayButtonText={'Sign form'}
                    confirmButton={
                        <AppButton
                            onClick={submitSignature}
                            isDisabled={signature.length < 2}
                            btnText={translateThis('signForm')}
                            isLoading={isSubmitting}
                        />
                    }
                />}
                {viewOpen ? <ModalPopUp
                    className='preview-popup'
                    isOpen={viewOpen}
                    onClose={onViewClose}
                    aria-labelledby="table-view"
                    aria-describedby="table-view"
                >
                    {selectedPreview}
                    <Button onClick={onViewClose} className='fill-btn'>Close</Button>
                </ModalPopUp> : null}
            </div>
        </>
    )
}


export default ParentDynamicFormsToSignTab;