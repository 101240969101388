import React from "react";
// import { element } from "prop-types";
import AppIcons from "../images/icons";
import './teachingPlan.scss';
import { translateThis } from "../../../../helpers/language.helper";




function TeachingPlan() {

  const TeachingPlanItems = [
    {
      image: AppIcons.teachingPlan1,
      title: translateThis('teachingPlan1_title_1'),
      description: translateThis('teachingPlan1_description_1'),
    },
    {
      image: AppIcons.teachingPlan2,
      title: translateThis('teachingPlan1_title_2'),
      description: translateThis('teachingPlan1_description_2'),
    },
    {
      image: AppIcons.teachingPlan3,
      title: translateThis('teachingPlan1_title_3'),
      description: translateThis('teachingPlan1_description_3'),
    },
    {
      image: AppIcons.teachingPlan4,
      title: translateThis('teachingPlan1_title_4'),
      description: translateThis('teachingPlan1_description_4'),
      list: <><ul>
        <li>{translateThis('teachingPlan1_description_list_1')}</li>
        <li>{translateThis('teachingPlan1_description_list_2')}</li>
        <li>{translateThis('teachingPlan1_description_list_3')}</li>
      </ul></>
    },

  ];


  return (
    <>
      <div className="Francisca-teachingPlan-wrp f-wrp">
        <div className="row">
          {TeachingPlanItems.map((element, key) => (
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12" key={key}>
              <div className="each-approach-sec f-wrp ">
                <div className="approach-con-sec f-wrp">
                  <span className='fee-sec-icon'><img src={element.image} alt={element.title} /></span>
                  <h2>{element.title}</h2>
                  <p>{element.description}</p>
                  {element.list}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TeachingPlan;