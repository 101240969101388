import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';

// @mui
import { Stack, Divider, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';

// components
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';

// services
import { getChild } from '../../../../services/api/child';

// helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_EXTENDED_FORMAT, DATE_TIME_FORMAT, TIME_12HR_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';
import { getChildLogBy, submitDailyLog } from '../../../../services/api/dailyLogs';
import { ProviderCommunicationFormDefaultValues } from '../models/providerCommunication.model';
import TimeInput from '../../../../components/inputs/timeInput';
import { providerConfigState } from '../../../../state/reducerAtoms/provider.atom';



const ProviderCommunicationForm = (props) => {
    const pageName = "Provider communication";
    const logKey = "provider-communication";

    const { childId, onChangeFormState = () => null } = props;
    const providerId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [logDate, setLogDate] = useState(dayjs());
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [childNeeds, setChildNeeds] = useState([]);
    const [submissionDate, setSubmissionDate] = useState(dayjs());


    // Save form temporarily 
    const [configState, setConfigState] = useRecoilState(providerConfigState);
    const defaultFormValues = configState.dailyCommunication?.providerCommunication[childId] || ProviderCommunicationFormDefaultValues
    const [initialFormData, setInitialFormData] = useState(defaultFormValues);
    const [formData, setFormData] = useState(defaultFormValues);

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({
        ...prv, ...{
            dailyCommunication: {
                ...prv.dailyCommunication,
                providerCommunication: { ...prv.dailyCommunication?.providerCommunication, ...modifiedObj }
            }
        }
    }));

    /**
        * Invokes when a cleanup triggered
        * @returns Fun
        */
    const cleanupFormFromState = () => {        
        setConfigState((prv) => {
            const providerCommunication = { ...prv.dailyCommunication?.providerCommunication, [childId]: '' };
            delete providerCommunication[childId];
            return ({
                ...prv, ...{
                    dailyCommunication: {
                        ...prv.dailyCommunication,
                        providerCommunication
                    }
                }
            })
        })
    };

    // END of temporarily save form

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(logKey));
        if (childId) {
            getChildById();
        }
        setInterval(() => {
            setSubmissionDate(dayjs())
        }, 1000);
        return () => {
            setInitialFormData(defaultFormValues)
        };
        // eslint-disable-next-line
    }, [childId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                    getData();
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => { });
    }

    // get data from api
    const getData = () => {
        if (selectedChild) {
            setIsLoading(true);
            getChildLogBy(selectedChild.parentId, childId, logKey)
                .then((data) => {
                    if (data?.formData) {
                        // setInitialFormData(data?.formData)
                        // setFormData(data?.formData)
                    }
                })
                .catch(() => showSnackbar().failure('Something went wrong'))
                .finally(() => setIsLoading(false));
        }

    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
        modifyConfigState({ [childId]: filteredFormData })
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild.id) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        let formDataAs = formData.concat(ProviderCommunicationFormDefaultValues)
        formDataAs = formDataAs.filter((v, i, a) => a.findIndex(v2 => ['questionId', 'type'].every(k => v2[k] === v[k])) === i)
        const postData = {
            logKey: 'provider-communication',
            logTitle: 'Provider Communication',
            additionalInfo: {
                isProviderSigned: true,
                providerSignature: signature,
                providerSignedDate: dayjs().format(DATE_TIME_FORMAT)
            },
            parentId: selectedChild.parentId,
            providerId,
            childId,
            formData: formDataAs,
            isProviderSigned: true,
            submissionDate: dayjs().format(DATE_TIME_FORMAT),
            logDate
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitDailyLog(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    cleanupFormFromState();
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectChildNeeds = (selectedItem, checked) => {
        let alreadySelectedItems = childNeeds;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setChildNeeds(alreadySelectedItems);
        setFormValue({
            questionId: 'provider_communication_form_question_childNeeds',
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: 'provider_communication_form_question_childNeeds',
            type: 'checkBox'
        })

    }


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Daily communication")}</h2>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis(pageName)}</h3>
                                </div>
                            </Grid>
                            {(selectedChild) && <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Stack className='panel-form' spacing={3}>
                                    <TextField
                                        type='text'
                                        disabled
                                        defaultValue={`${selectedChild?.firstName} ${selectedChild?.lastName}`}
                                        name="childName"
                                        label={translateThis('Child’s name')}
                                    />
                                </Stack>
                            </Grid>}
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Stack className='panel-form calender-sec' spacing={3}>
                                    <LocalizationProvider className="custom-textfield" dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                format={DATE_FORMAT}                                                
                                                label={translateThis('Log date')}
                                                defaultValue={logDate}
                                                maxDate={dayjs()}
                                                disableFuture
                                                onChange={(v) => setLogDate(dayjs(v).format(DATE_FORMAT))}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                            {submissionDate && <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Stack className='panel-form calender-sec' spacing={3}>
                                    <TextField
                                        type='text'                                        
                                        label={translateThis('Submission date and time')}
                                        value={dayjs(submissionDate).format(DATE_TIME_EXTENDED_FORMAT)}
                                        disabled
                                    />

                                </Stack>
                            </Grid>}

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis('Food')}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="breakfastLabel">{translateThis('breakFast')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('provider_communication_form_question_breakfast', initialFormData)?.answer}
                                            name="provider_communication_form_question_breakfast"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_communication_form_question_breakfast',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_communication_form_question_breakfast',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Ate well" control={<Radio />} label={translateThis('Ate well')} />
                                            <FormControlLabel value="Ate a little" control={<Radio />} label={translateThis('Ate a little')} />
                                            <FormControlLabel value="Did not eat" control={<Radio />} label={translateThis('Did not eat')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="morningSnackLabel">{translateThis('morningSnack')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('provider_communication_form_question_morningSnack', initialFormData)?.answer}
                                            name="provider_communication_form_question_morningSnack"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_communication_form_question_morningSnack',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_communication_form_question_morningSnack',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Ate well" control={<Radio />} label={translateThis('Ate well')} />
                                            <FormControlLabel value="Ate a little" control={<Radio />} label={translateThis('Ate a little')} />
                                            <FormControlLabel value="Did not eat" control={<Radio />} label={translateThis('Did not eat')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="lunchLabel">{translateThis('Lunch')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('provider_communication_form_question_lunch', initialFormData)?.answer}
                                            name="provider_communication_form_question_lunch"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_communication_form_question_lunch',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_communication_form_question_lunch',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Ate well" control={<Radio />} label={translateThis('Ate well')} />
                                            <FormControlLabel value="Ate a little" control={<Radio />} label={translateThis('Ate a little')} />
                                            <FormControlLabel value="Did not eat" control={<Radio />} label={translateThis('Did not eat')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="afternoonSnackLabel">{translateThis('afternoonSnack')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('provider_communication_form_question_afternoonSnack', initialFormData)?.answer}
                                            name="provider_communication_form_question_afternoonSnack"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_communication_form_question_afternoonSnack',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_communication_form_question_afternoonSnack',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Ate well" control={<Radio />} label={translateThis('Ate well')} />
                                            <FormControlLabel value="Ate a little" control={<Radio />} label={translateThis('Ate a little')} />
                                            <FormControlLabel value="Did not eat" control={<Radio />} label={translateThis('Did not eat')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="dinnerLabel">{translateThis('dinner')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('provider_communication_form_question_dinner', initialFormData)?.answer}
                                            name="provider_communication_form_question_dinner"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_communication_form_question_dinner',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_communication_form_question_dinner',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Ate well" control={<Radio />} label={translateThis('Ate well')} />
                                            <FormControlLabel value="Ate a little" control={<Radio />} label={translateThis('Ate a little')} />
                                            <FormControlLabel value="Did not eat" control={<Radio />} label={translateThis('Did not eat')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Divider style={{ display: 'none' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Diaper changes")} <i>({translateThis("optional")})</i></h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="firstChangeLabel">{translateThis('firstChange')}</FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter(`provider_communication_form_question_firstChangeTime`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_communication_form_question_firstChangeTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `provider_communication_form_question_firstChangeTime`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: `provider_communication_form_question_firstChangeTime`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextInput
                                                    key={`provider_communication_form_Key_staffName`}
                                                    label={translateThis('provider_communication_form_staffName')}
                                                    name='provider_communication_form_staffName'
                                                    id='provider_communication_form_staffName'
                                                    value={answerGetter('provider_communication_form_staffName', initialFormData)?.answer}
                                                    translationKey='provider_communication_form_staffName'
                                                    type='text'
                                                    required
                                                    placeholder={translateThis('provider_communication_form_staffName')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_communication_form_staffName',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <RadioGroup row
                                                    aria-labelledby="Radio Role"
                                                    defaultValue={answerGetter('provider_communication_form_change_condition', initialFormData)?.answer}
                                                    name="provider_communication_form_change_condition"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'provider_communication_form_change_condition',
                                                            answer: e.target.value,
                                                            translationKey: 'provider_communication_form_change_condition',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Dry" control={<Radio />} label={translateThis('Dry')} />
                                                    <FormControlLabel value="Wet" control={<Radio />} label={translateThis('Wet')} />
                                                    <FormControlLabel value="BM" control={<Radio />} label={translateThis('BM')} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="secondChangeLabel">{translateThis('secondChange')}</FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter(`provider_communication_form_question_secondChangeTime`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_communication_form_question_secondChangeTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `provider_communication_form_question_secondChangeTime`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: `provider_communication_form_question_secondChangeTime`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextInput
                                                    key={`provider_communication_form_Key_staffName_2`}
                                                    label={translateThis('provider_communication_form_staffName_2')}
                                                    name='provider_communication_form_staffName_2'
                                                    id='provider_communication_form_staffName_2'
                                                    value={answerGetter('provider_communication_form_staffName_2', initialFormData)?.answer}
                                                    translationKey='provider_communication_form_staffName_2'
                                                    type='text'
                                                    required
                                                    placeholder={translateThis('provider_communication_form_staffName_2')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_communication_form_staffName_2',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <RadioGroup row
                                                    aria-labelledby="Radio Role"
                                                    defaultValue={answerGetter('provider_communication_form_change_condition_2', initialFormData)?.answer}
                                                    name="provider_communication_form_change_condition_2"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'provider_communication_form_change_condition_2',
                                                            answer: e.target.value,
                                                            translationKey: 'provider_communication_form_change_condition_2',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Dry" control={<Radio />} label={translateThis('Dry')} />
                                                    <FormControlLabel value="Wet" control={<Radio />} label={translateThis('Wet')} />
                                                    <FormControlLabel value="BM" control={<Radio />} label={translateThis('BM')} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="thirdChangeLabel">{translateThis('thirdChange')}</FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter(`provider_communication_form_question_thirdChangeTime`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_communication_form_question_thirdChangeTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `provider_communication_form_question_thirdChangeTime`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: `provider_communication_form_question_thirdChangeTime`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                />


                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextInput
                                                    key={`provider_communication_form_Key_staffName_3`}
                                                    label={translateThis('provider_communication_form_staffName_3')}
                                                    name='provider_communication_form_staffName_3'
                                                    id='provider_communication_form_staffName_3'
                                                    value={answerGetter('provider_communication_form_staffName_3', initialFormData)?.answer}
                                                    translationKey='provider_communication_form_staffName_3'
                                                    type='text'
                                                    required
                                                    placeholder={translateThis('provider_communication_form_staffName_3')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_communication_form_staffName_3',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <RadioGroup row
                                                    aria-labelledby="Radio Role"
                                                    defaultValue={answerGetter('provider_communication_form_change_condition_3', initialFormData)?.answer}
                                                    name="provider_communication_form_change_condition_3"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'provider_communication_form_change_condition_3',
                                                            answer: e.target.value,
                                                            translationKey: 'provider_communication_form_change_condition_3',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Dry" control={<Radio />} label={translateThis('Dry')} />
                                                    <FormControlLabel value="Wet" control={<Radio />} label={translateThis('Wet')} />
                                                    <FormControlLabel value="BM" control={<Radio />} label={translateThis('BM')} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="fourthChangeLabel">{translateThis('fourthChange')}</FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter(`provider_communication_form_question_fourthChangeTime`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_communication_form_question_fourthChangeTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `provider_communication_form_question_fourthChangeTime`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: `provider_communication_form_question_fourthChangeTime`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextInput
                                                    key={`provider_communication_form_Key_staffName_4`}
                                                    label={translateThis('provider_communication_form_staffName_4')}
                                                    name='provider_communication_form_staffName_4'
                                                    id='provider_communication_form_staffName_4'
                                                    value={answerGetter('provider_communication_form_staffName_4', initialFormData)?.answer}
                                                    translationKey='provider_communication_form_staffName_4'
                                                    type='text'
                                                    required
                                                    placeholder={translateThis('provider_communication_form_staffName_4')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_communication_form_staffName_4',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <RadioGroup row
                                                    aria-labelledby="Radio Role"
                                                    defaultValue={answerGetter('provider_communication_form_change_condition_4', initialFormData)?.answer}
                                                    name="provider_communication_form_change_condition_4"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'provider_communication_form_change_condition_4',
                                                            answer: e.target.value,
                                                            translationKey: 'provider_communication_form_change_condition_4',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Dry" control={<Radio />} label={translateThis('Dry')} />
                                                    <FormControlLabel value="Wet" control={<Radio />} label={translateThis('Wet')} />
                                                    <FormControlLabel value="BM" control={<Radio />} label={translateThis('BM')} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="fifthChangeLabel">{translateThis('fifthChange')}</FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter(`provider_communication_form_question_fifthChangeTime`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_communication_form_question_fifthChangeTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `provider_communication_form_question_fifthChangeTime`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: `provider_communication_form_question_fifthChangeTime`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextInput
                                                    key={`provider_communication_form_Key_staffName_5`}
                                                    label={translateThis('provider_communication_form_staffName_5')}
                                                    name='provider_communication_form_staffName_5'
                                                    id='provider_communication_form_staffName_5'
                                                    value={answerGetter('provider_communication_form_staffName_5', initialFormData)?.answer}
                                                    translationKey='provider_communication_form_staffName_5'
                                                    type='text'
                                                    required
                                                    placeholder={translateThis('provider_communication_form_staffName_5')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_communication_form_staffName_5',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <RadioGroup row
                                                    aria-labelledby="Radio Role"
                                                    defaultValue={answerGetter('provider_communication_form_change_condition_5', initialFormData)?.answer}
                                                    name="provider_communication_form_change_condition_5"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'provider_communication_form_change_condition_5',
                                                            answer: e.target.value,
                                                            translationKey: 'provider_communication_form_change_condition_5',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Dry" control={<Radio />} label={translateThis('Dry')} />
                                                    <FormControlLabel value="Wet" control={<Radio />} label={translateThis('Wet')} />
                                                    <FormControlLabel value="BM" control={<Radio />} label={translateThis('BM')} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="sixChangeLabel">{translateThis('sixChange')}</FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter(`provider_communication_form_question_sixChangeTime`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_communication_form_question_sixChangeTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `provider_communication_form_question_sixChangeTime`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: `provider_communication_form_question_sixChangeTime`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextInput
                                                    key={`provider_communication_form_Key_staffName_6`}
                                                    label={translateThis('provider_communication_form_staffName_6')}
                                                    name='provider_communication_form_staffName_6'
                                                    id='provider_communication_form_staffName_6'
                                                    value={answerGetter('provider_communication_form_staffName_6', initialFormData)?.answer}
                                                    translationKey='provider_communication_form_staffName_6'
                                                    type='text'
                                                    required
                                                    placeholder={translateThis('provider_communication_form_staffName_6')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_communication_form_staffName_6',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <RadioGroup row
                                                    aria-labelledby="Radio Role"
                                                    defaultValue={answerGetter('provider_communication_form_change_condition_6', initialFormData)?.answer}
                                                    name="provider_communication_form_change_condition_6"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'provider_communication_form_change_condition_6',
                                                            answer: e.target.value,
                                                            translationKey: 'provider_communication_form_change_condition_6',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Dry" control={<Radio />} label={translateThis('Dry')} />
                                                    <FormControlLabel value="Wet" control={<Radio />} label={translateThis('Wet')} />
                                                    <FormControlLabel value="BM" control={<Radio />} label={translateThis('BM')} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis('Items')} <i>({translateThis('optional')})</i></h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="childNeedLabel">{translateThis('yourChildNeeds')}</FormLabel>
                                        <FormGroup row
                                            aria-labelledby="child needs"
                                            defaultValue={answerGetter('yourChildNeeds', initialFormData)?.answer}
                                            id="yourChildNeeds"
                                            name="yourChildNeeds"
                                            onChange={(e) => {
                                                onSelectChildNeeds(e.target.value, e.target.checked)
                                            }}
                                        >
                                            <FormControlLabel value="Diapers" control={<Checkbox />} label={translateThis('Diapers')} labelPlacement="end" />
                                            <FormControlLabel value="Underwear" control={<Checkbox />} label={translateThis('Underwear')} labelPlacement="end" />
                                            <FormControlLabel value="Clothing" control={<Checkbox />} label={translateThis('Clothing')} labelPlacement="end" />
                                            <FormControlLabel value="Blankets" control={<Checkbox />} label={translateThis('Blankets')} labelPlacement="end" />
                                        </FormGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis('Comments')} <i>({translateThis('optional')})</i></h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    key={`provider_communication_form_Key_comments`}
                                    label={translateThis('provider_communication_form_comments')}
                                    name='provider_communication_form_comments'
                                    id='provider_communication_form_comments'
                                    defaultValue={answerGetter('provider_communication_form_comments', initialFormData)?.answer}
                                    type='text'
                                    required
                                    multiline
                                    placeholder={translateThis('Corrective action needed to prevent reoccurrence')}
                                    className='custom-textfield'
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: "provider_communication_form_comments",
                                            answer: e.target.value,
                                            translationKey: 'provider_communication_form_comments',
                                            type: 'text'
                                        })
                                    }}
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    key={`provider_communication_form_Key_incident`}
                                    label={translateThis('provider_communication_form_incident')}
                                    name='provider_communication_form_incident'
                                    id='provider_communication_form_incident'
                                    defaultValue={answerGetter('provider_communication_form_incident', initialFormData)?.answer}
                                    type='text'
                                    required
                                    multiline
                                    placeholder={translateThis('provider_communication_form_incident')}
                                    className='custom-textfield'
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: 'provider_communication_form_incident',
                                            answer: e.target.value,
                                            translationKey: 'provider_communication_form_incident',
                                            type: 'text'
                                        })
                                    }}
                                    rows={4}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>

                                <div className='info-head f-wrp'>
                                    <span>&nbsp;</span>
                                </div>
                                <div style={{ maxWidth: '200px', float: 'right', marginTop: '-8px' }}>
                                    <TimeInput
                                        defaultValue={dayjs(answerGetter(`provider_communication_form_timeOut`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                        label={translateThis('provider_communication_form_timeOut')}
                                        onChange={(v) => {
                                            setFormValue({
                                                questionId: `provider_communication_form_timeOut`,
                                                answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                rawAnswer: dayjs(v),
                                                translationKey: `provider_communication_form_timeOut`,
                                                type: 'text'
                                            })
                                        }}
                                    />

                                </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className='info-head f-wrp'>
                                    <span>{translateThis("Staff signature")}</span>
                                </div>
                                <TextInput
                                    key={`provider_communication_form_Key_signatureName`}
                                    label={translateThis('provider_communication_form_question_signatureName')}
                                    name='provider_communication_form_question_signatureName'
                                    id='provider_communication_form_question_signatureName'
                                    translationKey='provider_communication_form_question_signatureName'
                                    type='text'
                                    required
                                    placeholder={translateThis('provider_communication_form_question_signatureName')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'provider_communication_form_question_signatureName',
                                            type: 'text'
                                        })
                                        setSignature(value)
                                    }}
                                    useDefaultValidation
                                />
                                <FormGroup className='check-label'>
                                    <FormControlLabel
                                        defaultChecked={isSigned}
                                        onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("I")}, {(signature) && <b>{signature},</b>} {translateThis("affirm that the information")}</span>
                                        } />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Stack>
                </>

                <br />

                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='cancel-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec pln-btn-style'>
                        {/* {(parentId && childId && formName) ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null} */}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>

                    </div>
                </div>
            </div>}
        </>
    );
}

ProviderCommunicationForm.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default ProviderCommunicationForm;