import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { getAuthRoleName, getLatestMe } from '../../helpers/auth.helper';
import { PARENT_ROLE, PROVIDER_ROLE, EMPLOYEE_ROLE } from '../../constants/index';
import ParentPortal from '../../features/parentPortal';
import ProviderPortal from '../../features/providerPortal';
import ApiCaller from '../../services/api/general';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { showSnackbar } from '../../components/snackbar/index';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';





const MyPortal = () => {
    const { facility = '' } = useParams();
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('facility')
    const loggedInAuthType = getAuthRoleName();
    const [isLoading, setIsLoading] = useState(true)
    const [configState, setConfigState] = useRecoilState(myPortalState);
    const appState = useRecoilValue(appConfigAtomState);

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ facilityInfo: { ...prv.facilityInfo, ...modifiedObj } } }));


    useEffect(() => {
        getLatestMe()
        if (facility) {
            getFacilityInfo()
        }        
        // eslint-disable-next-line
    }, [facility]);

    const getFacilityInfo = () => {
        if (configState) {
            apiCaller.getData(facility).then((data) => {
                if (data) {
                    modifyConfigState(data);
                } else {
                    showSnackbar().failure("Requested facility not found");
                    navigator('/')
                }               
            }).finally(() => setIsLoading(false))
        }
    }

    return (
        <>
            {
                isLoading ? <span>Loading...</span> : <>
                    {loggedInAuthType === PARENT_ROLE ? <ParentPortal key={`parent-lang-${appState?.selectedLang}`} /> :
                loggedInAuthType === PROVIDER_ROLE ? <ProviderPortal key={`provider-lang-${appState?.selectedLang}`} /> :
                loggedInAuthType === EMPLOYEE_ROLE ? <ProviderPortal key={`provider-emp-lang-${appState?.selectedLang}`} /> :
                    <Navigate to={`/${getCurrentFacilityURL()}/auth/login`} />}
                </>
            }

        </>
    )

}


export default MyPortal;