import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { translateThis, switchLanguage } from '../../../helpers/language.helper';
import '../index.scss';
import { myPortalState } from '../../../state/reducerAtoms/myPortal.atom';
import ParentPortalDrawer from './drawer';
import NotificationBell from '../../../components/notification/notificationBell';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import TutorialPopup from '../../../components/tutorialPopup';

const ParentPortalHeader = ({ header = '' }) => {
    const { pageHeader = '', tutorialLink = '' } = useRecoilValue(myPortalState);
    return (
        <>
            <AppBar className='app-portal-header' position="relative">
                <Toolbar>
                    <Typography variant="h4" noWrap component="div">
                    {translateThis(header) || translateThis(pageHeader)} {tutorialLink? <TutorialPopup /> : null}
                    </Typography>
                    <div className='left-sec'>
                        <div className='mobile-menu-sec'>
                            <ParentPortalDrawer />
                        </div>
                    </div>
                    <div className='header-notification' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='language-box'>
                            <AppPopOverMenu
                                icon={SVGIcons.GlobalIcon()}
                                options={[
                                    {
                                        label: 'English',
                                        onClick: () => switchLanguage('en')
                                    },
                                    {
                                        label: 'Spanish',
                                        onClick: () => switchLanguage('es')
                                    },
                                ]}
                            />
                        </div>
                        <NotificationBell />
                    </div>
                </Toolbar>
            </AppBar>
        </>

    )
}

ParentPortalHeader.propTypes = {
    header: PropTypes.string,
}

export default ParentPortalHeader;