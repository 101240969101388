import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AppIcons from '../images/icons/index';

import './gallery.scss';


function Gallery() {


  const galleryItems = [
    {
      image: AppIcons.gallery1,
    },
    {
      image: AppIcons.gallery2,
    },
    {
      image: AppIcons.gallery3,
    },
    {
      image: AppIcons.gallery4,
    },
    {
      image: AppIcons.gallery5,
    },
    {
      image: AppIcons.gallery6,
    },
    {
      image: AppIcons.gallery7,
    },
    {
      image: AppIcons.gallery8,
    },
    {
      image: AppIcons.gallery9,
    },
    {
      image: AppIcons.gallery10,
    },
    {
      image: AppIcons.gallery11,
    },
    {
      image: AppIcons.gallery12,
    },
    {
      image: AppIcons.gallery13,
    },
    {
      image: AppIcons.gallery14,
    },
    {
      image: AppIcons.gallery15,
    },
    {
      image: AppIcons.gallery16,
    },
    {
      image: AppIcons.gallery17,
    },
    {
      image: AppIcons.gallery18,
    },
    {
      image: AppIcons.gallery19,
    },
    {
      image: AppIcons.gallery20,
    },
    {
      image: AppIcons.gallery21,
    },
    {
      image: AppIcons.gallery22,
    },
    {
      image: AppIcons.gallery23,
    },
    {
      image: AppIcons.gallery24,
    },
    {
      image: AppIcons.gallery25,
    },
    {
      image: AppIcons.gallery26,
    },
    {
      image: AppIcons.gallery27,
    },
    {
      image: AppIcons.gallery28,
    },
    {
      image: AppIcons.gallery29,
    },
    {
      image: AppIcons.gallery30,
    },
    
    
  ];

  return (
    <>
      <div className="gallery-sec-wrp f-wrp">

        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={10}
          slidesPerView={4}
          className='content-sec-mob'
          navigation
          loop
          loopAdditionalSlides
          loopedSlidesLimit={false}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
          }}
          
        >
          {galleryItems.map((element, key) => (
            <SwiperSlide key={key}>
                <span className='gallery-image'><img src={element.image} alt='gallery' /></span>
            </SwiperSlide>
          ))}
        </Swiper>



      </div>






    </>
  );
}

export default Gallery;