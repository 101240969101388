import React from 'react';
import { Button } from '@mui/material';
import { NavHashLink } from 'react-router-hash-link';
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import Services from './Services/Services';
import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import { translateThis } from '../../../helpers/language.helper';
import Programs from './programs/programs';
import Classes from './classes/classes';




const ArgentinaTemplate = () => {



    const basicContentItems = [
        {
            title_1: translateThis('Learn'),
            title_2: translateThis('About us'),
            title_3: translateThis('our services & team'),
            content: <><p>{translateThis('ourServicesText1')}</p></>,
            buttonText: translateThis('Read more'),
            link: '#aboutUs',
        },
        {
            title_1: translateThis('Find the'),
            title_2: translateThis('Curriculum'),
            title_3: translateThis('activities & classes'),
            content: <><p>{translateThis('ourServicesText2')}</p></>,
            buttonText: translateThis('Read more'),
            link: '#curriculum',
        },
        {
            title_1: translateThis('View'),
            title_2: translateThis('Payment Options'),
            title_3: translateThis('and fees'),
            content: <><p>{translateThis('ourServicesText3')}</p></>,
            buttonText: translateThis('Read more'),
            link: '#payment',
        },
        {
            title_1: translateThis('You can'),
            title_2: translateThis('Contact us'),
            title_3: translateThis('and follow us'),
            content: <><p>{translateThis('ourServicesText4')}</p>
                <p>{translateThis('ourServicesText5')}</p>
                <p>{translateThis('ourServicesText6')}</p></>,
            socialButtons: <></>
        },
    ];

    const routineItems = [
        {
            icon: SVGIcons.TimerIcon(),
            title: translateThis('Hours'),
            content: translateThis('7:30 AM - 5:30 PM'),
        },
        {
            icon: SVGIcons.KidFaceIcon(),
            title: translateThis('Accepted ages'),
            content: translateThis('Babies from 3 months onwards up to 3 and half years old'),
        },
        {
            icon: SVGIcons.SunIcon(),
            title: translateThis('Days'),
            content: translateThis('Open from Monday to Friday'),
        },
        {
            icon: SVGIcons.DollarIcon(),
            title: translateThis('Subsidy programs'),
            content: translateThis('subsidyProgramsText'),
        },

    ];


    // custom home functions here
    const pageName = "Kairos";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='argentinaTemplate'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <span><img src={AppIcons.bannerBG} alt='banner' /></span>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <span><img src={AppIcons.bannerLogo} alt='banner icon' /></span>
                                    <div className='banner-con'>
                                        <h1>{translateThis('Caring babysitting for your kids')} </h1>
                                        <p>{translateThis('Where Little Dreams Begin and Big Adventures Unfold')}</p>
                                    </div>
                                    <Button onClick={() => {
                                        window.location.href = '/kairos/my-portal'
                                    }} className='sqr-btn'>Paren’ts Portal</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='basic-detail-wrp f-wrp'>
                    <div className='container'>
                        <div className='basic-detail-con f-wrp'>
                            <div className='row'>
                                {basicContentItems.map((element, key) => (
                                    <div className='col-lg-3 col-md-6 col-sm-12 col-xs-12' key={key}>
                                        <div className='basic-detail-box f-wrp'>
                                            <h3>{element.title_1}</h3>
                                            <h2>{element.title_2}</h2>
                                            <h3>{element.title_3}</h3>
                                            <p>{element.content}</p>

                                            {element.buttonText ?
                                                <NavHashLink smooth to={`${element.link}`} >
                                                    <Button className='sqr-btn'>{element.buttonText}</Button>
                                                </NavHashLink> : <><ul>
                                                    <li><Button onClick={() => {
                                                        window.location.href = '/kairos'
                                                    }} className='sqr-btn'>{SVGIcons.WhatsappIcon()}</Button></li>
                                                    <li><Button onClick={() => {
                                                        window.location.href = '/kairos'
                                                    }} className='sqr-btn'>{SVGIcons.FacebookIcon()}</Button></li>
                                                </ul></>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp' id='aboutUs'>
                    <div className='container'>
                        <div className='about-section-header f-wrp'>
                            <h1>{translateThis('About Kairos')}</h1>
                        </div>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                            <div className='about-con-sec'>
                                <p>{translateThis('argentinaAboutText_1')}</p>
                                <p>{translateThis('argentinaAboutText_2')}</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='info-wrapper-section f-wrp'>
                    <div className='routine-box f-wrp'>
                        <ul>
                            {routineItems.map((element, key) => (
                                <li key={key}>
                                    <div className='each-routine-box f-wrp'>
                                        <div className='icon-sec'>
                                            <span>{element.icon}</span>
                                        </div>
                                        <div className='content-sec'>
                                            <h3>{element.title}</h3>
                                            <p>{element.content}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='service-slider-sec f-wrp'>
                        <div className='service-section-header f-wrp'>
                            <h1>{translateThis('Our services')}</h1>
                            <p>{translateThis('Our services Text')}</p>
                        </div>
                        <Services />
                    </div>

                    <div className='our-team-sec f-wrp'>
                        <div className='our-team-header f-wrp'>
                            <h1>{translateThis('Our team')}</h1>
                            <p>{translateThis('Our team text')}</p>
                        </div>
                        <div className='team-member-wrap f-wrp'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                    <div className='each-team-box f-wrp'>
                                        <span><img src={AppIcons.team1} alt='team' /></span>
                                        <h3>{translateThis('Julia')}</h3>
                                        <p>{translateThis('Substitute')}</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                    <div className='each-team-box f-wrp'>
                                        <span><img src={AppIcons.team2} alt='team' /></span>
                                        <h3>{translateThis('Argentina')}</h3>
                                        <p>{translateThis('Owner')}</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                    <div className='each-team-box f-wrp'>
                                        <span><img src={AppIcons.team3} alt='team' /></span>
                                        <h3>{translateThis('Daili')}</h3>
                                        <p>{translateThis('Substitute')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='BG-img-separator bgImg_1 f-wrp' />

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gallery-section-wrap f-wrp'>
                    <div className='gallery-sec-header f-wrp'>
                        <h1>{translateThis('Photo gallery')}</h1>
                        <p>{translateThis('gallery-head-text')}</p>
                    </div>
                    <Gallery />

                    <div className='gap f-wrp' style={{ paddingTop: '75px' }} />
                </div>

                <div className='info-wrapper-section f-wrp'>
                    <div className='curriculum-sec-wrp f-wrp' id='curriculum'>
                        <div className='curriculum-header f-wrp'>
                            <h1>{translateThis('Curriculum')}</h1>
                            <p>{translateThis('Curriculum text')}</p>
                        </div>
                        <div className='curriculum-con-wrp f-wrp'>
                            <Programs />
                        </div>
                    </div>
                </div>

                <div className='classes-section-wrap f-wrp'>
                    <div className='classes-sec-header f-wrp'>
                        <h1>{translateThis('Classes')}</h1>
                        <p>{translateThis('Classes-text')}</p>
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='container'>
                        <Classes />
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </div>

                <div className='BG-img-separator bgImg_2 f-wrp' />

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='testimonial-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{translateThis('What parents say')}</h1>
                            <p>{translateThis('parentSayText')}</p>
                        </div>
                        <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        <Testimonial />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='pay-option-wrp f-wrp' id='payment'>
                    <div className='payment-section-wrp f-wrp'>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className='payment-content-sec f-wrp'>
                                    <h1>{translateThis('Payment Options')}</h1>
                                    <p>{translateThis('Payment Options_text1')}</p>
                                    <p>{translateThis('Payment Options_text2')}</p>


                                    <Button onClick={() => {
                                        window.location.href = '/kairos/my-portal'
                                    }} className='sqr-btn'>Pay online</Button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


            </div>
        </AppLayout>
    )
}

export default ArgentinaTemplate;