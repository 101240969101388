import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Divider, Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { Delete } from '@mui/icons-material';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_EXTENDED_FORMAT, DATE_TIME_FORMAT, TIME_12HR_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';
import TimeInput from '../../../../components/inputs/timeInput';



const ChildEnrollment = (props) => {
    const formName = 'child-enrollment';

    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);
    const [responsibleAdults, setResponsibleAdults] = useState([]);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    setInitialFormData({ temp: 'temp' })
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild &&
            formData?.weekly_schedule_Monday_from &&
            formData?.weekly_schedule_Monday_to &&
            formData?.weekly_schedule_Tuesday_from &&
            formData?.weekly_schedule_Tuesday_to &&
            formData?.weekly_schedule_Wednesday_from &&
            formData?.weekly_schedule_Wednesday_to &&
            formData?.weekly_schedule_Thursday_from &&
            formData?.weekly_schedule_Thursday_to &&
            formData?.weekly_schedule_Friday_from &&
            formData?.weekly_schedule_Friday_to &&
            formData?.weekly_schedule_Saturday_from &&
            formData?.weekly_schedule_Saturday_to &&
            formData?.weekly_schedule_Sunday_from &&
            formData?.weekly_schedule_Sunday_to
        ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName: 'child-enrollment',
            formTitle: 'Child enrollment',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };

    /**
     * When adding a new adult
     */
    const onAddNewAdult = () => {
        const lastAdded = responsibleAdults[responsibleAdults.length - 1] || 0;
        const currentlyAdded = responsibleAdults;
        currentlyAdded.push(lastAdded + 1);
        setResponsibleAdults([...currentlyAdded]);
        setFormValue({
            questionId: 'child_enrollment_form_no_of_adults',
            answer: currentlyAdded.length,
            rawAnswer: currentlyAdded,
            translationKey: 'child_enrollment_form_no_of_adults',
            type: 'text'
        })
    }

    /**
     * When removing a new adult
     */
    const onDeleteAddedAdult = (deleted) => {
        const currentlyAdded = responsibleAdults.filter((no) => no !== deleted);
        setResponsibleAdults([...currentlyAdded]);
        setFormValue({
            questionId: 'child_enrollment_form_no_of_adults',
            answer: currentlyAdded.length,
            rawAnswer: currentlyAdded,
            translationKey: 'child_enrollment_form_no_of_adults',
            type: 'text'
        })
    }


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>

                <>

                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Child Enrollment")}</h2>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            value={answerGetter('child_enrollment_form_DateOFApplication', initialFormData)?.answer ?
                                                dayjs(answerGetter('child_enrollment_form_DateOFApplication', initialFormData)?.answer) :
                                                ''
                                            }
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'child_enrollment_form_DateOFApplication',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'child_enrollment_form_DateOFApplication',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                            label={translateThis("Date of application")} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            value={answerGetter('child_enrollment_form_date_of_enrollment', initialFormData)?.answer ?
                                                dayjs(answerGetter('child_enrollment_form_date_of_enrollment', initialFormData)?.answer) :
                                                ''
                                            }
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'child_enrollment_form_date_of_enrollment',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'child_enrollment_form_date_of_enrollment',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                            label={translateThis("Date of enrollment")} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            value={answerGetter('child_enrollment_form_last_day_of_enrollment', initialFormData)?.answer ?
                                                dayjs(answerGetter('child_enrollment_form_last_day_of_enrollment', initialFormData)?.answer) :
                                                ''
                                            }
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'child_enrollment_form_last_day_of_enrollment',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'child_enrollment_form_last_day_of_enrollment',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                            label={translateThis("Last day of enrollment")} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Child")}</h3>
                                </div>
                            </Grid>
                            {selectedChild && <Grid item xs={12} sm={12} md={9}>
                                <TextInput
                                    key={`child_enrollment_form_Key_name`}
                                    label={translateThis('child_enrollment_form_question_name')}
                                    name='child_enrollment_form_question_name'
                                    id='child_enrollment_form_question_name'
                                    value={answerGetter('child_enrollment_form_question_name', initialFormData)?.answer || `${selectedChild?.firstName || ''} ${selectedChild?.lastName || ''}`}
                                    translationKey='child_enrollment_form_question_name'
                                    type='text'
                                    required
                                    isDisabled
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'child_enrollment_form_question_name',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>}
                            {selectedChild && <Grid item xs={12} sm={12} md={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            value={answerGetter('child_enrollment_form_Key_birthday', initialFormData)?.answer ?
                                                dayjs(answerGetter('child_enrollment_form_Key_birthday', initialFormData)?.answer) :
                                                dayjs(selectedChild.birthday)
                                            }
                                            disabled
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'child_enrollment_form_Key_birthday',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'child_enrollment_form_birthday',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                            label={translateThis("Birthday")} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>}
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`child_enrollment_form_Key_address`}
                                    label={translateThis('child_enrollment_form_question_address')}
                                    name='child_enrollment_form_question_address'
                                    id='child_enrollment_form_question_address'
                                    value={answerGetter('child_enrollment_form_question_address', initialFormData)?.answer}
                                    translationKey='child_enrollment_form_question_address'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'child_enrollment_form_question_address',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`child_enrollment_form_Key_city`}
                                    label={translateThis('child_enrollment_form_question_city')}
                                    name='child_enrollment_form_question_city'
                                    id='child_enrollment_form_question_city'
                                    value={answerGetter('child_enrollment_form_question_city', initialFormData)?.answer}
                                    translationKey='child_enrollment_form_question_city'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'child_enrollment_form_question_city',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`child_enrollment_form_Key_zipcode`}
                                    label={translateThis('child_enrollment_form_question_zipcode')}
                                    name='child_enrollment_form_question_zipcode'
                                    id='child_enrollment_form_question_zipcode'
                                    value={answerGetter('child_enrollment_form_question_zipcode', initialFormData)?.answer}
                                    translationKey='child_enrollment_form_question_zipcode'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'child_enrollment_form_question_zipcode',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <>
                                <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Responsible adult")} #{1}</h3>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_name_2`}
                                        label={translateThis('child_enrollment_form_question_name_2')}
                                        name='child_enrollment_form_question_name_2'
                                        id='child_enrollment_form_question_name_2'
                                        value={answerGetter('child_enrollment_form_question_name_2', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_name_2'
                                        type='text'
                                        required
                                        placeholder='Name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_name_2',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_email`}
                                        label={translateThis('child_enrollment_form_question_email')}
                                        name='child_enrollment_form_question_email'
                                        id='child_enrollment_form_question_email'
                                        value={answerGetter('child_enrollment_form_question_email', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_email'
                                        type='email'
                                        required
                                        placeholder={translateThis('child_enrollment_form_question_email')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_email',
                                                type: 'email'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_address_2`}
                                        label={translateThis('child_enrollment_form_question_address_2')}
                                        name='child_enrollment_form_question_address_2'
                                        id='child_enrollment_form_question_address_2'
                                        value={answerGetter('child_enrollment_form_question_address_2', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_address_2'
                                        type='text'
                                        required
                                        placeholder='Address'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_address_2',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_city_2`}
                                        label={translateThis('child_enrollment_form_question_city_2')}
                                        name='child_enrollment_form_question_city_2'
                                        id='child_enrollment_form_question_city_2'
                                        value={answerGetter('child_enrollment_form_question_city_2', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_city_2'
                                        type='text'
                                        required
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_city_2',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_zipcode_2`}
                                        label={translateThis('child_enrollment_form_question_zipcode_2')}
                                        name='child_enrollment_form_question_zipcode_2'
                                        id='child_enrollment_form_question_zipcode_2'
                                        value={answerGetter('child_enrollment_form_question_zipcode_2', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_zipcode_2'
                                        type='text'
                                        required
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_zipcode_2',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={4}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_cellphone`}
                                        label={translateThis('child_enrollment_form_question_cellphone')}
                                        name='child_enrollment_form_question_cellphone'
                                        id='child_enrollment_form_question_cellphone'
                                        value={answerGetter('child_enrollment_form_question_cellphone', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_cellphone'
                                        type='number'
                                        required
                                        placeholder={translateThis('child_enrollment_form_question_cellphone')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_cellphone',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={4}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_telephone`}
                                        label={translateThis('child_enrollment_form_question_telephone')}
                                        name='child_enrollment_form_question_telephone'
                                        id='child_enrollment_form_question_telephone'
                                        value={answerGetter('child_enrollment_form_question_telephone', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_telephone'
                                        type='number'
                                        required
                                        placeholder={translateThis('child_enrollment_form_question_telephone')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_telephone',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={4}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_emergencyContact`}
                                        label={translateThis('child_enrollment_form_question_emergencyContact')}
                                        name='child_enrollment_form_question_emergencyContact'
                                        id='child_enrollment_form_question_emergencyContact'
                                        value={answerGetter('child_enrollment_form_question_emergencyContact', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_emergencyContact'
                                        type='number'
                                        required
                                        placeholder={translateThis('child_enrollment_form_question_emergencyContact')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_emergencyContact',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_EmployerName`}
                                        label={translateThis('child_enrollment_form_question_EmployerName')}
                                        name='child_enrollment_form_question_EmployerName'
                                        id='child_enrollment_form_question_EmployerName'
                                        value={answerGetter('child_enrollment_form_question_EmployerName', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_EmployerName'
                                        type='text'
                                        required
                                        placeholder={translateThis('child_enrollment_form_question_EmployerName')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_EmployerName',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_workTelephone`}
                                        label={translateThis('child_enrollment_form_question_workTelephone')}
                                        name='child_enrollment_form_question_workTelephone'
                                        id='child_enrollment_form_question_workTelephone'
                                        value={answerGetter('child_enrollment_form_question_workTelephone', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_workTelephone'
                                        type='number'
                                        required
                                        placeholder={translateThis('child_enrollment_form_question_workTelephone')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_workTelephone',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_workAddress`}
                                        label={translateThis('child_enrollment_form_question_workAddress')}
                                        name='child_enrollment_form_question_workAddress'
                                        id='child_enrollment_form_question_workAddress'
                                        value={answerGetter('child_enrollment_form_question_workAddress', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_workAddress'
                                        type='text'
                                        required
                                        placeholder={translateThis('child_enrollment_form_question_workAddress')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_workAddress',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_city_3`}
                                        label={translateThis('child_enrollment_form_question_city_3')}
                                        name='child_enrollment_form_question_city_3'
                                        id='child_enrollment_form_question_city_3'
                                        value={answerGetter('child_enrollment_form_question_city_3', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_city_3'
                                        type='text'
                                        required
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_city_3',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`child_enrollment_form_Key_zipcode_3`}
                                        label={translateThis('child_enrollment_form_question_zipcode_3')}
                                        name='child_enrollment_form_question_zipcode_3'
                                        id='child_enrollment_form_question_zipcode_3'
                                        value={answerGetter('child_enrollment_form_question_zipcode_3', initialFormData)?.answer}
                                        translationKey='child_enrollment_form_question_zipcode_3'
                                        type='text'
                                        required
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'child_enrollment_form_question_zipcode_3',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                            </>


                            <>
                                {/* <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form' spacing={3}>
                                        <FormControl>
                                            <FormLabel id="child_enrollment_form_Key_role">{translateThis('Role')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Role"
                                                defaultValue={answerGetter('child_enrollment_form_Key_role', initialFormData)?.answer}
                                                id="child_enrollment_form_Key_role"
                                                name="child_enrollment_form_Key_role"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'child_enrollment_form_Key_role',
                                                        answer: e.target.value,
                                                        translationKey: 'child_enrollment_form_Key_role',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Parent" control={<Radio />} label="Parent" />
                                                <FormControlLabel value="Guardian" control={<Radio />} label="Guardian" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Grid> */}


                                {responsibleAdults.map((no, i) => (
                                    <Grid key={`adult-key-${no}`} container spacing={3} style={{ marginLeft: '0px', marginTop: '0px' }}>
                                        <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                            <div className='info-head f-wrp'>
                                                <h3>{translateThis("Responsible adult")} #{i + 2}</h3>
                                            </div>
                                            <div>
                                                <IconButton
                                                    onClick={() => onDeleteAddedAdult(no)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_name_2`}
                                                label={translateThis('child_enrollment_form_question_name_2')}
                                                name={`child_enrollment_form_question_adult_${no}_name`}
                                                id='child_enrollment_form_question_name_2'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_name`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_name_2'
                                                type='text'
                                                required
                                                placeholder='Name'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_name_2',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_email`}
                                                label={translateThis('child_enrollment_form_question_email')}
                                                name={`child_enrollment_form_question_adult_${no}_email`}
                                                id='child_enrollment_form_question_email'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_email`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_email'
                                                type='email'
                                                required
                                                placeholder={translateThis('child_enrollment_form_question_email')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_email',
                                                        type: 'email'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_address_2`}
                                                label={translateThis('child_enrollment_form_question_address_2')}
                                                name={`child_enrollment_form_question_adult_${no}_address`}
                                                id='child_enrollment_form_question_address_2'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_address`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_address_2'
                                                type='text'
                                                required
                                                placeholder='Address'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_address_2',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_city_2`}
                                                label={translateThis('child_enrollment_form_question_city_2')}
                                                name={`child_enrollment_form_question_adult_${no}_city`}
                                                id='child_enrollment_form_question_city_2'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_city`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_city_2'
                                                type='text'
                                                required
                                                placeholder='City'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_city_2',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_zipcode_2`}
                                                label={translateThis('child_enrollment_form_question_zipcode_2')}
                                                name={`child_enrollment_form_question_adult_${no}_zipcode`}
                                                id='child_enrollment_form_question_zipcode_2'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_zipcode`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_zipcode_2'
                                                type='text'
                                                required
                                                placeholder='Zipcode'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_zipcode_2',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_cellphone`}
                                                label={translateThis('child_enrollment_form_question_cellphone')}
                                                name={`child_enrollment_form_question_adult_${no}_cellphone`}
                                                id='child_enrollment_form_question_cellphone'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_cellphone`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_cellphone'
                                                type='number'
                                                required
                                                placeholder={translateThis('child_enrollment_form_question_cellphone')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_cellphone',
                                                        type: 'number'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_telephone`}
                                                label={translateThis('child_enrollment_form_question_telephone')}
                                                name={`child_enrollment_form_question_adult_${no}_telephone`}
                                                id='child_enrollment_form_question_telephone'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_telephone`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_telephone'
                                                type='number'
                                                required
                                                placeholder={translateThis('child_enrollment_form_question_telephone')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_telephone',
                                                        type: 'number'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_emergencyContact`}
                                                label={translateThis('child_enrollment_form_question_emergencyContact')}
                                                name={`child_enrollment_form_question_adult_${no}_emergencyContact`}
                                                id='child_enrollment_form_question_emergencyContact'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_emergencyContact`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_emergencyContact'
                                                type='number'
                                                required
                                                placeholder={translateThis('child_enrollment_form_question_emergencyContact')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_emergencyContact',
                                                        type: 'number'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_EmployerName`}
                                                label={translateThis('child_enrollment_form_question_EmployerName')}
                                                name={`child_enrollment_form_question_adult_${no}_employerName`}
                                                id='child_enrollment_form_question_EmployerName'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_employerName`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_EmployerName'
                                                type='text'
                                                required
                                                placeholder={translateThis('child_enrollment_form_question_EmployerName')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_EmployerName',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_workTelephone`}
                                                label={translateThis('child_enrollment_form_question_workTelephone')}
                                                name={`child_enrollment_form_question_adult_${no}_workTelephone`}
                                                id='child_enrollment_form_question_workTelephone'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_workTelephone`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_workTelephone'
                                                type='number'
                                                required
                                                placeholder={translateThis('child_enrollment_form_question_workTelephone')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_workTelephone',
                                                        type: 'number'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_workAddress`}
                                                label={translateThis('child_enrollment_form_question_workAddress')}
                                                name={`child_enrollment_form_question_adult_${no}_workAddress`}
                                                id='child_enrollment_form_question_workAddress'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_workAddress`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_workAddress'
                                                type='text'
                                                required
                                                placeholder={translateThis('child_enrollment_form_question_workAddress')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_workAddress',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_city_3`}
                                                label={translateThis('child_enrollment_form_question_city_3')}
                                                name={`child_enrollment_form_question_adult_${no}_workCity`}
                                                id='child_enrollment_form_question_city_3'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_workCity`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_city_3'
                                                type='text'
                                                required
                                                placeholder='City'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_city_3',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3}>
                                            <TextInput
                                                key={`child_enrollment_form_Key_zipcode_3`}
                                                label={translateThis('child_enrollment_form_question_zipcode_3')}
                                                name={`child_enrollment_form_question_adult_${no}_workZipCode`}
                                                id='child_enrollment_form_question_zipcode_3'
                                                value={answerGetter(`child_enrollment_form_question_adult_${no}_workZipCode`, initialFormData)?.answer}
                                                translationKey='child_enrollment_form_question_zipcode_3'
                                                type='text'
                                                required
                                                placeholder='Zipcode'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'child_enrollment_form_question_zipcode_3',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </>

                            <Grid item xs={12} sm={12} md={12}>
                                <br />
                                <Button onClick={onAddNewAdult} className='red-btn'>{translateThis('Add new Responsible adult +')}</Button>
                            </Grid>

                            <Grid item xs={12} sm={12} md={7}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis('Weekly schedule')}</h3>
                                </div>
                                <table className='Weekly-schedule-table' style={{ maxWidth: '500px' }}>
                                    <tbody>
                                        {["Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => <tr key={day}>
                                            <td style={{ paddingRight: '15px' }}><span>{translateThis(day)}</span></td>
                                            <td style={{ paddingRight: '15px' }}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter(`weekly_schedule_${day}_from`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('From*')}
                                                    required
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `weekly_schedule_${day}_from`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: `weekly_schedule_${day}_from`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </td>
                                            <td style={{ paddingRight: '15px' }}>
                                                <TimeInput
                                                    label={translateThis('To*')}
                                                    required
                                                    defaultValue={dayjs(answerGetter(`weekly_schedule_${day}_to`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: `weekly_schedule_${day}_to`,
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            translationKey: `weekly_schedule_${day}_to`,
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </td>
                                        </tr>)}

                                    </tbody>
                                </table>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <Divider style={{ display: 'none' }} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>


                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <span>{translateThis('Parent Guardian signature')}</span>
                                    </div>
                                    <TextInput
                                        key={`child_enrollment_form_Key_signatureName`}
                                        label={translateThis('child_enrollment_form_question_signatureName')}
                                        name='child_enrollment_form_question_signatureName'
                                        id='child_enrollment_form_question_signatureName'
                                        translationKey='child_enrollment_form_question_signatureName'
                                        type='text'
                                        required
                                        placeholder='Type your name'
                                        className='custom-textfield'
                                        getValue={(_, value) => {
                                            setSignature(value)
                                        }}
                                        useDefaultValidation
                                    />
                                    <FormGroup className='check-label'>
                                        <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("I")}, {signature && <b>{signature},</b>} {translateThis("affirm that the information")} </span>
                                        } />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </>

                <br />

                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='pln-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm() || !isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>

                    </div>
                </div>
            </div> : null}
        </>
    );
}

ChildEnrollment.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func,
}

export default ChildEnrollment;