import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Mui

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { updateParent } from '../../../services/api/parent';
import ProviderPortalHeader from '../components/header';
import AppIcons from '../../../assets/images/icons';
import { translateThis } from '../../../helpers/language.helper';



const ParentsList = (props) => {
    const { onViewClick = () => null } = props;
    const apiCaller = new ApiCaller('parent')
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        let switchStatusTo = '';
        let statusMsg = '';
        switch (status) {
            case 'active':
                switchStatusTo = 'inactive';
                statusMsg = 'rejected';
                break;
            case 'inactive':
                switchStatusTo = 'active';
                statusMsg = 'accepted'
                break;

            case 'blocked':
                switchStatusTo = 'active';
                statusMsg = 'unblocked';
                break;
            case 'unblock':
                switchStatusTo = 'blocked';
                statusMsg = 'blocked';
                break;
            default:
                break;
        }
        status = switchStatusTo;
        if (id) {
            updateParent(id, { status })
                .then(() => showSnackbar().success(`The selected parent has been ${statusMsg} successfully.`))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(() => showSnackbar().success('Parent deleted successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'fullName',
            headerName: 'Parent',
            minWidth: 150,
            flex: 2,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2,
            minWidth: 220,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'blocked' && <span className='tab-status'>{SVGIcons.StatusBlockedIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { id } = params.row;
                const { status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onViewClick(id)
                    },
                    {
                        label: status === "active" ? "Reject" : "Accept",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    status !== 'blocked' && {
                        label: status === "blocked" ? "Unblock" : "Block",
                        onClick: () => {
                            toggleActiveStatus(id, status === "blocked" ? "blocked" : "unblock")
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <>
            <ProviderPortalHeader header='Parents' />
            <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                    <div className='mob-table-wrp f-wrp'>
                        {dataArr.length > 0 ? <ul>
                            {dataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box'>
                                        <div className='status-with-btn'>
                                            {(element.status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                                                (element.status === 'blocked' && <span className='tab-status'>{SVGIcons.StatusBlockedIcon()}  </span>) ||
                                                (element.status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}
                                            <AppPopOverMenu
                                                icon={SVGIcons.StatusMobArrow()}
                                                options={[
                                                    {
                                                        label: element.status === "active" ? "Reject" : "Accept",
                                                        onClick: () => {
                                                            toggleActiveStatus(element.id, element.status)
                                                        }
                                                    },
                                                    element.status !== 'blocked' && {
                                                        label: element.status === "blocked" ? "Unblock" : "Block",
                                                        onClick: () => {
                                                            toggleActiveStatus(element.id, element.status === "blocked" ? "blocked" : "unblock")
                                                        }
                                                    },
                                                ]} />
                                        </div>
                                    </div>
                                    <div className='each-sec-box'>
                                        <h4>{element.firstName}{element.lastName}</h4>
                                        <span>{element.email}</span>
                                    </div>
                                    <div className='each-sec-box'>
                                        <ul className='btn-wrp'>
                                            <li><button className='pln-btn ForwardIcon' type='button' onClick={() => { setSelectedId(element.id); setOpenDeleteDialog(true) }}><SVGIcons.DeleteIcon /></button></li>
                                        </ul>
                                    </div>
                                    <Link className='full-li-btn' onClick={() => { onViewClick(element.id) }} />
                                </li>
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.parentPlaceholder} alt='empty' />
                                <h4>{translateThis('No statistics')}</h4>
                                <p>{translateThis('There are no information loaded in the system')}</p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                {openDeleteDialog && <ConfirmDialog
                    isOpen={openDeleteDialog}
                    onClose={() => {
                        setOpenDeleteDialog(false);
                        setSelectedId('');
                    }}
                    title={`Delete parent`}
                    className={'delete-popup'}
                    description={`Are you sure you want to delete this parent?\nThis action is permanent and cannot be undone.`}
                    okayButtonText={'Delete parent'}
                    onConfirm={deleteData}
                />}
            </div>
        </>
    )
}


ParentsList.propTypes = {
    onViewClick: PropTypes.func
}
export default ParentsList;