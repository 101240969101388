import React from "react";
// import { element } from "prop-types";
import AppIcons from "../images/icons";
import { translateThis } from '../../../../helpers/language.helper';
import './approach.scss';




function Approach() {

  const approachItems = [
    {
      image: AppIcons.approach1,
      title: 'Infant',
      description: 'Through fine motor skills fun activities we show them things like grabbing, sitting, standing, running and jumping.',
      ages: '0-2 years',
      days: 'Mon to Fri',
      hours: '6am - 6pm',
    },
    {
      image: AppIcons.approach2,
      title: 'Toddler',
      description: 'We teach our toddlers to draw, make music, sensory activities, letters and sounds, different types of animals, play dough, painting and potty training. Everything they need to be ready for pre-k.',
      ages: '3-5 years',
      days: 'Mon to Fri',
      hours: '6am - 6pm',
    },
    {
      image: AppIcons.approach3,
      title: 'School',
      description: 'We help them with their homework and help them study for their quizzes/tests. We encourage them to read for at least 30 minutes daily.',
      ages: '6-12 years',
      days: 'Mon to Fri',
      hours: '6am - 6pm',
    },
  ];


  return (
    <>
      <div className="Kathleen-approach-wrp f-wrp">
        <div className="row">
          {approachItems.map((element, key) => (
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
              <div className="each-approach-sec f-wrp ">
                <div className="approach-con-sec f-wrp">
                  <span className='fee-sec-icon'><img src={element.image} alt={element.title} /></span>
                  <h2>{translateThis(element.title)}</h2>
                  <p>{translateThis(element.description)}</p>
                </div>

                <div className="approach-basic-sec f-wrp">
                  <div className='trip-grid'>
                    <div className='age'>
                      <span><b>{translateThis('Age')}:</b>{translateThis(element.ages)}</span>
                    </div>
                    <div className='days'>
                      <span><b>{translateThis('Days')}:</b>{translateThis(element.days)}</span>
                    </div>
                    <div className='hours'>
                      <span><b>{translateThis('Hours')}:</b>{translateThis(element.hours)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="note-box">
              <h2>{translateThis('Join our waitlist for your child')}</h2>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
            <div className="waitList-box">
              <h2>{translateThis('Waitlist')}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Approach;