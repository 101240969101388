import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import Grid from '@mui/material/Grid';

import { showSnackbar } from '../../../components/snackbar';
import SVGIcons from '../../../assets/images/icons/svgIcons';

// ----------------------------------------------------------------------
import { getAuthId } from '../../../helpers/auth.helper';
import { DATE_TIME_READABLE_FORMAT, TIME_12HR_FORMAT, TIME_12HR_EXTENDED_FORMAT } from '../../../constants/index';
import ApiCaller from '../../../services/api/general';




const ClockInDailyEntry = () => {
    const apiCaller = new ApiCaller('attendance')
    const authId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [formData, setFormData] = useState([]);
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [clockIn, setClockIn] = useState('');
    const [clockOut, setClockOut] = useState('');
    const [attendanceId, setAttendanceId] = useState('');


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (authId) {
            getData()
        }
        setInterval(() => {
            setCurrentTime(dayjs())
        }, 1000);
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, [authId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData('session-status')
            .then((data) => {
                setFormData(data);
                // Lets clear everything first
                setClockIn('');        
                setClockOut('');  
                setAttendanceId('');
                // Now check for each property     
                if (data?.checkIn) {
                    setClockIn(dayjs(data?.checkIn).format(TIME_12HR_FORMAT))
                }
                if (data?.checkOut) {
                    setClockOut(dayjs(data?.checkOut).format(TIME_12HR_FORMAT));                    
                }
                if (data?.checkIn && !data?.checkOut) {
                    setAttendanceId(data?.id)
                }

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        const isValid = true;        
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {}
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            if (attendanceId) {
                postData.attendanceId = attendanceId;
            }
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    getData()
                    showSnackbar().success(`Successfully clocked ${clockIn ? 'out' : 'in'}!`)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };


    /**
     * The signup form elements
     */


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='clock-in-daily standard-form' spacing={3}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack direction="column" alignItems="flex-start" justifyContent="space-between" sx={{ my: 2 }}>
                                <div className='info-head f-wrp'>
                                    <br />
                                    <span style={{ display: 'flex' }}><SVGIcons.ClockIcon /> &nbsp; {dayjs().format(DATE_TIME_READABLE_FORMAT)}</span>
                                </div>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            {clockIn && <TextField
                                key={`clock-in-time`}
                                label={"Clock in"}
                                name={"Clock in"}
                                value={clockIn}
                                disabled
                            />}
                        </Grid>
                        <Grid item xs={6}>
                            {clockIn && <TextField
                                key={`clock-out-time`}
                                value={clockOut}
                                disabled
                                label={"Clock out"}
                                name={"Clock out"}
                            />}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                <h1 style={{ margin: '0' }}>{dayjs(currentTime).format(TIME_12HR_EXTENDED_FORMAT)}</h1>
                                <span style={{ fontWeight: '700' }}>Current time</span>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            {!clockOut ? <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='end'
                                endIcon={clockIn ? <SVGIcons.ClockOut /> : <SVGIcons.ClockIn />} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm()}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='login-btn'
                                onClick={onSubmit}>
                                {clockIn ? 'Clock out' : 'Clock in'}
                            </LoadingButton> : <span className='center-txt'>You have successfully marked your attendance today!.</span>}
                            <br />
                            <br />
                            <br />
                            <br />
                        </Grid>
                    </Grid>
                </Stack>
            </div>}
        </>
    );
}

ClockInDailyEntry.propTypes = {
}

export default ClockInDailyEntry;