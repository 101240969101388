import React from 'react';
import { Button } from '@mui/material';
// import Marquee from "react-fast-marquee";
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import Services from './Services/Services';
// import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import { translateThis } from '../../../helpers/language.helper';




const RafaelaTemplate = () => {

    const programItems = [
        {
            icon: AppIcons.Hours,
            title: translateThis('Hours'),
            description: <p>{translateThis('HoursText')}</p>,
        },
        {
            icon: AppIcons.Days,
            title: translateThis('Days'),
            description: <p>{translateThis('DaysText')}</p>,
        },
        {
            icon: AppIcons.acceptedAges,
            title: translateThis('acceptedAges'),
            description: <p>{translateThis('acceptedAgesText')}</p>,
        },
        {
            icon: AppIcons.subsidyPrograms,
            title: translateThis('subsidyPrograms'),
            description: <><p>{translateThis('subsidyProgramsText')}</p></>,
        },
    ];

    const founderItems = [
        {
            icon: AppIcons.founder2,
            title: translateThis('founder2Title'),
            description: <p>{translateThis('founder2Text')}</p>,
            position: translateThis('founder2Position'),
        },
        {
            icon: AppIcons.founder3,
            title: translateThis('founder3Title'),
            description: <p>{translateThis('founder3Text')}</p>,
            position: translateThis('founder3Position'),

        },
        {
            icon: AppIcons.founder4,
            title: translateThis('founder4Title'),
            description: <p>{translateThis('founder4Text')}</p>,
            position: translateThis('founder4Position'),
        },
    ];

  

    // custom home functions here
    const pageName = translateThis('Brum Brum Learning Preschool');
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='rafaelaTemplates'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <span><img src={AppIcons.bannerBG} alt='banner' /></span>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <span><img src={AppIcons.bannerLogo} alt='banner icon' /></span>
                                    <div className='banner-con'>
                                        <h1>{translateThis('bannerText')}<br /></h1>
                                    </div>
                                </div>
                                <Button onClick={() => {
                                    window.location.href = '/brumbrum/my-portal'
                                }} className='sqr-btn'>{translateThis('Parents Portal')}</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <h1>{translateThis('aboutHead')}</h1>
                                <p>{translateThis('aboutText_1')}</p>
                                <p>{translateThis('aboutText_2')}</p>
                                <span>{translateThis('Rafaela')}</span>
                            </div>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-slider-sec f-wrp'>
                    <Services />
                </div>
                <div className='program-sec-wrp f-wrp'>
                    <div className='program-con-wrp f-wrp'>
                        <div className='container'>
                            <div className='program-grid'>
                                {programItems.map((element, key) => (
                                    <div className='each-program-sec f-wrp' key={key}>
                                        <span className='program-icon'><img src={element.icon} alt={element.title} /></span>
                                        <h3>{element.title}</h3>
                                        {element.description}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='credential-sec-wrp f-wrp'>
                    <div className='container'>
                        <div className='credential-header f-wrp'>
                            <h1>{translateThis('Credentials')}</h1>
                        </div>
                        <div className='credential-content-wrp f-wrp'>
                            <div className='each-credential-blk'>
                                <span className='credential-img-sec'><img src={AppIcons.CDA} alt='credential' /></span>
                                <div className='credential-content-sec'>
                                    <p><b>{translateThis('CDAText_1')}</b>{translateThis('CDAText_2')}</p>
                                </div>
                            </div>
                            <div className='each-credential-blk'>
                                <span className='credential-img-sec'><img src={AppIcons.NAFCC} alt='credential' /></span>
                                <div className='credential-content-sec'>
                                    <p><b>{translateThis('NAFCCText_1')}</b>{translateThis('NAFCCText_2')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='activities-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='activities-grid-wrp f-wrp'>
                            <div className='activities-img-sec'>
                                <span><img src={AppIcons.plane} alt='Hot Ballon' /></span>
                            </div>
                            <div className='activities-con-sec'>
                                <h1>{translateThis('Activities')} </h1>
                                <p>{translateThis('Activities Text')}</p>
                                <ul>
                                    <li><span><img src={AppIcons.activity1} alt="activity" /></span><p>{translateThis('activity1')}</p></li>
                                    <li><span><img src={AppIcons.activity2} alt="activity" /></span><p>{translateThis('activity2')}</p></li>
                                    <li><span><img src={AppIcons.activity3} alt="activity" /></span><p>{translateThis('activity3')}</p></li>
                                    <li><span><img src={AppIcons.activity4} alt="activity" /></span><p>{translateThis('activity4')}</p></li>
                                    <li><span><img src={AppIcons.activity5} alt="activity" /></span><p>{translateThis('activity5')}</p></li>
                                    <li><span><img src={AppIcons.activity6} alt="activity" /></span><p>{translateThis('activity6')}</p></li>
                                    <li><span><img src={AppIcons.activity7} alt="activity" /></span><p>{translateThis('activity7')}</p></li>
                                    <li><span><img src={AppIcons.activity8} alt="activity" /></span><p>{translateThis('activity8')}</p></li>
                                    <li><span><img src={AppIcons.activity9} alt="activity" /></span><p>{translateThis('activity9')}</p></li>
                                    <li><span><img src={AppIcons.activity10} alt="activity" /></span><p>{translateThis('activity10')}</p></li>
                                    <li><span><img src={AppIcons.activity11} alt="activity" /></span><p>{translateThis('activity11')}</p></li>
                                    <li><span><img src={AppIcons.activity12} alt="activity" /></span><p>{translateThis('activity12')}</p></li>
                                    <li><span><img src={AppIcons.activity13} alt="activity" /></span><p>{translateThis('activity13')}</p></li>
                                    <li><span><img src={AppIcons.activity14} alt="activity" /></span><p>{translateThis('activity14')}</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='contact-blk-wrp f-wrp'>
                    <div className='contact-sec-blk f-wrp'>
                        <div className='contact-con'>
                            <div className='f-wrp'>
                                <h1>{translateThis('Get in touch')}</h1>
                                <p>{translateThis('Get in touch Text')}</p>
                                <ul>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.mapPoint} alt='pin point' /></span>
                                            <p>{translateThis('rafaelaAddress')}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.mail} alt='mail' /></span>
                                            <p>{translateThis('rafaelaEmail')}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.phone} alt='call' /></span>
                                            <p>{translateThis('rafaelaPhone')}</p>
                                        </div>
                                    </li>
                                </ul>
                                <Button onClick={() => {
                                    window.location.href = '/brumbrum'
                                }} className='sqr-btn'>{translateThis('Contact us')}</Button>
                            </div>
                        </div>
                        <div className='contact-img'>
                            <span><img src={AppIcons.contact} alt='child' /></span>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='gallery-sec-header f-wrp'>
                        <h1>{translateThis('Gallery')}</h1>
                    </div>
                    <Gallery />

                    <div className='gap theme-band f-wrp' >
                        {/* <Button onClick={() => {
                            window.location.href = '/'
                        }} className='sqr-btn'>{translateThis('Request virtual tour')}</Button> */}
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-founder-wrp f-wrp'>
                    <div className='founder-con-sec founder1 f-wrp'>
                        <div className='container'>
                            <div className='founder-con-wrp '>
                                <div className='founder-img'>
                                    <span><img src={AppIcons.founder1} alt='founder' /></span>
                                </div>
                                <div className='founder-con-blk'>
                                    <h1>{translateThis('founder1')}</h1>
                                    <span>{translateThis('Founder')}</span>
                                    <p>{translateThis('founder1Text')}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='founder-con-sec founder2 f-wrp'>
                        <div className='container'>
                            <div className='row'>

                                {founderItems.map((element, key) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
                                        <div className='each-founder-sec f-wrp'>
                                            <span className='founder-icon'><img src={element.icon} alt={element.title} /></span>
                                            <h3>{element.title}</h3>
                                            <span>{element.position}</span>
                                            {element.description}
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='testimonial-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{translateThis('Reviews')}</h1>
                        </div>
                        <Testimonial />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='pay-optiopn-wrp f-wrp'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className='payment-content-sec f-wrp'>
                                    <h1>Payment Options</h1>
                                    <p>At Brum Brum, we want to make it easy and convenient for you to access our childcare services. To ensure a hassle-free experience, we offer a variety of payment options to choose from, such as credit & debit card, as well as cash or bank transfers.</p>

                                    <p>We have an enrollment fee of only $75.</p>
                                    <Button onClick={() => {
                                        window.location.href = '/brumbrum/my-portal'
                                    }} className='sqr-btn'>Pay online</Button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                <div className='payment-button-sec f-wrp'>
                                    <span>
                                        <img src={AppIcons.payment} alt='payment' />
                                    </span>
                                    <Button onClick={() => {
                                        window.location.href = '/brumbrum/my-portal'
                                    }} className='sqr-btn'>Pay online</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap theme-band f-wrp' style={{ paddingTop: '50px' }} />

            </div>
        </AppLayout>
    )
}

export default RafaelaTemplate;