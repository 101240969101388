import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { translateThis } from '../../helpers/language.helper';


const ConfirmDialog = (props) => {
    const {
        isOpen = false,
        title = "",
        description = "",
        className = "",
        cancelButtonText = 'Cancel',
        onClose,
        okayButtonText = "Confirm",
        confirmButton = null,
        onConfirm } = props;

    const [open, setOpen] = React.useState(isOpen);
    const [isLoading, setIsLoading] = React.useState(false);

    /**
     * Handles the close click
     */
    const handleClose = () => {
        setOpen(false);
        setIsLoading(false);
        if (typeof onClose === "function") {
            onClose();
        }
    };

    /**
     * Handles the okay click
     */
    const handleConfirm = () => {        
        if (typeof onConfirm === "function") {
            setIsLoading(true);
            onConfirm();
        }
    };

    return (
        <div>
            <Dialog
                className={className}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {translateThis(title)}
                </DialogTitle>
                <DialogContent>
                    {(typeof description === 'string') ?<DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText> : <>{description}</>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{translateThis(cancelButtonText)}</Button>
                    {confirmButton ? <>{confirmButton}</> :<LoadingButton
                        loading={isLoading}
                        loadingPosition='start'
                        startIcon={<></>} // To remove Mui warning
                        autoFocus
                        size="large"
                        type="button"
                        variant="contained"
                        className='standard-btn pln-btn'
                        onClick={handleConfirm}>
                        {translateThis(okayButtonText)}
                    </LoadingButton>}
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.any,
    confirmButton: PropTypes.any,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    cancelButtonText: PropTypes.string,
    okayButtonText: PropTypes.string,
    className: PropTypes.string,
}

export default ConfirmDialog;