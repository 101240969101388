import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import ModalPopUp from '../../../components/modal/modal.popup';

import './index.scss';
import { DATE_FORMAT, TIME_12HR_FORMAT } from '../../../constants/index';


const ChildrenAttendanceList = () => {

    const [openViewMessageDialog, setOpenViewMessageDialog] = useState(false);
    const [showNewMessagePopup, setShowNewMessagePopup] = useState(false);

    const apiCaller = new ApiCaller('attendance/children-attendance-list');
    const [isLoading, setIsLoading] = useState(false);

    const [dataArr, setDataArr] = useState([]);
    // eslint-disable-next-line
    const [selectedMessage, setSelectedMessage] = useState({});

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Name',
            minWidth: 150,
            flex: 2,
            valueGetter: ({row}) =>
                `${row?.childId?.firstName || ''} ${row?.childId?.lastName || ''}`,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
                `${dayjs(row.createdAt).format(DATE_FORMAT)}`,
        },
        {
            field: 'checkIn',
            headerName: 'Check in',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
                `${dayjs(row.checkIn).format(TIME_12HR_FORMAT)}`,
        },
        {
            field: 'checkOut',
            headerName: 'Check out',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
            row.checkOut ? `${dayjs(row.checkOut).format(TIME_12HR_FORMAT)}` : '-',
        }
        

    ]


    return (
        <div className='parent-inbox-wrp f-wrp'>
            <div className='inbox-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec parent-table  f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>

                    {openViewMessageDialog && <ModalPopUp
                        isOpen={openViewMessageDialog}
                        onClose={() => {
                            setOpenViewMessageDialog(false);
                        }}
                        className={'modal-popup inbox-popup'}
                        children={<div/>}

                    />}
                    {showNewMessagePopup ? <ModalPopUp
                        isShowBackButton={false}
                        onClose={() => setShowNewMessagePopup(false)}
                        isOpen={showNewMessagePopup}
                    /> : null}
                </div>
            </div>
        </div>
    )
}
ChildrenAttendanceList.propTypes = {}

export default ChildrenAttendanceList;
