import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';

const ParentProviderAgreementView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView f-wrp">
                <div className='prof-sec'>
                    {/* <div className='prof-img'>
                        <span><img src={AppIcons.registerBG} alt='profile' /></span>
                    </div> */}
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div>
                <div className='gap' />
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="lft-section">
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.c4k} alt='profile' /></span>
                    </div>
                    <div className="ryt-section" style={{ textAlign: 'center' }}>
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>{translateThis("Parent-Provider Agreement Form")}</b></h2>
                        <p>{translateThis("This form tells us about the child care arrangement")}.</p>
                    </div>
                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 1 PARENT INFORMATION")}</b></h3>
                    <div className="flex-box">
                        <p>{translateThis("Parent Name")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_1', formData)?.answer || '-'}</b></p>
                        <p style={{ textAlign: 'center' }}>{translateThis("C4K Case Number")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_2', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Address")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_3', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("City")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_4', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("State")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_5', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Zipcode")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_6', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Telephone Number (Primary)")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_7', formData)?.answer || '-'}</b></p>
                        <p>({translateThis("Secondary")}): <b>{answerGetter('c4k_parentProviderAgreement_sectionOne_form_QN_8', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionOne_form_Key_QN_9")}:  </p>
                        <ul>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionOne_form_Key_QN_9", formData)?.answer === 'Part of my Application or Redetermination' ? 'checked' : ''}>{translateThis("Part of my Application or Redetermination")}</li>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionOne_form_Key_QN_9", formData)?.answer === 'Reporting changes or a new provider' ? 'checked' : ''}>{translateThis("Reporting changes or a new provider")}</li>
                        </ul>
                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 2 CHILD CARE PROVIDER INFORMATION")}</b></h3>

                    <div className="details-section">
                        <div className="lft-section">
                            <div className='check-box'>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1")}  </p>
                                <ul>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Unlicensed Individual (relative)' ? 'checked' : ''}>{translateThis("Unlicensed Individual (relative)")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Family Child Care Home' ? 'checked' : ''}>{translateThis("Licensed Family Child Care Home")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Child Care Center' ? 'checked' : ''}>{translateThis("Licensed Child Care Center")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Group Child Care Home' ? 'checked' : ''}>{translateThis("Licensed Group Child Care Home")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Licensed Youth Camp' ? 'checked' : ''}>{translateThis("Licensed Youth Camp")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Exempt Youth Camp' ? 'checked' : ''}>{translateThis("Exempt Youth Camp")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_1", formData)?.answer === 'Exempt Center Based Program' ? 'checked' : ''}>{translateThis("Exempt Center Based Program")}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div className='check-box'>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_2")}  </p>
                                <ul>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('National Assoc. for the Education of Young Children (NAEYC)') ? 'checked' : ''}>{translateThis("NAEYC")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('Council on Accreditation (COA)') ? 'checked' : ''}>{translateThis("COA")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('New England Assoc. of Schools and Colleges (NEASC)') ? 'checked' : ''}>{translateThis("NEASC")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_form_Key_QN_2", formData)?.rawAnswer.includes('National Assoc. for Family Child Care (NAFCC)') ? 'checked' : ''}>{translateThis("NAFCC")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 2A LICENSED CHILD CARE PROVIDERS/EXEMPT PROGRAMS")}</b></h3>

                    <div className="flex-box">
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2A_form_QN_1")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_1', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2A_form_QN_2")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_2', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Licensed Home")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_3', formData)?.answer || '-'} &nbsp; {answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_4', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Address where child care is provided")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_5', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("City")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_6', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("State")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_7', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Zipcode")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_8', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Telephone Number")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_9', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Date of Birth")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_10', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("C4K Provider ID")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_11', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2A_form_QN_12")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_12', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <p style={{ whiteSpace: 'nowrap' }}>{translateThis("c4k_parentProviderAgreement_sectionTwo_2A_form_Key_QN_13")}:  </p>
                        <ul>
                            <li className='checked'><b>{translateThis("I understand I must complete")} www.ctcare4kids.com.</b></li>
                        </ul>
                    </div>
                    <div className='gap' />
                    <p>{translateThis("Please list the address you would like notices")}:</p>
                    <div className='auto-flex'>
                        <p>{translateThis("Address")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_14', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("City")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_15', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("State")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_16', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Zipcode")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2A_form_QN_17', formData)?.answer || '-'}</b></p>
                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 2B UNLICENSED RELATIVE CHILD CARE PROVIDERS")}</b></h3>
                    <p><b>{translateThis("You must be related to the child by blood")} </b> {translateThis("This means the child is your grandchild")}</p>

                    <div className='gap' />

                    <div className="flex-box">
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2A_form_QN_1")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_1', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_QN_4")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_4', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("City")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_5', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("State")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_6', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Zipcode")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_7', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Telephone Number")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_8', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("C4K Provider ID")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_9', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Date of Birth")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_2', formData)?.answer || '-'}</b></p>
                        <div className='check-box inline'>
                            <p>{translateThis("Gender")}:  </p>
                            <ul>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_3", formData)?.answer === 'F (Female)' ? 'checked' : ''}>{translateThis("F Female")}</li>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_3", formData)?.answer === 'M (Male)' ? 'checked' : ''}>{translateThis("M Male")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <ul>
                            <li className='checked'><b>{translateThis("I understand I must complete")} www.ctcare4kids.com.</b></li>
                        </ul>
                    </div>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_10")}  </p>
                        <ul>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_10", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_10", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_QN_11")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_11', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_QN_12")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_12', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <p>{translateThis("Telephone of your other job")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_13', formData)?.answer || '-'}</b></p>
                    </div>
                    <p>{translateThis("If yes list your work schedule at your other job in the table below")}</p>
                    <div className="gap" />
                    <h4 style={{ textAlign: 'center' }}>{translateThis("Providers")}{translateThis("Use this table to list the hours and days you normally work your other job")}:  ({translateThis("circle AM or PM")}).</h4>
                    <div className='table-wrp'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{translateThis("TIME")}</th>
                                    <th>{translateThis("SUNDAY")}</th>
                                    <th>{translateThis("MONDAY")}</th>
                                    <th>{translateThis("TUESDAY")}</th>
                                    <th>{translateThis("WEDNESDAY")}</th>
                                    <th>{translateThis("THURSDAY")}</th>
                                    <th>{translateThis("FRIDAY")}</th>
                                    <th>{translateThis("SATURDAY")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{translateThis("Start")}</th>
                                    {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={`from-${day}`}>{answerGetter(`weekly_schedule_three_${day}_from`, formData)?.answer || '-'}</td>)}
                                </tr>
                                <tr>
                                    <th>{translateThis("End")}</th>
                                    {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={`from-${day}`}>{answerGetter(`weekly_schedule_three_${day}_to`, formData)?.answer || '-'}</td>)}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='gap' />
                    <div className='check-box inline'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_QN_14")}  </p>
                        <ul>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_QN_14", formData)?.answer === 'Child’s home' ? 'checked' : ''}>{translateThis("Child home")}</li>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_QN_14", formData)?.answer === 'Provider’s home' ? 'checked' : ''}>{translateThis("Provider home")}</li>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_QN_14", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                        </ul>
                    </div>
                    <div className='auto-flex'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_15")}  </p>
                            <ul>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_15", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_15", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        <p>{translateThis("Telephone Number")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_15_1', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='auto-flex'>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_16")}  </p>
                            <ul>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_16", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_16", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                        <div className='check-box inline'>
                            <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_17")}  </p>
                            <ul>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_17", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_17", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </div>
                    </div>
                    <p>{translateThis("What is the total number of children in your care at the same time on any day")}, <span style={{ textDecoration: 'underline' }}>{translateThis("including your own children")}</span> <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_18_1', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_19")} <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_19_1', formData)?.answer || '-'}</b></p>

                    <div className='check-box inline'>
                        <p style={{ display: 'inline', whiteSpace: 'normal' }}>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_20")}
                            <ul style={{ display: 'inline-block', paddingLeft: '25px' }}>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_20", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                                <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_20", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                            </ul>
                        </p>
                    </div>

                    <div className='check-box inline'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_21")}</p>
                        <ul>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_21", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_21", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_22")} <b>{answerGetter('c4k_parentProviderAgreement_sectionTwo_2B_form_QN_22_1', formData)?.answer || '-'}</b></p>
                    <div className='gap' />
                    <div className='check-box inline'>
                        <p>{translateThis("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_23")}  </p>
                        <ul>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_23", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("c4k_parentProviderAgreement_sectionTwo_2B_form_Key_QN_23", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <div className='gap' />
                    <ul className='bullet-list'>
                        <li>{translateThis("PP_BulletList_1")}</li>
                        <li>{translateThis("PP_BulletList_2")}</li>
                        <li>{translateThis("PP_BulletList_3")}</li>
                        <li>{translateThis("PP_BulletList_4")}</li>
                        <li>{translateThis("PP_BulletList_5")}</li>
                        <li>{translateThis("PP_BulletList_6")}</li>
                    </ul>

                    <div className='gap' />
                    <div className='gap' />
                    <p style={{ textAlign: 'center' }}><b>{translateThis("For a complete crime list please visit")} www.ctcare4kids.com <br />
                        {translateThis("NOTE All Unlicensed Relative Providers are subject to child")}</b></p>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box'>
                    <h3><b>{translateThis("SECTION 3 CHILDREN IN CARE")}</b></h3>
                    <p><b>{translateThis("Complete for each child needing Care 4 Kids assistance")}</b> {translateThis("If there are more than 3 children in your care")} www.ctcare4kids.com.</p>

                    <div className='gap' />
                    <div className='each-child'>

                        <h4>{translateThis("CHILD")} #1</h4>
                        <div className='auto-flex'>
                            <p>{translateThis("Last Name")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_3', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("First Name")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_1', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("M.I.")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_2', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Date of Birth")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_4', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_5")}: <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_5', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_6")} <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_6', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_7")}</p>
                                <ul>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_7", formData)?.answer === "Yes" ? 'checked' : ''}>{translateThis("Yes")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_7", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                </ul>
                            </div>
                            <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_7_1")} <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_7_1', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className='auto-flex'>
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8")}</p>
                                <ul>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8", formData)?.answer === "Yes" ? 'checked' : ''}>{translateThis("Yes")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                </ul>
                            </div>
                            <div className='check-box'>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_1")}</p>
                                <ul style={{ display: 'flex' }}>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === "Grandparent/Great Grandparent" ? 'checked' : ''}>{translateThis("Grandparent")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === 'Aunt/Uncle' ? 'checked' : ''}>{translateThis("Aunt Uncle")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === 'Sibling' ? 'checked' : ''}>{translateThis("Sibling")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === 'Other' ? 'checked' : ''}>Other: <b>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_8_3', formData)?.answer || '-'}</b></li>
                                </ul>
                            </div>
                        </div>
                        <div className='gap' />
                        <h4 style={{ textAlign: 'center' }}>{translateThis("CHILD CARE SCHEDULE Fill in the time the child is in your care")} ({translateThis("circle AM or PM")})</h4>
                        <div className='table-wrp'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{translateThis("TIME")}</th>
                                        <th>{translateThis("SUNDAY")}</th>
                                        <th>{translateThis("MONDAY")}</th>
                                        <th>{translateThis("TUESDAY")}</th>
                                        <th>{translateThis("WEDNESDAY")}</th>
                                        <th>{translateThis("THURSDAY")}</th>
                                        <th>{translateThis("FRIDAY")}</th>
                                        <th>{translateThis("SATURDAY")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>{translateThis("Schedule 1 Begin Time")}</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_one_${day}_from`, formData)?.answer || '-'}</td>)}
                                    </tr>
                                    <tr>
                                        <th>{translateThis("Schedule 1 End Time")}</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_one_${day}_to`, formData)?.answer || '-'}</td>)}
                                    </tr>

                                    <tr>
                                        <th>{translateThis("Schedule 2 Begin Time")}</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_two_${day}_from`, formData)?.answer || '-'}</td>)}
                                    </tr>
                                    <tr>
                                        <th>{translateThis("Schedule 2 End Time")}</th>
                                        {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`weekly_schedule_two_${day}_to`, formData)?.answer || '-'}</td>)}
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <br />
                        <div className="flex-box">
                            <div className='check-box inline'>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_9")}</p>
                                <ul>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_9", formData)?.answer === "Yes" ? 'checked' : ''}>{translateThis("Yes")}</li>
                                    <li className={answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_9", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                </ul>
                            </div>
                            <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_9_1")}:</p>
                        </div>
                        <p>{answerGetter('c4k_parentProviderAgreement_sectionThree_form_QN_9_1', formData)?.answer}</p>

                    </div>

                    <div className='gap' />
                    {(answerGetter('c4k_parentProviderAgreement_sectionOne_form_extraChildren', formData)?.answer) && <>
                        {answerGetter('c4k_parentProviderAgreement_sectionOne_form_extraChildren', formData)?.rawAnswer.map((no, i) => <div key={`each-child-${no}`} className='each-child'>

                            <h4>{translateThis("CHILD")} #{i + 2}</h4>
                            <div className='auto-flex'>
                                <p>{translateThis("Last Name")}: <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_3`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("First Name")}: <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_1`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("M.I.")}: <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_2`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Date of Birth")}: <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_4`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='auto-flex'>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_5")}: <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_5`, formData)?.answer || '-'}</b></p>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_6")} <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_6`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='auto-flex'>
                                <div className='check-box inline'>
                                    <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_7")}</p>
                                    <ul>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_7`, formData)?.answer === "Yes" ? 'checked' : ''}>{translateThis("Yes")}</li>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_7`, formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                    </ul>
                                </div>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_7_1")} <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_7_1`, formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='auto-flex'>
                                <div className='check-box inline'>
                                    <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8")}</p>
                                    <ul>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_8`, formData)?.answer === "Yes" ? 'checked' : ''}>{translateThis("Yes")}</li>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_8`, formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                    </ul>
                                </div>
                                <div className='check-box'>
                                    <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_1")}:</p>
                                    <ul style={{ display: 'flex' }}>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2`, formData)?.answer === "Grandparent/Great Grandparent" ? 'checked' : ''}>{translateThis("Grandparent")}</li>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2`, formData)?.answer === 'Aunt/Uncle' ? 'checked' : ''}>{translateThis("Aunt Uncle")}</li>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2`, formData)?.answer === 'Sibling' ? 'checked' : ''}>{translateThis("Sibling")}</li>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2`, formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}: <b>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_8_3`, formData)?.answer || '-'}</b></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='gap' />
                            <h4 style={{ textAlign: 'center' }}>{translateThis("CHILD CARE SCHEDULE Fill in the time the child is in your care")} ({translateThis("circle AM or PM")} )</h4>
                            <div className='table-wrp'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{translateThis("TIME")}</th>
                                            <th>{translateThis("SUNDAY")}</th>
                                            <th>{translateThis("MONDAY")}</th>
                                            <th>{translateThis("TUESDAY")}</th>
                                            <th>{translateThis("WEDNESDAY")}</th>
                                            <th>{translateThis("THURSDAY")}</th>
                                            <th>{translateThis("FRIDAY")}</th>
                                            <th>{translateThis("SATURDAY")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>{translateThis("Schedule 1 Begin Time")}</th>
                                            {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`child_${no}_weekly_schedule_one_${day}_from`, formData)?.answer || '-'}</td>)}
                                        </tr>
                                        <tr>
                                            <th>{translateThis("Schedule 1 End Time")}</th>
                                            {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`child_${no}_weekly_schedule_one_${day}_to`, formData)?.answer || '-'}</td>)}
                                        </tr>

                                        <tr>
                                            <th>{translateThis("Schedule 2 Begin Time")}</th>
                                            {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`child_${no}_weekly_schedule_two_${day}_from`, formData)?.answer || '-'}</td>)}
                                        </tr>
                                        <tr>
                                            <th>{translateThis("Schedule 2 End Time")}</th>
                                            {['Sunday', "Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => <td key={day}>{answerGetter(`child_${no}_weekly_schedule_two_${day}_to`, formData)?.answer || '-'}</td>)}
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div className="flex-box">
                                <div className='check-box inline'>
                                    <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_Key_QN_9")}</p>
                                    <ul>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_9`, formData)?.answer === "Yes" ? 'checked' : ''}>{translateThis("Yes")}</li>
                                        <li className={answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_Key_QN_9`, formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                                    </ul>
                                </div>
                                <p>{translateThis("c4k_parentProviderAgreement_sectionThree_form_QN_9_1")}:</p>
                            </div>
                            <p>{answerGetter(`child_${no}_c4k_parentProviderAgreement_sectionThree_form_QN_9_1`, formData)?.answer}</p>

                        </div>)}
                    </>}
                    <div className='gap' />
                    <hr />

                </div>
                <div className='gap' />

                <div className='each-section-box' style={{ display: 'block', float: 'left' }}>

                    <h3><b>{translateThis("SECTION 4 PROVIDER CERTIFICATION")}</b></h3>
                    <p><b>{translateThis("I certify that")}</b></p>

                    <ul className='bullet-list'>
                        <li>{translateThis("PP_Agreement_section4BList_1")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_2")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_3")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_4")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_5")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_6")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_7")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_8")} <b>{translateThis("PP_Agreement_section4BList_8_1")}</b> {translateThis("PP_Agreement_section4BList_8_2")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_9")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_10")} &nbsp;www.ctcare4kids.com.</li>
                        <li>{translateThis("PP_Agreement_section4BList_11")}</li>
                        <li>{translateThis("PP_Agreement_section4BList_12")}</li>
                    </ul>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className="signature-blk">
                    <div className="details-section">
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{data.additionalInfo?.providerSignature || '-'}</h3>
                                <p>{translateThis("Provider signature")}</p>
                                <p>{translateThis("Date")}: <b>{data.additionalInfo && data.additionalInfo?.providerSignature && data.additionalInfo?.providerSignedDate ? data.additionalInfo?.providerSignedDate : ''}</b></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap' />
                <div className='gap' />

                <div className='each-section-box' style={{ display: 'block', float: 'left' }}>
                    <div className='gap' />
                    <div className='gap' />
                    <h3><b>{translateThis("SECTION 5 PARENT CERTIFICATION")}</b></h3>
                    <p><b>{translateThis("I certify that")}</b> </p>
                    <ul className='bullet-list'>
                        <li>{translateThis("PP_Agreement_section5List_1")}</li>
                        <li>{translateThis("PP_Agreement_section5List_2")}</li>
                        <li>{translateThis("PP_Agreement_section5List_3")}</li>
                        <li>{translateThis("PP_Agreement_section5List_4")}</li>
                        <li>{translateThis("PP_Agreement_section5List_6")}&nbsp;www.ctcare4kids.com.</li>
                        <li>{translateThis("PP_Agreement_section5List_5")}</li>
                    </ul>
                    <div className='gap' />
                    <div className="signature-blk">
                        <div className="details-section">
                            <div className="ryt-section">
                                <div style={{ textAlign: 'center', float: 'right' }}>
                                    <h3>{data.additionalInfo?.parentSignature || '-'}</h3>
                                    <p>{translateThis("Parent Guardian signature")}</p>
                                    <p>{translateThis("Date")}: <b>{data.additionalInfo && data.additionalInfo?.parentSignedDate ? data.additionalInfo?.parentSignedDate : ''}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

ParentProviderAgreementView.propTypes = {
    data: PropTypes.any
}

export default ParentProviderAgreementView;