import React from 'react';
import { Link } from 'react-router-dom';
import AppIcons from '../images/icons';
import SVGIcons from '../images/icons/svgIcons';

import './footer.scss';



export default function AppFooter() {
    return (
        <div>
            <div className="project-main-footer YreneTemplate f-wrp">
                <div className="container">
                    <div className='copy-foot f-wrp'>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="copy-txt f-wrp">
                                    <p>© 2023 The Precious Moment · Terms</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className='dev-blk'>
                                    <p style={{ textAlign: 'center', fontWeight: '400', marginTop: '5px' }}>Built by &nbsp;<i style={{ cursor: 'pointer', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ textDecoration: "none", fontStyle: 'normal', cursor: 'pointer' }}>mihapp.co</span> &nbsp;<img style={{ display: 'inline-block', width: '25px', maxWidth: '25px', }} src={AppIcons.mihapp} alt="mihapp" /></i></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className='social-list f-wrp'>
                                    <ul>
                                        <li>
                                            <Link onClick={() => window.open('https://www.facebook.com/ThePreciousMomentFamilyDaycare/', '_blank')}>
                                                <span>{SVGIcons.FbIcon()}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={() => window.open('https://www.instagram.com/thepreciousmomentfdaycare/?hl=en', '_blank')}>
                                                <span>{SVGIcons.InstaIcon()}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}