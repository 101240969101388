import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import Services from './Services/Services';
import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import { translateThis } from '../../../helpers/language.helper';





const YreneTemplate = () => {

    const basicDetails = [
        {
            icon: SVGIcons.AcceptedAgesIcon(),
            title: translateThis('Accepted ages'),
            info: translateThis('Babies from 6 weeks onwards up to 12years old'),
        },
        {
            icon: SVGIcons.DaysIcon(),
            title: translateThis('Days'),
            info: translateThis('Open from Monday to Friday'),
        },
        {
            icon: SVGIcons.HoursIcon(),
            title: translateThis('Hours'),
            info: translateThis('timing'),
        },
        {
            icon: SVGIcons.SubsidyIcon(),
            title: translateThis('Subsidy programs'),
            info: translateThis('C4K Care 4 Kids CDF Children Defense Fund'),
        },
    ];


    // custom home functions here
    const pageName = "The Precious moment";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='YreneTemplate'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <span><img src={AppIcons.bannerBG} alt='banner' /></span>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <span className='logo-icon'><img src={AppIcons.bannerLogo} alt='banner icon' /></span>
                                    <Button onClick={() => {
                                        window.location.href = '/preciousmoment/my-portal'
                                    }} className='sqr-btn'>Parent Portal</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-slider-sec f-wrp'>
                    <Services />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <div className='about-con-box'>
                                    <h1><img src={AppIcons.abtHead} alt='Pencil' /></h1>
                                    <h1>{translateThis('About')}</h1>
                                    <p>{translateThis('yreneAboutText1')}</p>
                                    <p>{translateThis('yreneAboutText2')}</p>
                                    <h1><img src={AppIcons.abtFoot} alt='Pencil' /></h1>
                                </div>
                            </div>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='activities-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='activities-grid-wrp f-wrp'>
                            <div className='activities-img-sec'>
                                <span><img src={AppIcons.activity} alt='activity' /></span>
                            </div>
                            <div className='activities-con-sec'>
                                <h1>{translateThis('Activities')} </h1>
                                <p>{translateThis('Activities Text')}</p>
                                <ul>
                                    <li><span><img src={AppIcons.activity1} alt="activity" /></span><p>{translateThis('yreneActivity1')}</p></li>
                                    <li><span><img src={AppIcons.activity2} alt="activity" /></span><p>{translateThis('yreneActivity2')}</p></li>
                                    <li><span><img src={AppIcons.activity3} alt="activity" /></span><p>{translateThis('yreneActivity3')}</p></li>
                                    <li><span><img src={AppIcons.activity4} alt="activity" /></span><p>{translateThis('yreneActivity4')}</p></li>
                                    <li><span><img src={AppIcons.activity5} alt="activity" /></span><p>{translateThis('yreneActivity5')}</p></li>
                                    <li><span><img src={AppIcons.activity6} alt="activity" /></span><p>{translateThis('yreneActivity6')}</p></li>
                                    <li><span><img src={AppIcons.activity7} alt="activity" /></span><p>{translateThis('yreneActivity7')}</p></li>
                                    <li><span><img src={AppIcons.activity8} alt="activity" /></span><p>{translateThis('yreneActivity8')}</p></li>
                                    <li><span><img src={AppIcons.activity9} alt="activity" /></span><p>{translateThis('yreneActivity9')}</p></li>
                                    <li><span><img src={AppIcons.activity10} alt="activity" /></span><p>{translateThis('yreneActivity10')}</p></li>
                                    <li><span><img src={AppIcons.activity11} alt="activity" /></span><p>{translateThis('yreneActivity11')}</p></li>
                                    <li><span><img src={AppIcons.activity12} alt="activity" /></span><p>{translateThis('yreneActivity12')}</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='accepted-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='accepted-sec-blk f-wrp'>
                            <div className='accepted-img'>
                                <span><img src={AppIcons.acceptedBG} alt='Our payment' /></span>
                            </div>
                            <div className='accepted-con our-mission-wrp'>
                                <div className="each-program-sec f-wrp ">
                                    <ul>
                                        {basicDetails.map((element, key) => (
                                            <li key={key}>
                                                <span className='program-icon'>{element.icon}</span>
                                                <div className="detail-wrp">
                                                    <h3>{element.title}</h3>
                                                    <p>{element.info}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='gallery-sec-header f-wrp'>
                            <h1>{translateThis('Our Photos')}</h1>
                            <p>{translateThis('Our Photos Text')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Gallery />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='contactBox-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='contactBox-grid-wrp f-wrp'>
                            <div className='contactBox-con-sec'>
                                <div className='contactBox-con-box'>
                                    <h1><img src={AppIcons.abtHead} alt='Pencil' /></h1>
                                    <h1>{translateThis('Contact')}</h1>
                                    <p>{translateThis('yreneContactText1')}</p>
                                    <p>{translateThis('yreneContactText2')}</p>
                                    <p>{translateThis('yreneContactText3')}</p>
                                    <ul className='social-list'>
                                        <li>
                                            <Link onClick={() => window.open('https://www.facebook.com/ThePreciousMomentFamilyDaycare/', '_blank')} className='social-btn'>
                                                <img src={AppIcons.faceBook} alt='Face book' />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={() => window.open('https://wa.me/13477519009', '_blank')} className='social-btn'>
                                                <img src={AppIcons.whatsapp} alt='whatsapp' />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={() => window.open('https://www.instagram.com/thepreciousmomentfdaycare/?hl=en', '_blank')} className='social-btn'>
                                                <img src={AppIcons.instagram} alt='instagram' />
                                            </Link>
                                        </li>
                                    </ul>
                                    <h1><img src={AppIcons.abtFoot} alt='Pencil' /></h1>
                                </div>
                            </div>
                            <div className='contactBox-img-sec'>
                                <span><img src={AppIcons.contact} alt='contact' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='class-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='class-sec-header f-wrp'>
                            <h1>{translateThis('Classes')}</h1>
                            <p>{translateThis('Classes Text')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <div className='class-content-wrp f-wrp'>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className='each-class-box f-wrp'>
                                        <h1>{translateThis('Infant')}</h1>
                                        <p>{translateThis('InfantText')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className='each-class-box f-wrp'>
                                        <h1>{translateThis('Toddler')}</h1>
                                        <p>{translateThis('ToddlerText')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className='each-class-box f-wrp'>
                                        <h1>{translateThis('Schooler')}</h1>
                                        <p>{translateThis('SchoolerText')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='payment-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='payment-sec-blk f-wrp'>
                            <div className='payment-img'>
                                <span><img src={AppIcons.paymentBG} alt='Our payment' /></span>
                            </div>
                            <div className='payment-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Our Payment Options')}</h1>
                                    <p>{translateThis('Our Payment Options Text1')}</p><br />
                                    <p>{translateThis('Our Payment Options Text2')}</p><br />

                                    <Button onClick={() => {
                                        window.location.href = '/preciousmoment/my-portal'
                                    }} className='sqr-btn'>Pay online</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='staff-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='staff-sec-header f-wrp'>
                            <h1>{translateThis('Staff')}</h1>
                            <p>{translateThis('staffText')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <div className='staff-content-wrp f-wrp'>
                            <div className='each-staff-box'>
                                <span><img src={AppIcons.staff_1} alt='staff' /></span>
                                <h3>{translateThis('Yrene Luna')}</h3>
                                <p>{translateThis('Owner')}</p>
                            </div>
                            <div className='each-staff-box'>
                                <span><img src={AppIcons.staff_2} alt='staff' /></span>
                                <h3>{translateThis('Migelena Balcacer')}</h3>
                                <p>{translateThis('Substitute')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='testimonial-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{translateThis('Reviews')}</h1>
                            <p>{translateThis('ReviewsText')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Testimonial />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

            </div>
        </AppLayout >
    )
}

export default YreneTemplate;