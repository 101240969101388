import React from "react";

import './programs.scss';
import { translateThis } from "../../../../helpers/language.helper";
import AppIcons from "../images/icons";


function Programs() {

  const programItems1 = [
    {
      icon: AppIcons.program1,
      title: translateThis('Paint with a brush, crayons and play with clay in the courtyard'),
    },
    {
      icon: AppIcons.program2,
      title: translateThis('Outdoor playground twice a day, in the morning and in the afternoon'),
    },
    {
      icon: AppIcons.program3,
      title: translateThis('Welcome song with kids names'),
    },
  ];
  const programItems2 = [
    {
      icon: AppIcons.program4,
      title: translateThis('Circle time & Story time '),
    },
    {
      icon: AppIcons.program5,
      title: translateThis('AB Patterns & Shapes'),
    },
    {
      icon: AppIcons.program6,
      title: translateThis('Flash cards & Sight words'),
    },
    {
      icon: AppIcons.program7,
      title: translateThis('Gardening & planting seeds'),
    },
    
  ];
  const programItems3 = [
    {
      icon: AppIcons.program8,
      title: translateThis('Calendar & Holidays costumes'),
    },
    {
      icon: AppIcons.program9,
      title: translateThis('Holding pencil & Learning videos'),
    },
    {
      icon: AppIcons.program10,
      title: translateThis('Colors, Crafts & Scissors'),
    },
    
  ];
  const programItems4 = [
    {
      icon: AppIcons.program11,
      title: translateThis('Music, Dance & Singing '),
    },
    {
      icon: AppIcons.program12,
      title: translateThis('Spell and Write Name'),
    },
    {
      icon: AppIcons.program13,
      title: translateThis('Theme of the month'),
    },
    
  ];


  return (
    <>
      <div className="argentina-program-wrp f-wrp">

        <div className="row">
          {programItems1.map((element, key) => (
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
              <div className="each-program-sec Green1C f-wrp ">
                <ul>
                    <li>
                      <span className='program-icon'><img src={element.icon} alt={element.title} /></span>
                      <p>{element.title}</p>
                    </li>
                </ul>
              </div>
            </div>
            ))}
        </div>
      </div>
      <div className='gap f-wrp' style={{ paddingTop: '30px' }} />
      <div className="argentina-program-wrp f-wrp">

        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div className="each-program-sec Green1C f-wrp ">
              <ul>
                {programItems2.map((element, key) => (
                  <li key={key}>
                    <span className='program-icon'><img src={element.icon} alt={element.title} /></span>
                    <p>{element.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div className="each-program-sec Amaranth f-wrp ">
              <ul>
                {programItems3.map((element, key) => (
                  <li key={key}>
                    <span className='program-icon'><img src={element.icon} alt={element.title} /></span>
                    <p>{element.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div className="each-program-sec Amaranth f-wrp ">
              <ul>
                {programItems4.map((element, key) => (
                  <li key={key}>
                    <span className='program-icon'><img src={element.icon} alt={element.title} /></span>
                    <p>{element.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Programs;