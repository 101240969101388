import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AppIcons from '../../../assets/images/icons';
import './ppPlatformSlider.scss';


function SliderImageWeb() {


  const galleryItems = [
    {
      image: AppIcons.websiteSlider1,
    },
    {
      image: AppIcons.websiteSlider2,
    },
    {
      image: AppIcons.websiteSlider3,
    },
    {
      image: AppIcons.websiteSlider4,
    },
    {
      image: AppIcons.websiteSlider5,
    },
    {
      image: AppIcons.websiteSlider6,
    },
    {
      image: AppIcons.websiteSlider7,
    },
    {
      image: AppIcons.websiteSlider8,
    },
    {
      image: AppIcons.websiteSlider9,
    },
    {
      image: AppIcons.websiteSlider10,
    },
    {
      image: AppIcons.websiteSlider11,
    },
    {
      image: AppIcons.websiteSlider12,
    },
    {
      image: AppIcons.websiteSlider13,
    },
    {
      image: AppIcons.websiteSlider14,
    },
    {
      image: AppIcons.websiteSlider15,
    },
    {
      image: AppIcons.websiteSlider16,
    },
    {
      image: AppIcons.websiteSlider17,
    },
    {
      image: AppIcons.websiteSlider18,
    },
    {
      image: AppIcons.websiteSlider19,
    },
    {
      image: AppIcons.websiteSlider20,
    },
    {
      image: AppIcons.websiteSlider21,
    },
    {
      image: AppIcons.websiteSlider22,
    },
   
  ];

  return (
    <>
      <div className="ppPlatformSlider f-wrp">

        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          className='content-sec-mob'
          navigation
          centeredSlides
          loop
          speed={1200}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 1.05,
              spaceBetween: 0,
            },
          }}
          
        >
          {galleryItems.map((element, key) => (
            <SwiperSlide key={key}>
                <span className='gallery-image'><img src={element.image} alt='gallery' /></span>
            </SwiperSlide>
          ))}
        </Swiper>



      </div>






    </>
  );
}

export default SliderImageWeb;