import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import { translateThis } from "../../../../helpers/language.helper";
import AppIcons from "../images/icons";


function Testimonial() {


  const testimonialItems = [
    {
      name: translateThis('user1Name'),
      Image: AppIcons.user1,
      content: translateThis('user1_content'),
    },
    {
      name: translateThis('user2Name'),
      Image: AppIcons.user2,
      content: translateThis('user2_content'),
    },
    {
      name: translateThis('user3Name'),
      Image: AppIcons.user3,
      content: translateThis('user3_content'),
    },
    {
      name: translateThis('user4Name'),
      Image: AppIcons.user4,
      content: translateThis('user4_content'),
    },
    {
      name: translateThis('user5Name'),
      Image: AppIcons.user5,
      content: translateThis('user5_content'),
    },
    {
      name: translateThis('user6Name'),
      Image: AppIcons.user6,
      content: translateThis('user6_content'),
    },
    {
      name: translateThis('user7Name'),
      Image: AppIcons.user7,
      content: translateThis('user7_content'),
    },
    
  ];

  return (
    <>
      <div className="testimonial-sec-wrp rafaela">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  <p>{element.content}</p>
                  <h2><span><img src={element.Image} alt="user" /></span>{element.name}</h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;