import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import { DATE_FORMAT, TIME_12HR_FORMAT } from '../../../constants';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';


const DailyCheckInList = () => {
    
    const apiCaller = new ApiCaller('attendance');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'employeeId',
            headerName: 'Name',
            minWidth: 150,
            flex: 2,
            valueGetter: ({row}) =>
                `${row?.employeeId?.firstName || ''} ${row?.employeeId?.lastName || ''}`,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
                `${dayjs(row.createdAt).format(DATE_FORMAT)}`,
        },
        {
            field: 'checkIn',
            headerName: 'Check in',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
                `${dayjs(row.checkIn).format(TIME_12HR_FORMAT)}`,
        },
        {
            field: 'checkOut',
            headerName: 'Check out',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
            row.checkOut ? `${dayjs(row.checkOut).format(TIME_12HR_FORMAT)}` : '-',
        }
        

    ]


    return (
        <div className='employees-main-list f-wrp'>
            <div className='employees-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table PC-Table f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                        <div className='mob-table-wrp f-wrp'>
                            {dataArr.length > 0 ? <ul>
                                {dataArr.map((element) => (
                                    <li key={element.id}>
                                        <div className='each-sec-box'>
                                            {element.image && element.image?.tempURL ? <div className='each-img-blk' key={`image-${element.image.fileKey}`}>
                                                <div className='profile-img-sec'>
                                                    <span><img src={element.image.tempURL} alt={element.image.fileKey} /></span>
                                                </div>
                                            </div> : <div className='each-img-blk'>
                                                <div className='profile-img-sec'>
                                                    <span><img src={AppIcons.placeholder} alt='placeholder' /></span>
                                                </div>
                                            </div>}
                                        </div>
                                        <div className='each-sec-box'>
                                            <h4>{element.employeeId?.firstName}{element.employeeId?.lastName}</h4>
                                            <span>{dayjs(element.createdAt).format(DATE_FORMAT)}</span>
                                        </div>
                                        <div className='each-sec-box'>
                                            <ul className='btn-wrp'>
                                                <li style={{textAlign: 'right'}}><span style={{display: 'block', whiteSpace: 'nowrap'}}>{translateThis('In')}: {dayjs(element.checkIn).format(TIME_12HR_FORMAT)}</span>
                                                    <span style={{display: 'block', whiteSpace: 'nowrap'}}>{translateThis('Out')}: {dayjs(element.checkOut).format(TIME_12HR_FORMAT)}</span></li>
                                            </ul>
                                        </div>
                                    </li>
                                ))}
                            </ul> : <div className='empty-placeholder f-wrp'>
                                <div className='placeholder-con'>
                                    <img src={AppIcons.parentPlaceholder} alt='empty' />
                                    <h4>{translateThis('No statistics')}</h4>
                                    <p>{translateThis('There are no information loaded in the system')}</p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}


DailyCheckInList.propTypes = {
}
export default DailyCheckInList;