import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import AppIcons from "../images/icons";
import { translateThis } from "../../../../helpers/language.helper";


function Testimonial() {


  const testimonialItems = [
    {
      name: translateThis('client_name_1'),
      image: AppIcons.user1,
      content: translateThis('client_content_1'),
    },
    {
      name: translateThis('client_name_2'),
      image: AppIcons.user2,
      content: translateThis('client_content_2'),
    },
    {
      name: translateThis('client_name_3'),
      image: AppIcons.user3,
      content: translateThis('client_content_3'),
    },
    {
      name: translateThis('client_name_4'),
      shortName: 'IS',
      content: translateThis('client_content_4'),
    },

  ];

  return (
    <>
      <div className="Francisca-testimonial-sec-wrp">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={10}
            slidesPerView={2}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}

            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1275: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  <p>{element.content}</p>
                  <h2>
                    {element.shortName ? <span className="shortname">{element.shortName}</span> : null}
                    {element.image ? <span className="user"><img src={element.image} alt={element.name} /></span> : null}{element.name}
                  </h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;