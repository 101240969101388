import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

// @mui
import { Backdrop, CircularProgress } from '@mui/material';


import { getCurrentFacilityURL } from '../../helpers/helper.functions';
import ApiCaller from '../../services/api/general';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { showSnackbar } from '../snackbar';



const FacilityIdentifier = () => {
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('facility')

    const [isLoading, setIsLoading] = useState(false);
    const [configState, setConfigState] = useRecoilState(myPortalState);


    /**
     * The Legendary UseEffect function
     * You Know What I do :)
     */
    useEffect(() => {
        if(getCurrentFacilityURL() !== 'pdf-preview') { // Skip the check for pdf-previews
            getFacilityInfo();
        }
        
        // eslint-disable-next-line    
    }, []);

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ facilityInfo: { ...prv.facilityInfo, ...modifiedObj } } }));

    /**
     * Gets the current facility and saves to state
     */
    const getFacilityInfo = () => {
        const currentFacilityURL = getCurrentFacilityURL();
        if ((!configState?.facilityInfo?.facilityURL || configState?.facilityInfo?.facilityURL !== currentFacilityURL) && window.location.pathname !== "/") {
            setIsLoading(true);
            apiCaller.getData(currentFacilityURL).then((data) => {
                if (data) {
                    modifyConfigState(data);
                } else {
                    showSnackbar().failure("Requested facility not found");
                    navigator('/')
                }
            }).finally(() => setIsLoading(false));
        }
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default FacilityIdentifier;