import * as React from 'react';
import dayjs from 'dayjs';
// import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import ModalPopUp from '../../../components/modal/modal.popup';
// Components
import AppTable from '../../../components/table';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import { showSnackbar } from '../../../components/snackbar';


// Services
import ApiCaller from '../../../services/api/general';
import { DATE_READABLE_FORMAT } from '../../../constants';
import { generatePDFBy } from '../../../services/api/pdf';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';



const ParentDynamicFormsHistoryTab = () => {

    const apiCaller = new ApiCaller('form-submissions')
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataArr, setDataArr] = React.useState([]);
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedPreview, setSelectedPreview] = React.useState(<></>);


    const onViewClose = () => {
        setViewOpen(false);
    };
    /**
  the legendary use effect function
  This will run very first on render
   */
    React.useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({ query: `sortBy=createdAt:desc&isParentSigned=true&isProviderSigned=true` })
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * 
     * @param {string} formName 
     * @param {any} formData 
     */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }

    /**
   * Download PDF
   */
    const downloadPDF = (childId, userId, formName) => {
        if (childId && userId && formName) {
            showSnackbar().info("Selected form will be downloaded shortly")
            generatePDFBy(userId, childId, formName)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                })
        } else {
            showSnackbar().warning('Something went wrong!');
        }

    }

    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 1,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'formTitle',
            headerName: 'Form',
            flex: 1,
            minWidth: 280,
        },
        {
            field: 'createdAt',
            headerName: 'Submission date',
            flex: 2,
            minWidth: 130,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_READABLE_FORMAT)

        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: ({ row }) => {
                const { childId, userId, formName, pdfURL } = row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => {
                            if (pdfURL) {
                                window.open(pdfURL.tempURL, "_blank");
                            } else {
                                onFormPreviewClick(formName, row)
                            }
                        }
                    },
                    {
                        label: "Download",
                        onClick: () => {
                            if (pdfURL) {
                                window.open(pdfURL.tempURL, "_blank");
                            } else {
                                downloadPDF(childId?.id, userId?.id, formName)
                            }
                        }
                    }
                ]} />
            }
        }

    ]

    return (
        <>
            <AppTable
                key={'parent-payment-table'}
                columns={tableColumns}
                isLoading={isLoading}
                data={dataArr}
            />
            {viewOpen ? <ModalPopUp
                className='preview-popup'
                isOpen={viewOpen}
                onClose={onViewClose}
                aria-labelledby="table-view"
                aria-describedby="table-view"
            >
                {selectedPreview}
                <Button onClick={onViewClose} className='fill-btn'>Close</Button>
            </ModalPopUp> : null}
        </>
    )
}

export default ParentDynamicFormsHistoryTab;