import * as React from 'react';
import { useRecoilValue } from 'recoil';
import ReactPlayer from 'react-player/youtube';
import Dialog from '@mui/material/Dialog';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import SVGIcons from '../../assets/images/icons/svgIcons';

import './index.scss';



function TutorialPopup() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const { tutorialLink = '' } = useRecoilValue(myPortalState);
    return (
        <div className='tutorial-popup'>
            <button type='button' onClick={handleClickOpen} className="video-play" style={{ cursor: 'pointer' }}><SVGIcons.PlayRoundIcon /></button>
            <Dialog id="video-tutorial" open={open} onClose={handleClose}>
                <button type='button' className='close-btn' onClick={handleClose}>
                    <svg width="25" height="25" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.53341 25.8334L6.66675 23.9667L14.1334 16.5001L6.66675 9.03341L8.53341 7.16675L16.0001 14.6334L23.4667 7.16675L25.3334 9.03341L17.8667 16.5001L25.3334 23.9667L23.4667 25.8334L16.0001 18.3667L8.53341 25.8334Z" fill="#FFFFFF" />
                    </svg>
                </button>
                <span className='video-wrapper f-wrp'>
                    <ReactPlayer width={'100%'} height={'auto'} controls url={tutorialLink} />
                </span>
            </Dialog>
            
        </div>
    );
}

export default TutorialPopup