import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Testimonial() {


  const testimonialItems = [
    {
      name: 'Christina Callahan',
      shortName: 'CC',
      content: "We absolutely love Friendly Family Childcare! Our son was home with me until he was 16 months old and this was his first time going to daycare. We were very nervous but Blanca made the transition so easy. She genuinely loves are cares for every child there and always sends us pictures throughout the day. Our son has been there for 3 months now and he asks for Blanca almost everyday. He is so happy when he is there learning and playing with all of his friends. We feel so lucky to have found Family Friendly Childcare and always know that our son is safe and well taken care of when he is there. Highly recommend!",
    },
    {
      name: 'Liseth perez',
      shortName: 'LP',
      content: "I am so thankful to have found Friendly Family Childcare. Blanca responded to my inquiry right away and was able to schedule a tour for me and my twin daughters the same day. After a bad experience with another daycare, I was so hesitant to leave my girls anywhere else. But Blanca made the transition for my daughters and I very easy. She made sure to keep me updated everyday and sends pictures throughout the day and that always gives me a piece of mind. After the first week of starting Family Friendly Daycare both girls couldn’t wait to go back to see Blanca and their friends. Blanca is so loving with the children and you can really see the care and love that she provides while they are in her care. I am truly happy to have found a safe, educational and loving environment where my girls are so happy to go to everyday.",
    },
    {
      name: 'Natalia Garcia',
      shortName: 'NG',
      content: "I absolutely love this daycare! My daughter is so happy to be there and has learned to walk and interact with others so beautifully. As a first time mom I couldn’t be happier with this daycare. My daughter loves Blanca and Karolina and is excited to play with her friends everyday. There’s a different type of comfort with knowing your child is really cared for and is safe with caregivers that truly care for her.",
    },
    {
      name: 'Diana Abarca',
      shortName: 'DA',
      content: "After three years of being a stay at home mom I decided to return back to work. I was so nervous and stressing about finding a great child care for my two kids. I had sent a message to Friendly Family Childcare and Blanca responded quickly. My kids and I went to meet her and they were in love with all the toys and activities they have. My almost three year old loves Blanca and asks me everyday for her. My 18 month old loves her too. My kids are so happy and so am I. I love how Blanca sends me pictures throughout the day. Friendly family childcare, definitely is a place I would recommend. Thank you Blanca for being so great with my kids.",
    },
    {
      name: 'Mackenzie Gordon',
      shortName: 'MG',
      content: "After having my son home with me for the first year we decided to start him in daycare. I was nervous to have him start but Blanca was sweet and patient with us in the beginning, we were able to transition him to full time this past month and counldn't be happier. We love the staff and he has so much fun everyday, Blaca and Carolina take such good care of our sweet boy!",
    },
    {
      name: 'Diana Guerrero',
      shortName: 'DG',
      content: "Gracias friendly family childcare por abrir sus puertas para conocerlos, visitamos las instalaciones en Norwalk hermoso espacio con las herramientas perfectas para aprender, Luca la paso increíble en su entrevista con Mrs Blanca Garcia, educadora certificada, con una trayectoria increíble en cuidado infantil estamos emocionados en empezar",
    },
    {
      name: 'Tatiana Santiago',
      shortName: 'TS',
      content: "We couldnt be happier that we have found Friendly Family Daycare! If we could give more stars we would! The attention, love and care they give our daughter is all that we could ask for. We love that Blanca & Karolina are bi-lingual and are able to teach and speak to our daughter in Spanish. I have a 2.5 yr old who hasn’t started talking yet which makes me nervous because her frustrations can turn into tantrums or outbursts. This is also her first time in daycare and being in someone else’s care that isn’t family. Blanca has made our transition for her seamless. My daughter has gone from making sure I don’t leave when I drop her off, too running and giving Blanca hugs and kisses when we arrive. Then she goes straight to the playroom and doesn’t care that I’m leaving her there. Pick ups are even worse, she doesn’t want to leave and throws a fit because she wants to stay. She loves the toys they have there and sometimes brings them home with her because it’s the only way we can get her to leave. Nothing makes me more happy & at ease, than bringing my daughter somewhere where I know she is safe and enjoying herself!",
    },
    {
      name: 'Megret Solutions Consulting LLC',
      shortName: 'MS',
      content: "Excelente lugar ! Mi bebe ha evolucionado muchísimo desde que comenzó ! Muy buen trato y muy profesionales ! Los recomiendo al 100 por ciento !",
    },












    
  ];

  return (
    <>
      <div className="FriendFamily-testimonial-sec-wrp">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={10}
            slidesPerView={2}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1275: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  <p>{translateThis(element.content)}</p>
                  <h2><span>{translateThis(element.shortName)}</span>{translateThis(element.name)}</h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;