import React from 'react';
import { Button } from '@mui/material';
import Marquee from "react-fast-marquee";
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import KathleenServices from './Services/Services';
// import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import Programs from './programs/programs';
import SVGIcons from './images/icons/svgIcons';
import Teachers from './teachers/teachers';
import { translateThis } from '../../../helpers/language.helper';
import Mission from './ourMission/mission';
import TeachingPlan from './teachingPlan/teachingPlan';

const FranciscaTemplate = () => {

    const pageName = "J's Kids Castle";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='franciscaTemplate'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <div className='scroll-top f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollTop} alt='banner' /></span>
                            </Marquee>
                        </div>

                        <span className='pcBG'><img src={AppIcons.bannerBG} alt='banner' /></span>
                        <span className='mobBG'><img src={AppIcons.bannerBGMob} alt='banner' /></span>
                        <div className='scroll-bottom f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollBottom} alt='banner' /></span>
                            </Marquee>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <div className='banner-con'>
                                        <h1>{translateThis('bannerText_1')}</h1>
                                        <p>{translateThis('bannerText_2')}</p>
                                    </div>
                                </div>
                                <div className='banner-btn-sec f-wrp'>
                                    <Button onClick={() => {
                                        window.location.href = '/jskidscastle/my-portal'
                                    }} className='sqr-btn'>{translateThis('Parent Portal')}</Button>
                                    {/* <Button onClick={() => {
                                        window.location.href = '#'
                                    }} className='sqr-btn'>Our classes</Button> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <h1>{translateThis('our_philosophyHead')}</h1>
                                <p>{translateThis('our_philosophyPara_1')}</p>
                                <p>{translateThis('our_philosophyPara_2')}</p>
                                <p>{translateThis('our_philosophyPara_3')}</p>
                            </div>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='ourMission-wrp f-wrp'>
                    <div className='container'>
                        <Mission />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='service-slider-sec f-wrp'>
                    <div className='service-sec-header f-wrp'>
                        <div className='container'>
                            <h1>{translateThis('our_serviceHead')}</h1>
                            <p>{translateThis('our_serviceTitle')}</p>
                        </div>
                    </div>
                    <KathleenServices />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='programs-slider-sec f-wrp'>
                    <div className='container'>
                        <div className='programs-sec-header f-wrp'>
                            <h1>{translateThis('our_programHead')}</h1>
                            <p>{translateThis('our_programTitle')}</p>
                        </div>
                        <div className='gap f-wrp' style={{ paddingTop: '75px' }} />
                        <Programs />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='teachingPlan-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='teachingPlan-sec-header f-wrp'>
                            <h1>{translateThis('teachingPlan_Head')}</h1>
                            <p>{translateThis('teachingPlan_Title')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <TeachingPlan />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='info-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='info-sec-blk f-wrp'>
                            <div className='info-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('contactUs_Head')}</h1>
                                    <p>{translateThis('contactUs_text')}</p>
                                    <ul>
                                        <li><span>{SVGIcons.LicenseIcon()} </span>{translateThis('info_con_1')}</li>
                                        <li><span>{SVGIcons.PinIcon()} </span>{translateThis('info_con_2')}</li>
                                        <li><span>{SVGIcons.InstaIcon()} </span>{translateThis('info_con_3')}</li>
                                        <li><span>{SVGIcons.MailIcon()} </span>{translateThis('info_con_4')}</li>
                                        <li><span>{SVGIcons.PhoneIcon()} </span>{translateThis('info_con_5')}</li>
                                    </ul>
                                    <Button onClick={() => {
                                        window.location.href = '/jskidscastle'
                                    }} className='sqr-btn'>{translateThis('Get in touch')}</Button>
                                </div>
                            </div>
                            <div className='info-img'>
                                <span><img src={AppIcons.infoImg} alt='Our info' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='install-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='install-sec-blk f-wrp'>
                            <div className='install-img'>
                                <span><img src={AppIcons.installImg} alt='Our Payment Options' /></span>
                            </div>
                            <div className='install-con'>
                                <div className='f-wrp'>
                                    <h1>{translateThis('Our Payment Options')}</h1>
                                    <p>{translateThis('Our_Payment_Text_1')}</p>
                                    <p>{translateThis('Our_Payment_Text_2')}</p>

                                    <Button onClick={() => {
                                        window.location.href = '/jskidscastle/my-portal'
                                    }} className='sqr-btn'>{translateThis('Pay online')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='gallery-sec-header f-wrp'>
                            <h1>{translateThis('Our Photos')}</h1>
                            <p>{translateThis('Our Photos text')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Gallery />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='teachers-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='teachers-sec-header f-wrp'>
                            <h1>{translateThis('Our Teachers')}</h1>
                            <p>{translateThis('Our Teachers text')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Teachers />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='testimonial-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{translateThis('Our Clients Say')}</h1>
                            <p>{translateThis('Our Clients Say text')}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Testimonial />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

            </div>
        </AppLayout>
    )
}

export default FranciscaTemplate;