/* eslint-disable global-require */
const AppIcons = {
  bannerLogo: require('../bannerLogo.png'),
  bannerBG: require('../bannerBG.png'),
  logo: require('../Logo.png'),
  
  
  aboutImg: require('../aboutImg.png'),
  contact: require('../contact.png'),
  
  mihapp: require('../mihapp.png'),
  
  service1: require('../services/service1.png'),
  service2: require('../services/service2.png'),
  service3: require('../services/service3.png'),
  service4: require('../services/service4.png'),
  service5: require('../services/service5.png'),
  service6: require('../services/service6.png'),

  team1: require('../team/team1.png'),
  team2: require('../team/team2.png'),
  team3: require('../team/team3.png'),

  gallery1: require('../gallery/gallery_1.png'),
  gallery2: require('../gallery/gallery_2.png'),
  gallery3: require('../gallery/gallery_3.png'),
  gallery4: require('../gallery/gallery_4.png'),

  program1: require('../program/program1.png'),
  program2: require('../program/program2.png'),
  program3: require('../program/program3.png'),
  program4: require('../program/program4.png'),
  program5: require('../program/program5.png'),
  program6: require('../program/program6.png'),
  program7: require('../program/program7.png'),
  program8: require('../program/program8.png'),
  program9: require('../program/program9.png'),
  program10: require('../program/program10.png'),
  program11: require('../program/program11.png'),
  program12: require('../program/program12.png'),
  program13: require('../program/program13.png'),
  
  classes_1: require('../classes/classes_1.png'),
  classes_2: require('../classes/classes_2.png'),
  classes_3: require('../classes/classes_3.png'),

  user: require('../user.png'),
  
};
export default AppIcons;
/* eslint-enable global-require */
