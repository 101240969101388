import React from 'react';
import ConfirmDialog from '../dialogs/confirm.dialog';
import { logoutUser } from '../../helpers/auth.helper';
import { translateThis } from '../../helpers/language.helper';

const LogoutButton = ()=> {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    return (
        <>
        {/* eslint-disable-next-line */}
        <span className='logout-btn' onClick={()=>setOpenDeleteDialog(true)}>{translateThis('Logout')}</span>
        {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                title={translateThis("Confirm logout")}
                className={'delete-popup logout-popup'}
                description={<span>{translateThis('Are you sure you want to logout?')}</span>}
                okayButtonText={translateThis('Logout')}
                onConfirm={() => logoutUser('Logged out successfully!')}
            />}
        </>
    )
}

export default LogoutButton;