import React, { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Delete } from '@mui/icons-material';

// @mui
import { Stack, Divider, Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { answerGetter, onAddFormExtraSection, onDeleteFormExtraSection } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_EXTENDED_FORMAT, DATE_TIME_FORMAT, TIME_12HR_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';
import TimeInput from '../../../../components/inputs/timeInput';
import DateInput from '../../../../components/inputs/dateInput';



const SummerPPAgreement = (props) => {
    const formName = 'summer-pp-agreement';

    const stepRef = useRef(null)

    const { childId, onChangeFormState = () => null } = props;
    const [parentId, setParentId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [accreditedArr, setAccreditedArr] = useState([]);
    const [initialFormData, setInitialFormData] = useState(null);
    const [extraChildren, setExtraChildren] = useState([]);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                    if (data?.parentId) {
                        setParentId(data?.parentId)
                    }
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        if (parentId && childId && formName) {
            setIsLoading(true);
            getChildFormBy(parentId, childId, formName)
                .then((data) => {
                    setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                    if (data?.formData) {
                        // setInitialFormData(data?.formData)
                        // setFormData(data?.formData)
                    } else {
                        // setInitialFormData({})
                    }
                })
                .catch(() => showSnackbar().failure('Something went wrong'))
                .finally(() => setIsLoading(false));
        }

    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild && selectedChild?.parentId) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const submitThisForm = () => {
        const postData = {
            formName,
            formTitle: 'Summer parent provider agreement',
            userId: selectedChild?.parentId,
            childId,
            formData,
            isParentSigned: false,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: false,
                isProviderSigned: true,
                parentSignature: '',
                providerSignature: signature,
                providerSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        } else {
            showSnackbar().warning("Please fill the required fields")
        }
    };

    /**
     * On Next button click
     */
    const onNextClick = () => {
        if (currentStep === 4) {
            submitThisForm()
        } else {
            const newStep = currentStep + 1;
            setCurrentStep(newStep);
            stepRef.current.scrollIntoView()
        }
    }
    /**
         * On Next button click
         */
    const onPrevStepClick = () => {
        if (currentStep > 1) {
            const newStep = currentStep - 1;
            setCurrentStep(newStep);
            stepRef.current.scrollIntoView()
        }
    }

    const steps = [
        'Parent',
        'Provider',
        'Children',
        'Provider Certification',
    ];

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectAccreditedArr = (selectedItem, checked) => {
        let alreadySelectedItems = accreditedArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setAccreditedArr(alreadySelectedItems);
        setFormValue({
            questionId: 'c4k_summerPPAgreement_sectionTwo_form_Key_QN_2',
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: 'c4k_summerPPAgreement_sectionTwo_form_Key_QN_2',
            type: 'Checkbox'
        })
    }

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>

                <>

                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div ref={stepRef} className='info-head f-wrp'>                            
                            <h2>{translateThis('Summer Parent Provider agreement')}</h2>
                            <br />
                            <Box sx={{ width: '100%' }} className='stepper'>
                                <Stepper activeStep={currentStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{translateThis(label)}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <br />
                        </div>

                        {currentStep && <div style={{ display: currentStep === 1 ? 'block' : 'none' }} className='C4KParentProviderAgreementSectionOne f-wrp'>


                            <div className='info-head f-wrp'>
                                <h2>{translateThis('SECTION 1 Parent information')}</h2>
                                <p style={{ fontWeight: '400' }}> {translateThis('SECTION 1 Text')} <b>{translateThis('18')}</b>{translateThis('SECTION 1 Text2')}</p>
                            </div>
                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                <Grid item xs={12} sm={12} md={8}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_1`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_1')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_1'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_1'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_1', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_1'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_1')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_1',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_2`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_2')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_2'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_2'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_2', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_2'
                                        type='number'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_2')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_2',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_3`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_3')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_3'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_3'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_3', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_3'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_3')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_3',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_4`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_4')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_4'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_4'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_4', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_4'
                                        type='text'
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_4',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_5`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_5')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_5'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_5'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_5', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_5'
                                        type='text'
                                        placeholder='State'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_5',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_6`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_6')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_6'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_6'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_6', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_6'
                                        type='text'
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_6',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_7`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_7')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_7'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_7'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_7', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_7'
                                        type='number'
                                        placeholder='Primary Telephone Number'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_7',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionOne_form_Key_QN_8`}
                                        label={translateThis('c4k_summerPPAgreement_sectionOne_form_QN_8')}
                                        name='c4k_summerPPAgreement_sectionOne_form_QN_8'
                                        id='c4k_summerPPAgreement_sectionOne_form_QN_8'
                                        value={answerGetter('c4k_summerPPAgreement_sectionOne_form_QN_8', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionOne_form_QN_8'
                                        type='number'
                                        placeholder='Secondary Telephone Number'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionOne_form_QN_8',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form responsive-option' spacing={3} style={{ border: 'none', padding: '0' }}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionOne_form_Key_QN_9">{translateThis('c4k_summerPPAgreement_sectionOne_form_Key_QN_9')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Reason for submitting this form:"
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionOne_form_Key_QN_9', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionOne_form_Key_QN_9"
                                                name="c4k_summerPPAgreement_sectionOne_form_Key_QN_9"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionOne_form_Key_QN_9',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionOne_form_Key_QN_9',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Part of my Application or Redetermination" control={<Radio />} label={translateThis('Part of my Application or Redetermination')} />
                                                <FormControlLabel value="Reporting changes or a new provider" control={<Radio />} label={translateThis('Reporting changes or a new provider')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>}

                        {currentStep && <div style={{ display: currentStep === 2 ? 'block' : 'none' }} className='C4KParentProviderAgreementSectionTwo f-wrp'>


                            <div className='info-head f-wrp'>
                                <h2>{translateThis('SECTION 2 Applicant information head of household')}</h2>
                            </div>
                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <Stack className='panel-form box-class-form' spacing={3}>
                                        <FormControl>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_form_Key_QN_1">{translateThis('c4k_summerPPAgreement_sectionTwo_form_Key_QN_1')}</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="What type of child care provider are you?"
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_form_Key_QN_1', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_form_Key_QN_1"
                                                name="c4k_summerPPAgreement_sectionTwo_form_Key_QN_1"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_form_Key_QN_1',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_form_Key_QN_1',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Unlicensed Individual (relative)" control={<Radio />} label={translateThis("Unlicensed Individual")} />
                                                <FormControlLabel value="Licensed Family Child Care Home" control={<Radio />} label={translateThis("Licensed Family Child Care Home")} />
                                                <FormControlLabel value="Licensed Child Care Center" control={<Radio />} label={translateThis("Licensed Child Care Center")} />
                                                <FormControlLabel value="Licensed Group Child Care Home" control={<Radio />} label={translateThis("Licensed Group Child Care Home")} />
                                                <FormControlLabel value="Licensed Youth Camp" control={<Radio />} label={translateThis("Licensed Youth Camp")} />
                                                <FormControlLabel value="Exempt Youth Camp" control={<Radio />} label={translateThis("Exempt Youth Camp")} />
                                                <FormControlLabel value="Exempt Center Based Program" control={<Radio />} label={translateThis("Exempt Center Based Program")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Stack className='panel-form box-class-form' spacing={3}>
                                        <FormControl>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_form_Key_QN_2">{translateThis('c4k_summerPPAgreement_sectionTwo_form_Key_QN_2')}</FormLabel>
                                            <FormGroup
                                                aria-labelledby="Are you accredited by any of the following? (check if yes)"
                                                // defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_form_Key_QN_2', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_form_Key_QN_2"
                                                name="c4k_summerPPAgreement_sectionTwo_form_Key_QN_2"
                                                onChange={(e) => {
                                                    onSelectAccreditedArr(e.target.value, e.target.checked);
                                                }}
                                            >
                                                <FormControlLabel value="National Assoc. for the Education of Young Children (NAEYC)" control={<Checkbox defaultChecked={answerGetter('c4k_summerPPAgreement_sectionTwo_form_Key_QN_2', initialFormData)?.rawAnswer.includes('National Assoc. for the Education of Young Children (NAEYC)')} />} label={translateThis("NAEYC")} />
                                                <FormControlLabel value="Council on Accreditation (COA)" control={<Checkbox defaultChecked={answerGetter('c4k_summerPPAgreement_sectionTwo_form_Key_QN_2', initialFormData)?.rawAnswer.includes('Council on Accreditation (COA)')} />} label={translateThis("COA")} />
                                                <FormControlLabel value="New England Assoc. of Schools and Colleges (NEASC)" control={<Checkbox defaultChecked={answerGetter('c4k_summerPPAgreement_sectionTwo_form_Key_QN_2', initialFormData)?.rawAnswer.includes('New England Assoc. of Schools and Colleges (NEASC)')} />} label={translateThis("NEASC")} />
                                                <FormControlLabel value="National Assoc. for Family Child Care (NAFCC)" control={<Checkbox defaultChecked={answerGetter('c4k_summerPPAgreement_sectionTwo_form_Key_QN_2', initialFormData)?.rawAnswer.includes('National Assoc. for Family Child Care (NAFCC)')} />} label={translateThis("NAFCC")} />
                                            </FormGroup>

                                        </FormControl>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <div className='info-head f-wrp'>
                                <br />
                                <h2>{translateThis("SECTION 2A Child care provider information")}</h2>
                            </div>

                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_1`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_1')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_1'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_1'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_1', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_1'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_1')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_1',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className='mob-display-none'>
                                    <Divider style={{ display: 'none' }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_2`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_2')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_2'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_2'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_2', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_2'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_2')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_2',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_3`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_3')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_3'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_3'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_3', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_3'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_3')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_3',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_4`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_4')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_4'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_4'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_4', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_4'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_4')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_4',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_5`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_5')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_5'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_5'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_5', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_5'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_5')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_5',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_6`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_6')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_6'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_6'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_6', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_6'
                                        type='text'
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_6',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_7`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_7')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_7'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_7'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_7', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_7'
                                        type='text'
                                        placeholder='State'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_7',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_8`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_8')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_8'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_8'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_8', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_8'
                                        type='text'
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_8',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_9`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_9')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_9'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_9'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_9', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_9'
                                        type='number'
                                        placeholder='Telephone Number'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_9',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_10', initialFormData)?.answer ?
                                                    dayjs(answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_10', initialFormData)?.answer) :
                                                    ''
                                                }
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_10',
                                                        answer: dayjs(v).format(DATE_FORMAT),
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_10',
                                                        type: 'text'
                                                    })
                                                }
                                                }
                                                label={translateThis("Date of Birth Family Home Providers Only")} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_11`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_11')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_11'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_11'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_11', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_11'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_11')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_11',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_12`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_12')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_12'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_12'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_12', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_12'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_12')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_12',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControl className='inline-options'>
                                        <FormLabel style={{ whiteSpace: 'nowrap', transform: 'none', fontWeight: '400' }} className='main-head' id="c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13">{translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13')}</FormLabel>

                                        <FormGroup
                                            aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13')}
                                            defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13', initialFormData)?.answer}
                                            id="c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13"
                                            name="c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13',
                                                    answer: e.target.value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_13',
                                                    type: 'Checkbox'
                                                })
                                            }}>
                                            <FormControlLabel onChange={() => console.log('checked')} required control={<Checkbox />} label={
                                                <span>{translateThis('I understand I must complete')} <Link style={{ color: '#FF3D00' }} onClick={() => window.open('https://www.ctcare4kids.com', '_blank')}> &nbsp;www.ctcare4kids.com</Link></span>
                                            } />
                                        </FormGroup>

                                    </FormControl>



                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='info-txt'>
                                        <p>{translateThis("Please list the address you would like notices")}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_14`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_14')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_14'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_14'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_14', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_14'
                                        type='text'
                                        placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_14')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_14',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2} style={{ paddingTop: '0' }}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_15`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_15')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_15'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_15'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_15', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_15'
                                        type='text'
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_15',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2} style={{ paddingTop: '0' }}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_16`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_16')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_16'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_16'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_16', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_16'
                                        type='text'
                                        placeholder='State'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_16',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2} style={{ paddingTop: '0' }}>
                                    <TextInput
                                        key={`c4k_summerPPAgreement_sectionTwo_2A_form_Key_QN_17`}
                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2A_form_QN_17')}
                                        name='c4k_summerPPAgreement_sectionTwo_2A_form_QN_17'
                                        id='c4k_summerPPAgreement_sectionTwo_2A_form_QN_17'
                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2A_form_QN_17', initialFormData)?.answer}
                                        translationKey='c4k_summerPPAgreement_sectionTwo_2A_form_QN_17'
                                        type='text'
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2A_form_QN_17',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                            </Grid>

                            <div className='info-head f-wrp'>
                                <br />
                                <h2>{translateThis("Unlicensed relative child care providers")}</h2>
                                <p style={{ fontWeight: '400' }}><b>{translateThis("You must be related to the child by blood")} </b>{translateThis("This means the child is your grandchild")}</p>
                            </div>
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={6}>

                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_1`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_1')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_1'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_1'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_1', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_1'
                                            type='text'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_1')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_1',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_2', initialFormData)?.answer ?
                                                        dayjs(answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_2', initialFormData)?.answer) :
                                                        ''
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_2',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_2',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label="Date of Birth" />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormControl>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_3" style={{ paddingBottom: '0' }}>{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_3')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Gender:"
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_3', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_3"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_3"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_3',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_3',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="F (Female)" control={<Radio />} label={translateThis("F Female")} />
                                                <FormControlLabel value="M (Male)" control={<Radio />} label={translateThis("M Male")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_4`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_4')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_4'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_4'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_4', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_4'
                                            type='text'
                                            placeholder='Address where child care is provided'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_4',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_5`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_5')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_5'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_5'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_5', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_5'
                                            type='text'
                                            placeholder='City'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_5',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_6`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_6')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_6'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_6'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_6', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_6'
                                            type='text'
                                            placeholder='State'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_6',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_7`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_7')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_7'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_7'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_7', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_7'
                                            type='text'
                                            placeholder='Zipcode'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_7',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_8`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_8')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_8'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_8'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_8', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_8'
                                            type='number'
                                            placeholder='Telephone Number'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_8',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_9`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_9')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_9'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_9'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_9', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_9'
                                            type='text'
                                            placeholder='C4K Provider ID'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_9',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormGroup>
                                            <FormControlLabel onChange={() => console.log('checked')} required control={<Checkbox />} label={
                                                <span>{translateThis("I understand I must complete")}<Link style={{ color: '#FF3D00' }} onClick={() => window.open('https://www.ctcare4kids.com', '_blank')}> &nbsp;www.ctcare4kids.com</Link></span>
                                            } />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {(answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_11`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_11')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_11'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_11'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_11', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_11'
                                            type='text'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_11')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_11',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_12`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_12')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_12'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_12'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_12', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_12'
                                            type='text'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_12')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_12',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_10', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_13`}
                                            label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_13')}
                                            name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_13'
                                            id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_13'
                                            value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_13', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_13'
                                            type='number'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_13')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_13',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-txt'>
                                            <p>{translateThis("If yes list your work schedule at your other job in the table below")}</p>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} style={{ paddingTop: '0' }}>
                                        <div className='info-head f-wrp'>
                                            <h3>{translateThis("Use this table to list the hours and days you normally work your other job")}</h3>
                                            <br />
                                        </div>
                                        <table className='Weekly-schedule-table' style={{ maxWidth: '500px' }}>
                                            <tbody>
                                                {["Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => <tr key={day}>
                                                    <td style={{ paddingRight: '15px' }}><span>{translateThis(day)}</span></td>
                                                    <td style={{ paddingRight: '15px' }}>
                                                        <TimeInput
                                                            defaultValue={dayjs(answerGetter(`weekly_schedule_three_${day}_from`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                            label='From'
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: `weekly_schedule_three_${day}_from`,
                                                                    answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                    rawAnswer: dayjs(v),
                                                                    translationKey: `weekly_schedule_three_${day}_from`,
                                                                    type: 'text'
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ paddingRight: '15px' }}>
                                                        <TimeInput
                                                            label='To'
                                                            defaultValue={dayjs(answerGetter(`weekly_schedule_three_${day}_to`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: `weekly_schedule_three_${day}_to`,
                                                                    answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                    translationKey: `weekly_schedule_three_${day}_to`,
                                                                    type: 'text'
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>)}

                                            </tbody>
                                        </table>
                                    </Grid>


                                </Grid>
                            </Stack>

                            <br />
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl style={{ maxWidth: '650px' }}>
                                            <FormLabel className='main-head' id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_14">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_14')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_14')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_14', initialFormData?.formData)?.answer}
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_QN_14"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_14',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_14',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >

                                                <FormControlLabel value="Child’s home" control={<Radio />} label={translateThis("Child home")} />
                                                <FormControlLabel value="Provider’s home" control={<Radio />} label={translateThis("Provider home")} />
                                                <FormControlLabel value="Other" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <span>{translateThis("Other")}</span>
                                                    {(answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_14', formData)?.answer === "Other") && <TextInput
                                                        key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_14_1`}
                                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_14_1')}
                                                        name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_14_1'
                                                        id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_14_1'
                                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_14_1', initialFormData?.formData)?.answer}
                                                        translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_14_1'
                                                        type='text'
                                                        required
                                                        placeholder='Type here'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_14_1',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />}
                                                </div>
                                                } />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', minWidth: '200px' }}>
                                                    <span>{translateThis("No")}</span>
                                                    {(answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15', formData)?.answer === "Yes") && <TextInput
                                                        key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_15_1`}
                                                        label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_15_1')}
                                                        name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_15_1'
                                                        id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_15_1'
                                                        value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_15_1', initialFormData?.formData)?.answer}
                                                        translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_15_1'
                                                        type='number'
                                                        required
                                                        placeholder='Type here'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_15_1',
                                                                type: 'number'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />}
                                                </div>
                                                } />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_16',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_17',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_18">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_18')}</FormLabel>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_18_1`}
                                                label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_18_1')}
                                                name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_18_1'
                                                id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_18_1'
                                                value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_18_1', initialFormData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_18_1'
                                                type='text'
                                                placeholder='Number of children'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_18_1',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_19">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_19')}</FormLabel>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_19_1`}
                                                label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_19_1')}
                                                name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_19_1'
                                                id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_19_1'
                                                value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_19_1', initialFormData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_19_1'
                                                type='text'
                                                placeholder='Number of children'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_19_1',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20" style={{ whiteSpace: 'pre-line' }}>{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_20',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_21',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_22">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_22')}</FormLabel>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_22_1`}
                                                label={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_QN_22_1')}
                                                name='c4k_summerPPAgreement_sectionTwo_2B_form_QN_22_1'
                                                id='c4k_summerPPAgreement_sectionTwo_2B_form_QN_22_1'
                                                value={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_QN_22_1', initialFormData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionTwo_2B_form_QN_22_1'
                                                type='text'
                                                placeholder='Describe'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_QN_22_1',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23">{translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23"
                                                name="c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionTwo_2B_form_Key_QN_23',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className="parents-rights-sec">
                                            <ul>
                                                <li>{translateThis("PP_Agreement_section2BList_1")} <b>{translateThis("PP_Agreement_section2BList_1_1")}</b></li>
                                                <li>{translateThis("PP_Agreement_section2BList_2")}</li>
                                                <li>{translateThis("PP_Agreement_section2BList_3")}</li>
                                                <li>{translateThis("PP_Agreement_section2BList_4")}</li>
                                                <li>{translateThis("PP_Agreement_section2BList_5")}</li>
                                                <li>{translateThis("PP_Agreement_section2BList_6")}</li>
                                            </ul>

                                        </div>
                                    </Grid>

                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='info-txt' alignItems={'center'} spacing={3}>
                                <p style={{ textAlign: 'center' }}><b>{translateThis("For a complete crime list please visit")} <Link style={{ color: '#FF3D00' }} onClick={() => window.open('https://www.ctcare4kids.com', '_blank')}> &nbsp;www.ctcare4kids.com</Link> < br />{translateThis("NOTE All Unlicensed Relative Providers are subject to child")}</b></p>
                            </Stack>

                        </div>}

                        {currentStep && <div style={{ display: currentStep === 3 ? 'block' : 'none' }} className='C4KSummerPPAgreementSectionThree f-wrp'>


                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 3 Children in Care")}</h2>
                                <p><b>{translateThis("Complete for each child needing Care 4 Kids assistance")}</b> {translateThis("If there are more than 3 children in your care")} <Link style={{ color: 'inherit', textDecoration: 'underline' }} onClick={() => window.open('https://www.ctcare4kids.com', '_blank')}> &nbsp;www.ctcare4kids.com</Link></p>
                            </div>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <div className='info-head f-wrp'>
                                    <span>{translateThis("Child")}</span>
                                </div>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_1`}
                                            label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_1')}
                                            name='c4k_summerPPAgreement_sectionThree_form_QN_1'
                                            id='c4k_summerPPAgreement_sectionThree_form_QN_1'
                                            value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_1', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionThree_form_QN_1'
                                            type='text'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_1')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_1',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_2`}
                                            label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_2')}
                                            name='c4k_summerPPAgreement_sectionThree_form_QN_2'
                                            id='c4k_summerPPAgreement_sectionThree_form_QN_2'
                                            value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_2', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionThree_form_QN_2'
                                            type='text'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_2')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_2',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_3`}
                                            label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_3')}
                                            name='c4k_summerPPAgreement_sectionThree_form_QN_3'
                                            id='c4k_summerPPAgreement_sectionThree_form_QN_3'
                                            value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_3', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionThree_form_QN_3'
                                            type='text'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_3')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_3',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_4', initialFormData)?.answer ?
                                                        dayjs(answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_4', initialFormData)?.answer) :
                                                        ''
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'c4k_summerPPAgreement_sectionThree_form_QN_4',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_4',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label={translateThis('Date of Birth')} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <DateInput
                                            key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_5`}
                                            label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_5')}
                                            value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_5', initialFormData)?.answer ?
                                                dayjs(answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_5', initialFormData)?.answer) :
                                                ''
                                            }
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'c4k_summerPPAgreement_sectionThree_form_QN_5',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_5',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_6`}
                                            label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_6')}
                                            name='c4k_summerPPAgreement_sectionThree_form_QN_6'
                                            id='c4k_summerPPAgreement_sectionThree_form_QN_6'
                                            value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_6', initialFormData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionThree_form_QN_6'
                                            type='text'
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_6')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_6',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionThree_form_Key_QN_7" style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_7')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_7')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_7', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionThree_form_Key_QN_7"
                                                name="c4k_summerPPAgreement_sectionThree_form_Key_QN_7"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_7',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_7',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', minWidth: '200px' }}>
                                                    <span>{translateThis("No")}</span>
                                                    {(answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_7', formData)?.answer === "Yes") && <TextInput
                                                        key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_7_1`}
                                                        label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_7_1')}
                                                        name='c4k_summerPPAgreement_sectionThree_form_QN_7_1'
                                                        id='c4k_summerPPAgreement_sectionThree_form_QN_7_1'
                                                        value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_7_1', initialFormData?.formData)?.answer}
                                                        translationKey='c4k_summerPPAgreement_sectionThree_form_QN_7_1'
                                                        type='text'
                                                        required
                                                        placeholder='If yes, how much is the registration fee?'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_7_1',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />}
                                                </div>
                                                } />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionThree_form_Key_QN_8" style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8')}
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_8', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionThree_form_Key_QN_8"
                                                name="c4k_summerPPAgreement_sectionThree_form_Key_QN_8"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_8',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_8',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                            </RadioGroup>
                                            {(answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_8', formData)?.answer === "Yes") && <FormLabel id="c4k_summerPPAgreement_sectionThree_form_Key_QN_8_1" style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8_1')}</FormLabel>}
                                        </FormControl>

                                        {(answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_8', formData)?.answer === "Yes") && <RadioGroup row alignItems={'center'}
                                            aria-labelledby={translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8_1')}
                                            defaultValue={answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2', initialFormData)?.answer}
                                            id="c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2"
                                            name="c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2',
                                                    answer: e.target.value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Grandparent/Great Grandparent" control={<Radio />} label={translateThis("Grandparent")} />
                                            <FormControlLabel value="Aunt/Uncle" control={<Radio />} label={translateThis("Aunt Uncle")} />
                                            <FormControlLabel value="Sibling" control={<Radio />} label={translateThis("Sibling")} />
                                            <FormControlLabel value="Other" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', minWidth: '200px' }}>
                                                <span>{translateThis("Other")}</span>
                                                {(answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2', formData)?.answer === "Other") && <TextInput
                                                    key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_8_3`}
                                                    label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_8_3')}
                                                    name='c4k_summerPPAgreement_sectionThree_form_QN_8_3'
                                                    id='c4k_summerPPAgreement_sectionThree_form_QN_8_3'
                                                    value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_8_3', initialFormData?.formData)?.answer}
                                                    translationKey='c4k_summerPPAgreement_sectionThree_form_QN_8_3'
                                                    type='text'
                                                    required
                                                    placeholder='Type here'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_8_3',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />}
                                            </div>
                                            } />
                                        </RadioGroup>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp' style={{ textAlign: 'center' }}>
                                            <span>{translateThis("CHILD CARE SCHEDULE Fill in the time the child is in your care")}</span>
                                        </div>
                                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <Stack className='panel-form box-class-form weekly-schedule-box' spacing={3}>
                                                    <div className='info-head f-wrp' style={{ textAlign: 'center' }}>
                                                        <h3>{translateThis("Schedule 1")}</h3>
                                                        <br />
                                                    </div>
                                                    <table className='Weekly-schedule-table' style={{ maxWidth: '250px' }}>
                                                        <tbody>
                                                            {["Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => <tr key={day}>
                                                                <td style={{ paddingRight: '15px' }}><span>{translateThis(day)}</span></td>
                                                                <td style={{ paddingRight: '15px' }}>
                                                                    <TimeInput
                                                                        defaultValue={dayjs(answerGetter(`weekly_schedule_one_${day}_from`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                        label='From'
                                                                        onChange={(v) => {
                                                                            setFormValue({
                                                                                questionId: `weekly_schedule_one_${day}_from`,
                                                                                answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                rawAnswer: dayjs(v),
                                                                                translationKey: `weekly_schedule_one_${day}_from`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td style={{ paddingRight: '15px' }}>
                                                                    <TimeInput
                                                                        label='To'
                                                                        defaultValue={dayjs(answerGetter(`weekly_schedule_one_${day}_to`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                        onChange={(v) => {
                                                                            setFormValue({
                                                                                questionId: `weekly_schedule_one_${day}_to`,
                                                                                answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                translationKey: `weekly_schedule_one_${day}_to`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>)}

                                                        </tbody>
                                                    </table>
                                                </Stack>



                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <Stack className='panel-form box-class-form weekly-schedule-box' spacing={3}>
                                                    <div className='info-head f-wrp' style={{ textAlign: 'center' }}>
                                                        <h3>{translateThis("Schedule 2")}</h3>
                                                        <br />
                                                    </div>
                                                    <table className='Weekly-schedule-table' style={{ maxWidth: '250px' }}>
                                                        <tbody>
                                                            {["Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => <tr key={day}>
                                                                <td style={{ paddingRight: '15px' }}><span>{translateThis(day)}</span></td>
                                                                <td style={{ paddingRight: '15px' }}>
                                                                    <TimeInput
                                                                        defaultValue={dayjs(answerGetter(`weekly_schedule_two_${day}_from`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                        label='From'
                                                                        onChange={(v) => {
                                                                            setFormValue({
                                                                                questionId: `weekly_schedule_two_${day}_from`,
                                                                                answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                rawAnswer: dayjs(v),
                                                                                translationKey: `weekly_schedule_two_${day}_from`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td style={{ paddingRight: '15px' }}>
                                                                    <TimeInput
                                                                        label='To'
                                                                        defaultValue={dayjs(answerGetter(`weekly_schedule_two_${day}_to`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                        onChange={(v) => {
                                                                            setFormValue({
                                                                                questionId: `weekly_schedule_two_${day}_to`,
                                                                                answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                translationKey: `weekly_schedule_${day}_to`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>)}

                                                        </tbody>
                                                    </table>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_summerPPAgreement_sectionThree_form_Key_QN_9" style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_9')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Is this child care schedule the same each week?"
                                                defaultValue={answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_9', initialFormData)?.answer}
                                                id="c4k_summerPPAgreement_sectionThree_form_Key_QN_9"
                                                name="c4k_summerPPAgreement_sectionThree_form_Key_QN_9"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_9',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_9',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {(answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_9', formData)?.answer === "No") && <TextInput
                                            key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_9_1`}
                                            label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_9_1')}
                                            name='c4k_summerPPAgreement_sectionThree_form_QN_9_1'
                                            id='c4k_summerPPAgreement_sectionThree_form_QN_9_1'
                                            value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_9_1', initialFormData?.formData)?.answer}
                                            translationKey='c4k_summerPPAgreement_sectionThree_form_QN_9_1'
                                            type='text'
                                            required
                                            placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_9_1')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_9_1',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />}
                                    </Grid>
                                </Grid>
                            </Stack>
                            <br />

                            {extraChildren.map((no, i) => (
                                <Stack key={`adult-key-${no}`} style={{ marginLeft: '0px', marginTop: '15px' }} className='panel-form box-class-form'>
                                    <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <h3>{translateThis("Child")} #{i + 2}</h3>
                                        </div>
                                        <IconButton
                                            onClick={() => onDeleteFormExtraSection(
                                                'c4k_summerPPAgreement_sectionOne_form_extraChildren',
                                                extraChildren,
                                                setExtraChildren,
                                                setFormValue,
                                                no
                                            )}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                    <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_1`}
                                                label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_1')}
                                                name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_1`}
                                                id={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_1`}
                                                value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_1`, initialFormData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionThree_form_QN_1'
                                                type='text'
                                                placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_1')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_1',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={1}>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_2`}
                                                label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_2')}
                                                name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_2`}
                                                id={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_2`}
                                                value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_2`, initialFormData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionThree_form_QN_2'
                                                type='text'
                                                placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_2')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_2',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4}>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_3`}
                                                label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_3')}
                                                name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_3`}
                                                id={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_3`}
                                                value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_3`, initialFormData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionThree_form_QN_3'
                                                type='text'
                                                placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_3')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_3',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_4`, initialFormData)?.answer ?
                                                            dayjs(answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_4`, initialFormData)?.answer) :
                                                            ''
                                                        }
                                                        onChange={(v) => {
                                                            setFormValue({
                                                                questionId: `child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_4`,
                                                                answer: dayjs(v).format(DATE_FORMAT),
                                                                translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_4',
                                                                type: 'text'
                                                            })
                                                        }
                                                        }
                                                        label={translateThis('Date of Birth')} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <DateInput
                                                key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_5`}
                                                label={translateThis(`c4k_summerPPAgreement_sectionThree_form_QN_5`)}
                                                value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_5`, initialFormData)?.answer ?
                                                    dayjs(answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_5`, initialFormData)?.answer) :
                                                    ''
                                                }
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: `child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_5`,
                                                        answer: dayjs(v).format(DATE_FORMAT),
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_5',
                                                        type: 'text'
                                                    })
                                                }
                                                }
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_6`}
                                                label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_6')}
                                                name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_6`}
                                                value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_6`, initialFormData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionThree_form_QN_6'
                                                type='text'
                                                placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_6')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_6',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl className='inline-options'>
                                                <FormLabel id="c4k_summerPPAgreement_sectionThree_form_Key_QN_7" style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_7')}</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby={translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_7')}
                                                    defaultValue={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_7`, initialFormData)?.answer}
                                                    name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_7`}
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: `child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_7`,
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_7',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                    <FormControlLabel value="No" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', minWidth: '200px' }}>
                                                        <span>{translateThis("No")}</span>
                                                        {(answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_7`, formData)?.answer === "Yes") && <TextInput
                                                            key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_7_1`}
                                                            label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_7_1')}
                                                            id={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_7_1`}
                                                            name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_7_1`}
                                                            value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_7_1`, initialFormData?.formData)?.answer}
                                                            translationKey='c4k_summerPPAgreement_sectionThree_form_QN_7_1'
                                                            type='text'
                                                            required
                                                            placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_7_1')}
                                                            className='custom-textfield'
                                                            getValue={(name, value) => {
                                                                setFormValue({
                                                                    questionId: name,
                                                                    answer: value,
                                                                    translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_7_1',
                                                                    type: 'text'
                                                                })
                                                            }}
                                                            useDefaultValidation
                                                        />}
                                                    </div>
                                                    } />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl className='inline-options'>
                                                <FormLabel id="c4k_summerPPAgreement_sectionThree_form_Key_QN_8" style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8')}</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby={translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8')}
                                                    defaultValue={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8`, initialFormData)?.answer}
                                                    id="c4k_summerPPAgreement_sectionThree_form_Key_QN_8"
                                                    name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8`}
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: `child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8`,
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_8',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                    <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                                </RadioGroup>
                                                {(answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8`, formData)?.answer === "Yes") && <FormLabel id={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_1`} style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8_1')}</FormLabel>}
                                            </FormControl>

                                            {(answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8`, formData)?.answer === "Yes") && <RadioGroup row alignItems={'center'}
                                                aria-labelledby={translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_8_1')}
                                                defaultValue={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_2`, initialFormData)?.answer}
                                                id={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_2`}
                                                name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_2`}
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: `child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_2`,
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_8_2',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Grandparent/Great Grandparent" control={<Radio />} label={translateThis('Grandparent')} />
                                                <FormControlLabel value="Aunt/Uncle" control={<Radio />} label={translateThis('Aunt Uncle')} />
                                                <FormControlLabel value="Sibling" control={<Radio />} label={translateThis('Sibling')} />
                                                <FormControlLabel value="Other" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', minWidth: '200px' }}>
                                                    <span>{translateThis('Other')}</span>
                                                    {(answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_2`, formData)?.answer === "Other") && <TextInput
                                                        key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_8_3`}
                                                        label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_8_3')}
                                                        name={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_3`}
                                                        id={`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_3`}
                                                        value={answerGetter(`child_${no}_c4k_summerPPAgreement_sectionThree_form_QN_8_3`, initialFormData?.formData)?.answer}
                                                        translationKey='c4k_summerPPAgreement_sectionThree_form_QN_8_3'
                                                        type='text'
                                                        required
                                                        placeholder='Type here'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_8_3',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />}
                                                </div>
                                                } />
                                            </RadioGroup>}
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className='info-head f-wrp' style={{ textAlign: 'center' }}>
                                                <span>{translateThis("CHILD CARE SCHEDULE Fill in the time the child is in your care")}</span>
                                            </div>
                                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                                    <Stack className='panel-form box-class-form weekly-schedule-box' spacing={3}>
                                                        <div className='info-head f-wrp' style={{ textAlign: 'center' }}>
                                                            <h3>{translateThis("Schedule 1")}</h3>
                                                            <br />
                                                        </div>
                                                        <table className='Weekly-schedule-table' style={{ maxWidth: '250px' }}>
                                                            <tbody>
                                                                {["Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => <tr key={day}>
                                                                    <td style={{ paddingRight: '15px' }}><span>{translateThis(day)}</span></td>
                                                                    <td style={{ paddingRight: '15px' }}>
                                                                        <TimeInput
                                                                            defaultValue={dayjs(answerGetter(`child_${no}_weekly_schedule_one_${day}_from`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                            label='From'
                                                                            onChange={(v) => {
                                                                                setFormValue({
                                                                                    questionId: `child_${no}_weekly_schedule_one_${day}_from`,
                                                                                    answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                    rawAnswer: dayjs(v),
                                                                                    translationKey: `child_${no}_weekly_schedule_one_${day}_from`,
                                                                                    type: 'text'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ paddingRight: '15px' }}>
                                                                        <TimeInput
                                                                            label='To'
                                                                            defaultValue={dayjs(answerGetter(`child_${no}_weekly_schedule_one_${day}_to`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                            onChange={(v) => {
                                                                                setFormValue({
                                                                                    questionId: `child_${no}_weekly_schedule_one_${day}_to`,
                                                                                    answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                    translationKey: `child_${no}_weekly_schedule_one_${day}_to`,
                                                                                    type: 'text'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>)}

                                                            </tbody>
                                                        </table>
                                                    </Stack>



                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                                    <Stack className='panel-form box-class-form weekly-schedule-box' spacing={3}>
                                                        <div className='info-head f-wrp' style={{ textAlign: 'center' }}>
                                                            <h3>{translateThis("Schedule 2")}</h3>
                                                            <br />
                                                        </div>
                                                        <table className='Weekly-schedule-table' style={{ maxWidth: '250px' }}>
                                                            <tbody>
                                                                {["Monday", 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => <tr key={day}>
                                                                    <td style={{ paddingRight: '15px' }}><span>{translateThis(day)}</span></td>
                                                                    <td style={{ paddingRight: '15px' }}>
                                                                        <TimeInput
                                                                            defaultValue={dayjs(answerGetter(`child_${no}_weekly_schedule_two_${day}_from`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                            label='From'
                                                                            onChange={(v) => {
                                                                                setFormValue({
                                                                                    questionId: `child_${no}_weekly_schedule_two_${day}_from`,
                                                                                    answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                    rawAnswer: dayjs(v),
                                                                                    translationKey: `child_${no}_weekly_schedule_two_${day}_from`,
                                                                                    type: 'text'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ paddingRight: '15px' }}>
                                                                        <TimeInput
                                                                            label='To'
                                                                            defaultValue={dayjs(answerGetter(`child_${no}_weekly_schedule_two_${day}_to`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                            onChange={(v) => {
                                                                                setFormValue({
                                                                                    questionId: `child_${no}_weekly_schedule_two_${day}_to`,
                                                                                    answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                                    translationKey: `child_${no}_weekly_schedule_${day}_to`,
                                                                                    type: 'text'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>)}

                                                            </tbody>
                                                        </table>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl className='inline-options'>
                                                <FormLabel id="c4k_summerPPAgreement_sectionThree_form_Key_QN_9" style={{ transform: 'none' }}>{translateThis('c4k_summerPPAgreement_sectionThree_form_Key_QN_9')}</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby="Is this child care schedule the same each week?"
                                                    defaultValue={answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_9', initialFormData)?.answer}
                                                    id="c4k_summerPPAgreement_sectionThree_form_Key_QN_9"
                                                    name="c4k_summerPPAgreement_sectionThree_form_Key_QN_9"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_9',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_summerPPAgreement_sectionThree_form_Key_QN_9',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                    <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {(answerGetter('c4k_summerPPAgreement_sectionThree_form_Key_QN_9', formData)?.answer === "No") && <TextInput
                                                key={`c4k_summerPPAgreement_sectionThree_form_Key_QN_9_1`}
                                                label={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_9_1')}
                                                name='c4k_summerPPAgreement_sectionThree_form_QN_9_1'
                                                id='c4k_summerPPAgreement_sectionThree_form_QN_9_1'
                                                value={answerGetter('c4k_summerPPAgreement_sectionThree_form_QN_9_1', initialFormData?.formData)?.answer}
                                                translationKey='c4k_summerPPAgreement_sectionThree_form_QN_9_1'
                                                type='text'
                                                required
                                                placeholder={translateThis('c4k_summerPPAgreement_sectionThree_form_QN_9_1')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionThree_form_QN_9_1',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />}
                                        </Grid>
                                    </Grid>



                                </Stack>
                            ))}

                            <br />

                            <Stack spacing={3}>
                                <Button onClick={() => onAddFormExtraSection(
                                    'c4k_summerPPAgreement_sectionOne_form_extraChildren',
                                    extraChildren,
                                    setExtraChildren,
                                    setFormValue
                                )} className='red-btn'>{translateThis("Add new Child")}</Button>
                            </Stack>
                        </div>}

                        {currentStep && <div style={{ display: currentStep === 4 ? 'block' : 'none' }} className='C4KParentProviderAgreementSectionFour f-wrp'>

                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 4 Provider certification")}</h2>
                                <br />
                            </div>

                            <div className='parents-rights-sec'>
                                <p><b>{translateThis("I certify that")}</b></p>
                                <ul>
                                    <li>{translateThis("PP_Agreement_section4BList_1")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_2")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_3")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_4")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_5")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_6")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_7")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_8")} <b>{translateThis("PP_Agreement_section4BList_8_1")}</b> {translateThis("PP_Agreement_section4BList_8_2")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_9")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_10")} <Link style={{ color: '#FF3D00' }} onClick={() => window.open('https://www.ctcare4kids.com', '_blank')}> &nbsp;www.ctcare4kids.com</Link>.</li>
                                    <li>{translateThis("PP_Agreement_section4BList_11")}</li>
                                    <li>{translateThis("PP_Agreement_section4BList_12")}</li>
                                </ul>
                                <br />
                                <div className='info-head f-wrp'>
                                    <h2>{translateThis("SECTION 5 Parent certification")}</h2>
                                    <br />
                                </div>

                                <p><b>{translateThis("I certify that")}</b> </p>

                                <ul>
                                    <li>{translateThis("PP_Agreement_section5List_1")}</li>
                                    <li>{translateThis("PP_Agreement_section5List_2")}</li>
                                    <li>{translateThis("PP_Agreement_section5List_3")}</li>
                                    <li>{translateThis("PP_Agreement_section5List_4")}</li>
                                    <li>{translateThis("PP_Agreement_section5List_6")} <Link style={{ color: '#FF3D00' }} onClick={() => window.open('https://www.ctcare4kids.com', '_blank')}> &nbsp;www.ctcare4kids.com</Link></li>
                                    <li>{translateThis("PP_Agreement_section5List_5")}</li>
                                </ul>

                            </div>

                            <Stack className='panel-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Divider style={{ display: 'none' }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Stack className='panel-form' spacing={3}>
                                            <div className='info-head f-wrp'>
                                                <span style={{ color: '#98948A' }}>{translateThis('Provider Signature')}</span>
                                            </div>
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionFour_Key_QN_providerName`}
                                                label={translateThis('c4k_summerPPAgreement_sectionFour_QN_providerName')}
                                                name='c4k_summerPPAgreement_sectionFour_QN_providerName'
                                                id='c4k_summerPPAgreement_sectionFour_QN_providerName'
                                                translationKey='c4k_summerPPAgreement_sectionFour_QN_providerName'
                                                value={answerGetter('c4k_summerPPAgreement_sectionFour_QN_providerName', initialFormData?.formData)?.answer}
                                                type='text'
                                                required
                                                placeholder={translateThis('c4k_summerPPAgreement_sectionFour_QN_providerName')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_summerPPAgreement_sectionFour_QN_providerName',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                            <TextInput
                                                key={`c4k_summerPPAgreement_sectionFour_Key_QN_providerSign`}
                                                label={translateThis('c4k_summerPPAgreement_sectionFour_QN_providerSign')}
                                                name='c4k_summerPPAgreement_sectionFour_QN_providerSign'
                                                id='c4k_summerPPAgreement_sectionFour_QN_providerSign'
                                                translationKey='c4k_summerPPAgreement_sectionFour_QN_providerSign'
                                                value={answerGetter('c4k_summerPPAgreement_sectionFour_QN_providerSign', initialFormData?.formData)?.answer}
                                                type='text'
                                                required
                                                placeholder='Type your name'
                                                className='custom-textfield'
                                                getValue={(_, value) => {
                                                    setSignature(value)
                                                }}
                                                useDefaultValidation
                                            />
                                            <FormGroup className='check-label'>
                                                <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                                    <span>{translateThis('I')}, {signature && <b>{signature}, </b>} {translateThis('affirm that the information provided is true and complete')}</span>
                                                } />
                                            </FormGroup>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>}
                        <br />
                        <Divider />
                        <br />
                    </Stack>
                </>

                <br />

                <div className='button-sec mob-btn-grid f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='cancel-btn'>{translateThis('cancel')}</Button>
                    </div>
                    <div className='ryt-btn-sec pln-btn-style'>
                        <Button
                            onClick={onPrevStepClick}
                            disabled={currentStep === 1}
                            className='pln-btn'
                        >{translateThis('Previous')}</Button>

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={currentStep === 4 && !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onNextClick}>
                            {currentStep === 4 ? translateThis('Submit') : translateThis('Next')}
                        </LoadingButton>

                    </div>
                </div>
            </div> : null}
        </>
    );
}

SummerPPAgreement.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func,
}

export default SummerPPAgreement;