import React from "react";

import './classes.scss';
import { translateThis } from "../../../../helpers/language.helper";
import AppIcons from "../images/icons";


function Classes() {

  const classesItems = [
    {
      image: AppIcons.classes_1,
      type: translateThis('Infant'),
      ageRange: translateThis('3 - 12 months'),
      days: <p><b>{translateThis('Days')}:</b> {translateThis('5 days/week')}</p>,
      hours: <p><b>{translateThis('Hours')}:</b> {translateThis('7:30am - 5:30pm')}</p>,
      price: <p className="price"><b>{translateThis('$425')} </b> {translateThis('weekly')}</p>,
      title: translateThis('Activities'),
      content: <><p>{translateThis('classesText1')}</p>
        <p>{translateThis('classesText2')}</p>
        <p>{translateThis('classesText3')}</p>
        <p>{translateThis('classesText4')}</p>
        <p>{translateThis('classesText5')}</p></>,
    },
    {
      image: AppIcons.classes_2,
      type: translateThis('Toddler'),
      ageRange: translateThis('1 - 2 years'),
      days: <p><b>{translateThis('Days')}:</b> {translateThis('5 days/week')}</p>,
      hours: <p><b>{translateThis('Hours')}:</b> {translateThis('7:30am - 5:30pm')}</p>,
      price: <p className="price"><b>{translateThis('$425')} </b> {translateThis('weekly')}</p>,
      title: translateThis('Activities'),
      content: <><p>{translateThis('classesText6')}</p>
        <p>{translateThis('classesText7')}</p>
        <p>{translateThis('classesText8')}</p>
        <p>{translateThis('classesText9')}</p>
        <p>{translateThis('classesText10')}</p></>,
    },
    {
      image: AppIcons.classes_3,
      type: translateThis('Toddler'),
      ageRange: translateThis('2 - 3 years'),
      days: <p><b>{translateThis('Days')}:</b> {translateThis('5 days/week')}</p>,
      hours: <p><b>{translateThis('Hours')}:</b> {translateThis('7:30am - 5:30pm')}</p>,
      price: <p className="price"><b>{translateThis('$425')} </b> {translateThis('weekly')}</p>,
      title: translateThis('Activities'),
      content: <><p>{translateThis('classesText11')}</p>
        <p>{translateThis('classesText12')}</p>
        <p>{translateThis('classesText13')}</p>
        <p>{translateThis('classesText14')}</p>
        <p>{translateThis('classesText15')}</p></>,
    },
  ];



  return (

    <div className="argentina-classes-wrp f-wrp">
      {classesItems.map((element, key) => (
        <div className="row" key={key}>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="each-classes-sec f-wrp ">
              <span className='classes-icon'><img src={element.image} alt={element.title} /></span>
              <div className="class-basic-sec">
                <p style={{margin: '0', padding: '0'}}>{element.type}</p>
                <h1>{element.ageRange}</h1>
                {element.days}
                {element.hours}
                {element.price}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="class-detail-sec">

              <b>{element.title}: </b>
              {element.content}

            </div>
          </div>
        </div>
      ))}
    </div>

  );
}

export default Classes;