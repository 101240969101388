import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../images/icons/svgIcons';
// import SVGIcons from '../images/icons/index';

import './Services.scss';
import { translateThis } from "../../../../helpers/language.helper";


function KathleenServices() {


  const aboutItems = [
    {
      className: 'food-program',
      icon: SVGIcons.FoodProgramIcon(),
      title: translateThis('Food program'),
      description: translateThis('Food program text'),
    },
    {
      className: 'Medical-aid',
      icon: SVGIcons.MedicalAidIcon(),
      title: translateThis('Medical aid'),
      description: translateThis('Medical aid text'),
    },
    {
      className: 'playground',
      icon: SVGIcons.PlaygroundIcon(),
      title: translateThis('Playground'),
      description: translateThis('Playground text'),
    },
    {
      className: 'subsidizedCare',
      icon: SVGIcons.SubsidizedCareIcon(),
      title: translateThis('Subsidized care'),
      description: translateThis('Subsidized care text'),
    },
  ];

  return (
    <>
      <div className="Francisca-Services-wrp f-wrp">

        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={4}
          className='content-sec-mob'
          navigation
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1275: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}

        >
          {aboutItems.map((element, key) => (
            <SwiperSlide key={key}>
              <div className={`${element.className} each-service-sec f-wrp `}>
                <span className='service-icon'>{element.icon}</span>
                <div className="service-con-sec f-wrp">
                  <h2>{element.title}</h2>
                  <p>{element.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default KathleenServices;