import React from "react";

import SVGIcons from '../images/icons/svgIcons';
import { translateThis } from '../../../../helpers/language.helper';
import './programs.scss';


function Programs() {

  const programItems1 = [
    {
      icon: SVGIcons.ProgramIcon1(),
      title: 'Colors, Crafts & Scissors',
    },
    {
      icon: SVGIcons.ProgramIcon2(),
      title: 'Names, Phonics & Reading',
    },
    {
      icon: SVGIcons.ProgramIcon3(),
      title: 'Music, Dance & Singing',
    },
  ];
  const programItems2 = [
    {
      icon: SVGIcons.ProgramIcon4(),
      title: 'Outside play & Ball pit',
    },
    {
      icon: SVGIcons.ProgramIcon5(),
      title: 'Circle time & Story time',
    },
    {
      icon: SVGIcons.ProgramIcon6(),
      title: 'Theme of the month',
    },
  ];

  const programItems3 = [
    {
      icon: SVGIcons.ProgramIcon7(),
      title: 'AB Patterns & Shapes',
    },
    {
      icon: SVGIcons.ProgramIcon8(),
      title: 'Flash cards & Sight words',
    },
    {
      icon: SVGIcons.ProgramIcon9(),
      title: 'Calendar & Holidays',
    },
  ];

  const programItems4 = [
    {
      icon: SVGIcons.ProgramIcon10(),
      title: 'Wash hands & Toilet training',
    },
    {
      icon: SVGIcons.ProgramIcon11(),
      title: 'Tidy after self',
    },
    {
      icon: SVGIcons.ProgramIcon12(),
      title: 'Numbers and math ',
    },
  ];

  return (
    <>
      <div className="Kathleen-program-wrp f-wrp">

        <div className="row">
          
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec orange f-wrp ">
                <ul>
                  {programItems1.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec green f-wrp ">
                <ul>
                  {programItems2.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec yellow f-wrp ">
                <ul>
                  {programItems3.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="each-program-sec turquoise f-wrp ">
                <ul>
                  {programItems4.map((element, key) => (
                    <li key={key}>
                      <span className='program-icon'>{element.icon}</span>
                      <p>{translateThis(element.title)}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          

        </div>


      </div>
    </>
  );
}

export default Programs;