import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Delete } from '@mui/icons-material';

// @mui
import { Stack, Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_EXTENDED_FORMAT, DATE_TIME_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';


const WrittenPermission = (props) => {
    const formName = 'written-permission';
    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);
    const [responsibleAdults, setResponsibleAdults] = useState([]);
    const [emergencyResponsible, setEmergencyResponsible] = useState([]);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({})
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && isSigned && childId && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName,
            formTitle: 'Written permission',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };

    /**
     * When adding a new adult
     */
    const onAddNewItem = (name, val, setVal) => {
        const lastAdded = val[val.length - 1] || 0;
        const currentlyAdded = val;
        currentlyAdded.push(lastAdded + 1);
        setVal([...currentlyAdded]);
        setFormValue({
            questionId: name,
            answer: currentlyAdded.length,
            rawAnswer: currentlyAdded,
            translationKey: name,
            type: 'text'
        })
    }

    /**
     * When removing a new adult
     */
    const onDeleteItem = (name, val, setVal, deleted) => {
        const currentlyAdded = val.filter((no) => no !== deleted);
        setVal([...currentlyAdded]);
        setFormValue({
            questionId: name,
            answer: currentlyAdded.length,
            rawAnswer: currentlyAdded,
            translationKey: name,
            type: 'text'
        })
    }



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>
                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Written Permission")}</h2>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Child")}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9}>
                                {(selectedChild) && <TextInput
                                    key={`written_permission_form_Key_name`}
                                    label={translateThis('written_permission_form_question_name')}
                                    name='written_permission_form_question_name'
                                    id='written_permission_form_question_name'
                                    value={answerGetter('written_permission_form_question_name', initialFormData)?.answer || `${selectedChild?.firstName || ''} ${selectedChild?.lastName || ''}`}
                                    translationKey='written_permission_form_question_name'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_name',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />}
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        {(selectedChild && selectedChild.birthday) && <DatePicker
                                            value={answerGetter('written_permission_form_Key_birthday', initialFormData)?.answer ?
                                                dayjs(answerGetter('written_permission_form_Key_birthday', initialFormData)?.answer) :
                                                dayjs(selectedChild.birthday)
                                            }
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: 'written_permission_form_Key_birthday',
                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                    translationKey: 'written_permission_form_birthday',
                                                    type: 'text'
                                                })
                                            }
                                            }
                                            label={translateThis("Birthday")}
                                        />}
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_address`}
                                    label={translateThis('written_permission_form_question_address')}
                                    name='written_permission_form_question_address'
                                    id='written_permission_form_question_address'
                                    value={answerGetter('written_permission_form_question_address', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_address'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_address',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_city`}
                                    label={translateThis('written_permission_form_question_city')}
                                    name='written_permission_form_question_city'
                                    id='written_permission_form_question_city'
                                    value={answerGetter('written_permission_form_question_city', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_city'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_city',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_zipcode`}
                                    label={translateThis('written_permission_form_question_zipcode')}
                                    name='written_permission_form_question_zipcode'
                                    id='written_permission_form_question_zipcode'
                                    value={answerGetter('written_permission_form_question_zipcode', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_zipcode'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_zipcode',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Responsible adult")} #1 <i> {translateThis("Persons permitted to remove the child from the child care home on behalf of parent")}</i></h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianName`}
                                    label={translateThis('written_permission_form_question_guardianName')}
                                    name='written_permission_form_question_guardianName'
                                    id='written_permission_form_question_guardianName'
                                    value={answerGetter('written_permission_form_question_guardianName', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianName'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianPhone`}
                                    label={translateThis('written_permission_form_question_guardianPhone')}
                                    name='written_permission_form_question_guardianPhone'
                                    id='written_permission_form_question_guardianPhone'
                                    value={answerGetter('written_permission_form_question_guardianPhone', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianPhone'
                                    type='number'
                                    required
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianPhone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianRelationship`}
                                    label={translateThis('written_permission_form_question_guardianRelationship')}
                                    name='written_permission_form_question_guardianRelationship'
                                    id='written_permission_form_question_guardianRelationship'
                                    value={answerGetter('written_permission_form_question_guardianRelationship', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianRelationship'
                                    type='text'
                                    required
                                    placeholder='Relationship'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianRelationship',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>



                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianAddress`}
                                    label={translateThis('written_permission_form_question_guardianAddress')}
                                    name='written_permission_form_question_guardianAddress'
                                    id='written_permission_form_question_guardianAddress'
                                    value={answerGetter('written_permission_form_question_guardianAddress', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianAddress'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianAddress',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianCity`}
                                    label={translateThis('written_permission_form_question_guardianCity')}
                                    name='written_permission_form_question_guardianCity'
                                    id='written_permission_form_question_guardianCity'
                                    value={answerGetter('written_permission_form_question_guardianCity', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianCity'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianCity',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianZipcode`}
                                    label={translateThis('written_permission_form_question_guardianZipcode')}
                                    name='written_permission_form_question_guardianZipcode'
                                    id='written_permission_form_question_guardianZipcode'
                                    value={answerGetter('written_permission_form_question_guardianZipcode', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianZipcode'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianZipcode',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            {responsibleAdults.map((no, i) => (
                                <Grid key={`adult-key-${no}`} container spacing={3} style={{ marginLeft: '0px', marginTop: '0px' }}>
                                    <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <h3>{translateThis("Responsible adult")} #{i + 2}</h3>
                                        </div>
                                        <div>
                                            <IconButton
                                                onClick={() => onDeleteItem(
                                                    'written_permission_form_responsible_adults',
                                                    responsibleAdults,
                                                    setResponsibleAdults,
                                                    no
                                                )}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianName`}
                                            label={translateThis('written_permission_form_question_guardianName')}
                                            name={`child_enrollment_form_question_adult_${no}_guardianName`}
                                            id='written_permission_form_question_guardianName'
                                            value={answerGetter(`child_enrollment_form_question_adult_${no}_guardianName`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianName'
                                            type='text'
                                            required
                                            placeholder='Name'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianName',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianPhone`}
                                            label={translateThis('written_permission_form_question_guardianPhone')}
                                            name={`child_enrollment_form_question_adult_${no}_guardianPhone`}
                                            id='written_permission_form_question_guardianPhone'
                                            value={answerGetter(`child_enrollment_form_question_adult_${no}_guardianPhone`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianPhone'
                                            type='number'
                                            required
                                            placeholder='Phone'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianPhone',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianRelationship`}
                                            label={translateThis('written_permission_form_question_guardianRelationship')}
                                            name={`child_enrollment_form_question_adult_${no}_guardianRelationship`}
                                            id='written_permission_form_question_guardianRelationship'
                                            value={answerGetter(`child_enrollment_form_question_adult_${no}_guardianRelationship`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianRelationship'
                                            type='text'
                                            required
                                            placeholder='Relationship'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianRelationship',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianAddress`}
                                            label={translateThis('written_permission_form_question_guardianAddress')}
                                            name={`child_enrollment_form_question_adult_${no}_guardianAddress`}
                                            id='written_permission_form_question_guardianAddress'
                                            value={answerGetter(`child_enrollment_form_question_adult_${no}_guardianAddress`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianAddress'
                                            type='text'
                                            required
                                            placeholder='Address'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianAddress',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianCity`}
                                            label={translateThis('written_permission_form_question_guardianCity')}
                                            name={`child_enrollment_form_question_adult_${no}_guardianCity`}
                                            id='written_permission_form_question_guardianCity'
                                            value={answerGetter(`child_enrollment_form_question_adult_${no}_guardianCity`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianCity'
                                            type='text'
                                            required
                                            placeholder='City'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianCity',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianZipcode`}
                                            label={translateThis('written_permission_form_question_guardianZipcode')}
                                            name={`child_enrollment_form_question_adult_${no}_guardianZipCode`}
                                            id='written_permission_form_question_guardianZipcode'
                                            value={answerGetter(`child_enrollment_form_question_adult_${no}_guardianZipCode`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianZipcode'
                                            type='text'
                                            required
                                            placeholder='Zipcode'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianZipcode',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                </Grid>
                            ))}


                            <Grid item xs={12} sm={12} md={12}>
                                <br />
                                <Button onClick={() => onAddNewItem(
                                    'written_permission_form_responsible_adults',
                                    responsibleAdults,
                                    setResponsibleAdults
                                )} className='red-btn'>{translateThis("Add new Responsible adult +")}</Button>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Responsible adult")} #1 <i>{translateThis("In an emergency adults to be contacted if parent cannot be reached and to whom the child can be released")}</i></h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianName_2`}
                                    label={translateThis('written_permission_form_question_guardianName_2')}
                                    name='written_permission_form_question_guardianName_2'
                                    id='written_permission_form_question_guardianName_2'
                                    value={answerGetter('written_permission_form_question_guardianName_2', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianName_2'
                                    type='text'
                                    required
                                    placeholder={translateThis('written_permission_form_question_guardianName_2')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianName_2',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianPhone_2`}
                                    label={translateThis('written_permission_form_question_guardianPhone_2')}
                                    name='written_permission_form_question_guardianPhone_2'
                                    id='written_permission_form_question_guardianPhone_2'
                                    value={answerGetter('written_permission_form_question_guardianPhone_2', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianPhone_2'
                                    type='number'
                                    required
                                    placeholder={translateThis('written_permission_form_question_guardianPhone_2')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianPhone_2',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianRelationship_2`}
                                    label={translateThis('written_permission_form_question_guardianRelationship_2')}
                                    name='written_permission_form_question_guardianRelationship_2'
                                    id='written_permission_form_question_guardianRelationship_2'
                                    value={answerGetter('written_permission_form_question_guardianRelationship_2', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianRelationship_2'
                                    type='text'
                                    required
                                    placeholder='Relationship'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianRelationship_2',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianAddress_2`}
                                    label={translateThis('written_permission_form_question_guardianAddress_2')}
                                    name='written_permission_form_question_guardianAddress_2'
                                    id='written_permission_form_question_guardianAddress_2'
                                    value={answerGetter('written_permission_form_question_guardianAddress_2', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianAddress_2'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianAddress_2',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianCity_2`}
                                    label={translateThis('written_permission_form_question_guardianCity_2')}
                                    name='written_permission_form_question_guardianCity_2'
                                    id='written_permission_form_question_guardianCity_2'
                                    value={answerGetter('written_permission_form_question_guardianCity_2', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianCity_2'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianCity_2',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_guardianZipcode_2`}
                                    label={translateThis('written_permission_form_question_guardianZipcode_2')}
                                    name='written_permission_form_question_guardianZipcode_2'
                                    id='written_permission_form_question_guardianZipcode_2'
                                    value={answerGetter('written_permission_form_question_guardianZipcode_2', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_guardianZipcode_2'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_guardianZipcode_2',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            {emergencyResponsible.map((no, i) => (
                                <Grid key={`adult-key-${no}`} container spacing={3} style={{ marginLeft: '0px', marginTop: '0px' }}>
                                    <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <h3>Responsible adult #{i + 2}</h3>
                                        </div>
                                        <div>
                                            <IconButton
                                                onClick={() => onDeleteItem(
                                                    'written_permission_form_emergency_responsible',
                                                    emergencyResponsible,
                                                    setEmergencyResponsible,
                                                    no
                                                )}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianName`}
                                            label={translateThis('written_permission_form_question_guardianName')}
                                            name={`child_enrollment_form_question_emergency_responsible_${no}_guardianName`}
                                            id='written_permission_form_question_guardianName'
                                            value={answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianName`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianName'
                                            type='text'
                                            required
                                            placeholder='Name'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianName',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianPhone`}
                                            label={translateThis('written_permission_form_question_guardianPhone')}
                                            name={`child_enrollment_form_question_emergency_responsible_${no}_guardianPhone`}
                                            id='written_permission_form_question_guardianPhone'
                                            value={answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianPhone`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianPhone'
                                            type='number'
                                            required
                                            placeholder='Phone'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianPhone',
                                                    type: 'number'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianRelationship`}
                                            label={translateThis('written_permission_form_question_guardianRelationship')}
                                            name={`child_enrollment_form_question_emergency_responsible_${no}_guardianRelationship`}
                                            id='written_permission_form_question_guardianRelationship'
                                            value={answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianRelationship`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianRelationship'
                                            type='text'
                                            required
                                            placeholder='Relationship'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianRelationship',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianAddress`}
                                            label={translateThis('written_permission_form_question_guardianAddress')}
                                            name={`child_enrollment_form_question_emergency_responsible_${no}_guardianAddress`}
                                            id='written_permission_form_question_guardianAddress'
                                            value={answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianAddress`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianAddress'
                                            type='text'
                                            required
                                            placeholder='Address'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianAddress',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianCity`}
                                            label={translateThis('written_permission_form_question_guardianCity')}
                                            name={`child_enrollment_form_question_emergency_responsible_${no}_guardianCity`}
                                            id='written_permission_form_question_guardianCity'
                                            value={answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianCity`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianCity'
                                            type='text'
                                            required
                                            placeholder='City'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianCity',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TextInput
                                            key={`written_permission_form_Key_guardianZipcode`}
                                            label={translateThis('written_permission_form_question_guardianZipcode')}
                                            name={`child_enrollment_form_question_emergency_responsible_${no}_guardianZipCode`}
                                            id='written_permission_form_question_guardianZipcode'
                                            value={answerGetter(`child_enrollment_form_question_emergency_responsible_${no}_guardianZipCode`, initialFormData)?.answer}
                                            translationKey='written_permission_form_question_guardianZipcode'
                                            type='text'
                                            required
                                            placeholder='Zipcode'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'written_permission_form_question_guardianZipcode',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                </Grid>
                            ))}
                            <Grid item xs={12} sm={12} md={12}>
                                <br />
                                <Button onClick={() => onAddNewItem(
                                    'written_permission_form_emergency_responsible',
                                    emergencyResponsible,
                                    setEmergencyResponsible
                                )} className='red-btn'>{translateThis("Add new Responsible adult +")}</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Emergency Medical Care")}</h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_medicalCareName`}
                                    label={translateThis('written_permission_form_question_medicalCareName')}
                                    name='written_permission_form_question_medicalCareName'
                                    id='written_permission_form_question_medicalCareName'
                                    value={answerGetter('written_permission_form_question_medicalCareName', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_medicalCareName'
                                    type='text'
                                    required
                                    placeholder={translateThis('written_permission_form_question_medicalCareName')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_medicalCareName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_medicalCarePhone`}
                                    label={translateThis('written_permission_form_question_medicalCarePhone')}
                                    name='written_permission_form_question_medicalCarePhone'
                                    id='written_permission_form_question_medicalCarePhone'
                                    value={answerGetter('written_permission_form_question_medicalCarePhone', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_medicalCarePhone'
                                    type='number'
                                    required
                                    placeholder={translateThis('written_permission_form_question_medicalCarePhone')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_medicalCarePhone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_medicalCareAddress`}
                                    label={translateThis('written_permission_form_question_medicalCareAddress')}
                                    name='written_permission_form_question_medicalCareAddress'
                                    id='written_permission_form_question_medicalCareAddress'
                                    value={answerGetter('written_permission_form_question_medicalCareAddress', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_medicalCareAddress'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_medicalCareAddress',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_medicalCareCity`}
                                    label={translateThis('written_permission_form_question_medicalCareCity')}
                                    name='written_permission_form_question_medicalCareCity'
                                    id='written_permission_form_question_medicalCareCity'
                                    value={answerGetter('written_permission_form_question_medicalCareCity', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_medicalCareCity'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_medicalCareCity',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_medicalCareZipcode`}
                                    label={translateThis('written_permission_form_question_medicalCareZipcode')}
                                    name='written_permission_form_question_medicalCareZipcode'
                                    id='written_permission_form_question_medicalCareZipcode'
                                    value={answerGetter('written_permission_form_question_medicalCareZipcode', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_medicalCareZipcode'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_medicalCareZipcode',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>Physician</h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_physicianName`}
                                    label={translateThis('written_permission_form_question_physicianName')}
                                    name='written_permission_form_question_physicianName'
                                    id='written_permission_form_question_physicianName'
                                    value={answerGetter('written_permission_form_question_physicianName', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_physicianName'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_physicianName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_physicianPhone`}
                                    label={translateThis('written_permission_form_question_physicianPhone')}
                                    name='written_permission_form_question_physicianPhone'
                                    id='written_permission_form_question_physicianPhone'
                                    value={answerGetter('written_permission_form_question_physicianPhone', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_physicianPhone'
                                    type='number'
                                    required
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_physicianPhone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_physicianAddress`}
                                    label={translateThis('written_permission_form_question_physicianAddress')}
                                    name='written_permission_form_question_physicianAddress'
                                    id='written_permission_form_question_physicianAddress'
                                    value={answerGetter('written_permission_form_question_physicianAddress', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_physicianAddress'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_physicianAddress',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_physicianCity`}
                                    label={translateThis('written_permission_form_question_physicianCity')}
                                    name='written_permission_form_question_physicianCity'
                                    id='written_permission_form_question_physicianCity'
                                    value={answerGetter('written_permission_form_question_physicianCity', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_physicianCity'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_physicianCity',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_physicianZipcode`}
                                    label={translateThis('written_permission_form_question_physicianZipcode')}
                                    name='written_permission_form_question_physicianZipcode'
                                    id='written_permission_form_question_physicianZipcode'
                                    value={answerGetter('written_permission_form_question_physicianZipcode', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_physicianZipcode'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_physicianZipcode',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Dentist")}</h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_dentistName`}
                                    label={translateThis('written_permission_form_question_dentistName')}
                                    name='written_permission_form_question_dentistName'
                                    id='written_permission_form_question_dentistName'
                                    value={answerGetter('written_permission_form_question_dentistName', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_dentistName'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_dentistName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_dentistPhone`}
                                    label={translateThis('written_permission_form_question_dentistPhone')}
                                    name='written_permission_form_question_dentistPhone'
                                    id='written_permission_form_question_dentistPhone'
                                    value={answerGetter('written_permission_form_question_dentistPhone', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_dentistPhone'
                                    type='number'
                                    required
                                    placeholder='Phone'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_dentistPhone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`written_permission_form_Key_dentistAddress`}
                                    label={translateThis('written_permission_form_question_dentistAddress')}
                                    name='written_permission_form_question_dentistAddress'
                                    id='written_permission_form_question_dentistAddress'
                                    value={answerGetter('written_permission_form_question_dentistAddress', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_dentistAddress'
                                    type='text'
                                    required
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_dentistAddress',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_dentistCity`}
                                    label={translateThis('written_permission_form_question_dentistCity')}
                                    name='written_permission_form_question_dentistCity'
                                    id='written_permission_form_question_dentistCity'
                                    value={answerGetter('written_permission_form_question_dentistCity', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_dentistCity'
                                    type='text'
                                    required
                                    placeholder='City'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_dentistCity',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextInput
                                    key={`written_permission_form_Key_dentistZipcode`}
                                    label={translateThis('written_permission_form_question_dentistZipcode')}
                                    name='written_permission_form_question_dentistZipcode'
                                    id='written_permission_form_question_dentistZipcode'
                                    value={answerGetter('written_permission_form_question_dentistZipcode', initialFormData)?.answer}
                                    translationKey='written_permission_form_question_dentistZipcode'
                                    type='text'
                                    required
                                    placeholder='Zipcode'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'written_permission_form_question_dentistZipcode',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <span>{translateThis("My family child care provider and or approved substitute have my permission to")} </span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="sampleRadioTwo">{translateThis('permission_1')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('written_permission_form_Key_canTransport', initialFormData)?.answer}
                                            name="written_permission_form_Key_canTransport"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'written_permission_form_Key_canTransport',
                                                    answer: e.target.value,
                                                    translationKey: 'written_permission_form_Key_canTransport',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="sampleRadioTwo">{translateThis('permission_2')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('written_permission_form_Key_activity', initialFormData)?.answer}
                                            name="written_permission_form_Key_activity"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'written_permission_form_Key_activity',
                                                    answer: e.target.value,
                                                    translationKey: 'written_permission_form_Key_activity',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="sampleRadioTwo">{translateThis('permission_3')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('written_permission_form_Key_permission_3', initialFormData)?.answer}
                                            name="written_permission_form_Key_permission_3"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'written_permission_form_Key_permission_3',
                                                    answer: e.target.value,
                                                    translationKey: 'written_permission_form_Key_permission_3',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={answerGetter('written_permission_form_Key_permission_3', formData)?.answer === "Yes" ? <div className='textBoxZIndex'>
                                                <TextInput
                                                    key={`written_permission_form_Key_permission_3`}
                                                    label={translateThis('written_permission_form_permission_3_hospitalName')}
                                                    name='written_permission_form_permission_3_hospitalName'
                                                    id='written_permission_form_permission_3_hospitalName'
                                                    value={answerGetter('written_permission_form_permission_3_hospitalName', initialFormData)?.answer}
                                                    translationKey='written_permission_form_permission_3_hospitalName'
                                                    type='text'
                                                    required
                                                    placeholder={translateThis('written_permission_form_permission_3_hospitalName')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'written_permission_form_permission_3_hospitalName',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />

                                            </div> : "Yes"
                                            } />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="sampleRadioTwo">{translateThis('permission_4')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('written_permission_form_Key_permission_4', initialFormData)?.answer}
                                            name="written_permission_form_Key_permission_4"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'written_permission_form_Key_permission_4',
                                                    answer: e.target.value,
                                                    translationKey: 'written_permission_form_Key_permission_4',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="sampleRadioTwo">{translateThis('permission_5')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('written_permission_form_Key_permission_5', initialFormData)?.answer}
                                            name="written_permission_form_Key_permission_5"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'written_permission_form_Key_permission_5',
                                                    answer: e.target.value,
                                                    translationKey: 'written_permission_form_Key_permission_5',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack className='panel-form box-class-form' spacing={3}>
                                    <FormControl>
                                        <FormLabel id="sampleRadioTwo">{translateThis('permission_6')}</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="Radio Role"
                                            defaultValue={answerGetter('written_permission_form_Key_permission_6', initialFormData)?.answer}
                                            name="written_permission_form_Key_permission_6"
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'written_permission_form_Key_permission_6',
                                                    answer: e.target.value,
                                                    translationKey: 'written_permission_form_Key_permission_6',
                                                    type: 'radio'
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} />

                            {/* <Grid item xs={12} sm={6} md={6}>
                                <div className='info-head f-wrp'>
                                    <span>&nbsp;</span>
                                </div>
                                <div style={{ maxWidth: '200px', float: 'right' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                value={answerGetter('written_permission_form_Key_DateOFApplication', initialFormData)?.answer ?
                                                    dayjs(answerGetter('written_permission_form_Key_DateOFApplication', initialFormData)?.answer) :
                                                    ''
                                                }
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: 'written_permission_form_Key_DateOFApplication',
                                                        answer: dayjs(v).format(DATE_FORMAT),
                                                        translationKey: 'written_permission_form_DateOFApplication',
                                                        type: 'text'
                                                    })
                                                }
                                                }
                                                label="Date" />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>

                            </Grid> */}

                            <Grid item xs={12} sm={6} md={6}>


                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <span>{translateThis("Parent Guardian signature")}</span>
                                    </div>
                                    <TextInput
                                        key={`written_permission_form_key_question_signatureName`}
                                        label={translateThis('written_permission_form_question_signatureName')}
                                        name='written_permission_form_question_signatureName'
                                        id='written_permission_form_question_signatureName'
                                        translationKey='written_permission_form_question_signatureName'
                                        type='text'
                                        required
                                        placeholder='Type your name'
                                        className='custom-textfield'
                                        getValue={(_, value) => {
                                            setSignature(value)
                                        }}
                                        useDefaultValidation
                                    />
                                    <FormGroup className='check-label'>
                                        <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("I")}, {signature && <b>{signature},</b>} {translateThis("affirm that the information")}</span>
                                        } />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </>

                <br />

                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='pln-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm() || !isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>

                    </div>
                </div>
            </div> : null}
        </>
    );
}

WrittenPermission.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default WrittenPermission;