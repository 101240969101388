import React from 'react';

import './header.scss';
import BurgerMenu from './nav-section/burger';


export default function PPPlatformAppHeader() {
    return (
        <>
            <div className='main-head-wrp pp-platformAppHeader f-wrp'>
                <div className='container'>
                    <div className='burger-prof-sec'>
                        
                        <BurgerMenu />
                        
                    </div>
                </div>
            </div>
        </>
    )

}