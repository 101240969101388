import React from 'react';
import { Stack, Typography } from '@mui/material';
import AppIcons from '../../assets/images/icons/index';
import AppLayout from '../../layouts/app/AppLayout';
import AppLogo from '../../components/logo/appLogo';
import ResetPasswordForm from './forms/resetPassword.form';



const ResetPasswordPage = () => {
    const pageName = 'Forgot password';
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            pageName={pageName}
            bodyClassName='forgotpasswrd auth-layout'
        >

            <div className='auth-page-layout f-wrp'>
                <div className='container-fluid'>
                    <div className='flex-box'>
                        <div className='form-sec-wrp'>
                            <AppLogo />
                            <div className='auth-form'>
                                <Stack className='form-tile'>
                                    <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>Reset password</Typography>
                                    {/* <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>You will receive instructions to resetting your password</Typography> */}
                                </Stack>
                                <ResetPasswordForm />
                            </div>
                        </div>
                        <div className='form-img-wrp'>
                            <span><img src={AppIcons.registerBG} alt='register' /></span>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default ResetPasswordPage;