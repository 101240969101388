import * as React from 'react';
import { useRecoilValue } from 'recoil';
import Profile from '../../profile';
import TabLayout from '../../../components/tabs';

import './index.scss';

import ProviderPortalHeader from '../components/header';
// import AccountProfileList from './accountProfileList';
import ProviderAccountDesign from './accountDesign';
import { getAuthRoleName } from '../../../helpers/auth.helper';
import { PROVIDER_ROLE } from '../../../constants/index';
import { myPortalState } from '../../../state/reducerAtoms/myPortal.atom';



const ProviderAccountTab = () => {

    const tabNames = ["Profile"];
    const { facilityInfo } = useRecoilValue(myPortalState);
    if (getAuthRoleName() === PROVIDER_ROLE && facilityInfo?.features?.ppDesign) {
        tabNames.push('Design');
    }
    const tabContents = [
    //     <span key={'to_profiles'}><AccountProfileList /></span>,
        <span key={'to_profiles'}><Profile /></span>,
        <span key={'to_dailyCheckIn'}><ProviderAccountDesign /></span>,
    ];

    return (
        <div className='accountTab-wrp'>
            <ProviderPortalHeader header='Account' />
            {/* <Button onClick={console.log('clicked')} className='red-btn' style={{ maxWidth: '275px', float: 'right', marginBottom: '0' }}>+ Create admin profile</Button> */}
            <div className='tab-sec-wrp pay-table accountTab-section f-wrp'>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
            
        </div>
    )
}

export default ProviderAccountTab;