import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Stack} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Components
import AppTable from '../../../components/table/index';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { DATE_READABLE_FORMAT, DATE_TIME_FORMAT } from '../../../constants/index';
import AppButton from '../../../components/buttons/app.button';
import TextInput from '../../../components/inputs/textInput/textInput';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';
// import { downloadDailyLog } from '../../../services/api/pdf';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';
import ModalPopUp from '../../../components/modal/modal.popup';
import { generatePDFBy } from '../../../services/api/pdf';
import ApiCaller from '../../../services/api/general';



const AccidentReportToSignTab = () => {
    const apiCaller = new ApiCaller('form-submissions');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openSubmissionDialog, setOpenSubmissionDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [signature, setSignature] = useState('');
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedPreview, setSelectedPreview] = React.useState(<></>);

    
    const onViewClose = () => {
        setViewOpen(false);
    };

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);


    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        const filter = {
            query:'isProviderSigned=true&isParentSigned=false&formName=provider_accident_form'
        }
        apiCaller.getList(filter)
            .then((data) => {
                if (data) {
                    const dataArray = data?.results || [];
                    setDataArr(dataArray)
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false));
            

    }



    /**
   * submit Daily Log
   */
    const submitDailyLog = () => {
        if (selectedId && signature) {
            setIsSubmitting(true);
            apiCaller.updateData(selectedId, {
                isParentSigned: true,
                status: 'completed',
                additionalInfo: {
                    isParentSigned: true,
                    parentSignature: signature,
                    parentSignedDate: dayjs().format(DATE_TIME_FORMAT)
                }
            })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been submitted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenSubmissionDialog(false);
                    setIsSubmitting(false);
                    setSelectedId('');
                    setSignature('');
                    getDataArr();
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }

    }

    /**
   * Download Daily Log
   */
    // const downloadDailyLogPDF = (id, logTitle) => {
    //     if (id && logTitle) {
    //         showSnackbar().info("Selected daily log will be downloaded shortly")
    //         downloadDailyLog(id, logTitle)
    //             .then(({ success, message }) => success ?
    //                 showSnackbar().success(`The selected log has been downloaded successfully.`)
    //                 : showSnackbar().warning(message)
    //             )
    //             .catch(() => showSnackbar().failure("Something went wrong!."))
    //             .finally(() => {
    //             })
    //     } else {
    //         showSnackbar().warning('Something went wrong!.');
    //     }

    // }

    /**
         * 
         * @param {string} formName 
         * @param {any} formData 
         */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }

    /**
   * Download PDF
   */
    const downloadPDF = (childId, userId, formName) => {
        if (childId && userId && formName) {
            showSnackbar().info("Selected form will be downloaded shortly")
            generatePDFBy(userId, childId, formName)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                })
        } else {
            showSnackbar().warning('Something went wrong!');
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 1.3,
            minWidth: 120,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'formTitle',
            headerName: 'Form title',
            flex: 1.3,
            minWidth: 180,
        },
        
        {
            field: 'accidentDate',
            headerName: 'Accident date',
            flex: 2,
            minWidth: 140,
            valueGetter: (params) => dayjs(params.row?.parentSignedDate).format(DATE_READABLE_FORMAT),
        },
        {
            field: 'isParentSigned',
            headerName: 'Parent',
            flex: 0.8,
            minWidth: 110,
            renderCell: (params) => params.row?.isParentSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <AppButton
                    onClick={() => {
                        setOpenSubmissionDialog(true);
                        setSelectedId(params.row?.id);
                    }}
                    btnText='Sign'
                />,
        },
        {
            field: 'isProviderSigned',
            headerName: 'Provider',
            flex: 0.8,
            minWidth: 110,
            renderCell: (params) => params.row?.isProviderSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <span className='pending'><SVGIcons.ClockCountdownIcon /> Pending</span>,
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: ({ row }) => {
                const { childId, userId, formName } = row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onFormPreviewClick(formName, row)
                    },
                    {
                        label: "Download",
                        onClick: () => {
                            downloadPDF(childId?.id, userId?.id, formName)
                        }
                    }
                ]} />
            }
        },

    ]




    return (
        <>

            <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                <Stack className='panel-form f-wrp' spacing={3}>
                    <LocalizationProvider className="custom-textfield" dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label={translateThis("Date")} />
                        </DemoContainer>
                    </LocalizationProvider>
                    <p style={{ fontSize: '12px', margin: '0', paddingLeft: '15px', color: '#4C483E' }}>{translateThis("maximumRange")}</p>
                    <br/>
                </Stack>
                <div className='panel-table-sec parent-table  f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                </div>
                {openSubmissionDialog && <ConfirmDialog
                    isOpen={openSubmissionDialog}
                    onClose={() => {
                        setOpenSubmissionDialog(false);
                        // setSelectedId('');
                        setSignature('');
                    }}
                    title={`Form submission signature`}
                    className={'delete-popup'}
                    description={<div>
                        <TextInput
                            key={`parent_signature`}
                            label={translateThis('typeYourName')}
                            name='parent_signature'
                            id='parent_signature'
                            value={signature}
                            type='text'
                            required
                            placeholder={translateThis('typeYourName')}
                            className='custom-textfield'
                            getValue={(_, value) => {
                                setSignature(value)
                            }}
                            useDefaultValidation
                        />
                        <br />
                        <span>
                        {translateThis("maximumRangeText")}<span style={{ color: '#FF3D00' }}> {translateThis("This action cannot be undone")}</span>
                        </span>
                    </div>}
                    okayButtonText={'Sign form'}
                    confirmButton={
                        <AppButton
                            onClick={submitDailyLog}
                            isDisabled={signature.length < 2}
                            btnText={translateThis('signForm')}
                            isLoading={isSubmitting}
                        />
                    }
                />}
            </div>
            {viewOpen ? <ModalPopUp
                className='preview-popup'
                isOpen={viewOpen}
                onClose={onViewClose}
                aria-labelledby="table-view"
                aria-describedby="table-view"
            >
                {selectedPreview}
                <Button onClick={onViewClose} className='fill-btn'>{translateThis("close")}</Button>
            </ModalPopUp> : null}
        </>
    )
}


export default AccidentReportToSignTab;