import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AppIcons from '../images/icons/index';

import './Services.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Services() {


  const aboutItems = [
    {
      className: 'foodProgram',
      icon: AppIcons.foodProgram,
      title: translateThis('Food program'),
      description: translateThis('Saugatuck Outreach'),
    },
    {
      className: 'medicalSupport',
      icon: AppIcons.medicalSupport,
      title: translateThis('Medical support'),
      description: translateThis('CPR, Medical Assistance'),
    },
    {
      className: 'specialNeeds',
      icon: AppIcons.specialNeeds,
      title: translateThis('Special needs'),
      description: translateThis('We take care of children with special circumstances'),
    },
    {
      className: 'bilingual',
      icon: AppIcons.bilingual,
      title: translateThis('Bilingual'),
      description: translateThis('English Spanish'),
    },
    
    
  ];

  return (
    <>
      <div className="service-sec-wrp YreneTemplate f-wrp">

        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={4}
          className='content-sec-mob'
          navigation
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          
        >
          {aboutItems.map((element, key) => (
            <SwiperSlide key={key}>
              <div className={`${element.className} each-service-sec f-wrp `}>
                <span className='service-icon'><img src={element.icon} alt={element.title} /></span>
                <h3>{element.title}</h3>
                <p>{element.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default Services;