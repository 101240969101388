import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AppIcons from '../images/icons/index';

import './Services.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Services() {


  const aboutItems = [
    {
      className: 'ehsccp',
      icon: AppIcons.ehsccp,
      title: 'EHSCCP',
      description: 'We follow the Early Head Start Child Care Partnership that nurtures young minds ',
    },
    {
      className: 'subsidizedCare',
      icon: AppIcons.subsidizedCare,
      title: 'SUBSIDIZED CARE',
      description: 'Providing affordable and accessible care for all through subsidized support',
    },
    {
      className: 'bilingual',
      icon: AppIcons.bilingual,
      title: 'BILINGUAL',
      description: 'Fostering growth and learning in two languages through our bilingual program',
    },
    {
      className: 'medicalAid',
      icon: AppIcons.medicalAid,
      title: 'MEDICAL AID',
      description: 'Certified in CPR, equipped to deliver immediate emergency care',
    },
    {
      className: 'specialNeeds',
      icon: AppIcons.specialNeeds,
      title: 'SPECIAL NEEDS',
      description: 'Catering to diverse needs with specialized support and understanding',
    },
    {
      className: 'playground',
      icon: AppIcons.playground,
      title: 'PLAYGROUND',
      description: 'Our playground is not just a space for fun, but also a Montessori-inspired haven',
    },
    {
      className: 'ehsccp',
      icon: AppIcons.ehsccp,
      title: 'EHSCCP',
      description: 'We follow the Early Head Start Child Care Partnership that nurtures young minds ',
    },
    {
      className: 'subsidizedCare',
      icon: AppIcons.subsidizedCare,
      title: 'SUBSIDIZED CARE',
      description: 'Providing affordable and accessible care for all through subsidized support',
    },
    {
      className: 'bilingual',
      icon: AppIcons.bilingual,
      title: 'BILINGUAL',
      description: 'Fostering growth and learning in two languages through our bilingual program',
    },
    {
      className: 'medicalAid',
      icon: AppIcons.medicalAid,
      title: 'MEDICAL AID',
      description: 'Certified in CPR, equipped to deliver immediate emergency care',
    },
    {
      className: 'specialNeeds',
      icon: AppIcons.specialNeeds,
      title: 'SPECIAL NEEDS',
      description: 'Catering to diverse needs with specialized support and understanding',
    },
    {
      className: 'playground',
      icon: AppIcons.playground,
      title: 'PLAYGROUND',
      description: 'Our playground is not just a space for fun, but also a Montessori-inspired haven',
    },
  ];

  return (
    <>
      <div className="service-sec-wrp angelica f-wrp">

        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={4}
          className='content-sec-mob'
          navigation
          loop
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          
        >
          {aboutItems.map((element, key) => (
            <SwiperSlide key={key}>
              <div className={`${element.className} each-service-sec f-wrp `}>
                <span className='service-icon'><img src={element.icon} alt={element.title} /></span>
                <h3>{translateThis(element.title)}</h3>
                <p>{translateThis(element.description)}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>



      </div>






    </>
  );
}

export default Services;