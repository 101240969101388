import React from 'react';
// State management
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AppIcons from '../../../assets/images/icons/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';

import '../index.scss';
import { myPortalState } from '../../../state/reducerAtoms/myPortal.atom';
import LogoutButton from '../../../components/buttons/logout.button';
import { EMPLOYEE_ROLE, PROVIDER_ROLE } from '../../../constants/index';
import { getAuthRoleName } from '../../../helpers/auth.helper';
import { translateThis } from '../../../helpers/language.helper';




const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
        width: 0,
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const ProviderPortalDrawer = () => {
    const theme = useTheme();
    const [myPortalInitialState, setMyPortalState] = useRecoilState(myPortalState);
    const { facilityInfo = {} } = myPortalInitialState;
    const { features = {} } = facilityInfo;


    /**
     * Invokes when drawer open
     * @returns Fun
     */
    const handleDrawerOpen = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: true } }));

    /**
     * Invokes when drawer close
     * @returns Fun
     */
    const handleDrawerClose = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: false } }));

    /**
     * Invokes when user click a nav item
     * Passes the selected nav to my portal
     * @param {Object} item selected nav item
     */
    const onSelectNavItem = (item) => setMyPortalState((prv) => ({ ...prv, ...{ selectedNavItem: item.id, pageHeader: item.pageHeader, tutorialLink: item.tutorialLink } }))


    const myNavItems = [
        {
            title: 'Attendance',
            pageHeader: 'Attendance',
            allowedOnly: [PROVIDER_ROLE],
            featureName: 'kidCheckIn',
            id: 'kidCheckIn',
            icon: SVGIcons.ClockIcon(),
            link: '/my-portal'
        },
        {
            title: 'Clock In',
            pageHeader: 'Clock In',
            allowedOnly: [EMPLOYEE_ROLE],
            featureName: 'employee',
            id: 'clockIn',
            icon: SVGIcons.ClockIcon(),
            link: '/my-portal'
        },
        {
            title: 'Dashboard',
            pageHeader: 'Dashboard',
            id: 'dashboard',
            allowedOnly: [PROVIDER_ROLE],
            featureName: 'dashboard',
            icon: SVGIcons.DashboardIcon(),
            link: '/my-portal'
        },
        {
            title: 'Inbox',
            pageHeader: 'Inbox',
            allowedOnly: [PROVIDER_ROLE],
            id: 'inbox',
            featureName: 'inbox',
            icon: SVGIcons.InboxIcon(),
            link: '/my-portal'
        },
        {
            title: 'Parents',
            pageHeader: 'Parents',
            tutorialLink: 'https://www.youtube.com/watch?v=IJhT-pyPP-I',
            id: 'parents',
            allowedOnly: [PROVIDER_ROLE, EMPLOYEE_ROLE],
            featureName: 'parents',
            icon: SVGIcons.ParentIcon(),
            link: '/my-portal'
        },
        {
            title: 'Children',
            pageHeader: 'Children',
            allowedOnly: [PROVIDER_ROLE],
            id: 'children',
            featureName: 'kidCheckIn',
            icon: SVGIcons.ParentIcon(),
            link: '/my-portal'
        },
        {
            title: 'Daily comms',
            pageHeader: 'Daily comms',
            tutorialLink: 'https://youtu.be/wiwXc29RyS0',
            id: 'logs',
            featureName: 'dailyCommunication',
            icon: SVGIcons.CatalogIcon(),
            allowedOnly: [PROVIDER_ROLE, EMPLOYEE_ROLE],
            link: '/my-portal'
        },
        {
            title: 'Forms',
            pageHeader: 'Forms',
            tutorialLink: 'https://youtu.be/DCXSXTCigls',
            id: 'forms',
            allowedOnly: [PROVIDER_ROLE, EMPLOYEE_ROLE],
            featureName: 'forms',
            icon: SVGIcons.FormsIcon(),
            link: '/my-portal'
        },
        {
            title: 'Accident',
            pageHeader: 'Accident',
            tutorialLink: 'https://youtu.be/R0npuCCHARg',
            id: 'accident',
            allowedOnly: [PROVIDER_ROLE],
            featureName: 'accident',
            icon: SVGIcons.AccidentIcon(),
            link: '/my-portal'
        },
        {
            title: 'Payments',
            pageHeader: 'Payments',
            id: 'payments',
            allowedOnly: [PROVIDER_ROLE],
            featureName: 'payment',
            icon: SVGIcons.PaymentsIcon(),
            link: '/my-portal'
        },
        // {
        //     title: 'Pricing',
        //     pageHeader: 'Pricing',
        //     id: 'pricing',
        //     featureName: 'pricing',
        //     icon: SVGIcons.PricingIcon(),
        //     link: '/my-portal'
        // },        
        {
            title: 'Bulletin',
            pageHeader: 'Bulletin',
            tutorialLink: 'https://youtu.be/_jhzvgOWARI',
            id: 'bulletin',
            allowedOnly: [PROVIDER_ROLE, EMPLOYEE_ROLE],
            featureName: 'bulletin',
            icon: SVGIcons.BulletinIcon(),
            link: '/my-portal'
        },
        {
            title: 'Policies',
            pageHeader: 'Policies',
            tutorialLink: 'https://youtu.be/neYRZWg6RwQ',
            id: 'policies',
            allowedOnly: [PROVIDER_ROLE],
            featureName: 'policies',
            icon: SVGIcons.RuleIcon(),
            link: '/my-portal'
        },
        {
            title: 'Employees',
            pageHeader: 'Employees',
            tutorialLink: 'https://youtu.be/ipzLvyUH3J8',
            id: 'employees',
            allowedOnly: [PROVIDER_ROLE],
            featureName: 'employee',
            icon: SVGIcons.EmployeesIcon(),
            link: '/my-portal'
        },
        {
            title: 'tutorials',
            pageHeader: 'Tutorials',
            className: 'tutorials',
            id: 'tutorials',
            allowedOnly: [PROVIDER_ROLE],
            featureName: 'employee',
            icon: SVGIcons.TutorialIcon(),
            link: '/my-portal'
        },
        {
            title: 'Account',
            pageHeader: 'Account',
            id: 'accountList',
            allowedOnly: [PROVIDER_ROLE, EMPLOYEE_ROLE],
            featureName: 'account',
            icon: SVGIcons.ProfileIcon(),
            link: '/my-portal'
        },

    ].filter((item) => item.allowedOnly.includes(getAuthRoleName()));;

    const loginMenu = [
        {
            title: 'Logout',
            className: 'logout',
            icon: SVGIcons.LogoutIcon(),
            link: '/my-portal'
        },
    ];

    return (
        <>
            <DrawerHeader className='logo-sec-wrp mob-drawer-btn'>
                {myPortalInitialState.isDrawerOpen ? <>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <SVGIcons.BurgerMenuIcon />}
                    </IconButton></> : <><IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(myPortalInitialState.isDrawerOpen && { display: 'none' }),
                        }}
                        style={{ margin: '0', width: '100%' }}
                    >
                        <SVGIcons.BurgerMenuIcon />
                    </IconButton></>}


            </DrawerHeader>
            <Drawer variant="permanent" open={myPortalInitialState.isDrawerOpen} className={myPortalInitialState.isDrawerOpen ? 'drawer-open provider-drawer' : 'drawer-close provider-drawer'}>
                <div>
                    <DrawerHeader className='logo-sec-wrp'>
                        {myPortalInitialState.isDrawerOpen ? <>
                            <Link to={'#'} className='logo-img'><img src={facilityInfo?.facilityLogo?.tempURL || AppIcons.logo} alt='logo' /> </Link>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton></> : <><IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(myPortalInitialState.isDrawerOpen && { display: 'none' }),
                                }}
                                style={{ margin: '0', width: '100%' }}
                            >
                                <SVGIcons.BurgerMenuIcon />
                            </IconButton></>}


                    </DrawerHeader>
                    <List>
                        {myNavItems.filter((item) => features[item.featureName] === true).map((element, key) => (
                            <ListItem
                                className={myPortalInitialState.selectedNavItem === element.id ? 'active' : ''}
                                disablePadding sx={{ display: 'block' }}
                                onClick={() => onSelectNavItem(element)}
                                key={key}>
                                <ListItemButton className={`listButton ${element.id}`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                    <Button className='MobCloseBtn' onClick={handleDrawerClose} />
                                    <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                        <span>{element.icon}</span>
                                    </ListItemIcon>
                                    <ListItemText primary={translateThis(element.title)} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>
                <div>
                    <Divider />
                    <List>
                        {loginMenu.map((element, key) => (
                            <ListItem disablePadding sx={{ display: 'block' }} key={key}>
                                <ListItemButton className={`${element.className} listButton`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                    <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                        <span>{element.icon}</span>
                                    </ListItemIcon>
                                    <ListItemText primary={<LogoutButton />} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
            <Button className='closeBackBtn' onClick={handleDrawerClose} />
        </>

    );
}

export default ProviderPortalDrawer;