import * as React from 'react';
import TabLayout from '../../../components/tabs';

import './index.scss';

import ProviderPortalHeader from '../components/header';
import ClockAttendanceList from './clockAttendanceList';
import ClockInDailyEntry from './clockInDailyEntry';

const ChildrenAttendance = () => {
    
    const tabNames = ["Daily","Attendance"];
    const tabContents = [
        <span key={'to_daily'}><ClockInDailyEntry /></span>,        
        <span key={'to_attendance'}><ClockAttendanceList /></span>,
    ];

    return (
        <><ProviderPortalHeader header='Attendance' />
            <div className='tab-sec-wrp pay-table clockInTab-section f-wrp'>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
        </>
    )
}

export default ChildrenAttendance;